import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Environment } from './Environment';
import { ProductManager } from '../services/product-manager.service';
import { SingleProductAPIResponse, SingleProductDeploymentPartAPIResponse } from '../services/single-product-api-response';

@Injectable()
export class ProductDataService implements OnDestroy {
  public isLoadingDataChange: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  public productId!: string;
  public name!: string;
  public summary!: string;
  public logoUrl!: string;
  public screenshotsUrls!: string[];
  public tags!: {
    id: string;
    name: string;
  }[];
  public description!: string;
  public environments!: Environment[];

  public activityScore: number = 0;
  public codeQualityScore: number = 0;
  public contributorScore: number = 0;
  public scarmScore: number = 0;
  public vulnerabilityScore: number = 0;
  public languageCoverageScore: number = 0;

  public lastTrends: {
    scarm: number;
    codeQuality: number;
    contributor: number;
    activity: number;
    vulnerability: number;
    languageCoverage: number;
  } = {
    scarm: 0,
    codeQuality: 0,
    contributor: 0,
    activity: 0,
    vulnerability: 0,
    languageCoverage: 0,
  };

  public version: string = '';
  public manualProductVersion?: string;
  public releaseDate: Date | undefined;
  public creationDate: Date | undefined;
  public lastActivityDate?: Date | undefined;
  public sourceUrl: string = '';

  public totalLinesOfCode: number = 0;

  public navLinks: {
    name: string;
    href: string;
  }[] = [];

  public programmingLanguages: {
    name: string;
    value: number;
  }[] = [];

  public contributions: {
    name: string;
    value: number;
  }[] = [];

  public rawContributions: Record<string, number> = {};

  public totalContributions: number = 0;

  public defaultEnvironment!: Environment;

  public licenses: { licenseName: string; licenseUrl: string }[] = [];

  constructor(
    private route: ActivatedRoute,
    private productManager: ProductManager,
  ) {
    this.fetchData();
  }

  private mapDeployment(
    deployment: SingleProductDeploymentPartAPIResponse,
  ): Environment {
    return {
      id: deployment.id,
      creationDate: deployment.creationDate
        ? new Date(deployment.creationDate)
        : undefined,
      default: deployment.default,
      lastActivityDate: deployment.lastActivityDate
        ? new Date(deployment.lastActivityDate)
        : undefined,
      maintainer: deployment.maintainer,
      name: deployment.name,
      releaseDate: deployment.releaseDate
        ? new Date(deployment.releaseDate)
        : undefined,
      scores: deployment.scores,
      lastTrends: deployment.lastTrends,
      sourceUrl: deployment.sourceUrl,
      sourceType: deployment.sourceType,
      traits: deployment.traits,
      type: deployment.type,
      linesOfCode: deployment.linesOfCode,
      documentation: deployment.documentations,
      instructions: deployment.instructions,
      typeName: deployment.typeName,
      version: deployment.version,
      contributionsTotal: deployment.contributions
        ? deployment.contributions['_SUM']
        : 0,
      rawContributions: deployment.contributions,
      contributions: (() => {
        if (!deployment.contributions) return;

        const contributions = [];

        for (const [name, value] of Object.entries(deployment.contributions)) {
          if (name === '_SUM') continue;
          let inserted = false;

          const element = {
            name,
            value,
          };

          for (let i = 0; i < contributions.length; i++) {
            if (value > contributions[i].value) {
              inserted = true;
              contributions.splice(i, 0, element);
              break;
            }
          }

          if (!inserted) contributions.push(element);
        }

        return contributions;
      })(),
      downloadUrl: deployment.downloadUrl,
      followersCount: deployment.followersCount,
      contributorsCount: deployment.contributorsCount,
      programmingLanguages: deployment.programmingLanguages?.sort(
        (a, b) => b.value - a.value,
      ),
      starsCount: deployment.starsCount,
      commitsCount: deployment.commitsCount,
      openIssuesCount: deployment.openIssuesCount,
      closedIssuesCount: deployment.closedIssuesCount,
    };
  }

  private getData(
    componentData: SingleProductAPIResponse['component'],
    deployments: SingleProductDeploymentPartAPIResponse[],
  ) {
    const defaultDeployment = (this.defaultEnvironment = this.mapDeployment(
      deployments.find((deployment) => deployment.default)!,
    ));

    if (componentData) {
      this.scarmScore = componentData.scores.scarm;
      this.activityScore = componentData.scores.activity;
      this.codeQualityScore = componentData.scores.codeQuality;
      this.contributorScore = componentData.scores.contributor;
      this.vulnerabilityScore = componentData.scores.vulnerability;
      this.languageCoverageScore = componentData.scores.languageCoverage;
      this.lastTrends = componentData.lastTrends;
    } else {
      this.scarmScore = defaultDeployment.scores.scarm;
      this.activityScore = defaultDeployment.scores.activity;
      this.codeQualityScore = defaultDeployment.scores.codeQuality;
      this.contributorScore = defaultDeployment.scores.contributor;
      this.vulnerabilityScore = defaultDeployment.scores.vulnerability;
      this.languageCoverageScore = defaultDeployment.scores.languageCoverage;
      this.lastTrends = defaultDeployment.lastTrends;
    }

    this.version = defaultDeployment.version;
    this.releaseDate =
      (componentData?.releaseDate
        ? new Date(componentData?.releaseDate)
        : undefined) ?? defaultDeployment.releaseDate;
    this.creationDate =
      (componentData?.creationDate
        ? new Date(componentData?.creationDate)
        : undefined) ?? defaultDeployment.creationDate;
    this.lastActivityDate =
      (componentData?.lastActivityDate
        ? new Date(componentData?.lastActivityDate)
        : undefined) ?? defaultDeployment.lastActivityDate;
    this.programmingLanguages =
      componentData?.programmingLanguages ??
      defaultDeployment.programmingLanguages;
    for (const langauage of this.programmingLanguages) {
      this.totalLinesOfCode += langauage.value;
    }
    this.rawContributions =
      componentData?.contributions ?? defaultDeployment.rawContributions;
    this.contributions = ((contributions: Record<string, number>) => {
      const newContributions: any[] = [];

      for (const [name, value] of Object.entries(contributions)) {
        if (name === '_SUM') continue;
        let inserted = false;

        this.totalContributions += value;

        const element = {
          name,
          value,
        };

        for (let i = 0; i < newContributions.length; i++) {
          if (value > newContributions[i].value) {
            inserted = true;
            newContributions.splice(i, 0, element);
            break;
          }
        }

        if (!inserted) newContributions.push(element);
      }

      return newContributions;
    })(componentData?.contributions ?? defaultDeployment.rawContributions);
  }

  private async fetchData() {
    this.isLoadingDataChange.next(true);
    const productId = (await firstValueFrom(this.route.paramMap)).get(
      'product_id',
    );

    if (!productId) throw new Error(`CANT_GET_PRODUCT_ID`);

    const productData = await this.productManager.getProduct(productId);

    this.productId = productId;
    this.name = productData.name;
    this.summary = productData.summary;
    this.description = productData.description;
    this.logoUrl = productData.logoUrl;
    this.screenshotsUrls = productData.screenshotsUrls;
    this.tags = productData.tags;
    this.navLinks = productData.navLinks;

    this.environments = productData.deployments.map((deployment) =>
      this.mapDeployment(deployment),
    );

    this.environments = productData.deployments.map((deployment) => this.mapDeployment(deployment));
    this.licenses = productData.licenses;

    this.manualProductVersion = productData.productVersion;

    this.getData(productData.component, productData.deployments);

    this.isLoadingDataChange.next(false);
  }

  ngOnDestroy(): void {}
}
