import { Component, OnInit } from '@angular/core';
import { LikesManager } from 'src/app/services/likes-manager.service';
import { LikeType } from 'src/app/services/LikeType';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
  public hasFavoriteItems: boolean = false;

  public productLikes: {
    id: string;
    likeId: string;
    name: string;
    scarmScore: number;
    lastScarmTrend: number;
    summary: string;
  }[] = [];

  public deploymentLikes: {
    id: string;
    likeId: string;
    name: string;
    scarmScore: number;
    lastScarmTrend: number;
    summary: string;
    productId: string;
  }[] = [];

  public componentLikes: {
    id: string;
    likeId: string;
    name: string;
    scarmScore: number;
    lastScarmTrend: number;
    summary: string;
  }[] = [];

  constructor(
    private likesManager: LikesManager,
  ) {}

  private calculateHasFavoriteItems() {
    this.hasFavoriteItems = this.productLikes.length > 0 || this.deploymentLikes.length > 0 || this.componentLikes.length > 0;
  }

  public async removeProductLike(entityId: string, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    await this.likesManager.removeLike(entityId);
    const index = this.productLikes.findIndex((product) => product.id === entityId);
    this.productLikes.splice(index, 1);
    this.calculateHasFavoriteItems();
  }

  public async removeDeploymentLike(entityId: string, event: MouseEvent){
    event.preventDefault();
    event.stopPropagation();
    await this.likesManager.removeLike(entityId);
    const index = this.deploymentLikes.findIndex((deployment) => deployment.id === entityId);
    this.deploymentLikes.splice(index, 1);
    this.calculateHasFavoriteItems();
  }

  public async removeComponentLike(entityId: string, event: MouseEvent){
    event.preventDefault();
    event.stopPropagation();
    await this.likesManager.removeLike(entityId);
    const index = this.componentLikes.findIndex((component) => component.id === entityId);
    this.componentLikes.splice(index, 1);
    this.calculateHasFavoriteItems();
  }

  public async ngOnInit() {
    const productLikesPromise = this.likesManager.getLikes(0, 10, LikeType.PRODUCT);
    const deploymentLikesPromise = this.likesManager.getLikes(0, 10, LikeType.DEPLOYMENT);
    const componentLikesPromise = this.likesManager.getLikes(0, 10, LikeType.COMPONENT);
    const [
      productLikes, deploymentLikes, componentLikes
    ] = await Promise.all([productLikesPromise, deploymentLikesPromise, componentLikesPromise]);

    this.productLikes = productLikes;
    this.deploymentLikes = deploymentLikes;
    this.componentLikes = componentLikes;

    this.hasFavoriteItems = this.productLikes.length > 0 || this.deploymentLikes.length > 0 || this.componentLikes.length > 0;
  }
}
