import { Injector } from "@angular/core";
import { TranslateLoader, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

export class SseTranslationLoader implements TranslateLoader {

  private observable: Record<string, Observable<any>>;
  private eventSource: Record<string, EventSource>;

  constructor(
    private httpAddress: string,
    private injector: Injector,
  ) {
    this.observable = {};
    this.eventSource = {};
  }

  getTranslation(lang: string): Observable<any> {
    if(this.observable[lang]) return this.observable[lang];

    let eventSource = this.eventSource[lang];

    if(!eventSource) {
      eventSource = this.eventSource[lang] = new EventSource(`${this.httpAddress}${lang}`);
    }

    return this.observable[lang] = new Observable(observer => {
      eventSource.onmessage = (event) => {
        const translateService: TranslateService = this.injector.get(TranslateService);

        const messageData: any = JSON.parse(event.data);
        observer.next(messageData);
        translateService.setTranslation(lang, messageData);
      }
    });
    
  }
}