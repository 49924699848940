<div style="background-color: #DEEBFB" class="flex flex-row justify-content-center sticky top-0 z-5 transition-duration-200" #stickyMenu>
    <div class="container flex-grow-1 flex flex-row flex-nowrap align-content-center justify-content-between relative">
        <div class="flex flex-row overflow-x-auto flex-nowrap w-screen lg:w-auto flex-shrink-0">
            <div class="flex lg:hidden align-items-center ml-2">
                <install-dropdown [environments]="productData.environments" *ngIf="!isLoading"></install-dropdown>
                <p-skeleton width="8.75rem" height="2.75rem" *ngIf="!isInstallOnPageVisible && isLoading"></p-skeleton>
            </div>
            <a routerLink="deployments" pRipple class="font-bold p-4 flex flex-shrink-0 align-items-center no-underline text-black-black-100" routerLinkActive="active-link">
                <i class="icon-16 icon-server bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2 white-space-nowrap">{{ 'CLIENT.PRODUCT.NAV_BAR.DEPLOYMENTS' | translate }}</span>
            </a>
            <a routerLink="description" pRipple class="font-bold p-4 flex flex-shrink-0 align-items-center no-underline text-black-black-100" routerLinkActive="active-link">
                <i class="icon-16 icon-justify-right bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2 white-space-nowrap">{{ 'CLIENT.PRODUCT.NAV_BAR.DESCRIPTION' | translate }}</span>
            </a>
            <a routerLink="documentation" pRipple class="font-bold p-4 flex flex-shrink-0 align-items-center no-underline text-black-black-100" routerLinkActive="active-link">
                <i class="icon-16 icon-document bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2 white-space-nowrap">{{ 'CLIENT.PRODUCT.NAV_BAR.DOCUMENTATION' | translate }}</span>
            </a>
            <a routerLink="report" pRipple class="font-bold p-4 flex flex-shrink-0 align-items-center no-underline text-black-black-100" routerLinkActive="active-link">
                <i class="icon-16 icon-pie-chart bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2 white-space-nowrap">{{ 'CLIENT.PRODUCT.NAV_BAR.REPORTS' | translate }}</span>
            </a>
            <a routerLink="support" pRipple class="font-bold p-4 flex flex-shrink-0 align-items-center no-underline text-black-black-100" routerLinkActive="active-link">
                <i class="icon-16 icon-support bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2 white-space-nowrap">{{ 'CLIENT.PRODUCT.NAV_BAR.SUPPORT' | translate }}</span>
            </a>
            <ng-container *ngFor="let link of productData.navLinks">
                <a *ngIf="link.href.startsWith('/')" [routerLink]="link.href" pRipple class="font-bold p-4 flex flex-shrink-0 align-items-center no-underline text-black-black-100 gap-2">
                    <i class="pi pi-link text-grey-dark text-md"></i>
                    <span>
                        {{ link.name | translate }}
                    </span>
                </a>
                <a *ngIf="link.href.startsWith('http')" [href]="link.href" target="_blank" pRipple class="font-bold p-4 flex flex-shrink-0 align-items-center no-underline text-black-black-100 gap-2">
                    <i class="pi pi-external-link text-grey-dark text-sm"></i>
                    <span>
                        {{ link.name | translate }}
                    </span>
                </a>
            </ng-container>
            <!-- <a routerLink="similar" pRipple class="font-bold p-4 flex align-items-center no-underline text-black-black-100" routerLinkActive="active-link">
                <i class="icon-16 icon-similar bg-grey-dark" routerLinkActive="active-symbol"></i>
                <span class="ml-2">{{ 'CLIENT.PRODUCT.NAV_BAR.SIMILAR' | translate }}</span>
            </a> -->
        </div>
        <div class="hidden lg:flex relative align-items-center">
            <install-dropdown [environments]="productData.environments" *ngIf="!isInstallOnPageVisible && !isLoading"></install-dropdown>
            <p-skeleton width="8.75rem" height="2.75rem" *ngIf="!isInstallOnPageVisible && isLoading"></p-skeleton>
        </div>
    </div>
</div>

<div class="flex flex-row justify-content-center pt-4 md:pt-8 padding-bottom-160">
    <div class="container flex-grow-1 flex flex-column md:flex-row px-4 lg:px-0 align-content-center align-items-start gap-24 min-w-0">

        <div class="p-5 border-1 border-blue-light-hover border-round-lg sidebar hidden lg:flex flex-column flex-shrink-0 flex-wrap" *ngIf="isLoading">
            <p-skeleton styleClass="w-full h-12rem" class="w-full"></p-skeleton>
            <div class="flex flex-row justify-content-between mt-5">
                <div class="flex flex-column align-items-center gap-1">
                    <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
                    <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
                </div>
                <div class="flex flex-column align-items-center gap-1">
                    <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
                    <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
                </div>
                <div class="flex flex-column align-items-center gap-1">
                    <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
                    <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium text-base">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="flex mt-px-12">
                    <p-skeleton styleClass="w-7 h-2rem" class="w-7 h-2rem"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="flex flex-column mt-4">
                    <div *ngFor="let item of [0, 0, 0, 0]" class="flex flex-row align-items-center mb-3 gap-2">
                        <p-skeleton size="1.75rem"></p-skeleton>
                        <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="gap-3 flex align-items-center align-content-center align-self-stretch flex-wrap mt-3">
                    <p-skeleton *ngFor="let tag of [6, 10, 10, 6, 6, 10, 10, 6]" width="{{tag}}rem" height="2rem"></p-skeleton>
                </div>
            </div>
        </div>

        <div class="p-5 border-1 border-blue-light-hover border-round-lg sidebar hidden lg:flex flex-column flex-shrink-0 flex-wrap" *ngIf="!isLoading">
            <div class="p-3 rating-general min-w-0 max-w-full overflow-x-hidden">
                <ng-template [ngTemplateOutlet]="mainRating"></ng-template>
            </div>
            <ng-template [ngTemplateOutlet]="sidebar"></ng-template>
        </div>

        <div class="flex flex-column max-w-full w-full min-w-0">

            <div class="flex flex-column lg:hidden p-3 border-round-lg bg-white mb-6 sm:w-6">
                <ng-template [ngTemplateOutlet]="mainRating"></ng-template>
            </div>

            <div class="flex flex-row align-items-center justify-content-between">
                <p-skeleton width="16rem" height="4rem" *ngIf="isLoading"></p-skeleton>
                <span class="font-bold text-6xl text-blue-darker" *ngIf="!isLoading">{{ productData.name | translate }}</span>
                <div #installOnPage class="hidden lg:block relative lg:z-5">
                    <p-skeleton width="8.75rem" height="2.75rem" *ngIf="isLoading"></p-skeleton>
                    <install-dropdown [environments]="productData.environments" *ngIf="isInstallOnPageVisible && !isLoading"></install-dropdown>
                </div>
            </div>
            <div class="mt-5">
                <ng-container *ngIf="isLoading">
                    <p-skeleton styleClass="w-11" height="2rem"></p-skeleton>
                    <p-skeleton styleClass="w-5 mt-2" height="2rem"></p-skeleton>
                </ng-container>
                <span class="text-grey-dark text-xl font-semibold line-height-3" *ngIf="!isLoading">
                    {{ productData.summary | translate }}
                </span>
            </div>
            <button pRipple type="button" class="flex lg:hidden flex-row gap-2 align-items-center justify-content-center max-w-full w-full border-round-lg border-1 border-blue-normal bg-white font-semibold text-base px-4 py-3 text-blue-normal mt-4" (click)="sidebarVisible = !sidebarVisible">
                <i class="icon-16 icon-document bg-blue-normal flex-shrink-0"></i>
                <span class="min-w-0 flex-1 text-overflow-clip overflow-hidden">
                    {{ 'CLIENT.PRODUCT.SIDEBAR.BUTTON.SHOW_DETAILS' | translate }}
                </span>
            </button>
            <router-outlet class="flex flex-row w-full"></router-outlet>
        </div>

    </div>
</div>

<p-sidebar [(visible)]="sidebarVisible" position="left" styleClass="bg-special-background min-w-0" [modal]="true" [autoZIndex]="true" [baseZIndex]="10000" [fullScreen]="true">
    <ng-template pTemplate="header">
        <div class="flex flex-row overflow-x-hidden min-w-0 mr-4">
            <span class="font-semibold text-xl">
                {{ 'CLIENT.PRODUCT.SIDEBAR.SIDEBAR.DETAILS_TITLE' | translate }}
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="content">
        <ng-template [ngTemplateOutlet]="sidebar"></ng-template>
    </ng-template>
</p-sidebar>
<!-- TEMPLATES -->

<ng-template #mainRating>
    <p class="text-base font-bold flex align-items-center text-blue-darker m-0">
        <img title="{{ 'CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING' | translate }}" src="assets/round_star.svg" />
        <span class="pl-2">
            {{ 'CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING' | translate }}
        </span>
    </p>
    <div class="mt-3 flex flex-row align-items-center lg:justify-content-evenly gap-2">
        <div>
            <app-score-circle
                helpText="CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING.HELP_TEXT"
              [score] = "productData.scarmScore"
              [trend] = "productData.lastTrends.scarm"
            ></app-score-circle>
        </div>
        <div *ngIf="(productData.scarmScore * 10) >= 6" class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
         [class.bg-severity-low]="(productData.scarmScore * 10) >= 6 && (productData.scarmScore * 10) < 8"
         [class.bg-severity-no-risk]="(productData.scarmScore * 10) >= 8 && (productData.scarmScore * 10) < 10"
        >
        <span class="text-white" *ngIf="(productData.scarmScore * 10) >= 6 && (productData.scarmScore * 10) < 8">
            {{ 'CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING.SEVERITY.LOW' | translate }}
        </span>
        <span class="text-white" *ngIf="(productData.scarmScore * 10) >= 8 && (productData.scarmScore * 10) < 10">
            {{ 'CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING.SEVERITY.SAFE' | translate }}
        </span>
      </div>
    </div>
</ng-template>

<ng-template #sidebar>
    <div class="flex flex-row justify-content-between mt-5 flex-wrap">
        <ng-container *ngFor="let scoreName of scoresOrder; index as i;">
            <div *ngIf="i < 3; else showMore"  class="flex flex-column align-items-center">
                <span class="font-medium text-xs text-grey-dark-hover">
                    {{ 'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING' | translate }}
                </span>
                <app-score-circle
                    [helpText]="'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING.HELP_TEXT'"
                    [score] = "scoresWithTrends[scoreName][0]"
                    [trend] = "scoresWithTrends[scoreName][1]"
                    [radius]="32"
                    [outerStrokeColor]="'#BBD6F7'"
                    [outerStrokeGradientStopColor]="'#78ADED'"
                ></app-score-circle>
            </div>
            <ng-template #showMore>
                <div class="flex flex-column align-items-center" style="margin: 0 auto;" *ngIf="displayAllScores">
                    <span class="font-medium text-xs text-grey-dark-hover">
                        {{ 'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING' | translate }}
                    </span>
                    <app-score-circle
                        [helpText]="'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING.HELP_TEXT'"
                        [score] = "scoresWithTrends[scoreName][0]"
                        [trend] = "scoresWithTrends[scoreName][1]"
                        [radius]="32"
                        [outerStrokeColor]="'#BBD6F7'"
                        [outerStrokeGradientStopColor]="'#78ADED'"
                    ></app-score-circle>
                </div>
            </ng-template>
        </ng-container>
    </div>
    <div class="mt-px-12" *ngIf="!displayAllScores && numberOfPositiveScores > 3">
        <button class="show-more-btn font-bold text-sm text-grey-dark" pRipple (click)="showAllScores()">
            {{ 'CLIENT.PRODUCT.SIDEBAR.SHOW_MORE' | translate }} ({{numberOfPositiveScores-3}})
        </button>
    </div>
    <div class="mt-6" *ngIf="productData.manualProductVersion">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/folder-tree.svg" alt="">
            <span class="pl-2 ">{{ 'CLIENT.PRODUCT.SIDEBAR.PRODUCT_VERSION' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium text-base">
            <span>{{ productData.manualProductVersion }}</span>
        </div>
    </div>
    <!-- <div class="mt-5" *ngIf="productData.releaseDate">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/calendar-line.svg" alt="">
            <span class="pl-2">{{ 'CLIENT.PRODUCT.SIDEBAR.RELEASE_DATE' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>{{ productData.releaseDate | date : 'dd.MM.yyyy' }}</span>
        </div>
    </div> -->
    <div class="mt-5" *ngIf="productData.creationDate">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/calendar-line.svg" alt="">
            <span class="pl-2">{{ 'CLIENT.PRODUCT.SIDEBAR.CREATION_DATE' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>{{ productData.creationDate | date : 'dd.MM.yyyy' }}</span>
        </div>
    </div>
    <div class="mt-5" *ngIf="productData.lastActivityDate">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/calendar-today.svg" alt="">
            <span class="pl-2">{{ 'CLIENT.PRODUCT.SIDEBAR.LAST_ACTIVITY_DATE' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>{{ productData.lastActivityDate | date : 'dd.MM.yyyy' }}</span>
        </div>
    </div>
    <div class="mt-6" *ngIf="productData.sourceUrl">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/branch.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.SOURCE' | translate }}
            </span>
        </div>
        <div class="flex mt-px-12">
            <a href="{{ productData.sourceUrl }}" class="source-link border-grey-normal border-1 text-grey-dark" target="_blank">
                <i class="pi pi-github text-grey-darker"></i>
                <span class="font-normal">Github</span>
            </a>
        </div>
    </div>
    <div class="mt-6" *ngIf="productData.defaultEnvironment.maintainer">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/briefcase.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.MAINTAINER' | translate }}
            </span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>
                {{ productData.defaultEnvironment.maintainer }}
            </span>
        </div>
    </div>
    <div class="mt-6" *ngIf="productData.licenses">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <i class="pi pi-file-edit text-black-black-100"></i>
            <span class="pl-2 overflow-hidden">
                {{ 'CLIENT.PRODUCT.SIDEBAR.LICENSE' | translate }}
            </span>
        </div>
        <ng-container *ngFor="let license of productData.licenses">
            <a href="{{ license.licenseUrl }}" target="_blank" *ngIf="license.licenseUrl" class="no-underline">

                <div class="mt-px-12 text-grey-dark font-medium no-underline">
                    <span>
                        {{ license.licenseName }}
                    </span>
                    <i class="pi pi-external-link text-grey-dark ml-1 mr-1 text-xs"></i>
                </div>
            </a>
            <div *ngIf="!license.licenseUrl">
                <div class="mt-px-12 text-grey-dark">
                    <span>
                        {{ license.licenseName }}
                    </span>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="mt-6" *ngIf="productData.defaultEnvironment.traits.length > 0">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/card.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.PRODUCT_TRAITS' | translate }}
            </span>
        </div>
        <div class="flex flex-column mt-4">
            <div *ngFor="let trait of productData.defaultEnvironment.traits.slice(0, traitsToDisplay)" class="flex flex-row align-items-center mb-3">
                <img src="assets/check.svg" alt="check mark">
                <span class="ml-3 text-base text-blue-darker">{{ trait | translate }}</span>
            </div>
        </div>
        <div class="mt-px-12" *ngIf="traitsToDisplay < productData.defaultEnvironment.traits.length">
            <button class="show-more-btn font-bold text-sm text-grey-dark" (click)="showMoreTraits()" pRipple>
                {{ 'CLIENT.PRODUCT.SIDEBAR.SHOW_MORE' | translate }} ({{ productData.defaultEnvironment.traits.length - traitsToDisplay }})
            </button>
        </div>
    </div>
    <div class="mt-6" *ngIf="productData.tags.length > 0">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/similar.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.PRODUCT_CATEGORIES' | translate }}
            </span>
        </div>
        <div class="gap-3 flex align-items-center align-content-center align-self-stretch flex-wrap mt-3">
            <a routerLink="/catalog" [queryParams]="{tags: tag.id}" *ngFor="let tag of productData.tags" class="no-underline product-tag-btn bg-black-white border-blue-light-active text-blue-normal">
                <i class="pi pi-filter"></i>
                <span class="ml-2">{{ tag.name | translate }}</span>
            </a>
        </div>
    </div>
</ng-template>
