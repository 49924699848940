import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { ProductDataService } from './product-data.service';

@Component({
  selector: 'app-edit-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
  providers: [ ProductDataService ],
})
export class ProductPageComponent implements OnInit, AfterViewInit {
  public isLoading: boolean = true;

  public traitsToDisplay: number = 4;
  public displayAllScores: boolean = false;
  public sidebarVisible: boolean = false;
  public scoresWithTrends!: Record<string, [number, number]>;
  public scoresOrder: string[] = ['code', 'vulnerability', 'activity', 'contributor', 'language'];
  public numberOfPositiveScores: number = 0;

  constructor(
    public productData: ProductDataService,
  ) {}

  public ngOnInit() {
    this.productData.isLoadingDataChange.subscribe((value) => {
      this.isLoading = value;
    });
  }

  public ngAfterViewInit() {
    this.productData.isLoadingDataChange.subscribe(value => {
      if (value) return;

      this.isLoading = value;

      this.scoresWithTrends = {
        code: [this.productData.codeQualityScore, this.productData.lastTrends.codeQuality],
        vulnerability: [this.productData.vulnerabilityScore, this.productData.lastTrends.vulnerability],
        activity: [this.productData.activityScore, this.productData.lastTrends.activity],
        contributor: [this.productData.contributorScore, this.productData.lastTrends.contributor],
        language: [this.productData.languageCoverageScore, this.productData.lastTrends.languageCoverage],
      }
      for (const [key, value] of Object.entries(this.scoresWithTrends)) {
        if ((value[0] === null) || (value[0] == 0 && key == 'vulnerability')) {
          const index = this.scoresOrder.indexOf(key, 0)
          if (index > -1) {
            this.scoresOrder.splice(index, 1)
          }
        }
      }
      this.numberOfPositiveScores = this.scoresOrder.length;
    });
  }

  @ViewChild('installOnPage', {static: false}) private installOnPage!: ElementRef<HTMLElement>;
  @ViewChild('stickyMenu', {static: false}) private stickyMenu!: ElementRef<HTMLElement>;

  public isInstallOnPageVisible: boolean = true;
  public isInstallDropdownVisible: boolean = false;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.installOnPage){
      const rect = this.installOnPage.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 15;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isInstallOnPageVisible = topShown && bottomShown;
    }

    if(this.stickyMenu) {
      const rect = this.stickyMenu.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 30;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.stickyMenu.nativeElement.classList.toggle('shadow-1', !(topShown && bottomShown));
    }
  }

  public toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  public showMoreTraits() {
    this.traitsToDisplay = this.productData.defaultEnvironment.traits.length;
  }

  public showAllScores() {
    this.displayAllScores = true;
  }
}
