<div class="flex flex-column justify-content-center align-items-center relative mb-320px bg-blue-dark ">
  <div class="skew-bottom-right bg-blue-dark absolute left-0 right-0 h-2rem knowledge-skew"></div>
  <div class="container w-full flex flex-column flex-wrap align-content-center z-5 justify-content-center gap-5 text-center py-8 mb-8">
    <span class="text-white text-7xl font-bold">
      {{ 'CLIENT.KNOWLEDGE_DATABASE.TITLE' | translate }}
    </span>
    <div class="flex flex-column gap-6 align-items-center md:w-6 px-2 md:px-4">
      <span class="text-white text-xl font-semibold w-10 line-height-4">
        {{ 'CLIENT.KNOWLEDGE_DATABASE.DESCRIPTION' | translate }}
      </span>
      <!-- <span class="p-input-icon-left w-full">
          <i class="pi pi-search"></i>
          <input type="text" pInputText class="w-full bg-blue-light-hover border-blue-light-active text-blue-dark" placeholder="{{ 'CLIENT.KNOWLEDGE_DATABASE.SEARCH_INPUT.PLACEHOLDER' | translate }}" />
        </span> -->
    </div>
  </div>
  <div class="container flex flex-row gap-4 w-full absolute card-row z-5 min-w-0 overflow-x-auto px-4 md:px-0">

    <ng-container *ngIf="isLoading">
      <p-skeleton width="21rem" height="15rem" *ngFor="let element of [0,0,0,0]"></p-skeleton>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <ng-container *ngFor="let category of categories">
        <a class="flex flex-column bg-black-white p-4 border-round-lg card cursor-pointer no-underline min-w-21rem" href="/knowledge-base#{{category.id}}" *ngIf="category.count > 0">
          <div class="flex flex-column gap-3" style="overflow-x: clip;">
            <div>
              <img [src]="category.imageUrl" alt="">
            </div>
            <div class="flex flex-column gap-1">
              <span class="font-semibold text-xl text-blue-darker">
                {{ category.name | translate }}
              </span>
              <span class="text-grey-dark-hover white-space-nowrap">
                {{ category.count }} {{ 'CLIENT.KNOWLEDGE_DATABASE.CATEGORY_COUNT' | translate }}
              </span>
            </div>
          </div>
        </a>
      </ng-container>
    </ng-container>

  </div>
</div>

<ng-container *ngFor="let category of articleEntries">
  <div class="flex flex-column justify-content-center align-items-center pb-8 mb-8 px-4 md:px-0" [id]="category[0]" *ngIf="category[1].data.length > 0">
    <div class="container w-full flex flex-column flex-wrap align-content-center align-items-center z-5 justify-content-center" style="gap: 3.5rem;">
      <span class="font-bold text-6xl text-blue-darker" id="rating">
        {{ getCategoryName(category[0]) | translate }}
      </span>

      <div class="flex flex-column md:flex-row gap-4 w-full">
        <a [routerLink]="'/posts/' + article.id" class="flex flex-column gap-4 md:w-6 no-underline" *ngFor="let article of category[1].data.slice(0, 2)">
          <img [src]="article.image" alt="" class="w-full border-round-lg h-25rem object-fit-cover">
          <span class="font-bold text-xl text-blue-darker">
            {{ article.name | translate }}
          </span>
          <span class="font-normal text-base text-grey-dark-hover">
            {{ dateAgo(article.createdAt) }}
          </span>
          <span class="font-normal text-base text-grey-dark-hover">
            {{ article.summary | translate }}
          </span>
          <div class="flex">
            <div class="flex flex-row align-items-center text-black-black-100 text-sm font-bold py-3 px-4 bg-yellow-normal border-round-lg">
              <span>
                {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
              </span>
              <i class="icon-16 icon-chevron-right bg-black-black-100"></i>
            </div>
          </div>
        </a>
      </div>

      <div class="flex flex-column md:flex-row gap-4">
        <a [routerLink]="'/posts/' + article.id" class="flex flex-column gap-4 md:w-4 no-underline" *ngFor="let article of category[1].data.slice(2, 5)">
          <img [src]="article.image" alt="" class="w-full border-round-lg">
          <span class="font-bold text-xl text-blue-darker">
            {{ article.name | translate }}
          </span>
          <span class="font-normal text-base text-grey-dark-hover">
            {{ dateAgo(article.createdAt) }}
          </span>
          <span class="font-normal text-base text-grey-dark-hover">
            {{ article.summary | translate }}
          </span>
          <div class="flex">
            <div class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
              <span>
                {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
              </span>
              <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
            </div>
          </div>
        </a>
      </div>


      <p-paginator (onPageChange)="onPageChange($event, category[0])" [first]="category[1].page * category[1].itemsPerPage" [rows]="category[1].itemsPerPage" [totalRecords]="category[1].count" *ngIf="category[1].count > category[1].itemsPerPage"></p-paginator>

    </div>
  </div>
</ng-container>


<!-- <div class="flex flex-column justify-content-center align-items-center pb-8 mb-8">
  <div class="container w-full flex flex-column flex-wrap align-content-center align-items-center z-5 justify-content-center" style="gap: 3.5rem;">
    <span class="font-bold text-6xl text-blue-darker" id="products">
      Katalog produktów
    </span>

    <div class="flex flex-row gap-4">
      <div class="flex flex-column flex-1-1-33 gap-4">
        <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
        <div class="flex flex-column gap-2">
          <span class="font-bold text-xl text-blue-darker">
            Składowe oceny SourceMation
          </span>
          <span class="font-normal text-base text-grey-dark-hover">
            18.06.2023
          </span>
        </div>
        <span class="font-normal text-base text-grey-dark-hover">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
        </span>
        <div class="flex">
          <a href="#" class="flex flex-row align-items-center no-underline text-black-black-100 text-sm font-bold py-3 px-4 bg-yellow-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-black-black-100"></i>
          </a>
        </div>
      </div>
      <div class="flex flex-column flex-1-1-66 gap-4 p-5 bg-blue-dark border-round-2xl">
        <div class="flex flex-row">
          <div class="flex flex-column flex-1-1-50 gap-5">
            <div class="flex flex-column gap-3">
              <span class="text-black-white text-3xl font-bold">
                Proces dostarczania oprogramowania
              </span>
              <span class="font-medium text-base text-black-white">
                21.11.2023
              </span>
            </div>
            <div class="flex flex-column gap-3">
              <span class="align-self-stretch text-black-white font-bold">
                Etiam non ullamcorper leo. Mauris et finibus mauris. Morbi venenatis elit non varius luctus. Nulla facilisi. Integer vulputate libero ultrices tristique venenatis. Proin tincidunt erat quis tellus fringilla.
              </span>
              <span class="align-self-stretch text-black-white">
                Eget dignissim lacus tincidunt. Integer sed nisi ut nunc facilisis viverra id ut est. Curabitur lobortis lectus et scelerisque scelerisque.
              </span>
              <span class="align-self-stretch text-black-white">
                Pellentesque imperdiet hendrerit eros facilisis finibus. Nullam varius, purus sit amet dapibus vehicula.
              </span>
            </div>
            <div class="flex">
              <a href="#" class="flex flex-row align-items-center no-underline text-black-black-100 text-sm font-bold py-3 px-4 bg-yellow-normal border-round-lg">
                <span>
                  {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
                </span>
                <i class="icon-16 icon-chevron-right bg-black-black-100"></i>
              </a>
            </div>
          </div>
          <div class="flex-1-1-50">
            <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full justify-content-end">
      <a href="#" class="no-underline py-12px pr-3 text-sm font-bold text-grey-dark">Pokaż więcej (3)</a>
    </div>

  </div>
</div>

<div class="flex flex-column justify-content-center align-items-center pb-8 mb-8">
  <div class="container w-full flex flex-column align-content-center z-5 justify-content-center" style="gap: 3.5rem;">
    <div class="flex flex-row justify-content-center">
      <span class="font-bold text-6xl text-blue-darker" id="subscription">
        Subskrypcja
      </span>
    </div>

    <div class="flex flex-row bg-blue-dark border-round-2xl">
      <div class="flex-1-1-50" style="height: 650px;">
        <img src="/assets/about-us-1.png" alt="" class="w-full h-full" style="object-fit: cover; box-sizing: border-box; border-radius: inherit; border-top-left-radius: 16px; border-bottom-left-radius: 16px;">
      </div>
      <div class="flex-1-1-50 pt-8 flex flex-column gap-8" style="padding-left: 4.5rem;">
        <div class="flex flex-column gap-4">
          <span class="font-medium text-base text-black-white">
            21.11.2023
          </span>
          <span class="font-bold text-6xl text-black-white">
            Bezpieczeństwo oprogramowania Open Source
          </span>
        </div>
        <div class="flex flex-row">
          <a href="#" class="flex flex-row align-items-center no-underline text-black-black-100 text-sm font-bold py-3 px-4 bg-yellow-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-black-black-100"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="flex flex-row gap-4 pt-5">
      <div class="flex flex-column flex-1-1-50 gap-4">
        <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
        <span class="font-bold text-xl text-blue-darker">
          Składowe oceny SourceMation
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          3 godziny temu
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
        </span>
        <div class="flex">
          <a href="#" class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
          </a>
        </div>
      </div>
      <div class="flex flex-column flex-1-1-50 gap-4">
        <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
        <span class="font-bold text-xl text-blue-darker">
          Składowe oceny SourceMation
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          3 godziny temu
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
        </span>
        <div class="flex">
          <a href="#" class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
          </a>
        </div>
      </div>
      <div class="flex flex-column flex-1-1-50 gap-4">
        <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
        <span class="font-bold text-xl text-blue-darker">
          Składowe oceny SourceMation
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          3 godziny temu
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
        </span>
        <div class="flex">
          <a href="#" class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full justify-content-end">
      <a href="#" class="no-underline py-12px pr-3 text-sm font-bold text-grey-dark">Pokaż więcej (3)</a>
    </div>

  </div>
</div>

<div class="flex flex-column justify-content-end align-items-end mb-8" style="gap: 3.5rem;">
  <div class="flex flex-row justify-content-center w-full">
    <span class="font-bold text-6xl text-blue-darker" id="support">
      Wsparcie eksperckie
    </span>
  </div>
  <div class="container-xl w-full flex flex-column align-content-center z-5 justify-content-center lg:pl-3">
    <div class="flex flex-row">
      <div class="flex flex-column gap-6 py-8 pr-7" style="flex-basis: 43%;">
        <div class="flex flex-column gap-5">
          <span class="text-grey-dark-hover">
            21.11.2023
          </span>
          <span class="text-7xl font-bold text-blue-darker">
            Bezpieczeństwo oprogramowania
          </span>
        </div>
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacinia, lorem in molestie sodales, lectus neque convallis justo.
          Id tincidunt dolor sem et ante. Donec pharetra nulla nisi, ac sodales libero tempus ut.
        </span>
      </div>
      <div class="flex flex-1-1-57 w-full">
        <img src="/assets/about-us-1.png" alt="" class="w-full" style="height: 650px; object-fit: cover;">
      </div>
    </div>
  </div>
</div>

<div class="flex flex-column justify-content-center align-items-center pb-8 mb-8">
  <div class="container w-full flex flex-column align-content-center z-5 justify-content-center" style="gap: 3.5rem;">
    <div class="flex flex-row gap-4">
      <div class="flex flex-column flex-1-1-50 gap-4">
        <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
        <span class="font-bold text-xl text-blue-darker">
          Składowe oceny SourceMation
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          3 godziny temu
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
        </span>
        <div class="flex">
          <a href="#" class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
          </a>
        </div>
      </div>
      <div class="flex flex-column flex-1-1-50 gap-4">
        <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
        <span class="font-bold text-xl text-blue-darker">
          Składowe oceny SourceMation
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          3 godziny temu
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
        </span>
        <div class="flex">
          <a href="#" class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
          </a>
        </div>
      </div>
      <div class="flex flex-column flex-1-1-50 gap-4">
        <img src="/assets/about-us-1.png" alt="" class="w-full border-round-lg">
        <span class="font-bold text-xl text-blue-darker">
          Składowe oceny SourceMation
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          3 godziny temu
        </span>
        <span class="font-normal text-base text-grey-dark-hover">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
        </span>
        <div class="flex">
          <a href="#" class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
            <span>
              {{ 'CLIENT.KNOWLEDGE_DATABASE.READ_BUTTON' | translate }}
            </span>
            <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full justify-content-end">
      <a href="#" class="no-underline py-12px pr-3 text-sm font-bold text-grey-dark">Pokaż więcej (3)</a>
    </div>

  </div>
</div> -->

<div class="fixed bottom-0 right-0 mb-3 mr-3 z-5" *ngIf="isGoToTopVisible" (click)="goToTop()">
  <button class="border-circle p-4 bg-yellow-normal border-none shadow-1 cursor-pointer">
    <i class="pi pi-arrow-up text-black-black-100 text-4xl"></i>
  </button>
</div>