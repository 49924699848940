<div class="mt-7 gap-7">
  <p class="text-3xl font-bold text-blue-darker">
    {{ 'CLIENT.DEPLOYMENT.DOCUMENTATION_SECTION.TITLE' | translate }}
  </p>
  <p class="text-grey-dark-hover w-8">
    {{ 'CLIENT.DEPLOYMENT.DOCUMENTATION_SECTION.DESCRIPTION' | translate }}
  </p>

  <p-skeleton *ngIf="isLoading" styleClass="w-full" height="10rem"></p-skeleton>
  <p-table [value]="deploymentData.documentations" [tableStyle]="{ 'min-width': '50rem' }" *ngIf="!isLoading">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="name">
              <i class="pi pi-align-left"></i>
              <span class="ml-2">
                {{ 'CLIENT.DEPLOYMENT.DOCUMENTATION_SECTION.TABLE.HEADER.NAME' | translate }}
              </span>
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="url">
              <i class="pi pi-link"></i>
              <span class="ml-2">
                {{ 'CLIENT.DEPLOYMENT.DOCUMENTATION_SECTION.TABLE.HEADER.LINK' | translate }}
              </span>
              <p-sortIcon field="url"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-documentation>
        <tr>
            <td>{{ documentation.name }}</td>
            <td>
              <a [href]="documentation.address">
                {{ documentation.address }}
              </a>
            </td>
        </tr>
    </ng-template>
  </p-table>
</div>
