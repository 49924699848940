import { Component, Inject, Input, AfterViewInit, OnDestroy, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import { isPlatformBrowser } from "@angular/common";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import {CountriesDataService} from "../countries-data.service";


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit, OnDestroy {

  @Input({ required: true })
  inputData: {name: string, value: number, pictureSettings: object }[] = [];

  @Input({ required: true })
  chartId!: string;

  private root!: am5.Root;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
  ) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }


  ngAfterViewInit(){
    this.browserOnly(() => {
      let root = am5.Root.new(this.chartId);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          panX: false,
          wheelX: 'none',
          wheelY: 'none',
          paddingTop: 40,
          paddingBottom: 50,
          paddingLeft: 0,
          paddingRight: 0,
        })
      );

      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );

      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 60
      });
      xRenderer.grid.template.set('visible', false);

      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: xRenderer,
          categoryField: 'name',
          paddingTop: 15,
        })
      );

      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "wrap",
        maxWidth: 90,
      });

      // Create series
      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Udział',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          categoryXField: 'name',
          maskBullets: false,
          sequencedInterpolation: true,
          calculateAggregates: true,
        })
      );

      series1.columns.template.setAll({
        strokeOpacity: 0,
        maxWidth: 50,
      });

      let circleTemplate: am5.Template<am5.Circle> = am5.Template.new({});

      series1.bullets.push(function (root, series, dataItem) {
        let bulletContainer = am5.Container.new(root, {});
        let circle = bulletContainer.children.push(
          am5.Circle.new(
            root,
            {
              radius: 12
            },
            circleTemplate
          )
        );

        let maskCircle = bulletContainer.children.push(
          am5.Circle.new(root, { radius: 12 })
        );

        // only containers can be masked, so we add image to another container
        let imageContainer = bulletContainer.children.push(
          am5.Container.new(root, {
            mask: maskCircle
          })
        );

        let image = imageContainer.children.push(
          am5.Picture.new(root, {
            templateField: 'pictureSettings',
            centerX: am5.p50,
            centerY: am5.p50,
            height: 25
          })
        );

        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: bulletContainer
        });
      });

      // heatrule
      series1.set('heatRules', [
        {
          dataField: 'valueY',
          min: am5.color(0x77b6da),
          max: am5.color(0xbc71d8),
          target: series1.columns.template,
          key: 'fill'
        },
        {
          dataField: 'valueY',
          min: am5.color(0x77b6da),
          max: am5.color(0xbc71d8),
          target: circleTemplate,
          key: 'fill'
        }
      ]);

      series1.data.setAll(this.inputData);
      xAxis.data.setAll(this.inputData);

      series1.appear();
      xAxis.appear();

      this.root = root;
    });
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      this.root.dispose()
    });
  }

}
