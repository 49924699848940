import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserManager } from '../services/user-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { sameValues } from '../same-values.validator';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activate-password-page',
  templateUrl: './activate-password-page.component.html',
  styleUrls: ['./activate-password-page.component.scss']
})
export class ActivatePasswordPageComponent implements OnInit {
  public isLoading: boolean = true;
  public errored: boolean = false;
  public formSent: boolean = false;

  public formGroup!: FormGroup;

  constructor(
    private userManager: UserManager,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translate: TranslateService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.formGroup = new FormGroup({}, {
      validators: [
        sameValues(['password', 'confirmPassword']),
      ],
    });

    this.formGroup.addControl('password', new FormControl());
    this.formGroup.addControl('confirmPassword', new FormControl());
    this.isLoading = false;
  }

  public async submit() {
    if (this.formGroup.invalid) { 
      return;
    }

    this.isLoading = true;
    this.formSent = true;
    try {
      const { activationTokenId } = await firstValueFrom(this.route.params);

      const formControl = this.formGroup.get('password') as FormControl;

      await this.userManager.activateNewPassword(activationTokenId, {
        password: formControl.value,
      });

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('CLIENT.ACTIVATE_PASSWORD.NOTIFICATION.SUCCESS.TITLE'),
        detail: this.translate.instant('CLIENT.ACTIVATE_PASSWORD.NOTIFICATION.SUCCESS.DESCRIPTION'),
      });

      await this.router.navigate(['/signin']);
    } catch (err) { 
      this.errored = true;
    } finally { 
      this.isLoading = false;
    }
  }
}
