<div class="flex flex-column gap-5 bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover">
  <div class="flex flex-row gap-3 align-items-center">
    <i class="icon-star icon-24 bg-blue-darker"></i>
    <span class="font-bold text-3xl text-blue-darker">
        {{ 'CLIENT.PROFILE.MY_PROJECTS.ADD_PAGE.TITLE' | translate }}
    </span>
  </div>

  <span class="text-grey-dark-hover text-base w-10">
    {{ 'CLIENT.PROFILE.MY_PROJECTS.ADD_PAGE.DESCRIPTION' | translate }}
  </span>

  <div class="flex flex-column-reverse md:flex-row gap-4 md:gap-0">
    <form class="flex flex-column gap-3 md:w-7" [formGroup]="formGroup" (ngSubmit)="submit()">
      <div class="flex flex-column md:flex-row gap-3">
        <div class="w-full">
          <input
            type="text"
            formControlName="userProject"
            required
            pInputText
            placeholder="{{ 'CLIENT.PROFILE.MY_PROJECTS.ADD_PAGE.FORM.USER_PROJECT' | translate }}*"
            class="w-full bg-blue-light border-blue-light-hover"
          />
          <div *ngIf="formGroup.get('userProject')?.touched && formGroup.get('userProject')?.invalid">
            <div class="p-message-error">
              {{ 'CLIENT.PROFILE.MY_PROJECTS.ADD_PAGE.FORM.USER_PROJECT.INCORRECT' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column md:flex-row gap-3">
        <p-password [feedback]="false" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.PROFILE.MY_PROJECTS.ADD_PAGE.FORM.USER_TOKEN' | translate }}" formControlName="userToken"></p-password>
      </div>
      <p-button type="submit" styleClass="w-6 justify-content-center font-bold text-sm py-3 px-4">
        {{ 'CLIENT.PROFILE.MY_PROJECTS.ADD_PAGE.FORM.SEND_REQUEST' | translate }}
      </p-button>
    </form>
    <div id="checkBot" class="flex flex-grow-1 justify-content-center"></div>
  </div>

</div>
