import { Component, Inject, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailManagerService} from "../../services/email-manager.service";
import {Router} from "@angular/router";
import {SendEmailErrorMessage, SendEmailMessage} from "../../messages";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import { UserManager } from 'src/app/services/user-manager.service';
import { TURNSTILE } from 'src/app/turnstile.provider';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  formGroup!: FormGroup;
  isLoading: boolean = false;
  captchaToken!: FormControl<string | null>

  private botCheckId: string | undefined | null;

  constructor(
    private emailManager: EmailManagerService,
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService,
    private userManager: UserManager,
    @Inject(TURNSTILE)
    private turnstile: Promise<Turnstile.Turnstile>,
  ) {}

  private renderTurnstile(turnstile: Turnstile.Turnstile) {
    if(this.botCheckId) turnstile.remove(this.botCheckId);
    this.botCheckId = turnstile.render('#checkBot', {
      sitekey: environment.turnstileSiteKey,
      size: 'normal',
      theme: 'light',
      language: this.translate.currentLang,
      callback: (token) => {
        this.captchaToken.setValue(token);
      },
    });
  }

  public ngOnInit() {
    this.captchaToken = new FormControl('', [
      Validators.required,
    ]);

    this.turnstile.then((turnstile) => this.renderTurnstile(turnstile));

    this.translate.onLangChange.subscribe(() => {
      this.turnstile.then((turnstile) => this.renderTurnstile(turnstile));
    });

    const email = new FormControl('', [
      Validators.required,
      Validators.email
    ]);
    
    const firstName = new FormControl( '',
      [Validators.required],
    );

    const lastName = new FormControl( '',
      [Validators.required],
    );

    this.userManager.getUserData().then(({ email: userEmail, firstName: userFirstName, lastName: userLastName }) => {
      email.setValue(userEmail);
      firstName.setValue(userFirstName);
      lastName.setValue(userLastName);
    });

    this.formGroup = new FormGroup({
      firstName,
      lastName,
      email,
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[- +()0-9]+')
      ]),
      company: new FormControl(''),
      job: new FormControl(''),
      message: new FormControl('',[
        Validators.required,
      ]),
      captchaToken: this.captchaToken,
    })
  }

  public async submit() {
    try {
      this.isLoading = true;
      const firstName = this.formGroup.get('firstName')?.value;
      const lastName = this.formGroup.get('lastName')?.value;
      const email = this.formGroup.get('email')?.value
      const phone = this.formGroup.get('phone')?.value
      const company = this.formGroup.get('company')?.value
      const job = this.formGroup.get('job')?.value
      const message = this.formGroup.get('message')?.value

      this.emailManager.sendAskExpert({
        firstName,
        lastName,
        email,
        phone,
        company,
        job,
        message,
        token: this.captchaToken.value!,
      });

      this.router.navigate(['/']);
      const responseMessage = {
        severity: SendEmailMessage.severity,
        summary: this.translate.instant(SendEmailMessage.summary!),
        detail: this.translate.instant(SendEmailMessage.detail!),
      };
      this.messageService.add(responseMessage);
    } catch (err) {
        const responseMessage = {
          severity: SendEmailErrorMessage.severity,
          summary: this.translate.instant(SendEmailErrorMessage.summary!),
          detail: this.translate.instant(SendEmailErrorMessage.detail!),
        };
        this.messageService.add(responseMessage);
    } finally {
      this.isLoading = false;
    }
  }

}
