import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Subscription, timer } from 'rxjs';
import { UserManager } from '../services/user-manager.service';
import { GOOGLE_REGISTERED } from '../brodcast-messages';
import { GOOGLE_REGISTER } from '../brodcast-channels';

@Component({
  selector: 'app-google-register-page',
  templateUrl: './google-register-page.component.html',
  styleUrls: ['./google-register-page.component.scss']
})
export class GoogleRegisterPageComponent {

  public isLoading: boolean = true;
  public hasError: boolean = false;

  countDown!: Subscription;
  counter: number = 5;

  constructor(
    private route: ActivatedRoute,
    private userManager: UserManager,
  ) {}

  async ngOnInit() {
    try {
      this.isLoading = true;
      const fragment = await firstValueFrom(this.route.fragment)
      const accessToken = fragment?.split('&').find((fragment) => fragment.includes('access_token'))?.split('=')[1];

      const response = await this.userManager.registerUserByGoogle(accessToken!);
      if(response) {
        const channel = new BroadcastChannel(GOOGLE_REGISTER);
        channel.postMessage(GOOGLE_REGISTERED);
      }
      this.countDown = timer(0, 1000).subscribe(() => {
        if(this.counter > 0) --this.counter;
        if(this.counter === 0) {
          this.countDown.unsubscribe();
          window.close();
        }
      });
    } catch (err) {
      console.error(err);
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
