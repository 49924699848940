<div class="mt-6">
  <div class="flex flex-row justify-content-between align-items-center">
    <span class="text-blue-darker font-bold text-3xl w-full">
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.TITLE' | translate }}
    </span>
    <span class="flex flex-row justify-content-between md:justify-content-end gap-3 w-full min-w-0" #exportSelect>
        <div>
          <i class="pi pi-spin pi-spinner" *ngIf="isExporting"></i>
        </div>
        <p-dropdown
          [options]="exportOptions"
          optionLabel="name"
          (onHide)="removeOverflow()"
          [overlayOptions]="{mode: isMobile ? 'modal' : 'overlay'}"
          styleClass="bg-blue-light border-blue-light-hover h-full max-w-20rem"
          (onChange)="onTriggerExport($event)"
        >
            <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2 justify-content-between text-grey-dark">
                    <span>{{ 'CLIENT.PRODUCT.REPORT_SECTION.EXPORT_OPTIONS.TITLE' | translate }}</span>
                </div>
            </ng-template>
            <ng-template let-option pTemplate="item">
                <div class="flex align-items-center gap-2 justify-content-between text-grey-dark">
                    <div>{{ option.translationId | translate}}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </span>
  </div>
  <p class="text-grey-dark my-5 w-9">
    {{ 'CLIENT.PRODUCT.REPORT_SECTION.DESCRIPTION' | translate }}
  </p>

  <div class="flex flex-row align-items-center justify-content-between">
    <p class="text-xl font-bold text-blue-darker m-0">
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.TITLE' | translate }}
    </p>
    <help-button helpText="CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.HELP_TEXT"></help-button>
  </div>

  <div class="flex flex-column mt-5">
<!--    <div class="flex flex-row justify-content-end gap-2" *ngIf="isLoading">-->
<!--      <p-skeleton width="5rem" height="2.5rem"></p-skeleton>-->
<!--      <p-skeleton width="9rem" height="2.5rem"></p-skeleton>-->
<!--    </div>-->
<!--    <div class="flex flex-row justify-content-end gap-2" *ngIf="!isLoading">-->
<!--      <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
<!--        <i class="icon-16 icon-export bg-blue-normal"></i>-->
<!--        <span class="font-bold text-sm text-blue-normal">-->
<!--          {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.EXPORT_BUTTON' | translate }}-->
<!--        </span>-->
<!--      </p-button>-->
<!--      <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
<!--        <i class="icon-16 icon-filter bg-blue-normal"></i>-->
<!--        <span class="font-bold text-sm text-blue-normal">-->
<!--          {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.FILTERS' | translate }}-->
<!--        </span>-->
<!--      </p-button>-->
<!--    </div>-->

    <p-skeleton styleClass="w-full mt-2" height="40rem" *ngIf="isLoading"></p-skeleton>

    <p-table [value]="productData.environments" dataKey="name" *ngIf="!isLoading" [expandedRowKeys]="openedKeys">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
            <div class="flex flex-row align-items-center gap-2">
              <i class="icon-16 icon-lines bg-black-black-100"></i>
              <span>
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.HEADER.NAME' | translate }}
              </span>
            </div>
          </th>
          <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
            <div class="flex flex-row align-items-center gap-2">
              <span>
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.HEADER.RATING' | translate }}
              </span>
            </div>
          </th>
          <!-- <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-2">
            {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.HEADER.EVALUTION' | translate }}
          </th>-->
          <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-2">
            {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.HEADER.SEVERITY' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-component let-expanded="expanded">
        <tr [pRowToggler]="component" class="cursor-pointer">
          <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover">
            <div class="flex flex-row gap-2 align-items-center">
              <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"></i>
              <i class="icon-16 bg-black-black-100" [class.icon-on-prem]="component.type == 'ON_PREM'" [class.icon-cloud]="component.type == 'CLOUD'" [class.icon-container]="component.type == 'CONTAINERS'"></i>
              <span class="font-bold">
                {{ component.name | translate }}
              </span>
            </div>
          </td>
          <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
            <div class="flex flex-row gap-2">
              <i class="icon-round-star icon-16 bg-black-black-100"></i>
              <span>
                {{ component.scores.scarm * 10 | number: '1.1-1' }}
              </span>
            </div>
          </td>
          <!-- <td> </td> -->
          <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover">
          <div class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
              [class.bg-severity-critical]="component.scores.scarm * 10 >= 0 && component.scores.scarm * 10 < 1"
              [class.bg-severity-high]="component.scores.scarm * 10 >= 1 && component.scores.scarm * 10 < 3"
              [class.bg-severity-medium]="component.scores.scarm * 10 >= 3 && component.scores.scarm * 10 < 6"
              [class.bg-severity-low]="component.scores.scarm * 10 >= 6 && component.scores.scarm * 10 < 8"
              [class.bg-severity-no-risk]="component.scores.scarm * 10 >= 8 && component.scores.scarm * 10 <= 10"
            >
              <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 0 && component.scores.scarm * 10 < 1">
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.CRITICAL' | translate }}
              </span>
              <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 1 && component.scores.scarm * 10 < 3">
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.HIGH' | translate }}
              </span>
              <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 3 && component.scores.scarm * 10 < 6">
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.MEDIUM' | translate }}
              </span>
              <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 6 && component.scores.scarm * 10 < 8">
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.LOW' | translate }}
              </span>
              <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 8 && component.scores.scarm * 10 <= 10">
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.SAFE' | translate }}
              </span>
            </div>

          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-component>
        <ng-container *ngFor="let scoreKey of getScoresKeys(component.scores)">
          <tr *ngIf="scoreKey !== 'scarm'">
            <td class="pl-6 border-x-1 border-bottom-1 border-blue-light-hover">
              <div class="flex flex-row gap-2 align-items-center ">
                <i class="icon-16 bg-grey-darker"
                 [class.icon-code]="scoreKey == 'codeQuality'"
                 [class.icon-vulnerability]="scoreKey == 'vulnerability'"
                 [class.icon-time]="scoreKey == 'activity'"
                 [class.icon-people]="scoreKey == 'contributor'"
                 [class.icon-branch]="scoreKey == 'languageCoverage'"
                >
                </i>
                <ng-container [ngSwitch]="scoreKey">
                  <span class="text-sm" *ngSwitchCase="'codeQuality'">
                    {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SUBTABLE.CODE_QUALITY' | translate }}
                  </span>
                  <span class="text-sm" *ngSwitchCase="'contributor'">
                    {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SUBTABLE.CONTRIBUTORS' | translate }}
                  </span>
                  <span class="text-sm" *ngSwitchCase="'activity'">
                    {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SUBTABLE.ACTIVITY' | translate }}
                  </span>
                  <span class="text-sm" *ngSwitchCase="'vulnerability'">
                    {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SUBTABLE.VULNERABILITY' | translate }}
                  </span>
                  <span class="text-sm" *ngSwitchCase="'languageCoverage'">
                    {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SUBTABLE.LANGUAGE' | translate }}
                  </span>
                </ng-container>
              </div>
            </td>
            <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
              <div class="flex flex-row gap-2">
                <i class="icon-round-star icon-16 bg-black-black-100"></i>
                <span>
                  {{ component.scores[scoreKey] * 10 | number: '1.1-1' }}
                </span>
              </div>
            </td>
            <!-- <td> </td> -->
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
              <div class="border-round-lg px-3 py-2 justify-content-center flex flex-row" [class.bg-severity-critical]="(component.scores[scoreKey] * 10) >= 0 && (component.scores[scoreKey] * 10) < 1" [class.bg-severity-high]="(component.scores[scoreKey] * 10) >= 1 && (component.scores[scoreKey] * 10) < 3" [class.bg-severity-medium]="(component.scores[scoreKey] * 10) >= 3 && (component.scores[scoreKey] * 10) < 6" [class.bg-severity-low]="(component.scores[scoreKey] * 10) >= 6 && (component.scores[scoreKey] * 10) < 8" [class.bg-severity-no-risk]="(component.scores[scoreKey] * 10) >= 8 && (component.scores[scoreKey] * 10) <= 10">
                <span class="text-white text-nowrap" *ngIf="(component.scores[scoreKey] * 10) >= 0 && (component.scores[scoreKey] * 10) < 1">
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.CRITICAL' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="(component.scores[scoreKey] * 10) >= 1 && (component.scores[scoreKey] * 10) < 3">
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.HIGH' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="(component.scores[scoreKey] * 10) >= 3 && (component.scores[scoreKey] * 10) < 6">
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.MEDIUM' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="(component.scores[scoreKey] * 10) >= 6 && (component.scores[scoreKey] * 10) < 8">
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.LOW' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="(component.scores[scoreKey] * 10) >= 8 && (component.scores[scoreKey] * 10) <= 10">
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.TABLE_RATINGS.SEVERITY.SAFE' | translate }}
                </span>
              </div>
            </td>
          </tr>
          <!-- <ng-container *ngTemplateOutlet="extensiontemplate; context: evaluation"></ng-container>
          <ng-template #extensiontemplate>
            <ng-container *ngIf="evaluation.expanded">
              <tr *ngFor="let rating of evaluation.ratings">
                  <td class="pl-8 text-sm border-x-1 border-bottom-1 border-blue-light-hover">
                    {{ rating.name }}
                  </td>
                  <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
                    <div class="flex flex-row gap-2">
                      <i class="icon-round-star icon-16 bg-black-black-100"></i>
                      <span>
                        {{ rating.rating }}
                      </span>
                    </div>
                  </td>
                  <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
                    <div class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
                      [class.bg-critical]="rating.rating >= 0 && rating.rating < 1"
                      [class.bg-high]="rating.rating >= 1 && rating.rating < 3"
                      [class.bg-medium]="rating.rating >= 3 && rating.rating < 6"
                      [class.bg-low]="rating.rating >= 6 && rating.rating < 8"
                      [class.bg-no-risk]="rating.rating >= 8 && rating.rating < 10"
                    >
                      <span class="text-white" *ngIf="rating.rating >= 0 && rating.rating < 1">Krytyczna</span>
                      <span class="text-white" *ngIf="rating.rating >= 1 && rating.rating < 3">Wysoka</span>
                      <span class="text-white" *ngIf="rating.rating >= 3 && rating.rating < 6">Średnia</span>
                      <span class="text-white" *ngIf="rating.rating >= 6 && rating.rating < 8">Niska</span>
                      <span class="text-white" *ngIf="rating.rating >= 8 && rating.rating < 10">Bezpieczne</span>
                    </div>
                  </td>
              </tr>
            </ng-container>
          </ng-template> -->
        </ng-container>
      </ng-template>
    </p-table>
  </div>

  <div class="flex flex-row align-items-center justify-content-between mt-6">
    <span class="text-xl font-bold text-blue-darker">
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.SOFTWARE_COMPONENTS.TITLE' | translate }}
    </span>
    <help-button helpText="CLIENT.PRODUCT.REPORT_SECTION.SOFTWARE_COMPONENTS.HELP_TEXT"></help-button>
  </div>
  <p-skeleton styleClass="w-full" height="20rem" *ngIf="isLoading"></p-skeleton>
  <div class="flex flex-column w-full bg-component mt-5 align-items-center justify-content-center gap-5" *ngIf="!isLoading">
    <span class="text-blue-normal font-medium">
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.SOFTWARE_COMPONENTS.DESCRIPTION' | translate }}
    </span>
    <div class="flex flex-row gap-3">
      <p-dropdown [options]="productData.environments" [(ngModel)]="selectedEnvironment" [showClear]="true" [autoDisplayFirst]="false" placeholder="{{ 'CLIENT.PRODUCT.REPORT_SECTION.SOFTWARE_COMPONENTS.SELECT_PLACEHOLDER' | translate }}" class="h-full w-20rem" styleClass="h-full flex flex-row align-items-center">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedEnvironment">
            <i class="icon-server icon-16 bg-grey-dark"></i>
            <div>{{ selectedEnvironment.name | translate }}</div>
          </div>
        </ng-template>
        <ng-template pTemplate="item" let-item>
          <div class="flex flex-row gap-2 align-items-center">
            <i class="icon-16 icon-server bg-grey-dark"></i>
            <span>{{ item.name | translate }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="empty">
          <div class="flex flex-row gap-2 align-items-center">
            <i class="icon-16 icon-server bg-grey-dark"></i>
            <span>
              {{ 'CLIENT.PRODUCT.REPORT_SECTION.SOFTWARE_COMPONENTS.SELECT_PLACEHOLDER' | translate }}
            </span>
          </div>
        </ng-template>
      </p-dropdown>
      <a routerLink="../deployments/{{selectedEnvironment.id}}" class="no-underline text-black-white font-bold bg-blue-normal border-round-lg px-4 py-3 flex flex-row gap-2 align-items-center text-sm" *ngIf="selectedEnvironment">
        <span>
          {{ 'CLIENT.PRODUCT.REPORT_SECTION.SOFTWARE_COMPONENTS.BUTTON' | translate }}
        </span>
        <i class="icon-16 icon-double-chevron-right bg-black-white"></i>
      </a>
    </div>
  </div>

  <p class="text-3xl font-bold text-blue-darker m-0 mt-7">
    {{ 'CLIENT.PRODUCT.REPORT_SECTION.MAIN_SOFTWARE_COMPONENT.TITLE' | translate }}
  </p>

  <div class="flex flex-row align-items-center justify-content-between mt-6">
    <span class="text-xl font-bold text-blue-darker m-0">
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.CONTRIBUTIONS_MAP.TITLE' | translate }}
    </span>
    <help-button helpText="CLIENT.PRODUCT.REPORT_SECTION.CONTRIBUTIONS_MAP.HELP_TEXT"></help-button>
  </div>
  <div class="my-5">
    <span class="text-grey-dark">
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.CONTRIBUTIONS_MAP.DESCRIPTION' | translate }}
    </span>
  </div>
  <div>
    <app-contribution-map
      *ngIf="!isLoading"
      [contributions]="productData.rawContributions">
    </app-contribution-map>
  </div>

  <p class="flex flex-row align-items-center justify-content-between text-xl font-bold text-blue-darker m-0 mt-7">
    <span>
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_ACTIVITY.TITLE' | translate }}
    </span>
    <help-button helpText="CLIENT.PRODUCT.REPORT_SECTION.PROJECT_ACTIVITY.HELP_TEXT"></help-button>
  </p>

  <div class="text-base font-bold text-blue-darker m-0 flex flex-row gap-2 align-items-center mt-4" *ngIf="isLoading">
    <p-skeleton height="1rem" width="1rem"></p-skeleton>
    <p-skeleton height="1rem" width="5rem"></p-skeleton>
  </div>
  <div class="mt-4">
    <p-skeleton styleClass="w-full" height="5rem" *ngIf="isLoading"></p-skeleton>
    <div class="flex flex-row bg-blue-light border-1 border-grey-normal border-round-lg" *ngIf="!isLoading">

      <div class="flex flex-column gap-2 w-4 p-3">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-commits icon-16 bg-blue-darker"></i>
          <span class="font-bold text-base text-blue-darker">
            {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_ACTIVITY.COMMITS_AMOUNT' | translate }}
          </span>
        </div>
        <div class="pl-4 text-grey-normal-active font-medium">
          {{ productData.defaultEnvironment.commitsCount ? (productData.defaultEnvironment.commitsCount | splitNumber: ' ') : ('CLIENT.NO_DATA' | translate) }}
        </div>
      </div>

      <div class="flex flex-column gap-2 w-4 p-3">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-commits icon-16 bg-blue-darker"></i>
          <span class="font-bold text-base text-blue-darker">
            {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_ACTIVITY.OPEN_ISSUES_AMOUNT' | translate }}
          </span>
        </div>
        <div class="pl-4 text-grey-normal-active font-medium">
          {{ productData.defaultEnvironment.openIssuesCount ? (productData.defaultEnvironment.openIssuesCount | splitNumber: ' ') : ('CLIENT.NO_DATA' | translate) }}
        </div>
      </div>

      <div class="flex flex-column gap-2 w-4 p-3">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-commits icon-16 bg-blue-darker"></i>
          <span class="font-bold text-base text-blue-darker">
            {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_ACTIVITY.CLOSED_ISSUES_AMOUNT' | translate }}
          </span>
        </div>
        <div class="pl-4 text-grey-normal-active font-medium">
          {{ productData.defaultEnvironment.closedIssuesCount ? (productData.defaultEnvironment.closedIssuesCount | splitNumber: ' ') : ('CLIENT.NO_DATA' | translate) }}
        </div>
      </div>
    </div>
  </div>


  <p class="flex flex-row align-items-center justify-content-between text-xl font-bold text-blue-darker m-0 mt-5">
    <span>
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_STATS.TITLE' | translate }}
    </span>
    <help-button helpText="CLIENT.PRODUCT.REPORT_SECTION.PROJECT_STATS.HELP_TEXT"></help-button>
  </p>

  <div class="mt-4">
    <p-skeleton styleClass="w-full" height="7rem" *ngIf="isLoading"></p-skeleton>
    <p-table [value]="projectStats" dataKey="name" *ngIf="!isLoading">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
            <div class="flex flex-row align-items-center gap-2">
              <i class="icon-16 icon-round-star bg-black-black-100"></i>
              <span>
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_STATS.STARS' | translate }}
              </span>
            </div>
          </th>
          <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
            <div class="flex flex-row align-items-center gap-2">
              <i class="icon-16 icon-people bg-black-black-100"></i>
              <span>
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_STATS.WATCHERS' | translate }}
              </span>
            </div>
          </th>
          <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-2">
            <div class="flex flex-row align-items-center gap-2">
              <i class="icon-16 icon-branch bg-black-black-100"></i>
              <span>
                {{ 'CLIENT.PRODUCT.REPORT_SECTION.PROJECT_STATS.FORKS' | translate }}
              </span>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stats let-expanded="expanded">
        <tr>
          <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-center">
            <span>
              {{ stats.stars | splitNumber: ' ' }}
            </span>
          </td>
          <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover text-center">
            <span>
              {{ stats.followers | splitNumber: ' ' }}
            </span>
          </td>
          <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover text-center">
            <span>
              {{ stats.contributors | splitNumber: ' ' }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p class="flex flex-row align-items-center justify-content-between text-xl font-bold text-blue-darker mt-6" *ngIf="productData.contributions.length > 0">
    <span>
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.TOP_5_LOCATIONS.TITLE' | translate }}
    </span>
    <help-button helpText="CLIENT.PRODUCT.REPORT_SECTION.TOP_5_LOCATIONS.HELP_TEXT"></help-button>
  </p>

  <div class="mt-4 flex flex-column md:flex-row gap-4" *ngIf="productData.contributions.length > 0">
    <p-skeleton styleClass="w-full flex-grow-1" class="w-full flex-grow-1" height="10rem" *ngIf="isLoading"></p-skeleton>
    <p-skeleton styleClass="w-full" class="w-9" height="10rem" *ngIf="isLoading"></p-skeleton>
    <div class="flex w-full flex-grow-1" *ngIf="!isLoading">
      <p-table [value]="productData.contributions!.slice(0, 5)" dataKey="name" class="w-full">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
              <div class="flex flex-row align-items-center gap-2">
                <i class="icon-16 icon-location bg-black-black-100"></i>
                <span>
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.TOP_5_LOCATIONS.TABLE.HEADER.NAME' | translate }}
                </span>
              </div>
            </th>
            <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
              <div class="flex flex-row align-items-center gap-2">
                <i class="icon-16 icon-people bg-black-black-100"></i>
                <span>
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.TOP_5_LOCATIONS.TABLE.HEADER.CONTRIBUTORS' | translate }}
                </span>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-stats let-expanded="expanded">
          <tr>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-center">
              <span>
                {{ stats.name }}
              </span>
            </td>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-center">
              <span>
                {{ stats.value / productData.totalContributions * 100 | number: '1.1-2' }}% ({{ stats.value | splitNumber: ',' }})
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <app-chart
      [inputData]="topContributors"
      [chartId]="'contributors'"
      class="flex flex-row flex-grow-1 md:w-9 h-19rem"
      *ngIf="!isLoading"
    ></app-chart>
  </div>

  <p class="flex flex-row align-items-center justify-content-between text-xl font-bold text-blue-darker m-0 mt-5" *ngIf="productData.programmingLanguages.length > 0">
    <span>
      {{ 'CLIENT.PRODUCT.REPORT_SECTION.LANGUAGES.TITLE' | translate }}
    </span>
    <help-button helpText="CLIENT.PRODUCT.REPORT_SECTION.LANGUAGES.HELP_TEXT"></help-button>
  </p>
  <div class="mt-4 flex flex-column md:flex-row gap-4" *ngIf="productData.programmingLanguages.length > 0">
    <p-skeleton styleClass="w-full flex-grow-1" class="w-full flex-grow-1" height="10rem" *ngIf="isLoading"></p-skeleton>
    <p-skeleton styleClass="w-full" class="w-9" height="10rem" *ngIf="isLoading"></p-skeleton>
    <div class="flex w-full flex-grow-1" *ngIf="!isLoading">
      <p-table [value]="productData.programmingLanguages.slice(0, 5)" dataKey="name" class="w-full">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
              <div class="flex flex-row align-items-center gap-2">
                <i class="icon-16 icon-globe bg-black-black-100"></i>
                <span>
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.LANGUAGES.TABLE.HEADER.NAME' | translate }}
                </span>
              </div>
            </th>
            <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
              <div class="flex flex-row align-items-center gap-2">
                <i class="icon-16 icon-lines bg-black-black-100"></i>
                <span>
                  {{ 'CLIENT.PRODUCT.REPORT_SECTION.LANGUAGES.TABLE.HEADER.VALUE' | translate }}
                </span>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-stats let-expanded="expanded">
          <tr>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-center">
              <span>
                {{ stats.name }}
              </span>
            </td>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-center">
              <span>
                {{ stats.value / productData.totalLinesOfCode * 100 | number: '1.1-2' }}% ({{ stats.value | splitNumber: ',' }} LOC)
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <app-chart
      [inputData]="topProgrammingLangs"
      [chartId]="'languages'"
      class="flex flex-row flex-grow-1 w-full md:w-9 h-19rem"
      *ngIf="!isLoading"
    ></app-chart>
  </div>
</div>
