<div class="flex flex-column gap-5 bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover">
  <div class="flex flex-row gap-3 align-items-center">
    <i class="icon-support icon-24 bg-blue-darker"></i>
    <span class="text-blue-darker text-3xl font-bold">
      {{ 'CLIENT.PROFILE.ASK_EXPERT.TITLE' | translate }}
    </span>
  </div>

  <span class="text-grey-dark-hover text-base w-10">
    {{ 'CLIENT.PROFILE.ASK_EXPERT.DESCRIPTION' | translate }}
  </span>

  <div class="flex flex-column-reverse md:flex-row gap-4 md:gap-0">
    <form class="flex flex-column gap-3 md:w-7" [formGroup]="formGroup" (ngSubmit)="submit()">
      <div class="flex flex-column md:flex-row gap-3">
        <div class="w-full">
          <input
            type="text"
            formControlName="firstName"
            required
            pInputText
            placeholder="{{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.FIRST_NAME' | translate }}*"
            class="w-full bg-blue-light border-blue-light-hover"
          />
          <div *ngIf="formGroup.get('firstName')?.touched && formGroup.get('firstName')?.invalid">
            <div class="p-message-error">
              {{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.FIRST_NAME.INCORRECT' | translate }}
            </div>
          </div>
        </div>
        <div class="w-full">
          <input
            type="text"
            formControlName="lastName"
            required
            pInputText
            placeholder="{{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.LAST_NAME' | translate }}*"
            class="w-full bg-blue-light border-blue-light-hover"
          />
          <div *ngIf="formGroup.get('lastName')?.touched && formGroup.get('lastName')?.invalid">
            <div class="p-message-error">
              {{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.LAST_NAME.INCORRECT' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column md:flex-row gap-3">
        <div class="w-full">
          <input
            type="text"
            formControlName="email"
            required
            pInputText
            placeholder="{{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.EMAIL' | translate }}*"
            class="w-full bg-blue-light border-blue-light-hover"
          />
          <div *ngIf="formGroup.get('email')?.touched && formGroup.get('email')?.invalid">
            <div class="p-message-error">
              {{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.EMAIL.INCORRECT' | translate }}
            </div>
          </div>
        </div>
        <div class="w-full">
          <input
            type="text"
            formControlName="phone"
            required
            pInputText
            placeholder="{{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.PHONE_NUMBER' | translate }}*"
            class="w-full bg-blue-light border-blue-light-hover"
          />
          <div *ngIf="formGroup.get('phone')?.touched && formGroup.get('phone')?.invalid">
            <div class="p-message-error">
              {{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.PHONE_NUMBER.INCORRECT' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column md:flex-row gap-3">
        <input type="text" formControlName="company" pInputText placeholder="{{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.COMPANY_NAME' | translate }}" class="w-full bg-blue-light border-blue-light-hover" />
        <input type="text" formControlName="job" pInputText placeholder="{{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.JOB_POSITION' | translate }}" class="w-full bg-blue-light border-blue-light-hover" />
      </div>
      <textarea
        rows="10"
        formControlName="message"
        required
        pInputTextarea
        placeholder="{{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.MESSAGE' | translate }}"
        class="bg-blue-light border-blue-light-hover">
      </textarea>
      <div *ngIf="formGroup.get('message')?.touched && formGroup.get('message')?.invalid">
        <div class="p-message-error">
          {{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.MESSAGE.INCORRECT' | translate }}
        </div>
      </div>
      <p-button type="submit" styleClass="w-6 justify-content-center font-bold text-sm py-3 px-4" [disabled]="formGroup.invalid">
        {{ 'CLIENT.PROFILE.ASK_EXPERT.FORM.SEND_TICKET' | translate }}
      </p-button>
    </form>
    <div id="checkBot" class="flex flex-grow-1 justify-content-center"></div>
  </div>
</div>
