
<div class="flex flex-column justify-content-center align-items-center mb-8 mt-2 md:mt-8 md:px-8">
  <div class="container w-full flex flex-column gap-3 px-4 md:px-8" *ngIf="translate.currentLang.toLowerCase() === 'pl'">

    <div>
      <span class="font-bold text-4xl">REGULAMIN Z DNIA 15.04.2024 R. KORZYSTANIA ZE STRONY SOURCEMATION
      </span>
    </div>
    <div>
      <span class="num-paragraph">§1. DEFINICJE</span>
    </div>
    <div>
      <ol>
        <li>
          Cookies / Pliki cookie – małe pliki tekstowe przechowywane na urządzeniu Użytkownika przez przeglądarkę internetową podczas odwiedzania witryny internetowej.
        </li>
        <li>
          Regulamin – niniejszy dokument, którego celem jest zapewnienie zgodnego z prawem korzystania przez Użytkowników ze Strony.
        </li>
        <li>
          Strona – serwis internetowy, obejmujący stronę główną oraz wszelkie podstrony, dostępny pod adresami www.sourcemation.com, www.sourcemation.pl, www.sourcemation.io, www.sourcemation.net, www.sourcemation.eu, www.sourcemation.com.pl, którego właścicielem i administratorem jest Usługodawca, udostępniający określone informacje dotyczące oprogramowania o otwartym kodzie źródłowym, które użytkownik może pobierać i wdrażać w własnym środowisku. Właścicielem serwisu jest Usługodawca.
        </li>
        <li>
          Usługa/Usługi – usługa/usługi polegające na udostępnieniu przez Usługodawcę Użytkownikowi Strony, świadczona na rzecz Użytkowników przez Usługodawcę drogą elektroniczną zgodnie z przepisami ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną.
        </li>
        <li>
          Usługodawca – Linux Polska Sp. z o.o. z siedzibą w Warszawie (00-807), przy ul. Al. Jerozolimskie 100, wpisana do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Miasta Stołecznego Warszawy XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000326158, NIP 7010181018, REGON 141791601, e-mail biuro@linuxpolska.pl.
        </li>
        <li>
          Użytkownik – pełnoletnia osoba fizyczna, posiadająca pełną zdolność do czynności prawnych, korzystająca ze Strony.
        </li>
      </ol>
    </div>
    <div>
      <span class="num-paragraph">§2. POSTANOWIENIA OGÓLNE</span>
    </div>
    <div>
      <ol>
        <li>
          Regulamin określa zasady korzystania ze Strony przez Użytkowników.
        </li>
        <li>
          Korzystanie ze Strony oraz funkcji w niej dostępnych ma charakter nieodpłatny.
        </li>
        <li>
          Przed dokonaniem czynności wskazanych w ustępie powyżej, Usługodawca udostępnia Użytkownikom Regulamin.
        </li>
        <li>
          Użytkownik może korzystać ze Strony i jej funkcjonalności pod warunkiem zaakceptowania Regulaminu.
        </li>
        <li>
          Od momentu akceptacji Regulaminu Użytkownik jest zobowiązany do przestrzegania jego postanowień.
        </li>
        <li>
          Wyłączne prawa do treści udostępnianych na Stronie, w szczególności nazwy Strony, znaku towarowego, wchodzących w jego skład elementów graficznych, oprogramowania Strony oraz prawa w zakresie baz danych podlegają ochronie prawnej i przysługują Usługodawcy lub podmiotom, z którymi Usługodawca zawarł stosowne umowy.
        </li>
        <li>
          Zapisy ustępu poprzedzającego nie mają zastosowanie do ocenianego na Stronie przez system oceny ryzyka oprogramowania o otwartym kodzie źródłowym, którego producentem lub autorem nie jest Usługodawca lub jego producentem lub autorem jest strona trzecia.
        </li>
        <li>
          Strona korzysta z plików cookies do przechowywania informacji na temat działań Użytkownika na Stronie, takich jak preferencje, ustawienia, dane logowania, śledzenie zachowań użytkownika oraz inne informacje potrzebne do zapewnienia odpowiedniego funkcjonowania Strony.
        </li>
      </ol>
    </div>
    <div>
      <span class="num-paragraph">§3. WYMOGI TECHNICZNE</span>
    </div>
    <div>
      <ol>
        <li>
          Strona przeznaczona jest dla użytkowników urządzeń desktopowych z systemem operacyjnym Windows, macOS lub Linux oraz przeglądarką internetową Google Chrome, Mozilla Firefox lub Microsoft Edge.
        </li>
        <li>
          Celem poprawnego uruchomienia i korzystania ze Strony urządzenie musi spełniać następujące wymagania:
          <ol>
            <li>
              posiadać sprzęt komputerowy z parametrami co najmniej 4 GB pamięci RAM oraz procesorem co najmniej 1,6 GHz,
            </li>
            <li>
              posiadać dostęp do sieci Internet,
            </li>
            <li>
              posiadać oprogramowanie umożliwiające przeglądanie Internetu,
            </li>
            <li>
              posiadać konto poczty elektronicznej.
            </li>
          </ol>
        </li>
        <li>
          Koszty dostępu do internetu oraz transmisji danych, z których korzysta Użytkownik celem skorzystania ze Strony obciążają Użytkownika. Usługodawca ww. kosztów nie pokrywa oraz za nie nie odpowiada. Koszty transmisji danych określa operator lub dostawca usług telekomunikacyjnych, z których korzysta Użytkownik.
        </li>
      </ol>
    </div>
    <div>
      <span class="num-paragraph">§4. ZAWARCIE UMOWY I REZYGNACJA</span>
    </div>
    <div>
      <ol>
        <li>
          Celem rozpoczęcia korzystania ze Strony należy wpisać adres URL: www.sourcemation.com, www.sourcemation.pl, www.sourcemation.io, www.sourcemation.net, www.sourcemation.eu, lub www.sourcemation.com.pl w przeglądarkę internetową.
        </li>
        <li>
          Użytkownik ma możliwość stworzenia w ramach Strony swojego profilu, w ramach którego może przechowywać wybrane pozycje oprogramowania ocenianego na stronie. Dostęp do profilu Użytkownika zabezpieczony jest loginem oraz hasłem, nadawanym przez Użytkownika. Dostęp do profilu Użytkownika wymaga zalogowania się.
        </li>
        <li>
          Umowa o świadczenie usług zostaje zawarta:
          <ol>
            <li>
              w momencie wyświetlenia Strony poprzez wpisanie przez Użytkownika w przeglądarce internetowej adresu URL lub wyświetlenia Strony w wyniku kliknięcie w link przekierowujący do niej,
            </li>
            <li>
              w przypadku możliwości stworzenia profilu - w momencie założenia profilu przez Użytkownika.
            </li>
          </ol>
        </li>
        <li>
          Umowa zostaje zawarta na czas nieokreślony i jest wykonywana na zasadach wskazanych w Regulaminie.
        </li>
        <li>
          Korzystanie ze Strony oraz informacji na niej zawartych ma charakter nieodpłatny.
        </li>
        <li>
          W każdym czasie Użytkownik może wypowiedzieć umowę na świadczenie usług drogą elektroniczną poprzez usunięcie swojego profilu ze Strony oraz zaprzestanie korzystania ze Strony.
        </li>
        <li>
          Użytkownik nie ma obowiązku tworzenia swojego profilu na Stronie, wówczas nie będzie miał możliwości korzystania ze wszystkich usług udostępnianych na Stronie, tj. funkcji dostępnych po założeniu profilu Użytkownika.
        </li>
      </ol>
    </div>
    <div>
      <span class="num-paragraph">§5. ZAKRES USŁUG DOSTĘPNYCH NA STRONIE</span>
    </div>
    <div>
      <ol>
        <li>
          Usługodawca, w zakresie i na warunkach określonych w niniejszym Regulaminie świadczy Użytkownikowi usługę polegającą na nieodpłatnym udostępnieniu Strony wraz z jej funkcjami.
        </li>
        <li>
          Strona służy jako platforma informacyjna na temat oprogramowania open source oraz oceny ryzyka użycia oprogramowania open source.
        </li>
        <li>
          W ramach Strony Usługodawca udostępnia:
          <ol>
            <li>
              informacje dotyczące oprogramowania o otwartym kodzie źródłowym, które użytkownik może pobierać, wdrażać oraz uruchamiać w wybranym przez siebie środowisku IT,
            </li>
            <li>
              informacje dotyczące ryzyka oprogramowania o otwartym kodzie źródłowym, które oceniane jest w oparciu o wskaźniki ryzyka wskazane na Stronie,
            </li>
            <li>
              możliwość kontaktu z zespołem ekspertów Usługodawcy w celu uzyskania dodatkowych informacji lub skorzystania z usług eksperckich Usługodawcy,
            </li>
            <li>
              możliwość skorzystania z dodatkowo płatnych, personalizowanych usług eksperckich poprzez formularz kontaktu dostępny na Stronie,
            </li>
            <li>
              dostęp do bazy wiedzy, która zawiera zbiór dobrych praktyk oraz ciekawostek dotyczących dostępnego oprogramowania na Stronie,
            </li>
            <li>
              dostępne są artykuły dotyczące wykorzystania oprogramowania open source oraz praktyk związanych z bezpieczeństwem.
            </li>
            <li>
              możliwość stworzenia swojego profilu oraz personalizacji listy wybranego oprogramowania.
            </li>
          </ol>
        </li>
        <li>
          Wszelkie treści zamieszczone na Stronie mają charakter wyłącznie informacyjny i edukacyjny. Usługodawca nie ponosi odpowiedzialności za dokładność, kompletność ani aktualność zamieszczonych informacji. Treści te nie stanowią porad ani zaleceń, a Użytkownicy korzystają z nich na własne ryzyko.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§6. ZASADY KORZYSTANIA ZE STRONY</span>
    </div>
    <ol>
      <li>
        Użytkownicy są zobowiązani do korzystania ze Strony wyłącznie w sposób i na zasadach wskazanych w Regulaminie.
      </li>
      <li>
        Użytkownicy są zobowiązani do korzystania ze Strony, w taki sposób, by nie zakłócało to korzystania z niej innym Użytkownikom oraz Usługodawcy.
      </li>
      <li>
        Nie jest dozwolone korzystanie ze Strony w sposób naruszający obowiązujące prawo, przepisy Regulaminu bądź dobre obyczaje.
      </li>
      <li>
        Użytkownik jest zobowiązany niezwłocznie powiadomić Usługodawcę o każdym przypadku naruszenia jego praw, jak również o jakimkolwiek przypadku naruszenia zasad określonych w Regulaminie.
      </li>
      <li>
        Usługodawca może wstrzymać dostęp Użytkownikowi do Strony, w tym usunąć profil , jeśli korzysta on z niej dla celów niezgodnych z jej przeznaczeniem, działa na szkodę osób trzecich lub Usługodawcy, narusza obowiązujące prawo, postanowienia Regulaminu bądź dobre obyczaje.
      </li>
    </ol>

    <div>
      <span class="num-paragraph">§7. ODPOWIEDZIALNOŚĆ USŁUGODAWCY</span>
    </div>
    <div>
      <ol>
        <li>
          Usługodawca nie ponosi odpowiedzialności za wadliwe działanie Strony z przyczyn niezależnych od Usługodawcy, w tym z przyczyn leżących po stronie osób trzecich bądź też będących następstwem siły wyższej.
        </li>
        <li>
          Usługodawca nie ponosi odpowiedzialności za szkody powstałe w wyniku używania oprogramowania udostępnionego na Stronie, którego dostawcą są osoby trzecie.
        </li>
        <li>
          Usługodawca nie ponosi odpowiedzialności za zdarzenia zaistniałe w czasie korzystania ze Strony, a wynikające z działania osób trzecich lub siły wyższej.
        </li>
        <li>
          Użytkownik może korzystać ze Strony oraz treści zamieszczonych na Stronie wyłącznie w celach informacyjnych. Nie wolno reprodukować, publikować ani dystrybuować treści w żadnej formie bez zgody pisemnej Usługodawcy lub właścicieli odpowiednich praw.
        </li>
        <li>
          Użytkownik nie może korzystać ze Stron w celach innych niż te wskazane w Regulaminie. Użycie przez Użytkownika Strony w sposób sprzeczny z Regulaminem uprawnia Usługodawcę do żądania naprawienia powstałej w ten sposób szkody na zasadach ogólnych.
        </li>
        <li>
          Użytkownik zakładając profil zobowiązany jest posługiwać się prawdziwymi danymi oraz założyć profil w imieniu własnym i na swoją rzecz.
        </li>
        <li>
          Strona udostępniana Użytkownikom jest dostępna w wersji aktualnej na dzień udostępnienia. Podlega on rozwojowi co oznacza, że może zawierać błędy. Z uwagi na powyższe Użytkownikowi nie przysługują żadne uprawnienia gwarancyjne z tytułu wadliwego działania Strony.
        </li>
        <li>
          Usługodawca nie gwarantuje, że oprogramowanie udostępniane na Stronie jest wolne od wad oraz szkodliwego oprogramowania, stąd nie ponosi on odpowiedzialności za ewentualne szkody powstałe z tego tytułu.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§8. ODPOWIEDZIALNOŚĆ UŻYTKOWNIKA</span>
    </div>
    <div>
      <ol>
        <li>
          Użytkownik ponosi pełną odpowiedzialność za korzystanie ze Strony oraz za wszelkie działania podejmowane na podstawie informacji na niej zawartych. Usługodawca nie ponosi odpowiedzialności za szkody wynikłe z korzystania ze Strony lub informacji na niej zawartych.
        </li>
        <li>
          Niedozwolone jest udostępnianie przez Użytkownika profilu osobom trzecim. Za szkody powstałe na skutek przypadkowej utraty profilu, danych dostępowych do profilu lub na skutek udostępnienia profilu osobom trzecim odpowiada Użytkownik.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§9. PRAWA AUTORSKIE</span>
    </div>
    <div>
      <ol>
        <li>
          Treści zawarte na Stronie, w tym jej układ, teksty, zdjęcia, elementy graficzne są chronione prawem autorskim. Właścicielem praw autorskich jest Usługodawca, chyba, że zastrzeżono inaczej.
        </li>
        <li>
          Znaki towarowe lub logo użyte na Stronie podlegają ochronie prawnej a i ich wykorzystywanie bez zgody właściciela znaku towarowego jest zabronione. Właścicielem znaku towarowego lub logo jest Usługodawca chyba, że zastrzeżono inaczej.
        </li>
        <li>
          Usługodawca nie ponosi odpowiedzialności za treści udostępnione przez Użytkowników ani za treści znajdujące się na zewnętrznych stronach internetowych linkowanych na Strony.
        </li>
        <li>
          Usługodawca zastrzega sobie prawo do zmiany lub aktualizacji treści na Stronie bez wcześniejszego powiadomienia Użytkownika.
        </li>
        <li>
          Użytkownicy nie są uprawnieni do kopiowania, modyfikowania, reprodukcji, dystrybucji, w całości lub części zawartości udostępnianej na Stronie, bez uprzedniej pisemnej zgody Usługodawcy.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§10. TRYB POSTĘPOWANIA REKLAMACYJNEGO</span>
    </div>
    <div>
      <ol>
        <li>
          Użytkownik ma prawo do złożenia reklamacji do Usługodawcy w związku z niewykonaniem bądź nienależytym wykonaniem zobowiązań wynikających z umowy.
        </li>
        <li>
          Reklamacja powinna być wniesiona na adres poczty elektronicznej biuro@linuxpolska.pl zawierać imię i nazwisko, adres e-mail, opis wad i nieprawidłowości.
        </li>
        <li>
          Usługodawca nie później niż 14 dni od dnia złożenia reklamacji przekaże Użytkownikowi informację czy uznaje reklamację.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§11. ROZSTRZYGANIE SPORÓW </span>
    </div>
    <div>
      <ol>
        <li>
          Umowa podlega jurysdykcji i jest interpretowana zgodnie z prawem polskim, a Strony niniejszym poddają się jurysdykcji sądów polskich.
        </li>
        <li>
          Wszelkie spory powstałe między Stronami, niebędącymi konsumentami, rozstrzygane będą przez sąd właściwy dla siedziby Usługodawcy.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§12. DANE OSOBOWE </span>
    </div>
    <div>
      <ol>
        <li>
          Informacje wymagane przez przepisy prawa dotyczące kwestii danych osobowych Użytkowników zostały zawarte w Polityce Prywatności udostępnionej na Stronie.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§13. POSTANOWIENIA KOŃCOWE </span>
    </div>
    <div>
      <ol>
        <li>
          Regulamin udostępniony jest w języku polskim oraz języku angielskim.
        </li>
        <li>
          Rozstrzyganie ewentualnych sporów powstałych pomiędzy Usługodawcą a Użytkownikiem, zostaje poddane sądom właściwym zgodnie z postanowieniami przepisów prawa.
        </li>
        <li>
          Niniejszy Regulamin może ulec zmianie. Zmiany Regulaminu wchodzą w życie w terminie podanym wraz z informacją o jego zmianie, nie wcześniej jednak niż po upływie 14 dni kalendarzowych od momentu poinformowania o zmianach Regulaminu, z tym zastrzeżeniem, że zmieniony Regulamin będzie wiązać Użytkownika, o ile w terminie 14 dni od dnia otrzymania informacji o jego zmianie nie oświadczy on, że rezygnuje z umowy z uwagi braku akceptacji nowej treści Regulaminu.
        </li>
        <li>
          Informacja o zmianie Regulaminu będzie dostępna na Stronie.
        </li>
        <li>
          Oświadczenie o wypowiedzeniu Użytkownik może złożyć poprzez przesłanie go na adres poczty elektronicznej usługodawcy: contact_sourcemation@linuxpolska.pl. Wypowiedzenie jest skuteczne w momencie doręczenia oświadczenia o wypowiedzeniu Usługodawcy.
        </li>
        <li>
          Regulamin jest dostarczany nieodpłatnie Użytkownikowi (drogą elektroniczną) na każde jego żądanie lub może być pobrany samodzielnie przez Użytkownika za pośrednictwem Strony, z tym że Użytkownik pokrywa własne koszty usług telekomunikacyjne w zakresie dostępu do Internetu i transmisji danych.
        </li>
        <li>
          Usługodawca zastrzega sobie prawo do zaprzestania świadczenia usługi jeśli przestanie to być możliwe z przyczyn niezależnych od Usługodawcy jak również do okresowego wyłączania usługi w celu jej rozbudowy lub konserwacji o czym poinformuje Użytkownika z odpowiednim wyprzedzeniem, jak również do incydentalnych przerw z przyczyn technicznych w dostępie do usługi.
        </li>
        <li>
          W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy prawa obowiązującego na terytorium Rzeczypospolitej Polskiej, a w szczególności Kodeksu cywilnego, ustawy z dn. 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, właściwe przepisy normujące ochronę danych osobowych, w tym począwszy od 25 maja 2018 roku Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
        </li>
        <li>
          Usługodawca ma prawo do rozwiązania umowy z zachowaniem miesięcznego okresu wypowiedzenia.
        </li>
        <li>
          Bez uprzedniej zgody Usługodawcy Użytkownik nie może przenieść na osobę trzecią praw z niniejszej umowy.
        </li>
        <li>
          Niniejszy Regulamin wchodzi w życie w dniu 15.04.2024 r.
        </li>
      </ol>
    </div>
  </div>


  <div class="container w-full flex flex-column gap-3 px-4 md:px-8" *ngIf="translate.currentLang.toLowerCase() === 'en'">
    <div>
      <span class="font-bold text-4xl">TERMS OF USE FOR SOURCEMATION WEBSITE AS OF APRIL 15, 2024</span>
    </div>
    <div>
      <span class="num-paragraph">§1. DEFINITIONS</span>
    </div>
    <div>
      <ol>
        <li>
          Cookies / Cookie Files - small text files stored on the User's device by the web browser while visiting a website.
        </li>
        <li>
          Terms of Use - this document aimed at ensuring lawful use of the Website by Users.
        </li>
        <li>
          Website - an online service, including the main page and any subpages, available at the addresses www.sourcemation.com, www.sourcemation.pl, www.sourcemation.io, www.sourcemation.net, www.sourcemation.eu, www.sourcemation.com.pl, owned and administered by the Service Provider, providing specific information about open-source software that users can download and deploy in their own environment. The service is owned by the Service Provider.
        </li>
        <li>
          Service(s) - service(s) consisting of providing the User with access to the Website, provided to Users by the Service Provider electronically in accordance with the provisions of the Act of July 18, 2002, on the provision of electronic services.
        </li>
        <li>
          Service Provider - Linux Polska Sp. z o.o. with its registered office in Warsaw (00-807), at ul. Al. Jerozolimskie 100, entered into the Register of Entrepreneurs kept by the District Court for the Capital City of Warsaw, XII Commercial Division of the National Court Register, under KRS number 0000326158, NIP 7010181018, REGON 141791601, email: biuro@linuxpolska.pl.
        </li>
        <li>
          User - an adult natural person with full legal capacity who uses the Website.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§2. GENERAL PROVISIONS</span>
    </div>
    <div>
      <ol>
        <li>
          The Regulations determine the rules for using the Website by Users.
        </li>
        <li>
          The use of the Website and its available functions is free of charge.
        </li>
        <li>
          Before performing the actions indicated above, the Service Provider provides Users with the Regulations.
        </li>
        <li>
          The User may use the Website and its functionalities provided that they accept the Regulations.
        </li>
        <li>
          Upon acceptance of the Regulations, the User is obliged to comply with its provisions.
        </li>
        <li>
          The exclusive rights to the content made available on the Website, including the name of the Website, trademark, graphic elements constituting it, Website software, and rights to the extent of databases, are legally protected and belong to the Service Provider or entities with which the Service Provider has concluded appropriate agreements.
        </li>
        <li>
          The provisions of the preceding paragraph do not apply to the software risk assessment system evaluated on the Website, the producer or author of which is not the Service Provider, or the producer or author of which is a third party.
        </li>
        <li>
          The Website uses cookies to store information about User's actions on the Website, such as preferences, settings, login data, tracking user behavior, and other information necessary for the proper functioning of the Website.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§3. TECHNICAL REQUIREMENTS</span>
    </div>
    <div>
      <ol>
        <li>
          The Website is intended for desktop device users with Windows, macOS, or Linux operating systems and the Google Chrome, Mozilla Firefox, or Microsoft Edge web browsers.
        </li>
        <li>
          For successful website operation and use, the device must meet the following requirements:
          <ol>
            <li>
              Have computer hardware with at least 4 GB of RAM and a processor clocked at least 1.6 GHz.
            </li>
            <li>
              Have access to the Internet.
            </li>
            <li>
              Have software enabling Internet browsing.
            </li>
            <li>
              Have an email account.
            </li>
          </ol>
        </li>
        <li>
          The costs of Internet access and data transmission used by the User to access the Website are the User's responsibility. The Service Provider does not cover or bear the aforementioned costs. The costs of data transmission are determined by the operator or telecommunications service provider used by the User.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§4. CONCLUSION OF AGREEMENT AND RESIGNATION</span>
    </div>
    <div>
      <ol>
        <li>
          To begin using the Website, the User should enter the URL address: www.sourcemation.com, www.sourcemation.pl, www.sourcemation.io, www.sourcemation.net, www.sourcemation.eu, or www.sourcemation.com.pl into their web browser.
        </li>
        <li>
          The User has the option to create their profile within the Website, where they can store selected software items evaluated on the site. Access to the User's profile is secured by a login and password chosen by the User. Accessing the User's profile requires logging in.
        </li>
        <li>
          The agreement for the provision of services is concluded:
          <ol>
            <li>
              when the User views the Website by entering the URL address into their web browser or by clicking on a link redirecting to it,
            </li>
            <li>
              in the case of the possibility to create a profile - when the User creates a profile.
            </li>
          </ol>
        </li>
        <li>
          The agreement is concluded for an indefinite period and is executed in accordance with the provisions of the Regulations.
        </li>
        <li>
          The use of the Website and the information contained therein is free of charge.
        </li>
        <li>
          At any time, the User may terminate the agreement for the provision of electronic services by deleting their profile from the Website and ceasing to use the Website.
        </li>
        <li>
          The User is not obliged to create their profile on the Website; in this case, they will not have access to all the services available on the Website, i.e., functions available after creating a User profile.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§5. SCOPE OF SERVICES AVAILABLE ON THE WEBSITE</span>
    </div>
    <div>
      <ol>
        <li>
          The Service Provider, within the scope and under the conditions specified in this Regulations, provides the User with a service consisting of the free provision of the Website along with its functions.
        </li>
        <li>
          The Website serves as an informational platform about open-source software and risk assessment of open-source software usage.
        </li>
        <li>
          Within the Website, the Service Provider provides:
          <ol>
            <li>
              Information about open-source software that users can download, deploy, and run in their chosen IT environment.
            </li>
            <li>
              Information about the risk of open-source software, which is assessed based on risk indicators indicated on the Website.
            </li>
            <li>
              The possibility to contact the Service Provider's team of experts to obtain additional information or to use the Service Provider's expert services.
            </li>
            <li>
              The possibility to use additionally paid, personalized expert services through the contact form available on the Website.
            </li>
            <li>
              Access to a knowledge base containing a collection of best practices and interesting facts about the available software on the Website.
            </li>
            <li>
              Articles on the use of open-source software and security-related practices are available.
            </li>
            <li>
              The possibility to create a user profile and customize the list of selected software.
            </li>
          </ol>
        </li>
        <li>
          All content posted on the Website is for informational and educational purposes only. The Service Provider does not take responsibility for the accuracy, completeness, or timeliness of the information provided. This content does not constitute advice or recommendations, and Users use it at their own risk.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§6. RULES OF USING THE WEBSITE</span>
    </div>
    <div>
      <ol>
        <li>
          Users are obligated to use the Website only in the manner and according to the rules specified in the Regulations.
        </li>
        <li>
          Users are required to use the Website in a way that does not disrupt its use by other Users or the Service Provider.
        </li>
        <li>
          It is not allowed to use the Website in a manner that violates applicable law, the provisions of the Regulations, or good manners.
        </li>
        <li>
          The User is obligated to promptly notify the Service Provider of any infringement of their rights, as well as any violation of the rules specified in the Regulations.
        </li>
        <li>
          The Service Provider may suspend the User's access to the Website, including deleting the profile, if the User uses it for purposes inconsistent with its purpose, acts to the detriment of third parties or the Service Provider, violates applicable law, the provisions of the Regulations, or good manners.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§7. SERVICE PROVIDER'S LIABILITY</span>
    </div>
    <div>
      <ol>
        <li>
          The Service Provider shall not be liable for the faulty operation of the Website due to reasons beyond the Service Provider's control, including reasons attributable to third parties or force majeure.
        </li>
        <li>
          The Service Provider shall not be liable for damages resulting from the use of software provided on the Website by third-party suppliers.
        </li>
        <li>
          The Service Provider shall not be liable for events occurring during the use of the Website and resulting from the actions of third parties or force majeure.
        </li>
        <li>
          Users may use the Website and its content solely for informational purposes. Reproducing, publishing, or distributing content in any form without the written consent of the Service Provider or the owners of the respective rights is prohibited.
        </li>
        <li>
          Users may not use the Website for purposes other than those specified in the Regulations. If the User uses the Website in a manner contrary to the Regulations, the Service Provider is entitled to demand compensation for any resulting damage in accordance with general principles.
        </li>
        <li>
          When creating a profile, the User is obligated to use genuine data and create the profile in their own name and for their own benefit.
        </li>
        <li>
          The version of the Website provided to Users is available in its current version on the day of provision. It is subject to development, which means it may contain errors. Therefore, Users are not entitled to any warranty rights due to the faulty operation of the Website.
        </li>
        <li>
          The Service Provider does not guarantee that the software provided on the Website is free from defects and harmful software, and therefore, it shall not be liable for any damages resulting from this.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§8. USER'S LIABILITY</span>
    </div>
    <div>
      <ol>
        <li>
          The User is fully responsible for using the Website and for any actions taken based on the information contained therein. The Service Provider shall not be liable for any damages resulting from the use of the Website or the information contained therein.
        </li>
        <li>
          It is prohibited for the User to share their profile with third parties. The User is responsible for any damages resulting from accidental loss of the profile, access data to the profile, or sharing the profile with third parties.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§9. COPYRIGHTS</span>
    </div>
    <div>
      <ol>
        <li>
          The content contained on the Website, including its layout, texts, images, and graphic elements, is protected by copyright law. The owner of the copyrights is the Service Provider unless otherwise stated.
        </li>
        <li>
          Trademarks or logos used on the Website are subject to legal protection, and their use without the owner's permission is prohibited. The owner of the trademark or logo is the Service Provider unless otherwise stated.
        </li>
        <li>
          The Service Provider shall not be liable for content provided by Users or for content found on external websites linked to the Website.
        </li>
        <li>
          The Service Provider reserves the right to change or update the content on the Website without prior notice to the User.
        </li>
        <li>
          Users are not entitled to copy, modify, reproduce, distribute, in whole or in part, the content provided on the Website without the prior written consent of the Service Provider
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§10. PROCEDURE FOR COMPLAINT HANDLING</span>
    </div>
    <div>
      <ol>
        <li>
          The User has the right to file a complaint with the Service Provider regarding non-performance or improper performance of obligations arising from the contract.
        </li>
        <li>
          The complaint should be submitted to the email address biuro@linuxpolska.pl and include the user's full name, email address, and a description of the defects and irregularities.
        </li>
        <li>
          The Service Provider shall, no later than 14 days from the date of filing the complaint, inform the User whether the complaint is acknowledged.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§11. DISPUTE RESOLUTION</span>
    </div>
    <div>
      <ol>
        <li>
          The agreement is subject to the jurisdiction and interpreted in accordance with Polish law, and the Parties hereby submit to the jurisdiction of Polish courts.
        </li>
        <li>
          Any disputes arising between the Parties, which are not consumers, shall be resolved by the court having jurisdiction over the Service Provider's registered office.
        </li>
      </ol>
    </div>


    <div>
      <span class="num-paragraph">§12. PERSONAL DATA</span>
    </div>
    <div>
      <ol>
        <li>
          The information required by law regarding the personal data of Users has been included in the Privacy Policy available on the Website.
        </li>
      </ol>
    </div>

    <div>
      <span class="num-paragraph">§13. FINAL PROVISIONS</span>
    </div>
    <div>
      <ol>
        <li>
          The regulations are available in both Polish and English.
        </li>
        <li>
          Any disputes arising between the Service Provider and the User shall be subject to the jurisdiction of the courts in accordance with the provisions of the law.
        </li>
        <li>
          This Regulations may be subject to change. Changes to the Regulations come into effect within the period specified with the information about its change, however, no earlier than 14 calendar days from the date of notification of the changes to the Regulations, provided that the amended Regulations shall bind the User, unless within 14 days from the date of receiving information about its change, the User declares that he resigns from the agreement due to lack of acceptance of the new content of the Regulations.
        </li>
        <li>
          Information about the change of the Regulations will be available on the Website.
        </li>
        <li>
          The User's declaration of termination may be made by sending it to the Service Provider's email address: contact_sourcemation@linuxpolska.pl. Termination is effective upon delivery of the termination statement to the Service Provider.
        </li>
        <li>
          The Regulations are provided free of charge to the User (electronically) at his request or can be downloaded independently by the User through the Website, provided that the User bears his own telecommunication service costs in terms of Internet access and data transmission.
        </li>
        <li>
          The Service Provider reserves the right to cease providing the service if it becomes impossible for reasons beyond the Service Provider's control, as well as to periodically disable the service for expansion or maintenance purposes, of which the User will be notified in advance, as well as to incidental interruptions for technical reasons in access to the service.
        </li>
        <li>
          Matters not regulated in these Regulations are subject to the provisions of the law applicable in the territory of the Republic of Poland, and in particular the Civil Code, the Act of 18 July 2002 on the provision of electronic services, relevant provisions regulating the protection of personal data, including from 25 May 2018, Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.
        </li>
        <li>
          The Service Provider has the right to terminate the agreement with one month's notice.
        </li>
        <li>
          Without the prior consent of the Service Provider, the User cannot transfer the rights from this agreement to a third party.
        </li>
        <li>
          These Regulations come into force on 15.04.2024.
        </li>
      </ol>
    </div>

  </div>
</div>
