import { Component, OnInit } from '@angular/core';
import { UserManager } from '../services/user-manager.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-activate-email-page',
  templateUrl: './activate-email-page.component.html',
  styleUrls: ['./activate-email-page.component.scss']
})
export class ActivateEmailPageComponent implements OnInit {
  public isLoading: boolean = true;
  public errored: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userManager: UserManager,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    try { 
      const { activationTokenId } = await firstValueFrom(this.route.params);
      await this.userManager.activateNewEmail(activationTokenId);
    } catch (err) {
      this.errored = true;
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

}
