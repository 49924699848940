<div class="mt-6 min-w-0 max-w-full">

  <p class="text-3xl font-bold text-blue-darker m-0">
    {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.TITLE' | translate }}
  </p>

  <p class="text-grey-dark my-5">
    {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.DESCRIPTION' | translate }}
  </p>

  <div class="flex flex-row flex-wrap align-items-start align-self-stretch justify-content-between min-w-0 column-gap-4 row-gap-4 md:row-gap-0">
    <a routerLink="/knowledge-base" class="flex flex-row bg-black-white border-blue-light-hover border-1 border-round-sm knowledge-card gap-6 align-items-center justify-content-between no-underline overflow-hidden w-full md:w-5">
      <div class="pl-5 pt-5 inline-flex flex-wrap flex-column gap-2 align-self-start w-5">
        <p class="m-0 title text-blue-darker font-semibold">
          {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.FIRST_CARD.TITLE' | translate }}
        </p>
        <p class="text-grey-dark">
          {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.FIRST_CARD.DESCRIPTION' | translate }}
        </p>
      </div>
      <div class="wrapper flex-1 flex-shrink-0 flex-grow-1 w-4 h-full">
        <img src="assets/baza-wiedzy.png" alt="" class="object-position-left">
      </div>
    </a>

    <a routerLink="/about-us" fragment="contact" class="flex flex-row bg-black-white border-blue-light-hover border-1 border-round-sm expert-deployment gap-6 align-items-center justify-content-between no-underline overflow-hidden w-full md:w-6 flex-grow-1">
      <div class="pl-5 pt-5 inline-flex flex-wrap flex-column gap-2 align-self-start w-5">
        <p class="m-0 title text-blue-darker font-semibold">
          {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.SECOND_CARD.TITLE' | translate }}
        </p>
        <p class="text-grey-dark">
          {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.SECOND_CARD.DESCRIPTION' | translate }}
        </p>
      </div>
      <div class="wrapper flex-1 flex-shrink-0 flex-grow-1 w-6 h-full">
        <img src="assets/wdrozenie-eksperckie.png"  alt="" class="object-position-left">
      </div>
    </a>

    <a routerLink="/about-us" fragment="contact" class="flex flex-row bg-black-white border-blue-light-hover border-1 border-round-sm problem-card md:mt-4 justify-content-between no-underline overflow-hidden min-w-0 w-full md:w-12 flex-grow-1">
      <div class="pl-5 pt-5 inline-flex flex-wrap flex-column gap-2 align-self-start w-5">
        <p class="m-0 title text-blue-darker font-semibold">
          {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.THIRD_CARD.TITLE' | translate }}
        </p>
        <p class="text-grey-dark">
          {{ 'CLIENT.PRODUCT.SUPPORT_SECTION.THIRD_CARD.DESCRIPTION' | translate }}
        </p>
      </div>
      <div class="wrapper flex-1 flex-shrink-0 flex-grow-1 w-7">
        <img src="assets/masz-problem.png" alt="" class="object-position-left">
      </div>
    </a>
  </div>
</div>
