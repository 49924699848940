import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {firstValueFrom} from 'rxjs';
import {MyProjectsManagerService} from "../../../services/my-projects-manager.service";
import {Dependencies} from "./dependencies";
import {DetailsTable} from "./details-table";

@Component({
  selector: 'app-my-projects-details-page',
  templateUrl: './my-projects-details-page.component.html',
  styleUrls: ['./my-projects-details-page.component.scss']
})
export class MyProjectsDetailsPageComponent implements OnInit {

  hasError: boolean = false;
  isLoading: boolean = true;
  openedKeys: Record<string, boolean> = {};
  dependencies: Dependencies = {};
  tableData: DetailsTable[] = [];
  tableDataPage: DetailsTable[] = [];
  first = 0;
  itemsPerPage = 10;

  constructor(
    private myProjectsManager: MyProjectsManagerService,
    private route: ActivatedRoute,
  ) {}

  public async ngOnInit() {
    try {
      this.isLoading = true;
      const { item } = await firstValueFrom(this.route.params);
      this.dependencies = await this.myProjectsManager.check(item);
      for(const key in this.dependencies.deps) {
          let value = this.dependencies.deps[key];
          this.tableData.push({"name": key, "reqs": value["required_ver"], "cves": value["vulnerabilities"]});
      }
      this.tableDataPage = this.tableData.slice(this.first, this.itemsPerPage)
    } catch (err) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  public onPageChange(event:any){
    this.first = event.page*this.itemsPerPage;
    this.tableDataPage = this.tableData.slice(event.page*this.itemsPerPage,
                                              event.page*this.itemsPerPage+this.itemsPerPage)
  }

}
