import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { interval } from 'rxjs';
import { GITHUB_INTEGRATE, GOOGLE_INTEGRATE } from 'src/app/brodcast-channels';
import { GITHUB_INTEGRATED, GOOGLE_INTEGRATED } from 'src/app/brodcast-messages';
import { UserManager } from 'src/app/services/user-manager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  public formGroup!: FormGroup;

  public isLoading: boolean = true;

  public hasGoogle: boolean = false;
  public hasGithub: boolean = false;

  constructor(
    private userManager: UserManager,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    this.formGroup = new FormGroup({
      oldPassword: new FormControl(''),
      passwords: new FormGroup({
        newPassword: new FormControl(''),
        confirmNewPassword: new FormControl(''),
      }),
    });

    const { hasGithub, hasGoogle } = await this.userManager.getUserData();

    this.hasGithub = hasGithub;
    this.hasGoogle = hasGoogle;

    this.isLoading = false;
  }

  public async integrateWithGithub() {
    if(this.hasGithub) return;
    this.isLoading = true;
    const w = 485;
    const h = 550;

    const left = (screen.width/2)-(w/2);
    const top = (screen.height/2)-(h/2);
    const url = `https://github.com/login/oauth/authorize?client_id=${environment.githubClientId}&redirect_uri=${window.location.origin}/github-integrate&scope=read:user user:email`;
    const popup = window.open(new URL(url), '_blank', `popup,resizable=0,width=${w},height=${h},top=${top},left=${left}`);
    
    if(!popup) {
      this.isLoading = false;
      return;
    }

    const channel = new BroadcastChannel(GITHUB_INTEGRATE)
    channel.addEventListener('message', async (event) => {
      if(event.origin !== window.origin) return;
      if(event.data !== GITHUB_INTEGRATED) return;
      const response = await this.userManager.getUserData();
      if(!response) return;
      this.hasGithub = response.hasGithub;
      this.hasGoogle = response.hasGoogle;
    });

    popup?.focus();
    const isClosedInterval = interval(500).subscribe(() => {
      if(popup.closed) {
        this.isLoading = false;
        isClosedInterval.unsubscribe();
      }
    });
  }

  public async integrateWithGoogle() {
    if(this.hasGoogle) return;
    const w = 485;
    const h = 550;

    const left = (screen.width/2)-(w/2);
    const top = (screen.height/2)-(h/2);
    const state = (Math.random() + 1).toString(36).substring(7);
    let url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.googleClientId}&redirect_uri=${window.location.origin}/google-integrate&response_type=token&scope=openid email&state=${state}`
    const popup = window.open(new URL(url), '_blank', `popup,resizable=0,width=${w},height=${h},top=${top},left=${left}`);
    popup?.focus();

    if(!popup) {
      return;
    }

    const channel = new BroadcastChannel(GOOGLE_INTEGRATE);
    channel.addEventListener('message', async (event) => {
      if(event.origin !== window.origin) return;
      if(event.data !== GOOGLE_INTEGRATED) return;
      const response = await this.userManager.getUserData();
      if(!response) return;
      this.hasGithub = response.hasGithub;
      this.hasGoogle = response.hasGoogle;
    });

    const isClosedInterval = interval(500).subscribe(() => {
      if(popup?.closed) {
        this.isLoading = false;
        isClosedInterval.unsubscribe();
      }
    });
  }

  public isDeletionOpen: boolean = false;

  public async deleteAccount() {
    if(this.isDeletionOpen) {
      this.isDeletionOpen = false;

      await this.userManager.deleteUser();

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('CLIENT.PROFILE.ACCOUNT.DELETE_ACCOUNT.NOTIFICATION.SUCCESS'),
      });
      return;
    }
    this.isDeletionOpen = true;
  }

  public async submit() {
    try {
      this.isLoading = true;
      const password = this.formGroup.get('oldPassword')?.value;
      const newPassword = this.formGroup.get('passwords')?.get('newPassword')?.value;
      await this.userManager.setNewPassword({
        password,
        newPassword,
      });

      this.formGroup.reset();
    } catch (err) {

    } finally {
      this.isLoading = false;
    }
  }
}
