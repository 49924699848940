<div class="flex flex-column justify-content-center align-items-center">
  <div class="flex flex-column w-full-h-full justify-content-center align-items-center gap-3" style="max-width: 485px; max-height: 550;" *ngIf="isLoading">
    <i class="pi pi-github text-7xl"></i>
    <span class="font-semibold">
      {{ 'CLIENT.GIT_HUB.LOGIN.WAITING_STATE' | translate }}
    </span>
  </div>
  <div class="flex flex-column align-items-center gap-3" *ngIf="!hasError && !isLoading">
    <i class="pi pi-check-circle text-7xl"></i>
    <div class="flex flex-column gap-1 align-items-center">
      <span class="font-semibold text-center">
        {{ 'CLIENT.GIT_HUB.LOGIN.SUCCESS.FIRST_STATEMENT' | translate }}
      </span>
      <span>
        {{ 'CLIENT.GIT_HUB.LOGIN.SUCCESS.SECOND_STATEMENT' | translate }}
      </span>
      <span>
        {{ 'CLIENT.GIT_HUB.LOGIN.SUCCESS.COUNTER' | translate }}
        {{ counter }} {{ 'CLIENT.GIT_HUB.LOGIN.SUCCESS.COUNTER.SECONDS' | translate }}
      </span>
    </div>
  </div>
  <div class="flex flex-column align-items-center gap-3" *ngIf="hasError">
    <i class="pi pi-exclamation-circle text-7xl"></i>
    <span class="font-semibold text-center">
      {{ 'CLIENT.GIT_HUB.LOGIN.FAILURE.TITLE' | translate }}
    </span>
  </div>
</div>
