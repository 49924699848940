import { Injectable } from '@angular/core';
import { BlobExporterService } from './blob-exporter.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductDataService } from '../product-page/product-data.service';
import { DeploymentDataService } from '../deployment-page/deployment-data.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportReportService {

  constructor(
    private blobExporterService: BlobExporterService,
    private translate: TranslateService,
  ) {
    this.blobExporterService.isBlobGenerating.subscribe(value => {
      this.isExporting.next(value);
    });
  }

  public isExporting: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );
  public async exportDeploymentReport(exportType: string, ids: {productId: string, deploymentId: string}) {
    switch(exportType) {
      case 'PDF':
        this.blobExporterService.export(`products/${ids.productId}/deployments/${ids.deploymentId}/export/pdf`, 'report.pdf', 'application/pdf');
        break;
      case 'CSV':
        this.blobExporterService.export(`products/${ids.productId}/deployments/${ids.deploymentId}/export/csv`, 'csvreports.zip', 'application/zip');
        break;
      case 'XLSX':
        this.blobExporterService.export(`products/${ids.productId}/deployments/${ids.deploymentId}/export/xlsx`, 'report.xlsx', 'application/vnd.ms-excel');
        break;
      default:
        return false;
    }
    return true;
  }

  public async exportProductReport(exportType: string, ids: {productId: string}) {
    switch(exportType) {
      case 'PDF':
        this.blobExporterService.export(`products/${ids.productId}/export/pdf`, 'report.pdf', 'application/pdf');
        break;
      case 'CSV':
        this.blobExporterService.export(`products/${ids.productId}/export/csv`, 'csvreports.zip', 'application/zip');
        break;
      case 'XLSX':
        this.blobExporterService.export(`products/${ids.productId}/export/xlsx`, 'report.xlsx', 'application/vnd.ms-excel');
        break;
      default:
        return false;
    }
    return true;
  }

}
