<div class="flex flex-column w-full">
  <div class="flex flex-column w-full">
    <div class="relative flex justify-content-center align-items-center gap-2">
      <ng-container *ngIf="!windowSuppressed && !windowOpen">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
        <span class="absolute font-semibold text-xl">
          {{ counter }}
        </span>
      </ng-container>

      <ng-container *ngIf="windowSuppressed">
        <i class="pi pi-times-circle text-5xl"></i>
      </ng-container>
    </div>
    <p class="text-center text-2xl my-0" *ngIf="!windowSuppressed && !windowOpen">
       {{ 'CLIENT.DEPLOYMENT.INSTALL.DESCRIPTION' | translate }}
    </p>
    <div *ngIf="windowSuppressed" class="flex flex-column justify-content-center align-items-center">
      <p class="text-center text-2xl my-0">
        {{ 'CLIENT.DEPLOYMENT.INSTALL.WINDOW_SUPPRESSED.DESCRIPTION' | translate }}
      </p>
      <a [href]="deploymentData.downloadUrl" target="_blank">
        {{ 'CLIENT.DEPLOYMENT.INSTALL.WINDOW_SUPPRESSED.LINK' | translate }}
      </a>
    </div>
  </div>
  <div class="px-3 md:px-0 ql-editor" [innerHTML]="rendered"></div>
</div>
