import { Component, Input } from '@angular/core';

@Component({
  selector: 'help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent {
  @Input()
  helpText: string = '';
}
