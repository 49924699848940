import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-regulations-page',
  templateUrl: './regulations-page.component.html',
  styleUrls: ['./regulations-page.component.scss']
})
export class RegulationsPageComponent {
  constructor(public translate: TranslateService){}

}
