import { Injectable } from '@angular/core';
import { ApiClient } from './api-client.service';
import { LikeType } from './LikeType';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LikesManager {

  constructor(
    private apiClient: ApiClient,
  ) {}

  like(entityId: string, type: LikeType, productId?: string) {
    return this.apiClient.post('/likes', {
      id: entityId,
      likeType: type,
      productId: productId,
    })
  }

  removeLike(entityId: string) {
    return this.apiClient.delete(`/likes/${entityId}`);
  }

  getLikes(page: number, itemsPerPage: number, type: LikeType) {
    const params = (new HttpParams()).append('type', type);
    return this.apiClient.get<{
      id: string;
      likeId: string;
      name: string;
      summary: string;
      scarmScore: number;
      lastScarmTrend: number;
      type: LikeType;
      productId: string;
    }[]>('/likes', params);
  }
}
