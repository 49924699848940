import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, Renderer2, RendererFactory2, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductCatalogPageComponent } from './product-catalog-page/product-catalog-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { CardModule } from 'primeng/card';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { RegisterPageComponent } from './register-page/register-page.component';
import { PasswordModule } from 'primeng/password';
import { SecurityPageComponent } from './security-page/security-page.component';
import { RemindPasswordPageComponent } from './remind-password-page/remind-password-page.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DividerModule } from 'primeng/divider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProductEnvironmentsPageComponent } from './product-page/product-environments-page/product-environments-page.component';
import { ProductDescriptionPageComponent } from './product-page/product-description-page/product-description-page.component';
import { ProductDocumentationPageComponent } from './product-page/product-documentation-page/product-documentation-page.component';
import { ProductReportPageComponent } from './product-page/product-report-page/product-report-page.component';
import { ProductSupportPageComponent } from './product-page/product-support-page/product-support-page.component';
import { ProductSimilarPageComponent } from './product-page/product-similar-page/product-similar-page.component';
import { TableModule } from 'primeng/table';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnowledgeBasePageComponent } from './knowledge-base-page/knowledge-base-page.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { AddressComponent } from './profile-page/address/address.component';
import { SubscriptionComponent } from './profile-page/subscription/subscription.component';
import { SupportComponent } from './profile-page/support/support.component';
import { FavoriteComponent } from './profile-page/favorite/favorite.component';
import { AccountComponent } from './profile-page/account/account.component';
import { MyProjectsInfoPageComponent } from './profile-page/my-projects/my-projects-info-page/my-projects-info-page.component';
import { MyProjectsAddPageComponent } from './profile-page/my-projects/my-projects-add-page/my-projects-add-page.component';
import { MyProjectsDetailsPageComponent } from './profile-page/my-projects/my-projects-details-page/my-projects-details-page.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';
import { DeploymentPageComponent } from './deployment-page/deployment-page.component';
import { ComponentPageComponent } from './component-page/component-page.component';
import { SkeletonModule } from 'primeng/skeleton';
import { CheckboxModule } from 'primeng/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { DeploymentDescriptionPageComponent } from './deployment-page/deployment-description-page/deployment-description-page.component';
import { DeploymentDocumentationPageComponent } from './deployment-page/deployment-documentation-page/deployment-documentation-page.component';
import { DeploymentReportPageComponent } from './deployment-page/deployment-report-page/deployment-report-page.component';
import { RepositoriesModule } from './repositories/repositories.module';
import { InstallDropdownComponent } from './install-dropdown/install-dropdown.component';
import { FilterEnvironmentTypePipe } from './filter-environment-type.pipe';
import { ApiClient } from './services/api-client.service';
import { SessionService } from './services/session.service';
import { UserManager } from './services/user-manager.service';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { ActiveUser } from './services/active-user.service';
import { ProductManager } from './services/product-manager.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { SseTranslationLoader } from './sse-translation-loader';
import { environment } from 'src/environments/environment';
import { ImageModule } from 'primeng/image';
import { ActivateUserPageComponent } from './activate-user-page/activate-user-page.component';
import { ActivatePasswordPageComponent } from './activate-password-page/activate-password-page.component';
import { ActivateEmailPageComponent } from './activate-email-page/activate-email-page.component';
import { MainAppComponent } from './main-app-page/main-app.component';
import { AppComponent } from './app.component';
import { GithubIntegrationPageComponent } from './github-integration-page/github-integration-page.component';
import { GithubLoginPageComponent } from './github-login-page/github-login-page.component';
import { GithubRegisterPageComponent } from './github-register-page/github-register-page.component';
import { SplitNumberPipe } from './split-number.pipe';
import { DeploymentInstallPageComponent } from './deployment-page/deployment-install-page/deployment-install-page.component';
import { PaginatorModule } from 'primeng/paginator';
import { PostPageComponent } from './post-page/post-page.component';
import { ChartComponent } from './chart/chart.component';
import { ContributionMapComponent } from './contribution-map/contribution-map.component';
import { RegulationsPageComponent } from './regulations-page/regulations-page.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { GoogleLoginPageComponent } from './google-login-page/google-login-page.component';
import { GoogleRegisterPageComponent } from './google-register-page/google-register-page.component';
import { GoogleIntegrationPageComponent } from './google-integration-page/google-integration-page.component';
import { DeletionUserPageComponent } from './deletion-user-page/deletion-user-page.component';
import { TurnstileLoaderService } from './turnstileLoader.service';
import { TURNSTILE } from './turnstile.provider';
import { ScoreCircleComponent } from './score-circle/score-circle.component';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { SolutionsEzdrpPageComponent } from './solutions-ezdrp-page/solutions-ezdrp-page.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { HelpButtonComponent } from './help-button/help-button.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProductCardComponent } from './product-card/product-card.component';
import { DeploymentCardComponent } from './deployment-card/deployment-card.component';
import { EntityCardComponent } from './entity-card/entity-card.component';
import { TemplateNameDirective } from './template-name.directive';

function initializeApp(primeConfig: PrimeNGConfig) {
  primeConfig.ripple = true;
  return () => { };
}

@NgModule({
  declarations: [
    AppComponent,
    MainAppComponent,
    HomePageComponent,
    ProductCatalogPageComponent,
    ProductPageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    SecurityPageComponent,
    RemindPasswordPageComponent,
    ProductEnvironmentsPageComponent,
    ProductDescriptionPageComponent,
    ProductDocumentationPageComponent,
    ProductReportPageComponent,
    ProductSupportPageComponent,
    ProductSimilarPageComponent,
    AboutUsPageComponent,
    KnowledgeBasePageComponent,
    ProfilePageComponent,
    AddressComponent,
    SubscriptionComponent,
    SupportComponent,
    FavoriteComponent,
    AccountComponent,
    MyProjectsInfoPageComponent,
    MyProjectsAddPageComponent,
    MyProjectsDetailsPageComponent,
    DeploymentPageComponent,
    ComponentPageComponent,
    DeploymentDescriptionPageComponent,
    DeploymentDocumentationPageComponent,
    DeploymentReportPageComponent,
    InstallDropdownComponent,
    FilterEnvironmentTypePipe,
    ActivateUserPageComponent,
    ActivatePasswordPageComponent,
    ActivateEmailPageComponent,
    GithubIntegrationPageComponent,
    GithubLoginPageComponent,
    GithubRegisterPageComponent,
    SplitNumberPipe,
    DeploymentInstallPageComponent,
    PostPageComponent,
    ChartComponent,
    ContributionMapComponent,
    RegulationsPageComponent,
    PrivacyPolicyPageComponent,
    GoogleLoginPageComponent,
    GoogleRegisterPageComponent,
    GoogleIntegrationPageComponent,
    DeletionUserPageComponent,
    ScoreCircleComponent,
    SolutionsEzdrpPageComponent,
    HelpButtonComponent,
    ProductCardComponent,
    DeploymentCardComponent,
    EntityCardComponent,
    TemplateNameDirective,
  ],
  imports: [
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (injector: Injector) => (new SseTranslationLoader(`${environment.secure ? 'https://' : 'http://'}${environment.apiUrl}/translations/`, injector)),
        deps: [Injector],
        // useFactory: (http: HttpClient) => (new TranslateHttpLoader(http, 'http://localhost:3000/translations/', '')),
        // deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    SkeletonModule,
    DropdownModule,
    BrowserAnimationsModule,
    ButtonModule,
    CheckboxModule,
    RippleModule,
    BadgeModule,
    SidebarModule,
    TabMenuModule,
    CardModule,
    InputTextModule,
    BreadcrumbModule,
    TieredMenuModule,
    PasswordModule,
    AutoCompleteModule,
    DividerModule,
    TableModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    SliderModule,
    RadioButtonModule,
    ImageModule,
    MessagesModule,
    ToastModule,
    NgCircleProgressModule.forRoot({
      "radius": 50,
      "space": -18,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 14,
      "outerStrokeColor": "#2079E2",
      "outerStrokeGradientStopColor": "#134988",
      "innerStrokeColor": "#DEEBFB",
      "innerStrokeWidth": 22,
      "titleFontSize": "22",
      "titleFontWeight": "800",
      "title": "9.6",
      "animateTitle": false,
      "animationDuration": 1000,
      "showTitle": true,
      "showSubtitle": false,
      "showUnits": false,
      "showBackground": false,
      "showInnerStroke": true,
      "startFromZero": false,
      "lazy": false
    }),
    RepositoriesModule,
    PaginatorModule,
    PaginatorModule,
    InputSwitchModule,
    OverlayPanelModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [PrimeNGConfig],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (loader: TurnstileLoaderService) => {
        return () => {
          return loader;
        };
      },
      deps: [TurnstileLoaderService],
      multi: true,
    },
    {
      provide: TurnstileLoaderService,
      useFactory: (renderer2: RendererFactory2) => {
        const renderer = renderer2.createRenderer(window, null);
        return new TurnstileLoaderService(renderer);
      },
      deps: [RendererFactory2],
    },
    {
      provide: TURNSTILE,
      useFactory: (loader: TurnstileLoaderService) => {
        return loader.getTurnstile();
      },
      deps: [TurnstileLoaderService],
    },
    MessageService,
    importProvidersFrom(HttpClientModule),
    ApiClient,
    SessionService,
    UserManager,
    ActiveUser,
    ProductManager,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
