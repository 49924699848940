<div class="mt-6">
  <p class="text-3xl font-bold text-blue-darker m-0">
    {{ 'CLIENT.PRODUCT.DOCUMENTATION_SECTION.TITLE' | translate }}
  </p>
  <p class="text-grey-dark my-5">
    {{ 'CLIENT.PRODUCT.DOCUMENTATION_SECTION.DESCRIPTION' | translate }}
  </p>

  <p-skeleton styleClass="w-full" height="6rem" *ngIf="isLoading"></p-skeleton>

  <p-table [value]="productData.defaultEnvironment.documentation" [tableStyle]="{ 'min-width': '50rem' }" *ngIf="!isLoading">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="name">
              <i class="pi pi-align-left"></i>
              <span class="ml-2">
                {{ 'CLIENT.PRODUCT.DOCUMENTATION_SECTION.TABLE.HEADER.NAME' | translate }}
              </span>
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="url">
              <i class="pi pi-link"></i>
              <span class="ml-2">
                {{ 'CLIENT.PRODUCT.DOCUMENTATION_SECTION.TABLE.HEADER.LINK' | translate }}
              </span>
              <p-sortIcon field="url"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-documentation>
        <tr>
            <td>{{ documentation.name }}</td>
            <td>
              <a [href]="documentation.address" target="_blank">
                {{ documentation.address }}
              </a>
            </td>
        </tr>
    </ng-template>
  </p-table>
</div>