<entity-card [url]="product.url" [logoUrl]="product.logoUrl" [scarmRating]="product.scarmRating" [lastScarmTrend]="product.lastScarmTrend" [name]="product.name" [summary]="product.summary" [starsCount]="product.starsCount" [deploymentsCount]="product.deploymentsCount" (onLike)="likeHandle($event)">
    <ng-template [templateName]="'headerTemplate'">
        <div class="flex flex-row gap-1 align-items-center">
            <i class="bg-grey-dark icon-16 icon-server"></i>
            <span class="text-grey-dark text-xs font-bold">
                {{product.deploymentsCount}}
                <span *ngIf="product.deploymentsCount == 1">
                    {{ 'CLIENT.PRODUCT_CATALOG.CARD.DEPLOYMENT_AMOUNT.SINGULAR' | translate }}
                </span>
                <span *ngIf="product.deploymentsCount > 1 && product.deploymentsCount < 5">
                    {{ 'CLIENT.PRODUCT_CATALOG.CARD.DEPLOYMENT_AMOUNT.PLURAL' | translate }}
                </span>
                <span *ngIf="product.deploymentsCount >= 5">
                    {{ 'CLIENT.PRODUCT_CATALOG.CARD.DEPLOYMENT_AMOUNT.PLURAL_GENITIVE' | translate }}
                </span>
            </span>
        </div>
    </ng-template>
</entity-card>