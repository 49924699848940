<div class="flex flex-column justify-content-center align-items-center mb-8 mt-2 md:mt-8 md:px-8">
  <div class="container w-full flex flex-column gap-3 px-4 md:px-8" *ngIf="translate.currentLang.toLowerCase() === 'pl'">

    <div>
      <span class="font-bold text-4xl">
        POLITYKA PRYWATNOŚCI STRONY SOURCEMATION
      </span>
    </div>
    <div>
      Informujemy w niej o tym w jaki sposób firma Linux Polska Sp. z o. o. chroni prywatność Użytkowników Strony, czy osób, które wyraziły zgodę na przetwarzanie swoich danych osobowych w celu używania Strony, korzystania z usług przez nią oferowanych, otrzymywania informacji na temat Strony lub wysłały zapytanie, w tym jak używane są pliki cookies używane przez Stronę oraz o możliwości zarządzania nimi.
    </div>

    <h2>
      Administrator i właściciel strony www, Administrator Danych Osobowych (ADO)
    </h2>
    <div>
      Administratorem i właścicielem Strony, która dostępna jest pod adresami: www.sourcemation.com, www.sourcemation.pl, www.sourcemation.io, www.sourcemation.net, www.sourcemation.eu, www.sourcemation.com.pl (dalej: Strona), jest Linux Polska Sp. z o. o. z siedzibą w Warszawie, Aleje Jerozolimskie 100, 00-807 Warszawa, wpisana do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Miasta Stołecznego Warszawy XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000326158, NIP 7010181018, REGON: 141791601 mająca kapitał zakładowy 1 000 500,00 zł (zwana dalej: Linux Polska).
    </div>

    <h2>
      Kontakt w sprawie przetwarzania danych osobowych użytkowników
    </h2>
    <div>
      W ramach wdrożenia polityki ochrony danych osobowych w Linux Polska podjęto decyzję o nie wyznaczaniu Inspektora Ochrony Danych Osobowych. W sprawach związanych z ochroną danych osobowych można kontaktować się przez adres poczty elektronicznej biuro@linuxpolska.pl lub pisemnie na adres: Linux Polska Sp. z o. o., Aleje Jerozolimskie 100, 00-807 Warszawa.
    </div>

    <h2>
      Cele przetwarzania danych osobowych
    </h2>
    <ol>
      <li>
        Odpowiedzi na zapytanie skierowane przez formularz kontaktowy - art. 6.1.b) RODO.
        <ol>
          <li>
            Czas przetwarzania: do czasu przedawnienia roszczeń w związku z przedmiotem komunikacji.
          </li>
        </ol>
      </li>
      <li>
        Obsługi korespondencji – art. 6.1.f) RODO.
        <ol>
          <li>
            Czas przetwarzania: do czasu przedawnienia roszczeń w związku z przedmiotem komunikacji.
          </li>
          <li>
            Prawnie uzasadniony interes: komunikacja zewnętrzna.
          </li>
        </ol>
      </li>
      <li>
        Realizacji obowiązków podatkowych i księgowych – art. 6.1.c) RODO w połączeniu z właściwymi przepisami prawa podatkowego,
        <ol>
          <li>
            Czas przetwarzania: do czasu wymaganego przez przepisy podatkowe i inne.
          </li>
        </ol>
      </li>
      <li>
        Tworzenie archiwum na potrzeby ewentualnej konieczności obrony, ustalenia lub dochodzenia roszczeń – art. 6.1.f) RODO.
        <ol>
          <li>
            Czas przetwarzania: do czasu wygaśnięcia roszczeń związanych z przetwarzanymi informacjami.
          </li>
          <li>
            Prawnie uzasadniony interes: zabezpieczenie prawne administratora.
          </li>
        </ol>
      </li>
      <li>
        Obsługi mediów społecznościowych – art. 6.1.f) RODO.
        <ol>
          <li>
            Czas przetwarzania: do czasu istnienia systemu komunikacji w wykorzystywanych mediach społecznościowych.
          </li>
          <li>
            Prawnie uzasadniony interes: komunikacja zewnętrzna.
          </li>
        </ol>
      </li>
      <li>
        Analizy i statystyki dotyczących funkcjonowania Strony, sposobu użytkowania Strony, dostosowywania treści i interfejsu Strony do potrzeb Użytkowników - art. 6.1.f) RODO.
        <ol>
          <li>
            Czas przetwarzania: przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w stosunku do osoby, której dane dotyczą.
          </li>
          <li>
            Prawnie uzasadniony interes: zwiększanie efektywności funkcjonowania i poprawa jakości usług.
          </li>
        </ol>
      </li>
      <li>
        Udzielanie odpowiedzi na zadane pytania, obsługa zgłoszenia reklamacyjnego, obsługa zgłoszenia o błędach, naprawa błędów, usunięcie awarii, prowadzenia korespondencji w celu załatwienia danej sprawy - (art. 6 ust. 1 lit. a i f RODO).
        <ol>
          <li>
            Czas przetwarzania: do czasu przedawnienia roszczeń w związku z przedmiotem komunikacji.
          </li>
          <li>
            Prawnie uzasadniony interes: realizowanie zapytań i żądań Użytkowników, dochodzenie roszczeń.
          </li>
        </ol>
      </li>
      <li>
        Zapewnienie funkcjonowania formularza kontaktowego – art. 6.1.f) RODO.
        <ol>
          <li>
            Czas przetwarzania: przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w stosunku do osoby, której dane dotyczą.
          </li>
          <li>
            Prawnie uzasadniony interes: komunikacja zewnętrzna.
          </li>
        </ol>
      </li>
      <li>
        Obsługi, przygotowania i wykonania zamówień na świadczone usługi, wykonywania umowy, udzielania informacji o oferowanych usługach w zakresie niezbędnym do wykonania umowy – art. 6.1.b) RODO.
        <ol>
          <li>
            Czas przetwarzania: do czasu przedawnienia roszczeń w związku z przedmiotem komunikacji lub umowy.
          </li>
        </ol>
      </li>
      <li>
        Badania poziomu satysfakcji Klientów, ustalania poziomu jakości obsługi i zadowolenia Klientów ze świadczonych usług – art. 6.1.f) RODO.
        <ol>
          <li>
            Czas przetwarzania: przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w stosunku do osoby, której dane dotyczą.
          </li>
          <li>
            Prawnie uzasadniony interes: zwiększanie efektywności funkcjonowania i poprawa jakości usług.
          </li>
        </ol>
      </li>
      <li>
        Oferowania usług własnych w ramach marketingu bezpośredniego klientom Administratora – art. 6.1.f) RODO.
        <ol>
          <li>
            Czas przetwarzania: do czasu wniesienia sprzeciwu.
          </li>
          <li>
            Prawnie uzasadniony interes: marketing produktów własnych klientom.
          </li>
        </ol>
      </li>
      <li>
        Przekazywania informacji handlowych z wykorzystaniem środków komunikacji elektronicznej, w ramach marketingu bezpośredniego usług – art. 6.1.a) RODO.
        <ol>
          <li>
            Czas przetwarzania: do czasu wycofania zgody.
          </li>
        </ol>
      </li>
    </ol>

    <div>
      Dane będą przechowywane przez okres niezbędny do wykonania umów, a także przez okres wyznaczony przepisami prawa w zakresie ustalania, dochodzenia, ewidencjonowania, obrony w zakresie roszczeń cywilnych lub obowiązków publiczno-prawnych określonych przez przepisy prawa podatkowego i rachunkowości, zgodnie ze wskazaniem powyżej.
    </div>
    <div>
      Osoba, której dane są przetwarzane w celach określonych w pkt.10-12 powyżej ma prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania jej danych osobowych, co będzie skutkowało wstrzymaniem przetwarzania w tych celach.
    </div>
    <div>
      Podanie danych osobowych jest czynnością dobrowolną. Administrator nie przetwarza szczególnych kategorii danych osobowych.
    </div>

    <h2>
      Prawa osoby, której dotyczą dane osobowe
    </h2>
    <div>
      Osoba, której dane są przetwarzane ma prawo w każdym momencie:
      <ol>
        <li>
          wycofać wcześniej udzieloną zgodę na przetwarzanie swoich danych osobowych,
        </li>
        <li>
          żądać sprostowania ich treści,
        </li>
        <li>
          ograniczyć przetwarzanie danych (osoba może żądać, aby Administrator ograniczył przetwarzanie danych wyłącznie do ich przechowywania lub wykonywania uzgodnionych z Użytkownikiem działań, jeżeli w jego opinii Administrator ma nieprawidłowe dane lub przetwarza je bezpodstawnie),
        </li>
        <li>
          do wniesienia sprzeciwu wobec przetwarzania danych na podstawie prawnie uzasadnionego interesu lub ich usunięcia (Użytkownik powinien wskazać szczególną sytuację, która jego zdaniem uzasadnia zaprzestanie przetwarzania objętego sprzeciwem; dane przestaną być przetwarzane, chyba że Administrator wykaże, że podstawy przetwarzania przez niego danych są nadrzędne wobec praw Użytkownika),
        </li>
        <li>
          uzyskać dostęp do przekazanych danych oraz otrzymania ich kopii, czy przeniesienia danych do innego podmiotu (Użytkownik ma prawo otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego przekazane Administratorowi dane osobowe; Użytkownik może zlecić przesłanie tych danych bezpośrednio innemu podmiotowi),
        </li>
        <li>
          do wniesienia skargi do organu nadzorczego (Użytkownik po stwierdzeniu niezgodnego z prawem przetwarzania może złożyć w tej sprawie skargę do Prezesa Urzędu Ochrony Danych Osobowych lub innego właściwego organu nadzorczego).
        </li>
      </ol>
    </div>

    <h2>
      Pozostałe informacje na temat danych osobowych i praw osób, których dane dotyczą
    </h2>
    <div>
      Administrator nie przesyła danych osobowych poza Europejski Obszar Gospodarczy. Nie podejmuje też wobec Użytkownika decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, które wywoływałyby wobec niego skutki prawne lub w podobny sposób istotnie na niego wpływały.
    </div>
    <h3>
      Podmioty mające dostęp do danych osobowych
    </h3>
    <div>
      Dane osobowe mogą być przekazywane do zaufanych partnerów Administratora, którzy uczestniczą w obsłudze wykonania usług świadczonych przez Administratora, takich jak administratorzy systemów do obsługi marketingowej lub komunikacyjnej, administratorzy serwerów, na których są przechowywane dane lub do innych podmiotów, jeśli takie przekazanie wynika z przepisów prawa. Dane osobowe osób fizycznych, w tym osób fizycznych działających za klientów, są przetwarzane w systemach informatycznych, z jakich korzysta Administrator. Przetwarzane dane osobowe obejmują dane przekazane uprzednio do Administratora, w tym między innymi: imię i nazwisko, firmę klienta, stanowiska osób kontaktowych, numery telefonów, adresy e-maili lub inne służbowe dane kontaktowe.
    </div>

    <h2>
      Polityka plików cookies
    </h2>
    <div>
      Administrator korzysta z plików cookies na swoich stronach internetowych. Są to informacje, przekazywane i przechowywane na przeglądarce Użytkownika. Pozwalają one podejmować interakcję ze Stroną jako z systemem, a nie z pojedynczymi podstronami (logowanie, autowypełnianie danych w komentarzach itp.) są to cookie własne: sesyjne lub trwałe. Narzędzia używane przez administratora w celu zwiększenia możliwości strony lub analityki również mogą pozostawiać pliki cookie w przeglądarce Użytkownika - są to cookie podmiotów trzecich) .
    </div>
    <div>
      Linux Polska przetwarza informacje w celu ulepszania Strony, tak by była przyjazna użytkownikowi. Dane, które możemy pozyskiwać dotyczą: daty i godziny dostępu, przeglądanych stron i plików, kraju użytkownika, organizacji użytkownika (na podstawie adresu IP), rodzaju i wersji przeglądarki.
    </div>

    <h2>
      Pliki cookie podmiotów trzecich
    </h2>
    <h3>
      Facebook
    </h3>
    <div>
      Użytkownik, korzystający z przycisku “Udostępnij” znajdującego się na Stronie, loguje się w serwisie Facebook. Ciasteczko jest zapisywane, gdy przeglądarka uzyska dostęp do serwisu Facebook. Facebook udostępnia określone funkcje i narzędzia, np. piksele, SDK i API, które użytkownik może dodawać do swoich witryn lub aplikacji, aby umożliwić przesyłanie danych do Facebooka, w tym danych o działaniach podejmowanych na jego witrynach lub aplikacjach. Na Stronie wykorzystujemy piksel Facebooka. Piksel to narzędzie wykorzystujące pliki cookies, umożliwiające przesyłanie danych do Facebooka, w tym danych o działaniach podejmowanych przez użytkowników w witrynach i aplikacjach (tzw. dane o zdarzeniach).
    </div>

    <h3>
      Twitter
    </h3>
    <div>
      Użytkownik, korzystający z przycisku “Udostępnij” znajdującego się na stronie, loguje się w serwisie Twitter. Ciasteczko jest zapisywane, gdy przeglądarka uzyska dostęp do serwisu Twitter.
    </div>

    <h3>
      LinkedIn
    </h3>
    <div>
      Użytkownik, korzystający z przycisku “Udostępnij” znajdującego się na stronie, loguje się w serwisie LinkedIn. Ciasteczko jest zapisywane, gdy przeglądarka uzyska dostęp do serwisu LinkedIn.
    </div>

    <h3>
      Zoho SalesIQ
    </h3>
    <div>
      System ZOHO SalesIQ, z którego korzysta Linux Polska, przy pomocy wtyczki, zbiera dane, takie jak adres protokołu internetowego, typ przeglądarki, język przeglądarki, adres URL, dostępne pliki, strefa czasowa i system operacyjny odwiedzających Stronę.
    </div>
    <h3>
      Google Analytics
    </h3>
    <div>
      Google Analytics analizuje strony internetowe, a także gromadzi informacje o sposobie korzystania internautów z analizowanej strony, wykorzystując w tym celu pliki cookies. Dane obejmują: miejsce, z jakiego internauta trafił na Stronę, typ urządzenia, liczbę odwiedzin, odwiedzane strony, czas wizyty na Stronie, termin wizyty na Stronie.
    </div>

    <h2>
      Witryny internetowe stron trzecich
    </h2>
    <div>
      Strona zawiera odnośniki do witryn internetowych osób trzecich. Należy zdawać sobie sprawę z tego, że właściciele lub administratorzy takich stron internetowych (stron trzecich) mogą gromadzić, używać i przesyłać dane internetowe w oparciu o inne warunki niż Linux Polska. Po wejściu na stronę internetową (strony trzeciej) użytkownik powinien zapoznać się z polityką prywatności takich stron internetowych (stron trzecich).
    </div>

    <h2>
      Odpowiedzialność
    </h2>
    <div>
      Linux Polska nie ponosi odpowiedzialności za zdarzenia zaistniałe w czasie przeglądania Strony wynikające z działania osób trzecich, jak również nie ponosi odpowiedzialności za wadliwe działanie Strony z przyczyn niezależnych od niej, w tym z przyczyn leżących po stronie osób trzecich bądź też będących następstwem siły wyższej.
    </div>
    <h2>
      Zmiany polityki prywatności
    </h2>
    <div>
      Linux Polska zastrzega sobie prawo do aktualizacji polityki prywatności. O zmianie poinformujemy Użytkowników poprzez zamieszczenie nowej treści niniejszego dokumentu na Stronie.
    </div>
    <div>
      Niniejsza polityka w aktualnej wersji obowiązuje od dnia 17 maja 2024 r. Została opublikowana w dniu 17 maja 2024 r.
    </div>

  </div>



  <div class="container w-full flex flex-column gap-3 px-4 md:px-8" *ngIf="translate.currentLang.toLowerCase() === 'en'">
    <div>
      <span class="font-bold text-4xl">
        SOURCEMATION WEBSITE PRIVACY POLICY
      </span>
    </div>

    <div>
      In this document, we inform you about how Linux Polska Sp. z o. o. protects the privacy of Site Users, or those who have consented to the processing of their personal data in order to use the Site, using the services offered by the Site, receiving information about the Site or who sent an enquiry, including how the cookies are used by the Site and how they can be managed.
    </div>

    <h2>
      Website controller and owner, Personal Data Controller (PDC)
    </h2>
    <div>
      The controller and owner of the Site, which is available at: www.sourcemation.com, www.sourcemation.pl, www.sourcemation.io, www.sourcemation.net, www.sourcemation.eu, www.sourcemation.com.pl (hereinafter: Site), is Linux Polska Sp. z o. o. with the registered office in Warsaw, Jerozolimskie Avenue 100, 00-807 Warsaw, entered into the Register of Entrepreneurs kept by the District Court for the Capital City of Warsaw, Commercial Division number XII of the National Court Register, under KRS number: 0000326158, NIP 7010181018, REGON: 141791601, having a share capital of PLN 1 000 500,00 (hereinafter: Linux Polska).
    </div>

    <h2>
      Contact regarding the processing of User personal data
    </h2>
    <div>
      As part of the implementation of Linux Polska's data protection policy, it has been decided not to appoint a Data Protection Officer. In matters related to data protection, Users can contact via email address biuro@linuxpolska.pl or in writing: Linux Polska Sp. z o. o., Jerozolimskie Avenue 100, 00-807 Warsaw.
    </div>

    <h2>
      Purposes of personal data processing
    </h2>
    <ol>

      <li>
        To reply to an enquiry made via the contact form – Article 6.1.b) GDPR.
        <ol>
          <li>
            Duration of processing: until the statute of limitations for claims in relation to the subject of the communication.
          </li>
        </ol>
      </li>
      <li>
        To  handle correspondence – article 6.1.f) GDPR.
        <ol>
          <li>
            Duration of processing: until the statute of limitations for claims in relation to the subject matter of the communication.
          </li>
          <li>
            Legitimate interest: external communication.
          </li>
        </ol>
      </li>

      <li>
        To fulfil tax and accounting obligations – Article 6.1.c) GDPR in conjunction with the relevant tax legislation,
        <ol>
          <li>
            Duration of processing: until it is required by tax and other legislation.
          </li>
        </ol>
      </li>
      <li>
        To create an archive in case of a presumptive necessity to defend, establish or assert claims – Article 6.1.f) GDPR.
        <ol>
          <li>
            Duration of processing: until the expiry of claims related to the processed information.
          </li>
          <li>
            Legitimate interest: legal safeguarding of the controller.
          </li>
        </ol>
      </li>
      <li>
        To operate social media – Article 6.1.f) GDPR.
        <ol>
          <li>
            Duration of processing: for the duration of the communication system in the social media used.
          </li>
          <li>
            Legitimate interest: external communication.
          </li>
        </ol>
      </li>
      <li>
        To analyse and to generate statistics regarding the functioning of the Site, the manner the Site is used, the adaptation of the content and interface of the Site to the Users needs – Article 6.1.f) GDPR.
        <ol>
          <li>
            Duration of processing: for the duration of the legitimate interest pursued by the Controller, but no longer than the period of limitation of the Controller's claims against the data subject.
          </li>
          <li>
            Legitimate interest: increasing the efficiency of operations and improving the quality of services.
          </li>
        </ol>
      </li>

      <li>
        To provide answers to questions asked, handle a complaint, handle an error report, repair errors, rectify failures, conduct correspondence to solve the issue in question – (Article 6(1)(a) and (f) GDPR).
        <ol>
          <li>
            Duration of processing: until the statute of limitations for claims in relation to the subject of the communication.
          </li>
          <li>
            Legitimate interest: fulfilment of queries and requests from Users, assertion of claims.
          </li>
        </ol>
      </li>
      <li>
        To ensure functioning of the contact form – Article 6.1.f) GDPR.
        <ol>
          <li>
            Duration of processing: for the duration of the legitimate interest pursued by the Controller, but no longer than the period of limitation of the Controller's claims against the data subject.
          </li>
          <li>
            Legitimate interest: external communication.
          </li>
        </ol>
      </li>

      <li>
        To operate, prepare and execute orders for the services provided, perform the contract, provide information about the services offered to the extent necessary for the performance of the contract – Article 6.1.b) GDPR.
        <ol>
          <li>
            Duration of processing: until the statute of limitations for claims in relation to the subject of the communication or contract.
          </li>
        </ol>
      </li>

      <li>
        To survey customer satisfaction, determine the level of quality of service and customer satisfaction with the services provided – Article 6.1.f) GDPR.
        <ol>
          <li>
            Duration of processing: for the duration of the legitimate interest pursued by the Controller, but no longer than the period of limitation of the Controller's claims against the data subject.
          </li>
          <li>
            Legitimate interest: increasing the efficiency of operations and improving the quality of services.
          </li>
        </ol>
      </li>
      <li>
        To offer its own services as part of direct marketing to the Controller's customers – Article 6.1.f) GDPR.
        <ol>
          <li>
            Duration of processing: until an objection is lodged.
          </li>
          <li>
            Legitimate interest: marketing of own products to customers.
          </li>
        </ol>
      </li>
      <li>
        To transmit commercial information by means of electronic communication, as part of direct marketing of services – Article 6.1.a) GDPR.
        <ol>
          <li>
            Duration of processing: until consent is withdrawn.
          </li>
        </ol>
      </li>
    </ol>

    <div>
      The data will be stored for the period necessary for the performance of the contracts, as well as for the period prescribed by law for the establishment, investigation, recording, defence in respect of civil claims or public law obligations as defined by tax and accounting law, as indicated above.
    </div>
    <div>
      The person whose data is processed for the purposes set out in points 10-12 above has the right to object to the processing of their personal data at any time, which will result in the suspension of processing for these purposes.
    </div>
    <div>
      The provision of personal data is a voluntary act. The controller does not process special categories of personal data.
    </div>

    <h2>
      Rights of the data subject
    </h2>
    <div>
      At any time the data subject has the right to:
      <ol>
        <li>
          withdraw the prior consent for the processing of their personal data,
        </li>
        <li>
          request the rectification of its content,
        </li>
        <li>
          restrict the processing of the data (the person may request that the Controller restricts the processing of the data only to the storage of the data or to carry out activities agreed upon with the User, if, in the opinion of the User, the Controller has incorrect data or processes the data unduly),
        </li>
        <li>
          object to the processing of the data on the basis of legitimate interest or to its deletion (the User should indicate the particular situation which, in their opinion, justifies the cessation of the processing covered by the objection; the data will cease to be processed unless the Controller demonstrates that the grounds for the Controller's processing override the User's rights),
        </li>
        <li>
          access the data provided and receive its copy, or to have the data transferred to another entity (the User has the right to receive in a structured, commonly used format suitable for machine reading the personal data provided to the Controller; the User may have the data sent directly to another entity),
        </li>
        <li>
          lodge a complaint with a supervisory authority (the User, upon discovering unlawful processing, may lodge a complaint to this effect with the President of the Personal Data Protection Office or any other competent supervisory authority).
        </li>
      </ol>
    </div>
    <h2>
      Other information on personal data and data subjects' rights
    </h2>
    <div>
        The Controller does not transfer personal data outside the European Economic Area. It also does not subject the User to a decision based solely on automated processing, including profiling, which would produce legal effects concerning the User or significantly affect the User in a similar way.
    </div>
    <h3>
      Entities with access to personal data
    </h3>
    <div>
      Personal data may be transferred to the Controller's trusted partners who are involved in operating the performance of the services provided by the Controller, such as system administrators for marketing or communication services, administrators of the servers on which the data is stored, or to other entities, provided that such transfer is required by law. Personal data of individuals, including individuals acting for the clients, is processed in the IT systems used by the Controller. The personal data processed includes data previously provided to the Controller, including but not limited to: full name, client's company, positions of contact persons, telephone numbers, email addresses or other business contact data.
    </div>

    <h2>
      Cookie policy
    </h2>
    <div>
      The Controller uses cookies on its websites. These are information transmitted and stored on the User's browser. They allow interaction with the Site as a system, and not just with individual sub-pages (logging in, autocomplete data in comments, etc.). These are proprietary cookies: session or persistent. Tools used by the administrator to enhance the capabilities of the Site or analytics may also leave cookies in the User's browser - these are third-party cookies.
    </div>
    <div>
      Linux Polska processes information in order to improve the Site to make it user-friendly. The data we may collect relates to: date and time of access, pages and files viewed, user's country, user's organisation (based on IP address), browser type and version.
    </div>

    <h2>
      Third-party cookies
    </h2>
    <h3>
      Facebook
    </h3>
    <div>
      Having pressed the "Share" button on the Site, the User logs into Facebook. A cookie is saved when the browser accesses Facebook. Facebook provides certain features and tools, e.g., pixels, SDKs and APIs, which you can add to your sites or applications to enable data to be sent to Facebook, including data about actions taken on your sites or applications. We use the Facebook pixel on the Site. The pixel is a tool that uses cookies and allows data to be sent to Facebook, including data about actions taken by users on the sites and applications (known as event data).
    </div>

    <h3>
      Twitter
    </h3>
    <div>
      Having pressed the "Share" button on the Site, the User logs into Twitter. The cookie is saved when the browser accesses Twitter.
    </div>

    <h3>
      LinkedIn
    </h3>
    <div>
      Having pressed the "Share" button on the Site, the User logs into LinkedIn. The cookie is saved when the browser accesses LinkedIn.
    </div>

    <h3>
      Zoho SalesIQ
    </h3>
    <div>
      The ZOHO SalesIQ system used by Linux Polska, through plug-in, collects data such as Internet protocol address, browser type, browser language, URL, available files, time zone and operating system of the Site visitors.
    </div>

    <h3>
      Google Analytics
    </h3>
    <div>
      Google Analytics analyses websites and collects information about how visitors use the analysed website. To this end, it uses cookies. The data includes: the location from which the internet user reached the Site, the type of device, the number of visits, the pages visited, the date and time when the Site was visited.
    </div>

    <h2>
      Third-party websites
    </h2>
    <div>
      The Site contains links to third party websites. You should be aware that the owners or administrators of such websites (third parties) may collect, use and transmit Internet data under different terms and conditions than Linux Polska. Once you have accessed a (third party) website, you should review the privacy policy of the website.
    </div>

    <h2>
      Responsibility
    </h2>
    <div>
      Linux Polska shall not be liable for events resulting from the actions of third parties, which occur during browsing of the Site, nor shall it be liable for malfunctions of the Site for reasons beyond its control, including those caused by third parties or resulting from force majeure.
    </div>

    <h2>
      Changes to the privacy policy
    </h2>
    <div>
      Linux Polska reserves the right to update privacy policy. We will inform Users about the change by posting the new privacy policy on the Site.
    </div>
    <div>
      This policy in its current version is effective as of May 17, 2024. It was published on May 17, 2024.
    </div>

  </div>
</div>
