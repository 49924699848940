import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GITHUB_LOGIN, GOOGLE_LOGIN } from '../brodcast-channels';
import { GITHUB_LOGGED_IN, GOOGLE_LOGGED_IN } from '../brodcast-messages';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  formGroup!: FormGroup;

  public isSigningIn: boolean = false;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
      this.formGroup = new FormGroup({
          password: new FormControl('', [
            Validators.required,
          ]),
          email: new FormControl('', [
            Validators.required,
            Validators.email,
          ]),
      });
  }

  public loginByGoogle() {
    this.isSigningIn = true;
    const w = 485;
    const h = 550;

    const left = (screen.width/2)-(w/2);
    const top = (screen.height/2)-(h/2);
    const state = (Math.random() + 1).toString(36).substring(7);
    let url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.googleClientId}&redirect_uri=${window.location.origin}/google-login&response_type=token&scope=openid email&state=${state}`
    const popup = window.open(new URL(url), '_blank', `popup,resizable=0,width=${w},height=${h},top=${top},left=${left}`);
    popup?.focus();

    if(!popup) {
      return;
    }

    const channel = new BroadcastChannel(GOOGLE_LOGIN);
    channel.addEventListener('message', async (event) => {
      if(event.data !== GOOGLE_LOGGED_IN) return;
      const response = await this.sessionService.checkIsLoggedIn();
      if(!response) return;
      await this.router.navigate(['/']);
      this.isSigningIn = false;
    });

    const isClosedInterval = interval(500).subscribe(() => {;
      if(popup?.closed) {
        this.isSigningIn = false;
        isClosedInterval.unsubscribe();
      }
    });

  }

  public loginByGithub() {
    this.isSigningIn = true;
    const w = 485;
    const h = 550;

    const left = (screen.width/2)-(w/2);
    const top = (screen.height/2)-(h/2);
    const url = `https://github.com/login/oauth/authorize?client_id=${environment.githubClientId}&redirect_uri=${window.location.origin}/github-login&scope=read:user user:email`;
    const popup = window.open(new URL(url), '_blank', `popup,resizable=0,width=${w},height=${h},top=${top},left=${left}`);
    popup?.focus();

    if(!popup) {
      return;
    }

    const channel = new BroadcastChannel(GITHUB_LOGIN);
    channel.addEventListener('message', async (event) => {
      if(event.data !== GITHUB_LOGGED_IN) return;
      const response = await this.sessionService.checkIsLoggedIn();
      if(!response) return;
      await this.router.navigate(['/']);
      this.isSigningIn = false;
    });

    const isClosedInterval = interval(500).subscribe(() => {
      if(popup?.closed) {
        this.isSigningIn = false;
        isClosedInterval.unsubscribe();
      }
    });
  }

  public async submit() {
    // this.messageService.add({
    //   severity: 'success',
    //   detail: 'test',
    //   summary: 'description',
    //   life: 100000000000,
    // });
    // return;
    this.isSigningIn = true;
    const email = this.formGroup.get('email') as FormControl;
    const password = this.formGroup.get('password') as FormControl;

    const response = await this.sessionService.login(email.value, password.value);

    if(!response) {
      this.isSigningIn = false;
      return this.messageService.add({
        severity: 'error',
        summary: 'Błąd logowania',
        detail: 'Podane dane logowania nie są poprawne',
      });
    }
    await this.router.navigate(['/']);
    this.isSigningIn = false;
  }

  clear() {
    this.formGroup.reset();
  }
}
