<div class="flex flex-column gap-5 bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover">
  <div class="flex flex-row gap-3 align-items-center" *ngIf="!isLoading">
    <i class="icon-folder-tree icon-24 bg-blue-darker"></i>
    <span class="font-bold text-3xl text-blue-darker">
        {{ dependencies.url }}
    </span>
  </div>
  <span class="text-grey-dark-hover text-base w-10" *ngIf="!isLoading">
    {{ dependencies.name }}
  </span>

  <div class="flex flex-column gap-5">
      <p-table [value]="tableDataPage" dataKey="name" *ngIf="!isLoading" [expandedRowKeys]="openedKeys">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
            <div class="flex flex-row align-items-center gap-2">
              <i class="icon-16 icon-lines bg-black-black-100"></i>
              <span>
                {{ 'CLIENT.PROFILE.MY_PROJECTS.DETAILS_PAGE.DEPENDENCIES' | translate }}
              </span>
            </div>
          </th>
          <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
            <div class="flex flex-row align-items-center gap-2">
              <span>
                {{ 'CLIENT.PROFILE.MY_PROJECTS.DETAILS_PAGE.REQUIRED' | translate }}
              </span>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-center" colspan="3">
            {{ 'CLIENT.PROFILE.MY_PROJECTS.DETAILS_PAGE.NO_DEPENDENCIES' | translate }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dependency let-expanded="expanded">
        <tr [pRowToggler]="dependency">
          <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover">
              <div class="flex flex-row gap-2 align-items-center" [class.cursor-pointer]="dependency.cves.length">
              <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" *ngIf="dependency.cves.length"></i>
                {{dependency.name}}
            </div>
          </td>
          <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-left">
            <span>
                {{dependency.reqs}}
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-dependency>
        <ng-container *ngFor="let cve of dependency.cves">
          <tr>
            <td colspan="2" class="text-sm pl-6 border-x-1 border-bottom-1 border-blue-light-hover">
              <div class="flex flex-row gap-2 align-items-center">
                  {{ cve }}
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>

    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="first"
      [rows]="itemsPerPage"
      [totalRecords]="tableData.length"
      *ngIf="!isLoading">
    </p-paginator>

  </div>

</div>
