import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductRepository } from 'src/app/repositories/product-repository';
import { ProductDataService } from '../product-data.service';

@Component({
  selector: 'app-product-description-page',
  templateUrl: './product-description-page.component.html',
  styleUrls: ['./product-description-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductDescriptionPageComponent {
  public isLoading: boolean = true;

  constructor(
    public productData: ProductDataService,
  ) {}

  public ngOnInit(): void {
    this.productData.isLoadingDataChange.subscribe(value => this.isLoading = value);
  }
}
