<div class="flex flex-row justify-content-center md:pt-8">
    <div class="container flex-grow-1 flex flex-column md:flex-row gap-4 align-items-stretch align-content-center px-4 md:px-0">
        <div class="flex flex-column flex-grow-1 flex-nowrap w-full md:pl-4 lg:pl-0">
            <div>
                <img src="assets/login.png" alt="Decoration image" class="w-full">
            </div>
            <div class="flex flex-row sm:flex-column md:flex-row gap-3 sm:gap-6 md:gap-5 lg:gap-6 align-self-center text-grey-dark-hover align-items-center lg:px-8 lg:mx-5">
                <div class="flex flex-row gap-2 align-items-center text-sm" style="word-wrap: break-word; word-break: break-all">
                    <img src="assets/reports.svg" alt="">
                    {{ 'CLIENT.FORGOTTEN_PASSOWORD.TRAITS.FIRST_TRAIT' | translate }}
                </div>
                <div class="flex flex-row gap-2 align-items-center text-sm" style="word-wrap: break-word; word-break: break-all">
                    <img src="assets/callinsupport.svg" alt="">
                    {{ 'CLIENT.FORGOTTEN_PASSOWORD.TRAITS.SECOND_TRAIT' | translate }}
                </div>
                <div class="flex flex-row gap-2 align-items-center text-sm" style="word-wrap: break-word; word-break: break-all">
                    <img src="assets/certificate.svg" alt="">
                    {{ 'CLIENT.FORGOTTEN_PASSOWORD.TRAITS.THIRD_TRAIT' | translate }}
                </div>
            </div>
        </div>
        <form class="flex-grow-1 w-full" [formGroup]="formGroup" (ngSubmit)="submit()">
            <div class="flex flex-column gap-5 xl:w-6 md:pr-4 xl:pr-0">
                <span class="font-semibold text-5xl">
                    {{ 'CLIENT.FORGOTTEN_PASSOWORD.TITLE' | translate }}
                </span>
                <div class="flex flex-column">
                    <input type="text" pInputText formControlName="email" class="w-full bg-blue-light border-blue-light-hover text-grey" placeholder="{{ 'CLIENT.FORGOTTEN_PASSOWORD.FORM.EMAIL' | translate }}" />
                </div>
                <p-button class="w-full" styleClass="w-full justify-content-center font-semibold" type="submit" [disabled]="formGroup.invalid">
                    {{ 'CLIENT.FORGOTTEN_PASSOWORD.REMIND_PASSWORD_BUTTON' | translate }}
                </p-button>
                <div class="flex flex-row align-content-center">
                    <div class="font-normal">
                        <span class="text-grey-dark-hover">
                            {{ 'CLIENT.FORGOTTEN_PASSOWORD.SIGN_IN_LINK.DESCRIPTION' | translate }}
                        </span>
                        <a routerLink="/signin" class="no-underline text-blue-normal">
                            {{ 'CLIENT.FORGOTTEN_PASSOWORD.SIGN_IN_LINK.TITLE' | translate }}
                        </a>
                    </div>
                </div>
                <div class="flex flex-row align-content-center">
                    <div class="font-normal">
                        <span class="text-grey-dark-hover">
                            {{ 'CLIENT.FORGOTTEN_PASSOWORD.SIGN_UP_LINK.DESCRIPTION' | translate }}
                        </span>
                        <a routerLink="/signup" class="no-underline text-blue-normal">
                            {{ 'CLIENT.FORGOTTEN_PASSOWORD.SIGN_UP_LINK.TITLE' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>