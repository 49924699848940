<div class="flex flex-row justify-content-center md:pt-8">
  <div class="container flex-grow-1 flex flex-column md:flex-row gap-4 align-items-stretch align-content-center px-4 md:px-0">
    <div class="flex flex-column flex-grow-1 flex-nowrap w-full md:pl-4 lg:pl-0">
      <div>
        <img src="assets/login.png" alt="Decoration image" class="w-full">
      </div>
      <div class="flex flex-row sm:flex-column md:flex-row gap-3 sm:gap-6 md:gap-5 lg:gap-6 align-self-center text-grey-dark-hover align-items-center lg:px-8 lg:mx-5">
        <div class="flex flex-row gap-2 align-items-center text-sm">
          <img src="assets/reports.svg" alt="">
          {{ 'CLIENT.SIGN_IN.TRAITS.FIRST_TRAIT' | translate }}
        </div>
        <div class="flex flex-row gap-2 align-items-center text-sm">
          <img src="assets/callinsupport.svg" alt="">
          {{ 'CLIENT.SIGN_IN.TRAITS.SECOND_TRAIT' | translate }}
        </div>
        <div class="flex flex-row gap-2 align-items-center text-sm">
          <img src="assets/certificate.svg" alt="">
          {{ 'CLIENT.SIGN_IN.TRAITS.THIRD_TRAIT' | translate }}
        </div>
      </div>
    </div>
    <div class="flex-grow-1 w-full h-full flex flex-column" *ngIf="isSigningIn">
      <div class="flex flex-column w-6 h-full justify-content-center align-items-center gap-3">
        <i class="pi pi-spin pi-spinner font-bold" style="font-size: 3rem"></i>
        <span class="font-semibold text-3xl">
          {{ 'CLIENT.SIGN_IN.SPIN' | translate }}
        </span>
      </div>
    </div>
    <form class="flex-grow-1 w-full" [formGroup]="formGroup" (ngSubmit)="submit()" *ngIf="!isSigningIn">
      <div class="flex flex-column gap-5 xl:w-6 md:pr-4 xl:pr-0">
        <span class="font-semibold text-5xl">
          {{ 'CLIENT.SIGN_IN.TITLE' | translate }}
        </span>
        <div class="flex flex-column">
          <input type="text" pInputText class="w-full bg-blue-light border-blue-light-hover text-grey" placeholder="{{ 'CLIENT.SIGN_IN.FORM.EMAIL' | translate }}" formControlName="email" />
          <p-password [feedback]="false" class="pt-4" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.SIGN_IN.FORM.PASSWORD' | translate }}" formControlName="password"></p-password>
        </div>
        <p-button class="w-full" styleClass="w-full justify-content-center font-semibold" [disabled]="formGroup.invalid && !isSigningIn" type="submit">
          {{ 'CLIENT.SIGN_IN.FORM.BUTTON_SIGN_IN' | translate }}
        </p-button>
        <div class="flex flex-row gap-3 align-items-center">
          <div class="border-top-1 flex-grow-1 border-blue-light-active"></div>
          <span class="font-normal text-base text-grey-dark-hover">
            {{ 'CLIENT.SIGN_IN.DELIMITER' | translate }}
          </span>
          <div class="border-top-1 flex-grow-1 border-blue-light-active"></div>
        </div>
        <div class="flex flex-column gap-3">
          <a (click)="loginByGithub()" class="cursor-pointer no-underline border-1 border-round-lg px-4 py-3 border-gray-900 flex flex-row justify-content-center align-items-center bg-white gap-2">
            <img src="assets/github.svg" alt="github">
            <span class="font-semibold text-gray-900">
              {{ 'CLIENT.SIGN_IN.SIGN_IN_BY_GITHUB' | translate }}
            </span>
          </a>
          <div id="googleLogin"></div>
          <a (click)="loginByGoogle()" class="cursor-pointer no-underline border-1 border-round-lg px-4 py-3 border-gray-900 flex flex-row justify-content-center align-items-center bg-white gap-2">
            <img src="assets/google.svg" alt="github">
            <span class="font-semibold text-gray-900">
              {{ 'CLIENT.SIGN_IN.SIGN_IN_BY_GOOGLE' | translate }}
            </span>
          </a>
        </div>
        <div class="flex flex-row align-content-center">
          <div class="font-normal">
            <span class="text-grey-dark-hover">
              {{ 'CLIENT.SIGN_IN.LINK_FORGOTTEN_PASSWORD.DESCRIPTION' | translate }}
            </span>
            <a routerLink="/recover-password" class="no-underline text-blue-normal">
              {{ 'CLIENT.SIGN_IN.LINK_FORGOTTEN_PASSWORD.LINK' | translate }}
            </a>
          </div>
        </div>
        <div class="flex flex-row align-content-center">
          <div class="font-normal">
            <span class="text-grey-dark-hover">
              {{ 'CLIENT.SIGN_IN.LINK_REGISTER.DESCRIPTION' | translate }}
            </span>
            <a routerLink="/signup" class="no-underline text-blue-normal">
              {{ 'CLIENT.SIGN_IN.LINK_REGISTER.LINK' | translate }}
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
