import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleRepository } from './article-repository';
import { ComponentRepository } from './component-repository';
import { DeploymentRepository } from './deployment-repository';
import { ProductRepository } from './product-repository';
import { SessionRepository } from './session-repository';
import { SupportRepository } from './support-repository';
import { UserRepository } from './user-repository';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  providers: [
    ArticleRepository,
    ComponentRepository,
    DeploymentRepository,
    ProductRepository,
    SessionRepository,
    SupportRepository,
    UserRepository,
  ],
  exports: [],
  imports: [
    CommonModule,
    TranslateModule.forChild({}),
  ]
})
export class RepositoriesModule { }
