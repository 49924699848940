import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Subscription, timer } from 'rxjs';
import { UserManager } from '../services/user-manager.service';
import { GOOGLE_INTEGRATED } from '../brodcast-messages';
import { GOOGLE_INTEGRATE } from '../brodcast-channels';

@Component({
  selector: 'app-google-integration-page',
  templateUrl: './google-integration-page.component.html',
  styleUrls: ['./google-integration-page.component.scss']
})
export class GoogleIntegrationPageComponent {

  public isLoading: boolean = true;
  public hasError: boolean = false;

  countDown!: Subscription;
  counter: number = 5;

  constructor(
    private route: ActivatedRoute,
    private userManager: UserManager
  ) {}

  async ngOnInit() {
    try {
      this.isLoading = true;
      const fragment = await firstValueFrom(this.route.fragment)
      const accessToken = fragment?.split('&').find((fragment) => fragment.includes('access_token'))?.split('=')[1];

      const response = await this.userManager.connectWithGoogle(accessToken!);

      if(response) {
        const channel = new BroadcastChannel(GOOGLE_INTEGRATE);
        channel.postMessage(GOOGLE_INTEGRATED);
      }

      this.countDown = timer(0, 1000).subscribe(() => {
        if(this.counter > 0) --this.counter;
        if(this.counter === 0) {
          this.countDown.unsubscribe();
          window.close();
        }
      });
    } catch (err) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
  
}
