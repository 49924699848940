<div class="flex flex-row justify-content-center md:pt-8">
    <div class="container flex-grow-1 flex flex-column md:flex-row gap-4 align-items-stretch align-content-center px-4 md:px-0">
        <div class="flex flex-column flex-grow-1 flex-nowrap w-full md:pl-4 lg:pl-0">
            <div>
                <img src="assets/login.png" alt="Decoration image" class="w-full">
            </div>
            <div class="flex flex-row sm:flex-column md:flex-row gap-3 sm:gap-6 md:gap-5 lg:gap-6 align-self-center text-grey-dark-hover align-items-center lg:px-8 lg:mx-5">
                <div class="flex flex-row gap-2 align-items-center text-sm">
                    <img src="assets/reports.svg" alt="">
                    {{ 'CLIENT.SIGN_UP.TRAITS.FIRST_TRAIT' | translate }}
                </div>
                <div class="flex flex-row gap-2 align-items-center text-sm">
                    <img src="assets/callinsupport.svg" alt="">
                    {{ 'CLIENT.SIGN_UP.TRAITS.SECOND_TRAIT' | translate }}
                </div>
                <div class="flex flex-row gap-2 align-items-center text-sm">
                    <img src="assets/certificate.svg" alt="">
                    {{ 'CLIENT.SIGN_UP.TRAITS.THIRD_TRAIT' | translate }}
                </div>
            </div>
        </div>
        <div class="flex-grow-1 w-full">
            <form class="relative flex flex-column gap-5 xl:w-6 md:pr-4 xl:pr-0" (ngSubmit)="registerViaEmail()" [formGroup]="formGroup">
                <div class="absolute flex flex-column justify-content-center align-items-center top-0 bottom-0 left-0 right-0 z-5" style="background-color: rgba(243, 247, 252, 0.9);" *ngIf="isLoading">
                    <i class="pi pi-spin pi-spinner text-7xl"></i>
                </div>
                <span class="font-semibold text-5xl">
                    {{ 'CLIENT.SIGN_UP.TITLE' | translate }}
                </span>
                <div class="flex flex-column">
                    <input type="text" pInputText class="w-full bg-blue-light border-blue-light-hover text-grey" placeholder="{{ 'CLIENT.SIGN_UP.FORM.EMAIL' | translate }}" formControlName="email" />
                    <div *ngIf="emailFormControl.errors?.['email']" class="font-semibold text-red-normal text-xs pt-2">
                        {{ 'CLIENT.SIGN_UP.FORM.ERROR.NOT_AN_EMAIL' | translate }}
                    </div>
                    <ng-container formGroupName="password">
                        <p-password [feedback]="false" class="pt-4" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.SIGN_UP.FORM.PASSWORD' | translate }}" formControlName="password"></p-password>
                        <div *ngIf="passwordFormControl.errors?.['pattern']" class="font-semibold text-red-normal text-xs pt-2">
                            {{ 'CLIENT.SIGN_UP.FORM.ERROR.WEAK_PASSWORD' | translate }}
                        </div>
                        <p-password [feedback]="false" class="pt-4" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.SIGN_UP.FORM.COFIRM_PASSWORD' | translate }}" formControlName="confirmPassword"></p-password>
                    </ng-container>
                    <div *ngIf="passwordFormGroup.errors?.['notSame']" class="font-semibold text-red-normal text-xs pt-2">
                        {{ 'CLIENT.SIGN_UP.FORM.ERROR.NOT_SAME_PASSWORDS' | translate }}
                    </div>
                </div>
                <p-button class="w-full" styleClass="w-full justify-content-center font-semibold" [disabled]="formGroup.invalid && isLoading" type="submit" [disabled]="formGroup.invalid || formGroup.pristine">
                    {{ 'CLIENT.SIGN_UP.SIGN_UP_BUTTON' | translate }}
                </p-button>
                <div class="flex flex-row gap-3 align-items-center">
                    <div class="border-top-1 flex-grow-1 border-blue-light-active"></div>
                    <span class="font-normal text-base text-grey-dark-hover">{{ 'CLIENT.SIGN_UP.DELIMITER' | translate }}</span>
                    <div class="border-top-1 flex-grow-1 border-blue-light-active"></div>
                </div>
                <div class="flex flex-column gap-3">
                    <a (click)="registerViaGithub()" class="no-underline border-1 border-round-lg px-4 py-3 border-gray-900 flex flex-row justify-content-center align-items-center bg-gray-900 gap-2 text-white">
                        <img src="assets/github-white.svg" alt="github">
                        <span class="font-semibold">
                            {{ 'CLIENT.SIGN_UP.SIGN_UP_BY_GITHUB_BUTTON' | translate }}
                        </span>
                    </a>
                    <a (click)="registerViaGoogle()" class="no-underline border-1 border-round-lg px-4 py-3 border-gray-900 flex flex-row justify-content-center align-items-center bg-gray-900 gap-2 text-white">
                        <img src="assets/google.svg" alt="google">
                        <span class="font-semibold">
                            {{ 'CLIENT.SIGN_UP.SIGN_UP_BY_GOOGLE_BUTTON' | translate }}
                        </span>
                    </a>
                    <div id="googleLogin" style="display: none;"></div>
                </div>
                <div class="flex flex-row align-content-center justify-content-center">
                    <div class="font-normal">
                        <span class="text-grey-dark-hover">
                            {{ 'CLIENT.SIGN_UP.LINK_DESCRIPTION' | translate }}
                        </span>
                        <a routerLink="/signin" class="no-underline text-blue-normal">
                            {{ 'CLIENT.SIGN_UP.LINK_TITLE' | translate }}
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>