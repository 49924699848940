import { Injectable } from '@angular/core';
import { ApiClient } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class UserManager {
  constructor(
    private apiClient: ApiClient,
  ) {}

  public registerUser(
    email: string,
    password: string,
  ) {
    return this.apiClient.post(`/users`, {
      email,
      password,
    });
  }

  public deleteUser() {
    return this.apiClient.delete(`/users/self`);
  }

  public confirmUserDeletion(deletionTokenId: string) {
    return this.apiClient.put(`/deletion-tokens/${deletionTokenId}`);
  }

  public setUserData(data: {
    firstName: string;
    lastName: string;
    organizationName: string;
  }) {
    return this.apiClient.put(`/users/self`, data);
  }

  public setNewPassword(data: {
    password: string;
    newPassword: string;
  }) {
    return this.apiClient.put(`/users/self`, data);
  }

  public registerUserByGoogle(googleData: string) {
    return this.apiClient.post('/users/google', {
      googleData,
    });
  }

  public registerUserByGithub(githubCode: string) {
    return this.apiClient.post('/users/github', {
      githubCode
    });
  }

  public async activateNewPassword(passwordToken: string, options: {
    password: string;
  }) {
    return this.apiClient.put(`/password-resets/${passwordToken}`, options);
  }

  public async activateNewEmail(changeEmailToken: string) {
    return this.apiClient.post(`/email-resets/${changeEmailToken}`);
  }

  public async getUserData() {
    return this.apiClient.get<{ id: string, email: string, firstName: string, lastName: string, organizationName: string, hasGithub: boolean, hasGoogle: boolean,}>('/users/self');
  }

  public remindPassword(email: string) {
    return this.apiClient.post(`/password-resets`, {
      email,
    });
  }

  public activateUser(id: string) {
    return this.apiClient.post(`/user-activation/${id}`);
  }

  public connectWithGithub(githubCode: string) {
    return this.apiClient.post(`/users/self/integrations/github`, {
      githubCode,
    });
  }

  public connectWithGoogle(googleData: string) {
    return this.apiClient.post(`/users/self/integrations/google`, {
      googleData,
    });
  }
}
