import { ApplicationRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, firstValueFrom } from 'rxjs';

@Component({
  selector: 'main-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor (
    private translate: TranslateService,
    private appRef: ApplicationRef,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.translate.addLangs(['pl', 'en']);
    this.translate.setDefaultLang('en');
    this.translate.use(this.translate.defaultLang);

    this.translate.onTranslationChange.subscribe(() => {
      setTimeout(() => {
        this.appRef.tick();
      });
    });

    firstValueFrom(this.router.events.pipe(filter(event => event instanceof ActivationEnd))).then((event) => {
      if(event instanceof ActivationEnd) {
        if(!event.snapshot.queryParams['lang']) return;
        this.translate.use(event.snapshot.queryParams['lang']);
      }
    });
  }
}
