import { Injectable } from '@angular/core';
import { ApiClient } from './api-client.service';
import { BehaviorSubject } from 'rxjs';

interface UserData {
  id: string;
  email: string;
  lastName: string;
  firstName: string;
  organizationName: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public loginSubject: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);

  constructor(private apiClient: ApiClient) {}

  public async login(username: string, password: string) {
    try {
      const {id, email, lastName, firstName, organizationName } = (await this.apiClient.post(`/sessions`, {
        email: username,
        password,
      })) as any;
      this.loginSubject.next({ id, email, lastName, firstName, organizationName });
      return true;
    } catch (err) {
      console.error(err);
      this.loginSubject.next(null);
      return false;
    }
  }

  public async loginByGoogle(googleData: string) {
    try {
      const {id, email, lastName, firstName, organizationName } = await this.apiClient.post<{
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        organizationName: string;
      }>(`/sessions/google`, {
        googleData,
      });
      this.loginSubject.next({ id, email, lastName, firstName, organizationName });
      return true;
    } catch (err) {
      console.error(err);
      this.loginSubject.next(null);
      throw err;
    }
  }

  public async loginByGithub(githubCode: string) {
    try {
      const {id, email, lastName, firstName, organizationName } = await this.apiClient.post<{
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        organizationName: string;
      }>(`/sessions/github`, {
        githubCode,
      });
      this.loginSubject.next({ id, email, lastName, firstName, organizationName });
      return true;
    } catch (err) {
      console.error(err);
      this.loginSubject.next(null);
      throw err;
    }
  }

  public async logout() {
    await this.apiClient.delete(`/sessions`);
    this.loginSubject.next(null);
    return;
  }

  public async checkIsLoggedIn() {
    try {
      const {id, email, lastName, firstName, organizationName } = (await this.apiClient.get('/sessions')) as any;
      this.loginSubject.next({ id, email, lastName, firstName, organizationName });
      return true;
    } catch (err) {
      console.error(err);
      this.loginSubject.next(null);
      return false;
    }
  }
}
