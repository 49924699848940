import { Component, OnInit } from '@angular/core';
import { SecurityPageDataService } from './security-page-data.service';

@Component({
  selector: 'app-security-page',
  templateUrl: './security-page.component.html',
  styleUrls: ['./security-page.component.scss']
})
export class SecurityPageComponent {
  constructor(
    public securityPageData: SecurityPageDataService,
  ) {}
}
