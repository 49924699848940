<div style="background-color: #DEEBFB" class="flex flex-row justify-content-center sticky top-0 z-5 transition-duration-200" #stickyMenu>
    <div class="container flex-grow-1 flex flex-row flex-nowrap align-content-center justify-content-between">
        <div class="flex flex-row overflow-x-auto flex-nowrap w-screen lg:w-auto flex-shrink-0">
            <a routerLink="/products/{{deploymentData.productId}}" pRipple class="font-bold p-4 flex align-items-center no-underline text-black-black-100 flex-shrink-0">
                <i class="icon-double-chevron-left icon-16 bg-grey-dark flex-shrink-0"></i>
                <span class="ml-2 white-space-nowrap">
                    {{ 'CLIENT.DEPLOYMENT.NAVBAR.GO_BACK_TO_PRODUCT' | translate }}
                </span>
            </a>
            <a routerLink="description" pRipple class="font-bold p-4 flex align-items-center no-underline text-black-black-100 flex-shrink-0" routerLinkActive="active-link">
                <i class="icon-16 icon-justify-right bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2">
                    {{ 'CLIENT.DEPLOYMENT.NAVBAR.DESCRIPTION' | translate }}
                </span>
            </a>
            <a routerLink="documentation" pRipple class="font-bold p-4 flex align-items-center no-underline text-black-black-100 flex-shrink-0" routerLinkActive="active-link">
                <i class="icon-16 icon-document bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2">
                    {{ 'CLIENT.DEPLOYMENT.NAVBAR.DOCUMENTATION' | translate }}
                </span>
            </a>
            <a routerLink="report" pRipple class="font-bold p-4 flex align-items-center no-underline text-black-black-100 flex-shrink-0" routerLinkActive="active-link">
                <i class="icon-16 icon-pie-chart bg-grey-dark flex-shrink-0" routerLinkActive="active-symbol"></i>
                <span class="ml-2">
                    {{ 'CLIENT.DEPLOYMENT.NAVBAR.REPORT' | translate }}
                </span>
            </a>
        </div>
        <p-button class="align-self-center" styleClass="hidden md:block cta lg:mr-2" *ngIf="!isInstallOnPageVisible">
            <img src="assets/terminal.svg" style="width: 16px; height: 16px;" alt="install">
            <span class="mx-2 font-bold">
                {{ 'CLIENT.DEPLOYMENT.INSTALL_BUTTON' | translate }}
            </span>
        </p-button>
    </div>
</div>

<div class="flex flex-row justify-content-center pt-4 md:pt-8">
    <div class="container flex-grow-1 flex flex-column md:flex-row px-4 lg:px-0 align-content-center align-items-start gap-4 pb-8 mb-8 min-w-0">

        <div class="p-5 border-1 border-blue-light-hover border-round-lg sidebar hidden lg:flex flex-column flex-shrink-0 flex-wrap" *ngIf="isLoading">
            <p-skeleton styleClass="w-full h-12rem" class="w-full"></p-skeleton>
            <div class="flex flex-row justify-content-between mt-5">
                <div class="flex flex-column align-items-center gap-1">
                    <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
                    <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
                </div>
                <div class="flex flex-column align-items-center gap-1">
                    <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
                    <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
                </div>
                <div class="flex flex-column align-items-center gap-1">
                    <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
                    <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium text-base">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="flex mt-px-12">
                    <p-skeleton styleClass="w-7 h-2rem" class="w-7 h-2rem"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="mt-px-12 text-grey-dark font-medium">
                    <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="flex flex-column mt-4">
                    <div *ngFor="let item of [0, 0, 0, 0]" class="flex flex-row align-items-center mb-3 gap-2">
                        <p-skeleton size="1.75rem"></p-skeleton>
                        <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <div class="text-blue-darker font-semibold flex align-items-center gap-2">
                    <p-skeleton size="1rem"></p-skeleton>
                    <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
                </div>
                <div class="gap-3 flex align-items-center align-content-center align-self-stretch flex-wrap mt-3">
                    <p-skeleton *ngFor="let tag of [6, 10, 10, 6, 6, 10, 10, 6]" width="{{tag}}rem" height="2rem"></p-skeleton>
                </div>
            </div>
        </div>

        <div class="p-5 border-1 border-blue-light-hover border-round-lg sidebar hidden lg:flex flex-column flex-shrink-0 flex-wrap" *ngIf="!isLoading">
            <div class="p-3 rating-general min-w-0 max-w-full overflow-x-hidden">
                <ng-template [ngTemplateOutlet]="mainRating"></ng-template>
            </div>
            <ng-template [ngTemplateOutlet]="sidebar"></ng-template>
        </div>

        <div class="flex flex-column w-full">

            <div class="flex flex-row justify-content-between">
                <p-skeleton width="16rem" height="4rem" *ngIf="isLoading"></p-skeleton>
                <span class="font-bold text-3xl md:text-6xl text-blue-darker be-vietnam-pro" *ngIf="!isLoading">{{ deploymentData.name | translate }}</span>
                <div #installOnPage class="flex align-items-center">
                    <p-skeleton width="8.75rem" height="2.75rem" *ngIf="isLoading"></p-skeleton>
                    <a routerLink="install" class="align-self-center cta lg:mr-2 z-5 border-round-lg no-underline flex flex-row align-items-center" *ngIf="isInstallOnPageVisible && !isLoading">
                        <img src="assets/terminal.svg" style="width: 16px; height: 16px;" alt="install">
                        <span class="mx-2 font-bold">{{ 'CLIENT.DEPLOYMENT.INSTALL_BUTTON' | translate }}</span>
                    </a>
                </div>
            </div>

            <div class="flex flex-column lg:hidden p-3 border-round-lg bg-white mb-6 sm:w-6 min-w-0 overflow-x-hidden mt-4">
                <ng-template [ngTemplateOutlet]="mainRating"></ng-template>
            </div>

            <div class="mt-5">
                <ng-container *ngIf="isLoading">
                    <p-skeleton styleClass="w-11" height="2rem"></p-skeleton>
                    <p-skeleton styleClass="w-5 mt-2" height="2rem"></p-skeleton>
                </ng-container>
                <span class="text-grey-dark text-xl font-semibold line-height-3" *ngIf="!isLoading">
                    {{ deploymentData.summary | translate }}
                </span>
            </div>
            <router-outlet class="flex flex-row w-full"></router-outlet>
        </div>

    </div>
</div>

<ng-template #mainRating>
    <p class="text-base font-bold flex align-items-center text-blue-darker m-0">
        <img src="assets/round_star.svg" />
        <span class="pl-2">
            {{ 'CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING' | translate }}
        </span>
    </p>
    <div class="mt-3 flex flex-row align-items-center lg:justify-content-evenly gap-2">
        <app-score-circle
            helpText="CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING.HELP_TEXT"
            [score] = "deploymentData.generalRating"
            [trend] = "deploymentData.lastTrends.scarm"
        ></app-score-circle>
        <div *ngIf="(deploymentData.generalRating * 10) >= 6" class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
         [class.bg-severity-low]="(deploymentData.generalRating * 10) >= 6 && (deploymentData.generalRating * 10) < 8"
         [class.bg-severity-no-risk]="(deploymentData.generalRating * 10) >= 8 && (deploymentData.generalRating * 10) < 10"
        >
        <span class="text-white" *ngIf="(deploymentData.generalRating * 10) >= 6 && (deploymentData.generalRating * 10) < 8">
            {{ 'CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING.SEVERITY.LOW' | translate }}
        </span>
        <span class="text-white" *ngIf="(deploymentData.generalRating * 10) >= 8 && (deploymentData.generalRating * 10) < 10">
            {{ 'CLIENT.PRODUCT.SIDEBAR.GENERAL_RATING.SEVERITY.SAFE' | translate }}
        </span>
      </div>
    </div>
</ng-template>

<ng-template #sidebar>
    <div class="flex flex-row justify-content-between mt-5 flex-wrap">
        <ng-container *ngFor="let scoreName of scoresOrder; index as i;">

            <div *ngIf="i < 3; else showMore" class="flex flex-column align-items-center">
                <span class="font-medium text-xs text-grey-dark-hover">
                    {{ 'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING' | translate }}
                </span>
                <app-score-circle
                    [helpText]="'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING.HELP_TEXT'"
                    [score]="scoresWithTrends[scoreName][0]"
                    [trend] = "scoresWithTrends[scoreName][1]"
                    [radius]="32"
                    [outerStrokeColor]="'#BBD6F7'"
                    [outerStrokeGradientStopColor]="'#78ADED'"
                ></app-score-circle>
            </div>
            <ng-template #showMore>
                <div class="flex flex-column align-items-center" style="margin: 0 auto;" *ngIf="displayAllScores">
                    <span class="font-medium text-xs text-grey-dark-hover">
                        {{ 'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING' | translate }}
                    </span>
                    <app-score-circle
                        [helpText]="'CLIENT.PRODUCT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING.HELP_TEXT'"
                        [score]="scoresWithTrends[scoreName][0]"
                        [trend] = "scoresWithTrends[scoreName][1]"
                        [radius]="32"
                        [outerStrokeColor]="'#BBD6F7'"
                        [outerStrokeGradientStopColor]="'#78ADED'"
                    ></app-score-circle>
                </div>
            </ng-template>
        </ng-container>
    </div>
    <div class="mt-px-12" *ngIf="!displayAllScores && numberOfPositiveScores > 3">
        <button class="show-more-btn font-bold text-sm text-grey-dark" pRipple (click)="showAllScores()">
            {{ 'CLIENT.PRODUCT.SIDEBAR.SHOW_MORE' | translate }} ({{numberOfPositiveScores-3}})
        </button>
    </div>
    <!-- <div class="mt-6">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/folder-tree.svg" alt="">
            <span class="pl-2 ">{{ 'CLIENT.PRODUCT.SIDEBAR.PRODUCT_VERSION' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium text-base">
            <span>{{ deploymentData.version }}</span>
        </div>
    </div>
    <div class="mt-5" *ngIf="deploymentData.releaseDate">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/calendar-line.svg" alt="">
            <span class="pl-2">{{ 'CLIENT.PRODUCT.SIDEBAR.RELEASE_DATE' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>{{ deploymentData.releaseDate | date : 'dd.MM.yyyy' }}</span>
        </div>
    </div> -->
    <div class="mt-5" *ngIf="deploymentData.creationDate">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/calendar-line.svg" alt="">
            <span class="pl-2">{{ 'CLIENT.PRODUCT.SIDEBAR.CREATION_DATE' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>{{ deploymentData.creationDate | date : 'dd.MM.yyyy' }}</span>
        </div>
    </div>
    <div class="mt-5" *ngIf="deploymentData.lastActivityDate">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/calendar-today.svg" alt="">
            <span class="pl-2">{{ 'CLIENT.PRODUCT.SIDEBAR.LAST_ACTIVITY_DATE' | translate }}</span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>{{ deploymentData.lastActivityDate | date : 'dd.MM.yyyy' }}</span>
        </div>
    </div>
    <div class="mt-6" *ngIf="deploymentData.sourceUrl">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/branch.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.SOURCE' | translate }}
            </span>
        </div>
        <div class="flex mt-px-12">
            <a href="{{ deploymentData.sourceUrl }}" class="source-link border-grey-normal border-1 text-grey-dark" target="_blank">
                <i class="pi pi-github text-grey-darker"></i>
                <span class="font-normal">Github</span>
            </a>
        </div>
    </div>
    <div class="mt-6" *ngIf="deploymentData.maintainer">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/briefcase.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.MAINTAINER' | translate }}
            </span>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
            <span>
                {{ deploymentData.maintainer }}
            </span>
        </div>
    </div>
    <div class="mt-6" *ngIf="deploymentData.traits.length > 0">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/card.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.PRODUCT_TRAITS' | translate }}
            </span>
        </div>
        <div class="flex flex-column mt-4">
            <div *ngFor="let trait of deploymentData.traits.slice(0, traitsToDisplay)" class="flex flex-row align-items-center mb-3">
                <img src="assets/check.svg" alt="check mark">
                <span class="ml-3 text-base text-blue-darker">{{ trait | translate }}</span>
            </div>
        </div>
        <div class="mt-px-12" *ngIf="traitsToDisplay < deploymentData.traits.length">
            <button class="show-more-btn font-bold text-sm text-grey-dark" (click)="showMoreTraits()" pRipple>
                {{ 'CLIENT.PRODUCT.SIDEBAR.SHOW_MORE' | translate }} ({{ deploymentData.traits.length - traitsToDisplay }})
            </button>
        </div>
    </div>
    <div class="mt-6" *ngIf="deploymentData.tags.length > 0">
        <div class="text-blue-darker font-semibold flex align-items-center">
            <img src="assets/similar.svg" alt="">
            <span class="pl-2">
                {{ 'CLIENT.PRODUCT.SIDEBAR.PRODUCT_CATEGORIES' | translate }}
            </span>
        </div>
        <div class="gap-3 flex align-items-center align-content-center align-self-stretch flex-wrap mt-3">
            <div *ngFor="let tag of deploymentData.tags" class="product-tag-btn bg-black-white border-blue-light-active text-blue-normal">
                <i class="pi pi-filter"></i>
                <span class="ml-2">{{ tag.name | translate }}</span>
            </div>
        </div>
    </div>
</ng-template>
