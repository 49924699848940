import { Message } from "primeng/api";

export const SendEmailMessage: Message = {
  severity: 'success',
  summary: 'MESSAGE.SEND_EMAIL.SUCCESS',
  detail: 'MESSAGE.SEND_EMAIL.SUCCESS.DETAILS'
};


export const SendEmailErrorMessage: Message = {
  severity: 'error',
  summary: 'MESSAGE.SEND_EMAIL.ERROR',
  detail: 'MESSAGE.SEND_EMAIL.ERROR.DETAILS',
};


export const AddedToFavourites: Message = {
  severity: 'success',
  summary: 'MESSAGE.ADD_TO_FAVOURITES.SUCCESS',
  detail: 'MESSAGE.ADD_TO_FAVOURITES.SUCCESS.DETAILS',
}

export const AddedToFavouritesError: Message = {
  severity: 'info',
  summary: 'MESSAGE.ADD_TO_FAVOURITES.ERROR',
  detail: 'MESSAGE.ADD_TO_FAVOURITES.ERROR.DETAILS',
}

export const RemovedFromFavourites: Message = {
  severity: 'success',
  summary: 'MESSAGE.REMOVED_FROM_FAVOURITES.SUCCESS',
  detail: 'MESSAGE.REMOVED_FROM_FAVOURITES.SUCCESS.DETAILS',
}


export const MyProjectAddedMessage: Message = {
  severity: 'success',
  summary: 'MESSAGE.MY_PROJECT_ADDED.SUCCESS',
  detail: 'MESSAGE.MY_PROJECT_ADDED.SUCCESS.DETAILS'
};


export const MyProjectAddedErrorMessage: Message = {
  severity: 'error',
  summary: 'MESSAGE.MY_PROJECT_ADDED.ERROR',
  detail: 'MESSAGE.MY_PROJECT_ADDED.ERROR.DETAILS',
};
