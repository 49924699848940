<div class="flex flex-column gap-5 bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover">
  <div class="flex flex-row gap-3 align-items-center justify-content-between">
    <div class="flex gap-3 align-items-center">
      <i class="icon-folder-tree icon-24 bg-blue-darker"></i>
      <span class="font-bold text-3xl text-blue-darker">
        {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.TITLE' | translate }}
      </span>
    </div>
      <a routerLink="add" class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg">
        {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.ADD_BUTTON' | translate }}
      </a>
  </div>

  <span class="text-grey-dark-hover text-base w-10">
    {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.DESCRIPTION' | translate }}
  </span>

    <div class="flex" *ngIf="!isLoading">
        <p-table [value]="tableData" class="w-full">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
              <div>
                <span>
                  {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.USER_PROJECT' | translate }}
                </span>
              </div>
            </th>
            <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-2">
              <div>
                <span>
                  {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.STATUS' | translate }}
                </span>
              </div>
            </th>
            <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-2">
              <div>
                <span>
                  {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.DEPENDENCIES' | translate }}
                </span>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-center" colspan="3">
              {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.NO_PROJECTS' | translate }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-project>
          <tr>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover">
              <span class="flex gap-2">
                  <i class="icon-code icon-16 bg-black-black-100"></i>
                  {{project.name}}
              </span>
            </td>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
              <div class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
                  [class.bg-severity-low]="project.status=='ready'"
                  [class.bg-severity-medium]="project.status=='pending'"
                  [class.bg-severity-critical]="project.status=='error'"
                >
                  <span class="text-white text-nowrap" *ngIf="project.status=='error'">
                    {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.STATUS.ERROR' | translate }}
                  </span>
                  <span class="text-white text-nowrap" *ngIf="project.status=='pending'">
                    {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.STATUS.PENDING' | translate }}
                  </span>
                  <span class="text-white text-nowrap" *ngIf="project.status=='ready'">
                    {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.STATUS.READY' | translate }}
                  </span>
                </div>
            </td>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
              <a routerLink="details/{{ project.url }}" class="no-underline flex flex-row gap-2 text-black-black-100 align-items-center" *ngIf="project.status=='ready'">
                <span class="text-sm font-bold">
                  {{ 'CLIENT.PROFILE.MY_PROJECTS.INFO_PAGE.SHOW_DEPENDENCIES' | translate }}
                </span>
                <i class="icon-16 icon-link bg-black-black-100"></i>
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

</div>
