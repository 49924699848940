<a [routerLink]="url" class="no-underline border-1 border-blue-light-hover bg-white border-round-lg flex flex-column w-full md:w-21rem cursor-pointer">
    <div class="flex flex-row p-3 justify-content-between border-bottom-1 border-blue-light min-h-3rem">
        <img src="{{ logoUrl }}" alt="" class="max-w-7rem" style="max-height: 20px;">
        <ng-container *ngIf="headerTemplate">
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </ng-container>
    </div>
    <div class="flex flex-row px-3 py-4 gap-3 flex-grow-1 max-h-8rem">
        <div class="flex flex-column align-items-center">
            <app-score-circle
                [score] = "scarmRating"
                [trend] = "lastScarmTrend"
                [radius]="35"
                [outerStrokeColor]="'#2079E2'"
                [outerStrokeGradientStopColor]="'#134988'"
            >
            </app-score-circle>
        </div>
        <div class="flex flex-column gap-3 min-w-0">
            <span class="font-bold text-blue-darker overflow-x-hidden text-overflow-ellipsis" style="text-wrap: nowrap;">
                {{ name | translate }}
            </span>
            <span class="text-blue-darker text-sm webkit-box line-clamp-2 overflow-hidden webkit-box-vertical">
                {{ summary | translate }}
            </span>
        </div>
    </div>
    <div class="flex flex-row justify-content-between align-items-center px-3 py-2 border-top-1 border-blue-light">
        <div class="bg-grey-light p-2 border-round-lg flex flex-row align-items-center gap-2">
            <i class="icon-round-star icon-16 bg-blue-darker"></i>
            <span class="text-blue-darker font-bold text-sm" *ngIf="starsCount">
                {{ starsCount | splitNumber: ' ' }}
            </span>
            <span class="text-blue-darker font-bold text-sm" *ngIf="!starsCount">
                {{ 'CLIENT.NO_DATA' | translate }}
            </span>
        </div>
        <i class="icon-24 bg-blue-darker" [ngClass]="{'icon-star': !isLiked, 'icon-star-full': isLiked}" (click)="like($event)"></i>
    </div>
</a>