import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SessionService } from '../services/session.service';
import { ActiveUser } from '../services/active-user.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from "../services/local-storage.service";

@Component({
  selector: 'main-app',
  templateUrl: './main-app.component.html',
  styleUrls: ['./main-app.component.scss']
})
export class MainAppComponent implements OnInit {
  sidebarVisible: boolean = false;
  items: MenuItem[] | undefined;
  loggedMenu: MenuItem[] | undefined;
  isLoggedIn: boolean = true;
  selectedLanguage: any = {
    value: 'Polski',
    code: 'PL',
  };
  languages: any[] = [];

  public langExpanded: boolean = false;

  public solutionsExpanded: boolean = false;

  home: MenuItem | undefined;

  regulationsStorageKey = 'regulations'

  constructor(
    public router: Router,
    private sessionService: SessionService,
    public activeUser: ActiveUser,
    private translate: TranslateService,
    public localStorageService: LocalStorageService,
  ) {}

  shouldDisplayExpert() {
    if (/(\/signin)|(\/signup)|(\/recover-password)|(\/profile*)/.test(this.router.url)) return false;
    // if(['/signin', '/signup', '/recover-password', '/profile'].some((element) => element === this.router.url)) return false;
    return true;
  }

  shouldDisplayFooterLinks() {
    if (/(\/signin)|(\/signup)|(\/recover-password)/.test(this.router.url)) return false;
    return true;
  }

  onAccept(){
    this.localStorageService.setItem(this.regulationsStorageKey, 'true')
  }

  public langToggle(event: Event) {
    event.stopPropagation();
    this.langExpanded = !this.langExpanded;
  }

  public solutionsToggle(event: Event) {
    event.stopPropagation();
    this.solutionsExpanded = !this.solutionsExpanded;
  }

  public toggleSidebar(event: Event) {
    event.stopPropagation();
    this.sidebarVisible = !this.sidebarVisible;
  }

  public changeLanguage(language: any, event: Event) {
    event.stopPropagation();
    this.selectedLanguage = language;
    this.translate.use(language.code.toLowerCase());
    this.langExpanded = false;
  }

  ngOnInit() {
    this.translate.onTranslationChange.subscribe(() => {
      this.selectedLanguage = this.languages.find((language) => language.code === this.translate.currentLang.toUpperCase());
    });

    this.sessionService.checkIsLoggedIn();

    this.languages = [
      {
        value: 'Polski',
        code: 'PL',
      },
      {
        value: 'English',
        code: 'EN',
      },
    ];

    this.selectedLanguage = this.languages.find((language) => language.code === this.translate.currentLang.toUpperCase());

    this.items = [{ label: 'Katalog', routerLink: 'catalog' }, { label: 'Produkt' }];

    this.home = { icon: 'pi pi-home', routerLink: '/' };

    this.loggedMenu = [
      {
        label: '<div class="text-xl font-semibold">Jan Kowalski</div>',
        escape: false,
      },
      {
        label: 'Profil',
        icon: 'pi pi-fw pi-user',
        routerLink: 'profile/1'
      },
      {
        separator: true,
      },
      {
        label: 'Wyloguj',
        icon: 'pi pi-fw pi-power-off',
      }
    ];
  }

  @HostListener('document:click', ['$event'])
  handleMouseclick(event: PointerEvent) {
    if (this.langExpanded) {
      this.langExpanded = false;
    }
    if (this.solutionsExpanded) {
      this.solutionsExpanded = false;
    }
    if(this.sidebarVisible) this.sidebarVisible = false;
  }
}
