import { Component, OnInit } from '@angular/core';
import { UserManager } from '../services/user-manager.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-deletion-user-page',
  templateUrl: './deletion-user-page.component.html',
  styleUrls: ['./deletion-user-page.component.scss']
})
export class DeletionUserPageComponent implements OnInit {
  public isLoading: boolean = true;
  public hasError: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userManager: UserManager,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    try {
      this.isLoading = true;
      const { deletionTokenId } = await firstValueFrom(this.route.params)
      
      await this.userManager.confirmUserDeletion(deletionTokenId);

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('CLIENT.USER_DELETION.NOTIFICATION.SUCCESS.TITLE'),
        detail: this.translate.instant('CLIENT.USER_DELETION.NOTIFICATION.SUCCESS.DESCRIPTION'),
      })
    } catch (err) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
