import { Component, OnInit } from '@angular/core';
import { DeploymentDataService } from '../deployment-data.service';

@Component({
  selector: 'app-deployment-documentation-page',
  templateUrl: './deployment-documentation-page.component.html',
  styleUrls: ['./deployment-documentation-page.component.scss']
})
export class DeploymentDocumentationPageComponent implements OnInit {

  public isLoading: boolean = true;

  constructor(
    public deploymentData: DeploymentDataService,
  ) {}

  public ngOnInit(): void {
    this.deploymentData.isLoadingDataChange.subscribe(value => this.isLoading = value);
  }

}
