import {AfterViewInit, Component, Inject, Input, NgZone, OnDestroy, PLATFORM_ID} from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import {isPlatformBrowser} from "@angular/common";
import {CountriesDataService} from "../countries-data.service";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

@Component({
  selector: 'app-contribution-map',
  templateUrl: './contribution-map.component.html',
  styleUrls: ['./contribution-map.component.scss']
})
export class ContributionMapComponent implements AfterViewInit, OnDestroy{

  @Input({ required: true })
  contributions: Record<string, number> = {};

  private contributorsMap!: am5.Root;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private countriesDataService: CountriesDataService,
  ) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  private normalize(val: number, max: number, min: number) {
    return Number(((val - min) / (max - min)).toFixed(2)) * 100;
  }

  ngAfterViewInit(){
    this.browserOnly(() => {
      let root= this.contributorsMap = am5.Root.new('map');

      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      let chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'translateX',
          panY: 'translateY',
          projection: am5map.geoMercator(),
          layout: root.horizontalLayout,
        })
      );

      let polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          valueField: 'value',
          exclude: ['AQ'],
          calculateAggregates: true,
          stroke: am5.color(0x2079e2),
        })
      );

      const colors = [
        {
          range: [0, 0],
          color: 0xF8FAFC,
        },
        {
          range: [1, 3],
          color: 0xE9F1FB,
        },
        {
          range: [4, 25],
          color: 0xD9E8F9,
        },
        {
          range: [26, 50],
          color: 0xCADEF8,
        },
        {
          range: [51, 100],
          color: 0xBAD5F6,
        },
      ];

      polygonSeries.mapPolygons.template.adapters.add("fill", (fill, target) => {
        if(target.dataItem && fill) {
          const data = target.dataItem.dataContext as any;
          const normalized = data.normalizedValue;
          const color = colors.find((obj) => normalized >= obj.range[0] && normalized <= obj.range[1]);
          target.set('fill', am5.color(color!.color));
        }
        return fill;
      });

      const minValue = 0;
      let maxValue = 0;

      for (const [name, value] of Object.entries(this.contributions!)) {
        if(value > maxValue) maxValue = value;
      }

      const count: Record<any, number> = {};

      polygonSeries.data.setAll(am5geodata_worldLow.features.map((feature) => {
        const country = this.countriesDataService.countriesAlpha2.get(feature.id!.toString());
        let value = 0;

        if(country) {
          value = this.contributions[country['alpha-3']] ?? 0;
        }

        const normalized = this.normalize(value, maxValue, minValue);

        if(!count[normalized]) count[normalized] = 0;
        count[normalized] += 1;

        return {
          id: feature.id,
          value,
          normalizedValue: normalized,
        };
      }));

      polygonSeries.mapPolygons.template.setAll({
        strokeWidth: 0.8,
        tooltipText: '{id}: {value}',
        interactive: true
      });

    });
  }

  public ngOnDestroy() {
    this.browserOnly(() => {
      if(this.contributorsMap) this.contributorsMap.dispose();
    });
  }

}
