import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ProductCatalogPageComponent } from './product-catalog-page/product-catalog-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { SecurityPageComponent } from './security-page/security-page.component';
import { RemindPasswordPageComponent } from './remind-password-page/remind-password-page.component';
import { ProductEnvironmentsPageComponent } from './product-page/product-environments-page/product-environments-page.component';
import { ProductDescriptionPageComponent } from './product-page/product-description-page/product-description-page.component';
import { ProductDocumentationPageComponent } from './product-page/product-documentation-page/product-documentation-page.component';
import { ProductReportPageComponent } from './product-page/product-report-page/product-report-page.component';
import { ProductSupportPageComponent } from './product-page/product-support-page/product-support-page.component';
import { ProductSimilarPageComponent } from './product-page/product-similar-page/product-similar-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { KnowledgeBasePageComponent } from './knowledge-base-page/knowledge-base-page.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { AddressComponent } from './profile-page/address/address.component';
import { SubscriptionComponent } from './profile-page/subscription/subscription.component';
import { SupportComponent } from './profile-page/support/support.component';
import { FavoriteComponent } from './profile-page/favorite/favorite.component';
import { AccountComponent } from './profile-page/account/account.component';
import { MyProjectsInfoPageComponent } from './profile-page/my-projects/my-projects-info-page/my-projects-info-page.component';
import { MyProjectsAddPageComponent } from './profile-page/my-projects/my-projects-add-page/my-projects-add-page.component';
import { MyProjectsDetailsPageComponent } from './profile-page/my-projects/my-projects-details-page/my-projects-details-page.component';
import { DeploymentPageComponent } from './deployment-page/deployment-page.component';
import { ComponentPageComponent } from './component-page/component-page.component';
import { DeploymentDescriptionPageComponent } from './deployment-page/deployment-description-page/deployment-description-page.component';
import { DeploymentDocumentationPageComponent } from './deployment-page/deployment-documentation-page/deployment-documentation-page.component';
import { DeploymentReportPageComponent } from './deployment-page/deployment-report-page/deployment-report-page.component';
import { ActivateUserPageComponent } from './activate-user-page/activate-user-page.component';
import { ActivatePasswordPageComponent } from './activate-password-page/activate-password-page.component';
import { ActivateEmailPageComponent } from './activate-email-page/activate-email-page.component';
import { MainAppComponent } from './main-app-page/main-app.component';
import { GithubIntegrationPageComponent } from './github-integration-page/github-integration-page.component';
import { GithubLoginPageComponent } from './github-login-page/github-login-page.component';
import { GithubRegisterPageComponent } from './github-register-page/github-register-page.component';
import { DeploymentInstallPageComponent } from './deployment-page/deployment-install-page/deployment-install-page.component';
import { PostPageComponent } from './post-page/post-page.component';
import { RegulationsPageComponent } from './regulations-page/regulations-page.component'
import {PrivacyPolicyPageComponent} from "./privacy-policy-page/privacy-policy-page.component";
import { GoogleLoginPageComponent } from './google-login-page/google-login-page.component';
import { GoogleRegisterPageComponent } from './google-register-page/google-register-page.component';
import { GoogleIntegrationPageComponent } from './google-integration-page/google-integration-page.component';
import { DeletionUserPageComponent } from './deletion-user-page/deletion-user-page.component';
import {SolutionsEzdrpPageComponent} from "./solutions-ezdrp-page/solutions-ezdrp-page.component";

const routes: Routes = [
  {
    path: '',
    component: MainAppComponent,
    children: [
      {
        path: '',
        component: HomePageComponent,
      },
      {
        path: 'signin',
        component: LoginPageComponent,
      },
      {
        'path': 'delete-user/:deletionTokenId',
        component: DeletionUserPageComponent,
      },
      {
        path: 'activate-user/:activationTokenId',
        component: ActivateUserPageComponent,
      },
      {
        path: 'confirm-password/:activationTokenId',
        component: ActivatePasswordPageComponent,
      },
      {
        path: 'confirm-email/:activationTokenId',
        component: ActivateEmailPageComponent,
      },
      {
        path: 'products/:product_id',
        component: ProductPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'deployments',
            pathMatch: 'full',
          },
          {
            path: 'deployments',
            component: ProductEnvironmentsPageComponent,
          },
          {
            path: 'description',
            component: ProductDescriptionPageComponent,
          },
          {
            path: 'documentation',
            component: ProductDocumentationPageComponent,
          },
          {
            path: 'report',
            component: ProductReportPageComponent,
          },
          {
            path: 'support',
            component: ProductSupportPageComponent,
          },
          {
            path: 'similar',
            component: ProductSimilarPageComponent,
          },
        ],
      },
      {
        path: 'catalog',
        component: ProductCatalogPageComponent,
      },
      {
        path: 'products/:product_id/deployments/:deployment_id',
        component: DeploymentPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'report',
            pathMatch: 'full',
          },
          {
            path: 'install',
            component: DeploymentInstallPageComponent,
          },
          {
            path: 'report',
            component: DeploymentReportPageComponent,
          },
          {
            path: 'description',
            component: DeploymentDescriptionPageComponent,
          },
          {
            path: 'documentation',
            component: DeploymentDocumentationPageComponent,
          },
        ],
      },
      {
        path: 'component/:componentId',
        component: ComponentPageComponent,
      },
      {
        path: 'signup',
        component: RegisterPageComponent,
      },
      {
        path: 'security',
        component: SecurityPageComponent,
      },
      {
        path: 'recover-password',
        component: RemindPasswordPageComponent,
      },
      {
        path: 'about-us',
        component: AboutUsPageComponent,
      },
      {
        path: 'knowledge-base',
        component: KnowledgeBasePageComponent,
      },
      {
        path: 'posts/:postId',
        component: PostPageComponent,
      },
      {
        path: 'profile',
        component: ProfilePageComponent,
        children: [
          {
            path: '',
            redirectTo: 'address',
            pathMatch: 'full',
          },
          {
            path: 'address',
            component: AddressComponent,
          },
          // {
          //   path: 'subscription',
          //   component: SubscriptionComponent,
          // },
          {
            path: 'support',
            component: SupportComponent
          },
          {
            path: 'favorite',
            component: FavoriteComponent,
          },
          {
            path: 'my-projects',
            children: [
              {
                path: '',
                component: MyProjectsInfoPageComponent,
              },
              {
                path: 'add',
                component: MyProjectsAddPageComponent,
              },
              {
                path: 'details/:item',
                component: MyProjectsDetailsPageComponent,
              },
            ],
          },
          {
            path: 'account',
            component: AccountComponent,
          },
        ],
      },
      {
        path: 'regulations',
        component: RegulationsPageComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyPageComponent,
      },
      {
        path: 'solutions/ezdrp',
        component: SolutionsEzdrpPageComponent,
      }
    ],
  },
  {
    path: 'github-integrate',
    component: GithubIntegrationPageComponent,
  },
  {
    path: 'github-login',
    component: GithubLoginPageComponent,
  },
  {
    path: 'github-register',
    component: GithubRegisterPageComponent,
  },
  {
    path: 'google-integrate',
    component: GoogleIntegrationPageComponent,
  },
  {
    path: 'google-login',
    component: GoogleLoginPageComponent,
  },
  {
    path: 'google-register',
    component: GoogleRegisterPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
