import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, firstValueFrom, timer } from 'rxjs';
import { SessionService } from '../services/session.service';
import { GITHUB_LOGIN } from '../brodcast-channels';
import { GITHUB_LOGGED_IN } from '../brodcast-messages';

@Component({
  selector: 'app-github-login-page',
  templateUrl: './github-login-page.component.html',
  styleUrls: ['./github-login-page.component.scss']
})
export class GithubLoginPageComponent {

  public isLoading: boolean = true;
  public hasError: boolean = false;

  countDown!: Subscription;
  counter: number = 5;

  constructor(
    private route: ActivatedRoute,
    private auth: SessionService,
  ) {}

  async ngOnInit() {
    
    try {
      this.isLoading = true;
      const { code } = await firstValueFrom(this.route.queryParams);

      const response = await this.auth.loginByGithub(code);
      if(response) {
        const channel = new BroadcastChannel(GITHUB_LOGIN);
        channel.postMessage(GITHUB_LOGGED_IN);
      }
    } catch (err) {
      this.hasError = true;
    } finally {
      this.countDown = timer(0, 1000).subscribe(() => {
        if(this.counter > 0) --this.counter;
        if(this.counter === 0) {
          this.countDown.unsubscribe();
          window.close();
        }
      });
      this.isLoading = false;
    }
  }
}
