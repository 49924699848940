import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class BlobExporterService {

  private baseUrl: URL;
  public isBlobGenerating: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  constructor(
    private httpClient: HttpClient,
    private fileSaverService: FileSaverService,
    private translate: TranslateService,
  ) {
    const protocol = environment.secure ? 'https://' : 'http://';
    this.baseUrl = new URL(`${protocol}${environment.apiUrl}`);
  }

  public export(endpoint: string,
                reportName: string,
                fileType: string) {

    this.isBlobGenerating.next(true);
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;
    const generated = this.httpClient.get(
        url.toString(),
        {
            responseType: 'blob',
            headers: { lang: this.translate.currentLang ?? 'EN' },
        }
    )

    generated.subscribe(content => {
        const blob = new Blob([content], { type: fileType });
        this.fileSaverService.save(blob, reportName);
        this.isBlobGenerating.next(false);
    });
  }
}
