import { Component, HostListener, OnInit } from '@angular/core';
import { PostManager } from '../services/post-manager.service';
import {formatDistanceToNow, Locale} from "date-fns";
import { pl, enGB } from 'date-fns/locale';
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-knowledge-base-page',
  templateUrl: './knowledge-base-page.component.html',
  styleUrls: ['./knowledge-base-page.component.scss']
})
export class KnowledgeBasePageComponent implements OnInit {
  public isGoToTopVisible: boolean = false;

  public categories: {
    id: string;
    name: string;
    imageUrl: string;
    count: number;
  }[] = [];

  public articles: Record<string, {
    data: {
      id: string;
      name: string;
      summary: string;
      image: string;
      tags: {
          id: string;
          name: string;
      }[];
      createdAt: string;
    }[];
    isLoading: boolean;
    count: number;
    page: number;
    itemsPerPage: number;
  }> = {};

  public articleEntries: [string, {
    data: {
        id: string;
        name: string;
        summary: string;
        image: string;
        tags: {
            id: string;
            name: string;
        }[];
        createdAt: string;
    }[];
    isLoading: boolean;
    count: number;
    page: number;
    itemsPerPage: number;
  }][] = [];

  private languageMap: Record<string, Locale>= {
    'en': enGB,
    'pl': pl
  }
  public isLoading: boolean = true;

  constructor(
    private postManager: PostManager,
    private translate: TranslateService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    const { data } = await this.postManager.getPostCategories();

    this.categories = data;

    this.isLoading = false;

    const promises: Promise<any>[] = [];

    for (const category of this.categories) {
      this.articles[category.id] = {
        data: [],
        itemsPerPage: 5,
        page: 1,
        count: 0,
        isLoading: true,
      };
      const promise = this.goToPage(category.id).then(() => {
        this.articles[category.id].isLoading = false;
      })
      promises.push(promise);
    }
    this.getEntriesOfArticles();
    await Promise.all(promises);
  }

  public getEntriesOfArticles() {
    this.articleEntries = Object.entries(this.articles);
  }

  public getCategoryName(categoryId: string) {
    return this.categories.find((category) => category.id === categoryId)!.name;
  }

  public async goToPage(categoryId: string) {
    await this.postManager.getPosts({
      categories: [categoryId],
      itemsPerPage: this.articles[categoryId].itemsPerPage,
      page: this.articles[categoryId].page,
    }).then(({data, count}) => {
      this.articles[categoryId].count = count;
      this.articles[categoryId].data = [];
      this.articles[categoryId].data.push(...data);
    });
  }

  public async onPageChange(event: {
    first?: number | undefined;
    rows?: number | undefined;
    page?: number | undefined;
    pageCount?: number | undefined;
  }, categoryId: string) {
    if(typeof event.page !== 'number') return;
    if(event.page + 1 === this.articles[categoryId].page) return;
    this.articles[categoryId].page = event.page + 1;
    this.articles[categoryId].isLoading = true;
    await this.goToPage(categoryId);
    this.articles[categoryId].isLoading = false;
  }

  @HostListener('window:scroll', ['$event'])
  public checkIsGoToTopVisible(event: Event) {
    if(window.scrollY > 750) return this.isGoToTopVisible = true;
    return this.isGoToTopVisible = false;
  }

  public goToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  public dateAgo(date: string){
    const language = this.languageMap[this.translate.currentLang]
    return formatDistanceToNow(
      new Date(date),
      {
        addSuffix: true,
        locale: language
      }
    )
  }
}
