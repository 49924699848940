import { Injectable } from '@angular/core';
import { ApiClient } from './api-client.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostManager {

  constructor(
    private apiClient: ApiClient,
  ) {}

  public getPostCategories() {
    return this.apiClient.get<{
      count: number;
      data: {
        id: string;
        name: string;
        imageUrl: string;
        count: number;
      }[];
    }>('/post-categories');
  }

  public getPost(postId: string)  {
    return this.apiClient.get<{
      id: string;
      name: string;
      summary: string;
      author: string;
      image: string;
      createdAt: string;
      tags: {
        id: string;
        name: string;
      }[];
      contents: Record<string, object>;
    }>(`/posts/${postId}`);
  }

  public getPosts(options: {
    page: number;
    itemsPerPage: number;
    categories: string[];
  }) {

    let params = new HttpParams().append('page', options.page).append('itemsPerPage', options.itemsPerPage);

    for(let category of options.categories) {
      params = params.append('category', category);
    }

    return this.apiClient.get<{
      count: number;
      data: {
        id: string;
        name: string;
        summary: string;
        image: string;
        tags: {
          id: string;
          name: string;
        }[];
        createdAt: string;
      }[];
    }>('/posts', params);
  }
}
