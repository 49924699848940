<div class="flex flex-column py-6 align-items-center">
  <div class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="!isLoading && !errored">
    <span class="font-bold text-7xl">
      {{ 'CLIENT.ACTIVATE_EMAIL.SUCCESS.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4">
      {{ 'CLIENT.ACTIVATE_EMAIL.SUCCESS.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-check-circle mt-8 text-green-dark-hover text-7xl"></i>
  </div>

  <div class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="isLoading">
    <span class="font-bold text-7xl">
      {{ 'CLIENT.ACTIVATE_EMAIL.PROCESSING.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4">
      {{ 'CLIENT.ACTIVATE_EMAIL.PROCESSING.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-spin pi-spinner mt-8 text-grey-dark text-7xl"></i>
  </div>

  <div class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="!isLoading && errored">
    <span class="font-bold text-7xl">
      {{ 'CLIENT.ACTIVATE_EMAIL.ERROR.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4">
      {{ 'CLIENT.ACTIVATE_EMAIL.ERROR.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-times-circle mt-8 text-red-dark text-7xl"></i>
  </div>
</div>