import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiClient {

  private baseUrl: URL;

  constructor(private http: HttpClient, private translate: TranslateService,) {
    const protocol = environment.secure ? 'https://' : 'http://';
    this.baseUrl = new URL(`${protocol}${environment.apiUrl}`);
  }

  public get<TResponse>(endpoint: string, params?: HttpParams) {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;

    return firstValueFrom(this.http.get<TResponse>(url.toString(), {
      params,
      withCredentials: true,
      headers: {
        lang: this.translate.currentLang ?? 'EN',
      }
    }));
  }

  public post<TResponse>(endpoint: string, body?: Record<string, any>, params?: HttpParams) {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;

    return firstValueFrom(this.http.post<TResponse>(url.toString(), body, {
      params,
      withCredentials: true,
      headers: {
        lang: this.translate.currentLang ?? 'EN',
      }
    }));
  }

  public delete<TResponse>(endpoint: string, body?: Record<string, any>, params?: HttpParams) {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;

    return firstValueFrom(this.http.delete<TResponse>(url.toString(), {
      params,
      body,
      withCredentials: true,
      headers: {
        lang: this.translate.currentLang ?? 'EN',
      }
    }));
  }

  public put<TResponse>(endpoint: string, body?: Record<string, any>, params?: HttpParams) {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;

    return firstValueFrom(this.http.put<TResponse>(url.toString(), body, {
      params,
      withCredentials: true,
      headers: {
        lang: this.translate.currentLang ?? 'EN',
      }
    }));
  }
}
