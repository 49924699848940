<div class="flex flex-column justify-content-center align-items-center relative">
  <div class="absolute left-0 right-0 bottom-0 top-0 skew-bottom-right bg-no-repeat bg-cover bg-center" style="background-image: url({{imageUrl}});"></div>
  <div class="skew-bottom-right opacity-80 bg-blue-dark-hover absolute left-0 right-0 top-0 bottom-0 knowledge-skew"></div>
  <div class="container w-full flex flex-column flex-wrap align-content-center z-5 justify-content-center gap-5 text-center py-8 mb-8">
      <span class="text-white text-6xl md:text-7xl font-bold max-w-full px-4 md:w-6 md:px-0 white-space-normal">
          {{ name | translate }}
      </span>
      <div class="flex flex-column gap-6 align-items-start w-full md:w-6 px-3">
        <span class="text-white text-xl font-semibold text-left">
          {{ summary | translate }}
        </span>
      </div>
  </div>
</div>

<div class="flex flex-column justify-content-center align-items-center mb-8 mt-3 md:mt-8">
  <div class="container w-full px-3 md:px-0 ql-editor" [innerHTML]="rendered"></div>
</div>
