import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {MyProjectAddedErrorMessage, MyProjectAddedMessage} from "../../../messages";
import {MessageService} from "primeng/api";
import {MyProjectsManagerService} from "../../../services/my-projects-manager.service";
import {Router} from '@angular/router';

@Component({
  selector: 'app-my-projects-add-page',
  templateUrl: './my-projects-add-page.component.html',
  styleUrls: ['./my-projects-add-page.component.scss']
})
export class MyProjectsAddPageComponent implements OnInit {

  formGroup!: FormGroup;
  hasError: boolean = false;
  isLoading: boolean = true;

  constructor(
    private myProjectsManager: MyProjectsManagerService,
    private translate: TranslateService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  public ngOnInit() {
    try {
      this.isLoading = true;
      const userProject = new FormControl( '',
        [Validators.required],
      );

      this.formGroup = new FormGroup({
        userProject,
        userToken: new FormControl(''),
      })
    } catch (err) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  public async submit() {
    try {
      this.isLoading = true;
      const userProject = this.formGroup.get('userProject')?.value;
      const userToken = this.formGroup.get('userToken')?.value;

      let response = await this.myProjectsManager.triggerAnalyze({
        userProject,
        userToken,
      });

      this.router.navigate(['/profile/my-projects']);
      const responseMessage = {
        severity: MyProjectAddedMessage.severity,
        summary: this.translate.instant(MyProjectAddedMessage.summary!),
        detail: this.translate.instant(MyProjectAddedMessage.detail!),
      };
      this.messageService.add(responseMessage);
    } catch (err) {
        const responseMessage = {
          severity: MyProjectAddedErrorMessage.severity,
          summary: this.translate.instant(MyProjectAddedErrorMessage.summary!),
          detail: this.translate.instant(MyProjectAddedErrorMessage.detail!),
        };
        this.messageService.add(responseMessage);
        this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

}
