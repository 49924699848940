<entity-card [url]="deployment.url" [logoUrl]="deployment.logoUrl" [scarmRating]="deployment.scarmRating" [lastScarmTrend]="deployment.lastScarmTrend" [name]="deployment.name" [summary]="deployment.summary" [starsCount]="deployment.starsCount" (onLike)="likeHandle($event)">
    <ng-template [templateName]="'headerTemplate'">
        <ng-container [ngSwitch]="deployment.deployment | translate | uppercase">
            <i class="icon-docker bg-grey-dark w-5rem" *ngSwitchCase="'DOCKER'"></i>
            <i class="icon-alibaba bg-grey-dark w-5rem" *ngSwitchCase="'ALIBABA'"></i>
            <i class="icon-aws bg-grey-dark w-5rem" *ngSwitchCase="'AWS'"></i>
            <i class="icon-google bg-grey-dark w-5rem" *ngSwitchCase="'GCP'"></i>
            <i class="icon-kubernetes bg-grey-dark w-5rem" *ngSwitchCase="'KUBERNETES'"></i>
            <i class="icon-microsoft bg-grey-dark w-5rem" *ngSwitchCase="'AZURE'"></i>
            <i class="icon-openshift bg-grey-dark w-5rem" *ngSwitchCase="'OPENSHIFT'"></i>
            <i class="icon-virtualbox bg-grey-dark w-5rem" *ngSwitchCase="'VIRTUALBOX'"></i>
            <i class="icon-rancher bg-grey-dark w-5rem" *ngSwitchCase="'RANCHER'"></i>
            <i class="icon-linux bg-grey-dark w-5rem" *ngSwitchCase="'LINUX-SERVER'"></i>
            <span *ngSwitchCase="'VMWARE-WORKSTATION'" class="flex relative">
                <i class="icon-vmware bg-grey-dark w-5rem"></i>
                <span class="absolute text-xs text-grey-dark" style="top: 14px; right: 0px;">
                    WORKSTATION
                </span>
            </span>
            <span *ngSwitchCase="'VMWARE-VCENTER'" class="flex relative">
                <i class="icon-vmware bg-grey-dark w-5rem"></i>
                <span class="absolute text-xs text-grey-dark" style="top: 14px; right: 0px;">
                    V-CENTER
                </span>
            </span>
            <span *ngSwitchDefault>
                {{deployment.deployment | translate }}
            </span>
        </ng-container>
    </ng-template>
</entity-card>