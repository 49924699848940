<div class="mt-7 gap-7">
  <p class="text-3xl font-bold text-blue-darker">
    {{ 'CLIENT.DEPLOYMENT.DESCRIPTION_SECTION.DESCRIPTION_TITLE' | translate }}
  </p>
  <div *ngIf="isLoading" class="flex flex-column gap-1 w-8">
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-10"></p-skeleton>
  </div>
  <div class="text-grey-dark-hover w-8" *ngIf="!isLoading">
    {{ deploymentData.description | translate }}
  </div>
  <div class="grid mt-4">
    <!-- <div *ngFor="let photoUrl of photosUrls" class="col-4">
      <div class="img-wrapper">
        <img [src]="photoUrl" alt="" style="object-fit: contain;">
      </div>
    </div> -->
  </div>
</div>
