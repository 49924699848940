import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TurnstileLoaderService {

  private isLoadedBehavior: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  public isLoaded() {
    return this.isLoadedBehavior.asObservable();
  }

  constructor(
    private renderer: Renderer2,
  ) {
    const headElement = this.renderer.selectRootElement('head', true) as HTMLHeadElement;

    const script = this.renderer.createElement('script') as HTMLScriptElement;

    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js'
    script.type = 'text/javascript';
    script.defer = true;

    script.onload = () => {
      this.isLoadedBehavior.next(true);
    };

    script.onerror = (err) => {
      console.error(err);
    }

    headElement.appendChild(script);
  }

  public getTurnstile() {
    return new Promise((resolve) => {
      this.isLoaded().subscribe((value) => {
        if(value) return resolve((window as any).turnstile);
      });
    });
  }

}
