import { Injectable } from '@angular/core';
import { ApiClient } from './api-client.service';


@Injectable({
  providedIn: 'root'
})
export class EmailManagerService {

  constructor(
    private apiClient: ApiClient,
  ) { }

  public sendContactUs(data: {
    name: string;
    email: string;
    topic: string;
    message: string;
    token: string;
  }) {
    return this.apiClient.post('/emails/contact-us', data)
  }

  public sendAskExpert(data:{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    company: string;
    job: string;
    message: string;
    token: string;
  }){
    return this.apiClient.post('/emails/ask-expert', data)
  }
}
