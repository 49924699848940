<div class="w-full bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover">
  <div class="absolute top-50 left-50" *ngIf="isLoading">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
  
  <form class="flex flex-column gap-5" [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="flex flex-row gap-3 align-items-center">
      <i class="icon-24 icon-address bg-blue-darker"></i>
      <span class="font-semibold text-3xl text-blue-darker">
        {{ 'CLIENT.PROFILE.PROFILE_DETAILS.TITLE' | translate }}
      </span>
    </div>
    <div class="flex flex-column md:w-4 gap-3">
      <input type="text" formControlName="firstName" autocomplete="name" pInputText placeholder="{{ 'CLIENT.PROFILE.PROFILE_DETAILS.FORM.FIRST_NAME' | translate }}" class="w-full bg-blue-light border-blue-light-hover" [disabled]="isLoading" />
      <input type="text" formControlName="lastName" autocomplete="family-name" pInputText placeholder="{{ 'CLIENT.PROFILE.PROFILE_DETAILS.FORM.LAST_NAME' | translate }}" class="w-full bg-blue-light border-blue-light-hover" [disabled]="isLoading" />
      <input type="text" formControlName="organizationName" autocomplete="organization" pInputText placeholder="{{ 'CLIENT.PROFILE.PROFILE_DETAILS.FORM.COMPANY_NAME' | translate }}" class="w-full bg-blue-light border-blue-light-hover" [disabled]="isLoading" />
      <p-button styleClass="w-full justify-content-center font-semibold text-sm" [disabled]="isLoading || formGroup.pristine" type="submit">
        {{ 'CLIENT.PROFILE.PROFILE_DETAILS.FORM.SEND' | translate }}
      </p-button>
    </div>
  </form>
</div>
