<div>
  <p class="text-3xl font-bold text-blue-darker">
    {{ 'CLIENT.PRODUCT.DEPLOYMENTS_SECTION.TITLE' | translate }}
  </p>
  <div class="flex gap-5"  *ngIf="isLoading">
    <div class="w-4 flex flex-column align-items-center">
      <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
      <img src="assets/arrow_1.svg" alt="">
      <div class="flex flex-column gap-3 w-full mt-3">
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
      </div>
    </div>
    <div class="w-4 flex flex-column align-items-center">
      <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
      <img src="assets/arrow_1.svg" alt="">
      <div class="flex flex-column gap-3 w-full mt-3">
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
      </div>
    </div>
    <div class="w-4 flex flex-column align-items-center">
      <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
      <img src="assets/arrow_1.svg" alt="">
      <div class="flex flex-column gap-3 w-full mt-3">
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
        <p-skeleton height="54px" class="w-full" styleClass="w-full"></p-skeleton>
      </div>
    </div>
  </div>
  <div class="flex flex-column lg:flex-row gap-5" *ngIf="!isLoading">
    <div class="lg:w-4 flex flex-column align-items-center" *ngIf="onPremEnvironments.length > 0">
      <div class="w-full bg-grey-light-active border-grey-normal p-3 flex justify-content-center border-1 border-round-lg">
        <img src="assets/on-prem.svg" alt="">
        <span class="font-medium text-grey-dark-hover ml-2">
          {{ 'CLIENT.PRODUCT.DEPLOYMENTS_SECTION.ON-PREMISE' | translate }}
        </span>
      </div>
      <img src="assets/arrow_1.svg" alt="">

      <ng-container *ngFor="let environment of onPremEnvironments">
        <a routerLink="{{ environment.id }}"
          class="no-underline border-blue-light-active w-full bg-blue-light p-3 flex flex-row gap-2 align-items-center justify-content-center align-self-stretch mt-3 cursor-pointer border-1 border-round-lg">
          <img src="assets/on-prem.svg" alt="">
          <span class="flex-grow-1 text-center font-medium text-blue-normal">
            {{ environment.typeName | translate }}
          </span>
        </a>
      </ng-container>
    </div>
    <div class="lg:w-4 flex flex-column align-items-center" *ngIf="cloudEnvironments.length > 0">
      <div class="w-full bg-grey-light-active border-grey-normal p-3 flex justify-content-center border-1 border-round-lg">
        <img src="assets/cloud.svg" alt="">
        <span class="font-medium text-grey-dark-hover ml-2">
          {{ 'CLIENT.PRODUCT.DEPLOYMENTS_SECTION.CLOUD' | translate }}
        </span>
      </div>
      <img src="assets/arrow_1.svg" alt="">

      <ng-container *ngFor="let environment of cloudEnvironments">
        <a routerLink="{{ environment.id }}" class="no-underline border-blue-light-active w-full bg-blue-light p-3 flex flex-row gap-2 align-items-center justify-content-center align-self-stretch mt-3 cursor-pointer border-1 border-round-lg">
          <img src="assets/on-prem.svg" alt="">
          <span class="flex-grow-1 text-center font-medium text-blue-normal">
            {{ environment.typeName | translate }}
          </span>
        </a>
      </ng-container>

    </div>
    <div class="lg:w-4 flex flex-column align-items-center" *ngIf="containerEnvironments.length > 0">
      <div class="w-full bg-grey-light-active border-grey-normal p-3 flex justify-content-center align-items-center border-1 border-round-lg">
        <img src="assets/cloud.svg" alt="container">
        <span class="font-medium text-grey-dark-hover ml-2">
          {{ 'CLIENT.PRODUCT.DEPLOYMENTS_SECTION.CONTAINERS' | translate }}
        </span>
      </div>
      <img src="assets/arrow_1.svg" alt="">
      <ng-container *ngFor="let environment of containerEnvironments">
        <a routerLink="{{ environment.id }}" class="no-underline border-blue-light-active w-full bg-blue-light p-3 flex flex-row gap-2 align-items-center justify-content-center align-self-stretch mt-3 cursor-pointer border-1 border-round-lg">
          <img src="assets/on-prem.svg" alt="">
          <span class="flex-grow-1 text-center font-medium text-blue-normal">
            {{ environment.typeName | translate }}
          </span>
        </a>
      </ng-container>

    </div>
  </div>
</div>