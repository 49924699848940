import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitNumber'
})
export class SplitNumberPipe implements PipeTransform {

  transform(value: any, splitter: string = " "): unknown {
    return Number(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, splitter);
  }

}
