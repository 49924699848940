import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeploymentDataService } from '../deployment-data.service';
import { Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

@Component({
  selector: 'app-deployment-install-page',
  templateUrl: './deployment-install-page.component.html',
  styleUrls: ['./deployment-install-page.component.scss']
})
export class DeploymentInstallPageComponent implements OnInit, OnDestroy {

  public isLoading: boolean = true;
  public counter: number = 5;

  public windowSuppressed: boolean = false;
  public windowOpen: boolean = false;

  public $timer!: Subscription;
  public rendered!: string;

  private $langChange!: Subscription;

  constructor(
    public deploymentData: DeploymentDataService,
    private translate: TranslateService,
  ) {}

  ngOnDestroy() {
    this.$langChange?.unsubscribe();
    this.$timer.unsubscribe();
  }

  private render(language: string) {
    const converter = new QuillDeltaToHtmlConverter(this.deploymentData.instructions[language.toUpperCase()]['ops'] as any, {});
    this.rendered = converter.convert();
  };

  ngOnInit() {
    this.deploymentData.isLoadingDataChange.subscribe((value) => {
      this.isLoading = value;
      if(!value) {
        this.render(this.translate.currentLang);
        this.$langChange = this.translate.onLangChange.subscribe(({lang}) => this.render(lang));
        this.$timer = timer(0, 1000).subscribe(() => {
          if(this.counter > 0) --this.counter;
          if(this.counter <= 0) {
            this.$timer.unsubscribe();
            const downloadWindow = window.open(this.deploymentData.downloadUrl);
            if(!downloadWindow) {
              this.windowSuppressed = true;
            } else {
              this.windowOpen = true;
            }
          }
        });
      }
    });



  }
}
