import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PostManager } from '../services/post-manager.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-post-page',
  templateUrl: './post-page.component.html',
  styleUrls: ['./post-page.component.scss']
})
export class PostPageComponent implements OnInit {
  public isLoading: boolean = true;

  public name!: string;
  public summary!: string;
  public imageUrl!: string;
  public author!: string;
  public createdAt!: Date;
  public contents!: Record<string, any>;

  public rendered!: string;

  private $langChange!: Subscription;

  constructor(
    private postManager: PostManager,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    const { postId } = await firstValueFrom(this.route.params);
    const { author, contents, createdAt, image, name, summary, tags } = await this.postManager.getPost(postId);
    this.name = name;
    this.summary = summary;
    this.imageUrl = image;
    this.author = author;
    this.createdAt = new Date(createdAt);
    this.contents = contents;

    this.render(this.translate.currentLang);
    this.$langChange = this.translate.onLangChange.subscribe(({lang}) => this.render(lang));

    this.isLoading = false;
  }

  private render(language: string) {
    console.log(this.contents[language.toUpperCase()]);
    const converter = new QuillDeltaToHtmlConverter(this.contents[language.toUpperCase()]['ops'] as any, {});
    this.rendered = converter.convert();
    console.log(this.rendered);
  };
}
