import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveUser {

  public email: string | null = null;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public organizationName: string | null = null;
  public id: string | null = null;
  public isLoggedIn: boolean = false;

  constructor(
    private sessionService: SessionService,
  ) {
    this.sessionService.loginSubject.subscribe((value) => {
      if(!value) return this.deleteUser();
      const {id, email, lastName, firstName, organizationName } = value;
      this.setUser(id, email, firstName, lastName, organizationName);
    });
  }

  public setUser(
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    organizationName: string,
  ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.organizationName = organizationName;
    this.isLoggedIn = true;
  }

  public deleteUser() {
    this.id = null;
    this.email = null;
    this.firstName = null;
    this.lastName = null;
    this.organizationName = null;
    this.isLoggedIn = false;
  }
}
