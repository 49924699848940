import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductManager } from '../services/product-manager.service';
import { HomePageProduct } from './product';

@Injectable()
export class HomeDataService {
  public isLoadingDataChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public topProducts: HomePageProduct[] = [];

  constructor(
    private productManager: ProductManager,
  ) {
    this.fetchData();
  }

  private async fetchData() {
    this.isLoadingDataChange.next(true);

    const {totalAmount, data} = (await this.productManager.getProductList(0, 15) as any);

    this.topProducts = data.map((product: any) => {
      return {
        id: product.id,
        name: product.name,
        summary: product.summary,
        logoUrl: '',
        scarmScore: product.scarmRating,
        lastScarmTrend: product.lastScarmTrend,
      };
    });

    this.isLoadingDataChange.next(false);
  }
}
