import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserManager } from '../services/user-manager.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-remind-password-page',
  templateUrl: './remind-password-page.component.html',
  styleUrls: ['./remind-password-page.component.scss']
})
export class RemindPasswordPageComponent {

  public isLoading: boolean = false;

  public formGroup!: FormGroup;

  constructor(
    private userManager: UserManager,
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  public ngOnInit() {
      this.formGroup = new FormGroup({
          email: new FormControl('', [
            Validators.required,
            Validators.email,
          ]),
      });
  }

  public async submit() {
    try {
      this.isLoading = true;
      const emailControl = this.formGroup.get('email');

      await this.userManager.remindPassword(emailControl?.value);

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('CLIENT.FORGOTTEN_PASSOWORD.NOTIFICATION.SUCCESS.TITLE'),
        detail: this.translate.instant('CLIENT.FORGOTTEN_PASSOWORD.NOTIFICATION.SUCCESS.DESCRIPTION'),
      });

      await this.router.navigate(['..']);
    } catch (err) {
      console.error(err);
      if(err instanceof HttpErrorResponse) {
        switch(err.status) {
          case 500:
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('CLIENT.FORGOTTEN_PASSOWORD.NOTIFICATION.ERROR_500.TITLE'),
              detail: this.translate.instant('CLIENT.FORGOTTEN_PASSOWORD.NOTIFICATION.ERROR_500.DESCRIPTION'),
            });
            break;
        }
      }
    } finally {
      this.isLoading = false;
    }
  }
}
