import { Component } from '@angular/core';

@Component({
  selector: 'app-product-support-page',
  templateUrl: './product-support-page.component.html',
  styleUrls: ['./product-support-page.component.scss']
})
export class ProductSupportPageComponent {

}
