import { AfterViewInit, ApplicationRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserManager } from '../services/user-manager.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { sameValues } from '../same-values.validator';
import { TranslateService } from '@ngx-translate/core';
import { GITHUB_REGISTER, GOOGLE_REGISTER } from '../brodcast-channels';
import { GITHUB_REGISTERED, GOOGLE_REGISTERED } from '../brodcast-messages';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  public formGroup!: FormGroup;
  public emailFormControl!: FormControl;
  public passwordFormControl!: FormControl;
  public passwordFormGroup!: FormGroup;
  public isLoading: boolean = false;
  public loginElement!: HTMLElement;

  constructor(
    private appRef: ApplicationRef,
    private userManager: UserManager,
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {

    this.passwordFormControl = new FormControl('', [
      Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/),
      Validators.required,
    ])

    this.passwordFormGroup = new FormGroup({
      password: this.passwordFormControl,
      confirmPassword: new FormControl('', [
        Validators.required,
      ]),
    }, [
      sameValues(['password', 'confirmPassword']),
    ]);

    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ])

    this.formGroup = new FormGroup({
      password: this.passwordFormGroup,
      email: this.emailFormControl,
    });
  }

  public stringify(obj: object) {
    return JSON.stringify(obj);
  }

  public async registerViaEmail() {
    if(this.formGroup.invalid) return;

    try {
      this.isLoading = true;
      const emailControl = this.formGroup.get('email');
      const passwordGroup = this.formGroup.get('password') as FormGroup
      const passwordControl = passwordGroup.get('password');
  
      await this.userManager.registerUser(emailControl?.value, passwordControl?.value);

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('CLIENT.SIGN_UP.NOTIFICATION.VIA_EMAIL.SUCCESS.TITLE'),
        detail: this.translate.instant('CLIENT.SIGN_UP.NOTIFICATION.VIA_EMAIL.SUCCESS.DESCRIPTION'),
      });

      await this.router.navigate(['/signin']);
      
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public registerViaGithub() {
    this.isLoading = true;
    const w = 485;
    const h = 550;

    const left = (screen.width/2)-(w/2);
    const top = (screen.height/2)-(h/2);
    const url = `https://github.com/login/oauth/authorize?client_id=${environment.githubClientId}&redirect_uri=${window.location.origin}/github-register&scope=read:user user:email`;
    const popup = window.open(new URL(url), '_blank', `popup,resizable=0,width=${w},height=${h},top=${top},left=${left}`);
    
    if(!popup) {
      return;
    }

    popup.focus();

    const channel = new BroadcastChannel(GITHUB_REGISTER);
    channel.addEventListener('message', async (event) => {
      if(event.origin !== window.origin) return;
      if(event.data !== GITHUB_REGISTERED) return;
      await this.router.navigate(['/signin']);

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('CLIENT.SIGN_UP.NOTIFICATION.VIA_GITHUB.SUCCESS.TITLE'),
        detail: this.translate.instant('CLIENT.SIGN_UP.NOTIFICATION.VIA_GITHUB.SUCCESS.DESCRIPTION'),
      });

      this.isLoading = false;
    });

    const isClosedInterval = interval(500).subscribe(() => {
      if(popup?.closed) {
        this.isLoading = false;
        isClosedInterval.unsubscribe();
      }
    });
  }

  public registerViaGoogle() {
    const w = 485;
    const h = 550;

    const left = (screen.width/2)-(w/2);
    const top = (screen.height/2)-(h/2);
    const state = (Math.random() + 1).toString(36).substring(7);
    let url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.googleClientId}&redirect_uri=${window.location.origin}/google-register&response_type=token&scope=openid email&state=${state}`
    const popup = window.open(new URL(url), '_blank', `popup,resizable=0,width=${w},height=${h},top=${top},left=${left}`);
    popup?.focus();

    if(!popup) {
      return;
    }

    const channel = new BroadcastChannel(GOOGLE_REGISTER);
    channel.addEventListener('message', async (event) => {
      if(event.origin !== window.origin) return;
      if(event.data !== GOOGLE_REGISTERED) return;
      await this.router.navigate(['/signin']);

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('CLIENT.SIGN_UP.NOTIFICATION.VIA_GOOGLE.SUCCESS.TITLE'),
        detail: this.translate.instant('CLIENT.SIGN_UP.NOTIFICATION.VIA_GOOGLE.SUCCESS.DESCRIPTION'),
      });

      this.isLoading = false;
    });

    const isClosedInterval = interval(500).subscribe(() => {
      if(popup?.closed) {
        this.isLoading = false;
        isClosedInterval.unsubscribe();
      }
    });
  }
}
