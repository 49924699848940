<div class="flex flex-column relative w-full bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover overflow-hidden">

  <div class="flex flex-column md:flex-row gap-5">
    <div class="flex flex-column gap-5 md:w-4">
      <div class="flex flex-row gap-3 align-items-center">
        <i class="icon-similar icon-24 bg-blue-darker"></i>
        <span class="font-bold text-3xl">
          {{ 'CLIENT.PROFILE.ACCOUNT.TITLE' | translate }}
        </span>
      </div>
      <form class="flex flex-column gap-5" [formGroup]="formGroup" (ngSubmit)="submit()">
        <span class="text-xl font-semibold">
          {{ 'CLIENT.PROFILE.ACCOUNT.CHANGE_PASSWORD.TITLE' | translate }}
        </span>
        <div class="flex flex-column gap-3">
          <p-password [feedback]="false" formControlName="oldPassword" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.PROFILE.ACCOUNT.FORM.PASSWORD' | translate }}"></p-password>
          <ng-container formGroupName="passwords">
            <p-password [feedback]="false" formControlName="newPassword" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.PROFILE.ACCOUNT.FORM.NEW_PASSWORD' | translate }}"></p-password>
            <p-password [feedback]="false" formControlName="confirmNewPassword" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.PROFILE.ACCOUNT.FORM.CONFIRM_NEW_PASSWORD' | translate }}"></p-password>
          </ng-container>
          <p-button styleClass="w-full justify-content-center font-bold text-sm py-3 px-4" [disabled]="formGroup.pristine || formGroup.invalid" type="submit">
            {{ 'CLIENT.PROFILE.ACCOUNT.FORM.SEND_NEW_PASSWORD' | translate }}
          </p-button>
        </div>
      </form>
    
      <div class="flex flex-column gap-3">
        <span class="font-semibold text-xl">
          {{ 'CLIENT.PROFILE.ACCOUNT.DELETE_ACCOUNT.TITLE' | translate }}
        </span>
        <span class="font-semibold text-red-normal" *ngIf="isDeletionOpen">
          {{ 'CLIENT.PROFILE.ACCOUNT.DELETE_ACCOUNT.DESCRIPTION_CONFIRMATION' | translate }}
        </span>
        <p-button styleClass="w-full justify-content-center font-bold text-sm bg-white text-red-dark border-red-dark" (onClick)="deleteAccount()">
          <span *ngIf="!isDeletionOpen">
            {{ 'CLIENT.PROFILE.ACCOUNT.DELETE_ACCOUNT.BUTTON' | translate }}
          </span>
          <span *ngIf="isDeletionOpen">
            {{ 'CLIENT.PROFILE.ACCOUNT.DELETE_ACCOUNT.CONFIRMATION' | translate }}
          </span>
        </p-button>
      </div>
    </div>
  
    <div class="flex flex-column gap-5 md:w-4">
      <span class="font-bold text-3xl">
        {{ 'CLIENT.PROFILE.ACCOUNT.INTEGRATIONS.TITLE' | translate }}
      </span>
      <div class="flex flex-column gap-2">
        <a (click)="integrateWithGithub()" class="cursor-pointer no-underline border-1 border-round-lg px-4 py-3 border-gray-900 flex flex-row justify-content-center align-items-center bg-white gap-2">
          <img src="assets/github.svg" alt="github">
          <span class="font-semibold text-gray-900" *ngIf="!hasGithub">
            {{ 'CLIENT.PROFILE.ACCOUNT.INTEGRATE_WITH_GITHUB' | translate }}
          </span>
          <span class="font-semibold text-green-dark-hover" *ngIf="hasGithub">
            {{ 'CLIENT.PROFILE.ACCOUNT.INTEGRATE_WITH_GITHUB' | translate }}
          </span>
        </a>
        <div id="googleLogin"></div>
        <a (click)="integrateWithGoogle()" class="cursor-pointer no-underline border-1 border-round-lg px-4 py-3 border-gray-900 flex flex-row justify-content-center align-items-center bg-white gap-2">
          <img src="assets/google.svg" alt="google">
          <span class="font-semibold text-gray-900" *ngIf="!hasGoogle">
            {{ 'CLIENT.PROFILE.ACCOUNT.INTEGRATE_WITH_GOOGLE' | translate }}
          </span>
          <span class="font-semibold text-green-dark-hover" *ngIf="hasGoogle">
            {{ 'CLIENT.PROFILE.ACCOUNT.INTEGRATED_WITH_GOOGLE' | translate }}
          </span>
        </a>
      </div>
    </div>
  
  </div>

  <div class="absolute flex top-0 bottom-0 left-0 right-0 justify-content-center align-items-center bg-white-alpha-90 z-5" *ngIf="isLoading">
    <i class="pi pi-spin pi-spinner text-7xl"></i>
  </div>

</div>