<div class="max-w-full mt-6">
  <p class="text-3xl font-bold text-blue-darker">{{ 'CLIENT.PRODUCT.DESCRIPTION_SECTION.TITLE' | translate }}</p>
  <div class="flex flex-column gap-1 w-10" *ngIf="isLoading">
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-full"></p-skeleton>
    <p-skeleton styleClass="w-10"></p-skeleton>
  </div>
  <div class="text-grey-dark w-10" *ngIf="!isLoading">
    {{ productData.description | translate }}
  </div>

  <div class="grid mt-4">
    <ng-container *ngIf="isLoading">
      <div class="col-4" *ngFor="let item of [0, 0, 0, 0, 0, 0, 0, 0, 0]">
        <p-skeleton styleClass="w-full" class="w-full" height="13rem"></p-skeleton>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let photoUrl of productData.screenshotsUrls" class="col-4">
        <p-image [src]="photoUrl" [preview]="true"></p-image>
      </div>
    </ng-container>
  </div>
</div>
