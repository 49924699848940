import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { DeploymentDataService } from '../deployment-data.service';
import { CountriesDataService } from "../../countries-data.service";
import { ExportOption } from "../../export-option";
import { DOCUMENT } from '@angular/common';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { ExportReportService } from "../../services/export-report.service";


@Component({
  selector: 'app-deployment-report-page',
  templateUrl: './deployment-report-page.component.html',
  styleUrls: ['./deployment-report-page.component.scss']
})
export class DeploymentReportPageComponent implements OnInit, AfterViewInit {
  isMobile: boolean = window.innerWidth < 768;

  exportOptions: ExportOption[] = [
    // {
    //   name: 'PDF',
    //   translationId: 'CLIENT.DEPLOYMENT.REPORT_SECTION.EXPORT_OPTIONS.PDF',
    // },
    {
      name: 'CSV',
      translationId: 'CLIENT.DEPLOYMENT.REPORT_SECTION.EXPORT_OPTIONS.CSV',
    },
    {
      name: 'XLSX',
      translationId: 'CLIENT.DEPLOYMENT.REPORT_SECTION.EXPORT_OPTIONS.XLSX',
    },
  ];

  public isExporting: boolean = false;
  public isLoading: boolean = true;
  public topContributors: { name: string, value: number, pictureSettings: object }[] = [];
  public topProgrammingLangs: { name: string, value: number, pictureSettings: object }[] = [];

  public openedKeys: any;

  constructor(
    public deploymentData: DeploymentDataService,
    public countriesDataService: CountriesDataService,
    public exportReportService: ExportReportService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit(): void {
    this.deploymentData.isLoadingDataChange.subscribe(value => {
      this.isLoading = value;

      this.getFirstKey();
    });
  }

  ngAfterViewInit() {
    this.deploymentData.isLoadingDataChange.subscribe(value => {
      if(value) return;

      this.topContributors = Object.entries(this.deploymentData.contributions).slice(0, 5).map(([key, value]) => ({
        name: key,
        value,
        pictureSettings: {
          src: (() => {
            const country = this.countriesDataService.countriesAlpha3.get(key);
            return `/assets/flags/${country?.['alpha-2'].toLocaleLowerCase()}.svg`;
          })()
        },
      }));

      this.topProgrammingLangs = this.deploymentData.programmingLanguages.slice(0, 5).map((data) => ({
        name: data.name,
        value: data.value,
        pictureSettings: {
          src: (() => {
            switch (data.name) {
              default:
                return `/assets/languages/${data.name.toLocaleLowerCase()}.svg`
            }
          })()
        },
      }));
    });
    this.exportReportService.isExporting.subscribe(value => {
      this.isExporting = value;
    });
  }

  public getFirstKey() {
    this.openedKeys = {[this.deploymentData.components[0].name]: true};
  }

  public getObjectKeys(component: object) {
    return Object.keys(component);
  }

  public onPageChange(event:any){
    this.deploymentData.getComponentsPage(event.page)
  }

  public removeOverflow() {
    this.document.body.classList.remove('p-overflow-hidden');
  }

  public onTriggerExport(event: DropdownChangeEvent) {
    if(!event.value) return;
    const reportFormat = event.value.name;
    this.exportReportService.exportDeploymentReport(reportFormat,
                                                    { productId: this.deploymentData.productId,
                                                      deploymentId: this.deploymentData.deploymentId });
  }
}
