import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductManager } from '../services/product-manager.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityPageDataService {

  itemsPerPage: number = 12;
  page: number = 0;

  isLoadingDataChange: BehaviorSubject<boolean> = new BehaviorSubject(true);

  products: {
    id: string;
    name: string;
    summary: string;
    rating: number;
    lastTrend: number;
  }[] = [];

  constructor(
    private productManager: ProductManager,
  ) {
    this.fetchData();
  }

  private async fetchData() {
    this.isLoadingDataChange.next(true);

    const {data} = await this.productManager.getProductList(this.page, this.itemsPerPage);

    this.products = data.map((product) => {
      return {
        id: product.id,
        name: product.name,
        summary: product.summary,
        rating: product.scarmRating ?? 0,
        lastTrend: product.lastScarmTrend,
      };
    });

    this.isLoadingDataChange.next(false);
  }
}
