import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../product-catalog-page/Entity';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {
  @Input({
    required: true,
  })
  product!: Product;

  @Output()
  public onLike: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  likeHandle(event: MouseEvent) {
    this.onLike.next(event)
  }
}
