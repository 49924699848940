import { Injectable } from '@angular/core';
import * as countries from 'src/assets/country_codes.json';

@Injectable({
  providedIn: 'root'
})
export class CountriesDataService {

  countriesAlpha2: Map<string, {
    name: string;
    'alpha-2': string;
    'alpha-3': string;
  }>;

  countriesAlpha3: Map<string, {
    name: string;
    'alpha-2': string;
    'alpha-3': string;
  }>;

  constructor() {
    this.countriesAlpha2 = new Map();
    this.countriesAlpha3 = new Map();

    Array.from(countries).forEach((country) => {
      this.countriesAlpha2.set(country['alpha-2'], country);
      this.countriesAlpha3.set(country['alpha-3'], country);
    });
  }
}
