import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, firstValueFrom, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Deployment, Entity, Product } from './Entity';
import { ProductManager } from '../services/product-manager.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Injectable()
export class ProductCatalogDataService {
  isLoadingDataChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public products: Entity[] = [];

  public deploymentTypes: {
    name: string;
    id: string;
  }[] = [];
  public environments: string[] = [];

  public sortBy?: {
    name: string,
    direction: 'asc'|'desc',
  };

  public totalNumber: number = 0;

  public productTags: { id: string, name: string; }[] = [];
  public tags: string[] = [];

  public page: number = 0;
  public itemsPerPage: number = 15;

  public searchText: string = '';

  public type: 'products' | 'deployments' = 'products';

  public ratingValues: number[] = [0, 10];

  public fullTextSearchChanged: Subject<string> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private productManager: ProductManager,
    private router: Router,
  ) {
    this.refreshData();
    this.fullTextSearchChanged.pipe((debounceTime(1000))).subscribe(async () => {
      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          searchText: this.searchText,
        },
        queryParamsHandling: 'merge',
      });
      this.refreshData();
    });
  }

  private async getDeployments() {
    return this.productManager.getDeploymentsList(this.page, this.itemsPerPage, {
      tagIds: this.tags,
      environmentIds: this.environments,
      ratingValues: this.ratingValues,
      searchText: this.searchText,
    },
      this.sortBy
    );
  }

  private async getProducts() {
    return this.productManager.getProductList(this.page, this.itemsPerPage, {
      tagIds: this.tags,
      environmentIds: this.environments,
      ratingValues: this.ratingValues,
      searchText: this.searchText,
    },
      this.sortBy
  );
  }

  public async onSlideEnd() {
    this.page = 0;
    this.refreshData();
  }

  public async toggleTag(event: CheckboxChangeEvent) {
    this.tags = event.checked;
    this.page = 0;
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tags: event.checked,
      },
      queryParamsHandling: 'merge',
    });
    this.refreshData();
  }

  public async toggleEnvironment(event: CheckboxChangeEvent) {
    this.environments = event.checked;
    this.page = 0;
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        environments: event.checked,
      },
      queryParamsHandling: 'merge',
    });
    this.refreshData();
  }

  public searchTextChange(event: InputEvent) {
    this.page = 0;
    this.fullTextSearchChanged.next(this.searchText);
  }

  private async getEntities() {
    let promise: Promise<unknown>;

    switch(this.type) {
      case 'deployments':
        promise = this.getDeployments().then(({data, totalAmount}) => {
          this.totalNumber = totalAmount;
          this.products = data.map<Deployment>((deployment) => ({
            deployment: deployment.deployment,
            id: deployment.id,
            isLiked: deployment.isLiked,
            logoUrl: deployment.logoUrl,
            name: deployment.name,
            url: `/products/${deployment.productId}/deployments/${deployment.id}`,
            scarmRating: deployment.scarmRating,
            lastScarmTrend: deployment.lastScarmTrend,
            starsCount: deployment.starsCount,
            summary: deployment.summary,
            type: 'deployment',
            productId: deployment.productId,
          }))
        });
        break;
      case 'products':
        promise = this.getProducts().then(({data, totalAmount}) => {
          this.totalNumber = totalAmount;
          this.products = data.map<Product>((product) => ({
            deploymentsCount: product.deploymentsCount,
            id: product.id,
            url: `/products/${product.id}`,
            isLiked: product.isLiked,
            logoUrl: product.logoUrl,
            name: product.name,
            scarmRating: product.scarmRating,
            lastScarmTrend: product.lastScarmTrend,
            starsCount: product.starsCount,
            summary: product.summary,
            type: 'product',
          }))
        });
        break;
    }

    return promise;
  }

  public async refreshData() {
    this.isLoadingDataChange.next(true);

    const queryParams = await firstValueFrom(this.route.queryParams)

    this.searchText = queryParams['searchText'];

    let environments = [];
    if(typeof queryParams['environments'] === 'string') environments.push(queryParams['environments']);
    else environments = queryParams['environments'];
    this.environments = environments;

    let tags = [];
    if(typeof queryParams['tags'] === 'string') tags.push(queryParams['tags']);
    else tags = queryParams['tags'];
    this.tags = tags;

    const promise = this.getEntities();

    const productTagsPromise = this.productManager.getProductTags(0, 1).then((response) => {
      const { items } = response;
      this.productTags = items;
    });

    const productCategoriesPromise = this.productManager.getProductCategories(0, 1).then((response) => {
      const { data } = response;
      this.deploymentTypes = data;
    });

    await Promise.all([productTagsPromise, productCategoriesPromise, promise]);

    this.isLoadingDataChange.next(false);
  }
}
