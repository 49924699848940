import { Injectable } from '@angular/core';
import { ApiClient } from './api-client.service';
import { BehaviorSubject } from "rxjs";
import { AnalyzeResponse, BasicInfo, Dependencies, ProjectList } from "./my-projects-manager.interfaces";

@Injectable({
  providedIn: 'root'
})
export class MyProjectsManagerService {

  constructor(
    private apiClient: ApiClient,
  ) { }

  public async checkbasic(itemId: string){
    let data = { itemId: itemId };
    let current: BasicInfo = await this.apiClient.post('/my-projects/checkbasic', data);
    return current;
  }

  public async check(itemId: string){
    let data = { itemId: itemId };
    let current: Dependencies = await this.apiClient.post('/my-projects/check', data);
    return current;
  }

  public async list(){
    let current: ProjectList[] = await this.apiClient.get('/my-projects/list');
    return current;
  }

  public async triggerAnalyze(data:{
    userProject: string;
    userToken: string;
  }){
    let current: AnalyzeResponse = await this.apiClient.post<AnalyzeResponse>('/my-projects/analyze', data)
    return current;
  }

}
