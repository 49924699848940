import { Component, Inject, OnInit } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import { ProductDataService } from '../product-data.service';
import { Environment } from '../Environment';
import { DOCUMENT } from '@angular/common';
import {CountriesDataService} from "../../countries-data.service";
import { ExportOption } from "../../export-option";
import { DropdownChangeEvent } from 'primeng/dropdown';
import { ExportReportService } from "../../services/export-report.service";

@Component({
  selector: 'app-product-report-page',
  templateUrl: './product-report-page.component.html',
  styleUrls: ['./product-report-page.component.scss']
})
export class ProductReportPageComponent implements OnInit {
  public selectedEnvironment: Environment | undefined;

  public projectStats: {
    stars: number;
    followers: number;
    contributors: number;
  }[] = [];

  public topContributors: { name: string, value: number, pictureSettings: object }[] = [];
  public topProgrammingLangs: { name: string, value: number, pictureSettings: object }[] = [];

  isMobile: boolean = window.innerWidth < 768;

  exportOptions: ExportOption[] = [
    // {
    //   name: 'PDF',
    //   translationId: 'CLIENT.PRODUCT.REPORT_SECTION.EXPORT_OPTIONS.PDF',
    // },
    {
      name: 'CSV',
      translationId: 'CLIENT.PRODUCT.REPORT_SECTION.EXPORT_OPTIONS.CSV',
    },
    {
      name: 'XLSX',
      translationId: 'CLIENT.PRODUCT.REPORT_SECTION.EXPORT_OPTIONS.XLSX',
    },
  ];

  public isExporting: boolean = false;

  public isLoading: boolean = true;

  public openedKeys: any;

  constructor(
    public productData: ProductDataService,
    public countriesDataService: CountriesDataService,
    public exportReportService: ExportReportService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  public getFirstKey() {
    this.openedKeys = {[this.productData.environments[0].name]: true};
  }

  public ngOnInit(): void {
    this.productData.isLoadingDataChange.subscribe((value) => {
      if (value) return;

      this.projectStats = [
        {
          stars: Number(this.productData.defaultEnvironment.starsCount),
          followers: Number(this.productData.defaultEnvironment.followersCount),
          contributors: Number(this.productData.defaultEnvironment.contributorsCount)
        },
      ];

      this.isLoading = value;

      this.getFirstKey();

      this.topContributors = this.productData.contributions.slice(0, 5).map((data) => ({
        name: data.name,
        value: data.value,
        pictureSettings: {
          src: (() => {
            const country = this.countriesDataService.countriesAlpha3.get(data.name);
            return `/assets/flags/${country?.['alpha-2'].toLocaleLowerCase()}.svg`;
          })()
        },
      }));

      this.topProgrammingLangs = this.productData.programmingLanguages.slice(0, 5).map((data) => ({
        name: data.name,
        value: data.value,
        pictureSettings: {
          src: (() => {
            switch (data.name) {
              default:
                return `/assets/languages/${data.name.toLocaleLowerCase()}.svg`
            }
          })()
        },
      }));

    });
  }

  ngAfterViewInit() {
    this.exportReportService.isExporting.subscribe(value => {
      this.isExporting = value;
    });
  }

  public getScoresKeys(scores: Record<string, number>) {
    return Object.keys(scores);
  }

  public removeOverflow() {
    this.document.body.classList.remove('p-overflow-hidden');
  }

  public onTriggerExport(event: DropdownChangeEvent) {
    if(!event.value) return;
    const reportFormat = event.value.name;
    this.exportReportService.exportProductReport(reportFormat,
                                                 { productId: this.productData.productId });
  }
}
