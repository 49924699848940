import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'install-dropdown',
  templateUrl: './install-dropdown.component.html',
  styleUrls: ['./install-dropdown.component.scss']
})
export class InstallDropdownComponent implements OnInit {
  public isInstallDropdownVisible: boolean = false;

  public hasOnPrems: boolean = false;
  public hasContainers: boolean = false;
  public hasCloud: boolean = false;

  @Input()
  public environments!: any[];

  public toggleInstallDropdown(event: Event) {
    event.stopPropagation();
    this.isInstallDropdownVisible = !this.isInstallDropdownVisible;
  }

  @HostListener('document:click', ['$event'])
  handleMouseclick(event: PointerEvent) {
    if(this.isInstallDropdownVisible) {
      this.isInstallDropdownVisible = false;
    }
  }

  public ngOnInit(): void {
    this.hasCloud = this.environments.some(environment => environment.type === 'CLOUD');
    this.hasContainers = this.environments.some(environment => environment.type === 'CONTAINERS');
    this.hasOnPrems = this.environments.some(environment => environment.type === 'ON_PREM');
  }
}
