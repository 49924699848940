import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, interval, Subscription, timer } from 'rxjs';
import { SessionService } from '../services/session.service';
import { GOOGLE_LOGIN } from '../brodcast-channels';
import { GOOGLE_LOGGED_IN } from '../brodcast-messages';

@Component({
  selector: 'app-google-login-page',
  templateUrl: './google-login-page.component.html',
  styleUrls: ['./google-login-page.component.scss']
})
export class GoogleLoginPageComponent implements AfterViewInit {
  public hasError: boolean = false;
  public isLoading: boolean = true;
  
  public countDown!: Subscription;
  public counter: number = 5;

  constructor(
    private route: ActivatedRoute,
    private auth: SessionService,
  ) {}

  public async ngAfterViewInit() {
    try {
      this.isLoading = true;
      const fragment = await firstValueFrom(this.route.fragment)
      const accessToken = fragment?.split('&').find((fragment) => fragment.includes('access_token'))?.split('=')[1];
      const response = await this.auth.loginByGoogle(accessToken!);
      if(response) {
        const channel = new BroadcastChannel(GOOGLE_LOGIN);
        channel.postMessage(GOOGLE_LOGGED_IN);
      }
    } catch (err) {
      this.hasError = true;
    } finally {
      this.countDown = timer(0, 1000).subscribe(() => {
        if(this.counter > 0) --this.counter;
        if(this.counter === 0) {
          this.countDown.unsubscribe();
          window.close();
        }
      });
      this.isLoading = false;
    }
  }
}
