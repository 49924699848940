<div class="flex flex-row justify-content-center pt-5 md:pt-7 mb-6 md:mb-8 relative">
  <div class="container flex-grow-1 flex flex-row flex-wrap align-items-start z-5 px-4 md:px-0">
    <div class="flex flex-column md:flex-row w-full gap-144px summary-section">
      <div class="flex flex-column gap-4 flex-grow-1 w-full md:w-8">
        <p class="m-0 text-5xl md:text-7xl font-superbold text-blue-darker" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.TITLE' | translate"></p>
        <div class="flex flex-column gap-3">
          <p class="font-semibold text-xl text-grey-dark m-0 mt-5" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.SUMMARY' | translate"></p>
        </div>
      </div>
      <div class="flex-grow-1 mt-6 md:mt-0 w-full md:w-4">
        <img src="assets/manage-folders.png" alt="" style="width: 100%;">
      </div>
    </div>
  </div>
  <img src="/assets/decoration_pixels.svg" class="absolute left-0 z-0">
</div>

<!--WHY SM-->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
    <img src="/assets/decoration_pixels.svg" class="absolute w-10rem right-0 z-0" style="transform: scaleY(-1) scaleX(-1);">
    <div class="container w-full flex flex-column z-5 md:px-px-160">
        <div class="mb-3 mx-4 md:mx-0">
            <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.PROS.TITLE' | translate }}</p>
        </div>
        <div class="flex flex-row gap-4 overflow-auto flex-nowrap w-full px-4 slider" #postScroller >
            <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-3 slide">
              <i class="icon-shield-check icon-90 bg-blue-normal"></i>
              <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.SOLUTIONS.EZDRP.PROS.FIRST_CARD.TITLE' | translate }}</p>
              <p class="text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.PROS.FIRST_CARD.DESCRIPTION' | translate"></p>
            </div>
            <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-3 slide">
                <i class="icon-round-star icon-90 bg-blue-normal"></i>
                <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.SOLUTIONS.EZDRP.PROS.SECOND_CARD.TITLE' | translate }}</p>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.PROS.SECOND_CARD.DESCRIPTION' | translate"></p>
            </div>
            <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-3 slide">
                <i class="icon-commits icon-90 bg-blue-normal"></i>
                <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.SOLUTIONS.EZDRP.PROS.THIRD_CARD.TITLE' | translate }}</p>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.PROS.THIRD_CARD.DESCRIPTION' | translate"></p>
            </div>
        </div>
        <div class="md:hidden flex flex-row gap-4 justify-content-center mt-3">
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="promoDotClickHandle(0)" [ngClass]="{'bg-blue-normal': currentPagePostScroller == 0}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="promoDotClickHandle(1)" [ngClass]="{'bg-blue-normal': currentPagePostScroller == 1}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="promoDotClickHandle(2)" [ngClass]="{'bg-blue-normal': currentPagePostScroller == 2}"></div>
        </div>
    </div>
</div>

<!--REPOSITORY-->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container w-full flex flex-column z-5 md:px-px-160">
    <div class="mb-3 mx-4 md:mx-0">
      <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.REPOSITORY.TITLE' | translate }}</p>
      <div class="flex flex-column gap-3">
        <p class="font-semibold text-xl text-grey-dark m-0 mt-5" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.REPOSITORY.SUMMARY' | translate"></p>
      </div>
    </div>

    <div class="flex flex-column md:flex-row align-items-stretch md:align-items-start gap-3 pt-4 px-4 md:px-0">
      <a routerLink="/catalog" [queryParams]="{tags: ezdrpTagId}" pRipple class="flex align-items-center cta px-4 py-3 no-underline border-round-lg flex-grow-1 justify-content-center">
        <span class="mx-2 font-bold">{{ 'CLIENT.SOLUTIONS.EZDRP.REPOSITORY.BUTTON.REPO' | translate }}</span>
        <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
      </a>
      <a routerLink="/products/{{ezdrpId}}/report" pRipple class="flex align-items-center cta px-4 py-3 no-underline border-round-lg flex-grow-1 justify-content-center text-center">
        <span class="mx-2 font-bold">{{ 'CLIENT.SOLUTIONS.EZDRP.REPOSITORY.BUTTON.RISK' | translate }}</span>
        <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
      </a>
      <a routerLink="/products/{{ezdrpId}}/documentation" pRipple class="flex align-items-center cta px-4 py-3 no-underline border-round-lg flex-grow-1 justify-content-center text-center">
        <span class="mx-2 font-bold">{{ 'CLIENT.SOLUTIONS.EZDRP.REPOSITORY.BUTTON.DOCS' | translate }}</span>
        <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
      </a>
    </div>

    <div class="flex flex-column md:flex-row align-items-stretch md:align-items-start gap-3 pt-6 w-full px-4 md:px-0">
      <div class="flex-1 flex align-items-center justify-content-center">
        <a routerLink="/products/{{ezdrpId}}/deployments" class="no-underline border-blue-light-active w-full bg-blue-light p-5 flex flex-row gap-2 align-items-center justify-content-center mt-3 cursor-pointer border-1 border-round-lg font-bold text-3xl text-blue-darker text-center">
          <i class="icon-24 icon-container bg-blue-darker"></i>
          <span class="text-center">
            {{ 'CLIENT.SOLUTIONS.EZDRP.REPOSITORY.BUTTON.CONTAINERS'| translate }}
          </span>
          <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
        </a>
      </div>
      <div class="flex-1 flex align-items-center justify-content-center ">
        <a routerLink="/products/{{ezdrpId}}/deployments/{{ezdrpSaasId}}/report" class="no-underline border-blue-light-active w-full bg-blue-light p-5 flex flex-row gap-2 align-items-center justify-content-center mt-3 cursor-pointer border-1 border-round-lg font-bold text-3xl text-blue-darker text-center">
          <i class="icon-24 icon-cloud bg-blue-darker"></i>
          <span class="text-center">
            {{ 'CLIENT.SOLUTIONS.EZDRP.REPOSITORY.BUTTON.CLOUD'| translate }}
          </span>
          <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
        </a>
      </div>
    </div>
  </div>
  <img src="/assets/decoration_pixels.svg" class="absolute left-0 z-0">
</div>

<!--SUBSCRIPTIONS-->
<div class="flex flex-column justify-content-center pt-4 skew-top-right-lg bg-blue-light-hover align-items-center pb-8 mb-8">

  <div class="container flex flex-column w-full align-items-center z-5 px-4 md:pt-180px">
    <div class="mb-3 mx-4 md:mx-0 w-10">
      <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.TITLE' | translate }}</p>
      <p class="text-grey-dark text-lg" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.DESCRIPTION' | translate"></p>
    </div>

    <div class="flex flex-row align-items-center gap-2 mb-2">
      <span class="font-normal text-blue-darker" style="word-break: keep-all;">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.SAAS' | translate }}</span>
        <p-inputSwitch [(ngModel)]="subscriptionIsOnDemand"></p-inputSwitch>
      <span class="font-normal text-blue-darker" style="word-break: keep-all;">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.ON_DEMAND' | translate }}</span>
    </div>

    <div class="flex flex-row gap-4 overflow-auto align-items-stretch flex-nowrap w-full slider" #subscriptionScroller>
      <!-- Basic -->
      <div class="flex flex-none md:flex-auto flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-full md:w-3 gap-4 slide" *ngIf="subscriptionIsOnDemand">
        <div class="flex flex-column gap-6">
          <div class="flex flex-column gap-5">
            <div class="flex flex-column gap-2 overflow-hidden">
              <span class="font-semibold text-xl text-center text-grey-dark"> {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.TITLE' | translate }} </span>
              <span class="font-bold text-3xl text-center text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.NAME' | translate }}</span>
            </div>
            <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center overflow-hidden">
              <span class="text-4xl font-bold">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.DETAILS' | translate }}</span>
              <span class="text-xl pl-3 font-semibold">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.UNIT' | translate }}</span>
            </div>
          </div>
          <div  class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-check icon-24 bg-blue-normal"></i>
                <span class="font-normal text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.NOT_INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-minus icon-24 bg-grey-normal-active"></i>
                <span class="font-normal text-grey-normal-active">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.NOT_INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="flex flex-row mt-auto align-items-center justify-content-around">
          <a routerLink="/products/{{ezdrpId}}"
             class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg overflow-hidden text-center"
          >
            {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.BASIC.SUBSCRIBE' | translate }}
          </a>
        </div>
      </div>

      <!-- Standard -->
      <div class="flex flex-none md:flex-auto flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-full md:w-3 gap-4 slide">
        <div class="flex flex-column gap-6">
          <div class="flex flex-column gap-5">
            <div class="flex flex-column gap-2 overflow-hidden">
              <span class="font-semibold text-xl text-center text-grey-dark"> {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.TITLE' | translate }} </span>
              <span class="font-bold text-3xl text-center text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.NAME' | translate }}</span>
            </div>
            <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center overflow-hidden">
              <span class="text-2xl font-bold" *ngIf="subscriptionIsOnDemand">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.ONDEMAND_DETAILS' | translate }}</span>
              <span class="text-2xl font-bold" *ngIf="!subscriptionIsOnDemand">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.SAAS_DETAILS' | translate }}</span>
              <span class="text-xl pl-1 font-semibold">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.UNIT' | translate }}</span>
            </div>
          </div>
          <div  class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-check icon-24 bg-blue-normal"></i>
                <span class="font-normal text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.NOT_INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-minus icon-24 bg-grey-normal-active"></i>
                <span class="font-normal text-grey-normal-active">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.NOT_INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="flex flex-row mt-auto align-items-center justify-content-around">
          <a routerLink="/about-us"
             fragment="contact"
             [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.EZDRP.STANDARD.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.EZDRP.STANDARD.MESSAGE'}"
             class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg overflow-hidden text-center"
          >
            {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.STANDARD.SUBSCRIBE' | translate }}
          </a>
        </div>
      </div>

      <!-- Advanced -->
      <div class="flex flex-none md:flex-auto flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-full md:w-3 gap-4 slide">
        <div class="flex flex-column gap-6">
          <div class="flex flex-column gap-5">
            <div class="flex flex-column gap-2 overflow-hidden">
              <span class="font-semibold text-xl text-center text-grey-dark"> {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.TITLE' | translate }} </span>
              <span class="font-bold text-3xl text-center text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.NAME' | translate }}</span>
            </div>
            <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center overflow-hidden">
              <span class="text-2xl font-bold" *ngIf="subscriptionIsOnDemand">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.ONDEMAND_DETAILS' | translate }}</span>
              <span class="text-2xl font-bold" *ngIf="!subscriptionIsOnDemand">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.SAAS_DETAILS' | translate }}</span>
              <span class="text-xl pl-1 font-semibold">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.UNIT' | translate }}</span>
            </div>
          </div>
          <div  class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-check icon-24 bg-blue-normal"></i>
                <span class="font-normal text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.NOT_INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-minus icon-24 bg-grey-normal-active"></i>
                <span class="font-normal text-grey-normal-active">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.NOT_INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="flex flex-row mt-auto align-items-center justify-content-around">
          <a routerLink="/about-us"
             fragment="contact"
             [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.EZDRP.ADVANCED.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.EZDRP.ADVANCED.MESSAGE'}"
             class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg overflow-hidden text-center"
          >
            {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ADVANCED.SUBSCRIBE' | translate }}
          </a>
        </div>
      </div>

      <!-- Enterprise -->
      <div class="flex flex-none md:flex-auto flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-full md:w-3 gap-4 slide">
        <div class="flex flex-column gap-6">
          <div class="flex flex-column gap-5">
            <div class="flex flex-column gap-2 overflow-hidden">
              <span class="font-semibold text-xl text-center text-grey-dark"> {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.TITLE' | translate }} </span>
              <span class="font-bold text-3xl text-center text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.NAME' | translate }}</span>
            </div>
            <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center overflow-hidden">
              <span class="text-4xl font-bold">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.DETAILS' | translate }}</span>
              <span class="text-xl pl-3 font-semibold">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.UNIT' | translate }}</span>
            </div>
          </div>
          <div  class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-check icon-24 bg-blue-normal"></i>
                <span class="font-normal text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
            <ng-container  *ngFor="let n of numSequence(12); let i = index;">
              <div *ngIf="hasTranslation('CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.NOT_INCLUDED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                <i class="flex-shrink-0 icon-minus icon-24 bg-grey-normal-active"></i>
                <span class="font-normal text-grey-normal-active">{{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.NOT_INCLUDED.POINT_'+i | translate }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="flex flex-row mt-auto align-items-center justify-content-around">
          <a routerLink="/about-us"
             fragment="contact"
             [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.EZDRP.ENTERPRISE.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.EZDRP.ENTERPRISE.MESSAGE'}"
             class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg overflow-hidden text-center"
          >
            {{ 'CLIENT.SOLUTIONS.EZDRP.SUBSCRIPTIONS.PLAN.ENTERPRISE.SUBSCRIBE' | translate }}
          </a>
        </div>
      </div>

      </div>
        <div class="md:hidden flex flex-row gap-4 justify-content-center mt-3">
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="subscriptionsDotClickHandle(0)" [ngClass]="{'bg-blue-normal': currentPageSubscriptionScroller == 0}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="subscriptionsDotClickHandle(1)" [ngClass]="{'bg-blue-normal': currentPageSubscriptionScroller == 1}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="subscriptionsDotClickHandle(2)" [ngClass]="{'bg-blue-normal': currentPageSubscriptionScroller == 2}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="subscriptionsDotClickHandle(3)" [ngClass]="{'bg-blue-normal': currentPageSubscriptionScroller == 3}" *ngIf="subscriptionIsOnDemand"></div>
        </div>
  </div>
</div>

<!--STAGES AND POSTS-->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container w-full flex flex-column z-5 md:px-px-160">
    <div class="mb-3 mx-4 md:mx-0 gap-3">
      <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.TITLE' | translate }}</p>
      <p class="font-bold text-3xl md:text-3xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.SUBTITLE' | translate }}</p>
      <div class="flex flex-column gap-3">
        <p class="font-semibold text-xl text-grey-dark m-0 mt-5" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.STAGES.SUMMARY' | translate"></p>
      </div>
    </div>
    <div class="flex flex-column md:flex-row gap-3 pt-4 px-4 md:px-0">
      <div class="flex flex-column gap-3 p-5 bg-blue-darker border-grey-normal border-1 border-round-2xl align-items-start w-full">
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_1.TITLE'| translate }}
        </span>
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_1.DESCRIPTION'| translate }}
        </span>
      </div>
      <div class="flex flex-column gap-3 p-5 bg-blue-darker border-grey-normal border-1 border-round-2xl align-items-start w-full">
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_2.TITLE'| translate }}
        </span>
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_2.DESCRIPTION'| translate }}
        </span>
      </div>
      <div class="flex flex-column gap-3 p-5 bg-blue-darker border-grey-normal border-1 border-round-2xl align-items-start w-full">
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_3.TITLE'| translate }}
        </span>
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_3.DESCRIPTION'| translate }}
        </span>
      </div>
      <div class="flex flex-column gap-3 p-5 bg-blue-darker border-grey-normal border-1 border-round-2xl align-items-start w-full">
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_4.TITLE'| translate }}
        </span>
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_4.DESCRIPTION'| translate }}
        </span>
      </div>
      <div class="flex flex-column gap-3 p-5 bg-blue-darker border-grey-normal border-1 border-round-2xl align-items-start w-full">
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_5.TITLE'| translate }}
        </span>
        <span class="text-white font-semibold">
          {{ 'CLIENT.SOLUTIONS.EZDRP.STAGES.STAGE_5.DESCRIPTION'| translate }}
        </span>
      </div>
    </div>

<!--POSTS-->
    <div class="flex flex-column gap-6 pt-4 px-4 md:px-0">
      <div class="flex flex-column md:flex-row mt-8 md:gap-7">
        <div class="cover">
          <img src="assets/compliance.jpg" alt="" class="md:w-27rem border-round-lg">
        </div>
        <div>
          <p class="font-bold text-3xl">{{ 'CLIENT.SOLUTIONS.EZDRP.POSTS.FIRST_POST.TITLE' | translate }}</p>
          <p class="text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.POSTS.FIRST_POST.DESCRIPTION' | translate"></p>
        </div>
      </div>
    </div>

    <div class="flex flex-column gap-6 px-4 md:px-0">
      <div class="flex flex-column md:flex-row mt-8 md:gap-7">
        <div class="cover">
          <img src="assets/service.jpg" alt="" class="md:w-27rem border-round-lg">
        </div>
        <div>
          <p class="font-bold text-3xl">{{ 'CLIENT.SOLUTIONS.EZDRP.POSTS.SECOND_POST.TITLE' | translate }}</p>
          <p class="text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.POSTS.SECOND_POST.DESCRIPTION' | translate"></p>
        </div>
      </div>
    </div>
  </div>
</div>

<!--IMPLEMENTATIONS-->

<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container flex flex-column w-full align-items-center z-5 px-4">

    <div class="mb-3 mx-4 md:mx-0 w-10">
        <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.EZDRP.IMPLEMENTATIONS.TITLE' | translate }}</p>
    </div>

    <div class="flex flex-column md:flex-row w-full align-items-start gap-4">
      <div class="flex flex-column gap-4">
        <a href="https://www.youtube.com/watch?v=S7ghFN9HN9Y" target="_blank" class="flex flex-column gap-4 no-underline">
          <div class="cover">
            <img src="assets/video.jpg" alt="" class="md:w-27rem h-19rem border-round-lg">
          </div>
          <span class="font-bold text-xl text-blue-darker">
            {{ 'CLIENT.SOLUTIONS.EZDRP.VIDEO.TITLE' | translate }}
          </span>
          <span class="font-normal text-base text-grey-dark-hover">
            {{ 'CLIENT.SOLUTIONS.EZDRP.VIDEO.DESCRIPTION' | translate }}
          </span>
          <div class="flex">
            <div class="flex flex-row align-items-center no-underline text-blue-normal text-sm font-bold py-3 px-4 border-1 border-blue-normal border-round-lg">
              <span>
                {{ 'CLIENT.SOLUTIONS.EZDRP.VIDEO.BUTTON' | translate }}
              </span>
              <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
            </div>
          </div>
        </a>
      </div>

      <div class="flex flex-row flex-wrap md:flex-nowrap align-items-start justify-content-center p-5 gap-3 w-auto bg-blue-darker border-grey-normal border-1 border-round-2xl">
        <div>
          <p class="font-bold text-3xl text-white">{{ 'CLIENT.SOLUTIONS.EZDRP.IMPLEMENTATIONS.FIRST_POST.TITLE' | translate }}</p>
          <p class="text-white" [innerHTML]="'CLIENT.SOLUTIONS.EZDRP.IMPLEMENTATIONS.FIRST_POST.DESCRIPTION' | translate"></p>
        </div>
        <div class="cover">
          <img src="assets/nask.png" alt="" class="md:w-27rem border-round-lg">
        </div>
      </div>

    </div>
  </div>
</div>



