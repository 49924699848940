import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserManager } from 'src/app/services/user-manager.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  public isLoading: boolean = false;

  public formGroup!: FormGroup;

  constructor(
    private userManager: UserManager
  ) {}

  async ngOnInit() {

    this.isLoading = true;

    const firstNameControl = new FormControl('');
    const lastNameControl = new FormControl('');
    const organizationNameControl = new FormControl('');

    this.formGroup = new FormGroup({
      firstName: firstNameControl,
      lastName: lastNameControl,
      organizationName: organizationNameControl,
    });

    const { firstName, lastName, organizationName } = await this.userManager.getUserData();

    firstNameControl.setValue(firstName);
    lastNameControl.setValue(lastName);
    organizationNameControl.setValue(organizationName);

    this.isLoading = false;

  }

  public async submit() {
    try {
      this.isLoading = true;
      await this.userManager.setUserData({
        organizationName: this.formGroup.get('organizationName')?.value ?? undefined,
        firstName: this.formGroup.get('firstName')?.value ?? undefined,
        lastName: this.formGroup.get('lastName')?.value ?? undefined,
      })
    } catch (err) {
      
    } finally {
      this.isLoading = false;
    }
  }
}
