<div class="flex flex-column gap-5 bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover">
  <div class="flex flex-row gap-3 align-items-center">
    <i class="icon-star icon-24 bg-blue-darker"></i>
    <span class="font-bold text-3xl text-blue-darker">
      {{ 'CLIENT.PROFILE.FAVORITES.TITLE' | translate }}
    </span>
  </div>

  <div class="md:w-10 px-4 md:px-0" *ngIf="!hasFavoriteItems">
    <span class="font-normal text-base text-grey-dark-hover">
      {{ 'CLIENT.PROFILE.FAVORITES.HAS_NO_ELEMENTS' | translate }}
    </span>
  </div>

  <div class="md:w-10 px-4 md:px-0" *ngIf="hasFavoriteItems">
    <span class="font-normal text-base text-grey-dark-hover">
      {{ 'CLIENT.PROFILE.FAVORITES.HAS_ELEMENTS' | translate }}
    </span>
  </div>

  <span class="p-input-icon-left w-full" *ngIf="hasFavoriteItems">
    <i class="pi pi-search"></i>
    <input type="text" pInputText placeholder="{{ 'CLIENT.PROFILE.FAVORITES.SEARCH' | translate }}" class="w-full bg-blue-light-hover border-blue-light-active text-blue-dark py-4 pr-3" />
  </span>

  <div class="hidden md:flex flex-column p-5 bg-blue-light border-round-3xl gap-5 mt-7 relative" *ngIf="!hasFavoriteItems">
    <img src="assets/arrow.png" alt="" class="absolute" style="width: 46px; height: 88px; top: -60px; left: 20px;">
    <div class="flex flex-row justify-content-between align-items-center">
      <div class="flex flex-row gap-3 align-items-center">
        <i class="icon-star icon-40 bg-blue-darker"></i>
        <span class="font-bold text-6xl text-blue-darker opacity-50">
          {{ 'CLIENT.PROFILE.FAVORITES.EXAMPLE.TITLE' | translate }}
        </span>
      </div>
      <p-button class="align-self-center" styleClass="cta" class="opacity-50">
        <img src="assets/terminal.svg" style="width: 16px; height: 16px;" alt="install">
        <span class="mx-2 font-bold">{{ 'CLIENT.PROFILE.FAVORITES.EXAMPLE.INSTALL_BUTTON' | translate }}</span>
      </p-button>
    </div>
    <div class="flex flex-row opacity-50">
      <div class="flex flex-row border-round-lg border-1 border-grey-normal bg-white">
        <div class="flex flex-row align-items-center p-3 border-right-1 border-grey-normal gap-2">
          <i class="icon-round-star bg-grey-dark-hover icon-16"></i>
          <span class="font-bold text-base text-grey-darker">
            73376
          </span>
        </div>
        <div class="flex flex-row align-items-center p-3 border-right-1 border-grey-normal gap-2">
          <i class="icon-people bg-grey-dark-hover icon-16"></i>
          <span class="font-bold text-base text-grey-darker">
            5430694
          </span>
        </div>
        <div class="flex flex-row align-items-center p-3 gap-2">
          <i class="icon-branch bg-grey-dark-hover icon-16"></i>
          <span class="font-bold text-base text-grey-darker">
            78094
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-row opacity-50">
      <span class="font-semibold text-xl text-grey-dark-hover">
        {{ 'CLIENT.PROFILE.FAVORITES.EXAMPLE.SUMMARY' | translate }}
      </span>
    </div>
  </div>

  <span class="font-semibold text-xl" *ngIf="hasFavoriteItems && productLikes.length > 0">
    {{ 'CLIENT.PROFILE.FAVORITES.CATEGORY.PRODUCTS.TITLE' | translate }}
  </span>

  <div class="flex flex-column gap-3"  *ngIf="hasFavoriteItems && productLikes.length > 0">
    <a routerLink="/products/{{product.id}}" class="flex flex-row bg-blue-light border-round-lg p-4 justify-content-between no-underline text-blue-darker" *ngFor="let product of productLikes">
      <div class="flex flex-column gap-3 justify-content-center">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-star-full icon-24 bg-blue-darker cursor-pointer" (click)="removeProductLike(product.id, $event)"></i>
          <span class="font-semibold text-xl">
            {{ product.name | translate }}
          </span>
        </div>
        <span>
          {{ product.summary | translate }}
        </span>
      </div>
      <app-score-circle
        [score] = "product.scarmScore"
        [trend] = "product.lastScarmTrend"
        [radius]="35"
        [outerStrokeColor]="'#BBD6F7'"
        [outerStrokeGradientStopColor]="'#78ADED'"
      >
      </app-score-circle>
    </a>
  </div>

  <span class="font-semibold text-xl" *ngIf="hasFavoriteItems && deploymentLikes.length > 0">
    {{ 'CLIENT.PROFILE.FAVORITES.CATEGORY.DEPLOYMENTS.TITLE' | translate }}
  </span>

  <div class="flex flex-column"  *ngIf="hasFavoriteItems && deploymentLikes.length > 0">
    <a routerLink="/products/{{deployment.productId}}/deployments/{{deployment.id}}" class="flex flex-row bg-blue-light border-round-lg p-4 justify-content-between no-underline text-blue-darker" *ngFor="let deployment of deploymentLikes">
      <div class="flex flex-column gap-3 justify-content-center">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-star-full icon-24 bg-blue-darker cursor-pointer" (click)="removeDeploymentLike(deployment.id, $event)"></i>
          <span class="font-semibold text-xl">
            {{ deployment.name | translate }}
          </span>
        </div>
        <span>
          {{ deployment.summary | translate }}
        </span>
      </div>
      <app-score-circle
        [score] = "deployment.scarmScore"
        [trend] = "deployment.lastScarmTrend"
        [radius]="35"
        [outerStrokeColor]="'#BBD6F7'"
        [outerStrokeGradientStopColor]="'#78ADED'"
      >
      </app-score-circle>
    </a>
  </div>

  <span class="font-semibold text-xl" *ngIf="hasFavoriteItems && componentLikes.length > 0">
    {{ 'CLIENT.PROFILE.FAVORITES.CATEGORY.COMPONENTS.TITLE' | translate }}
  </span>

  <div class="flex flex-column"  *ngIf="hasFavoriteItems && componentLikes.length > 0">
    <a routerLink="/component/{{component.id}}" class="flex flex-row bg-blue-light border-round-lg p-4 justify-content-between no-underline text-blue-darker" *ngFor="let component of componentLikes">
      <div class="flex flex-column gap-3 justify-content-center">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-star-full icon-24 bg-blue-darker cursor-pointer" (click)="removeComponentLike(component.id, $event)"></i>
          <span class="font-semibold text-xl">
            {{ component.name | translate }}
          </span>
        </div>
        <span>
          {{ component.summary | translate }}
        </span>
      </div>
      <app-score-circle
        [score] = "component.scarmScore"
        [trend] = "component.lastScarmTrend"
        [radius]="35"
        [outerStrokeColor]="'#BBD6F7'"
        [outerStrokeGradientStopColor]="'#78ADED'"
      >
      </app-score-circle>
    </a>
  </div>

</div>

<div class="md:hidden relative">
  <div class="flex flex-column bg-white border-round-lg border-blue-light-hover border-1 mt-5 opacity-50">
    <div class="flex flex-row p-3 border-blue-light-hover border-bottom-1 justify-content-between align-items-center">
      <img src="/assets/logo/eurolinux.png" alt="EuroLinux" class="w-8rem max-w-8rem" style="height:20px;">
      <span class="text-grey-dark font-bold text-base">Docker</span>
    </div>
    <div class="flex flex-row px-3 py-4 border-blue-light-hover border-bottom-1 gap-3">
      <div class="flex w-5rem">
        <app-score-circle
        [score] = "0.9"
        [trend] = "1"
        [radius]="30"
        [outerStrokeColor]="'#2079E2'"
        [outerStrokeGradientStopColor]="'#134988'"
      >
      </app-score-circle>
      </div>
      <div class="flex flex-column gap-3 flex-shrink-1 text-blue-darker">
        <span class="font-bold text-sm">{{ 'CLIENT.PROFILE.FAVORITES.EXAMPLE.TITLE' | translate }}</span>
        <span class="webkit-box line-clamp-3 overflow-hidden webkit-box-vertical text-sm">{{ 'CLIENT.PROFILE.FAVORITES.EXAMPLE.SUMMARY' | translate }}</span>
      </div>
    </div>
    <div class="flex flex-row px-3 py-2 align-items-center justify-content-between flex-wrap">
      <div class="flex flex-row bg-grey-light border-round-lg p-2 gap-2 align-items-center">
        <i class="icon-round-star icon-16 bg-blue-darker"></i>
        <span class="text-blue-darker font-bold text-sm">
          {{ 34567 | splitNumber: ' ' }}
        </span>
      </div>
      <i class="icon-24 bg-blue-darker icon-star"></i>
    </div>
  </div>
  <img src="assets/arrow.png" alt="" class="absolute" style="width: -46px; height: 88px; bottom: 45px; right: 5px; transform: scale(-1, 1);">
</div>
