<p-button class="align-self-center" styleClass="cta" (onClick)="toggleInstallDropdown($event)">
    <img src="assets/terminal.svg" style="width: 16px; height: 16px;" alt="install">
    <span class="mx-2 font-bold">{{ 'CLIENT.INSTALL_BUTTON.TITLE' | translate }}</span>
    <img src="assets/chevron-down.svg" style="width: 16px; height: 16px;" alt="install">
</p-button>
<div class="flex flex-column absolute bg-black-white w-18rem md:right-0 border-round-lg border-1 border-blue-light-active max-h-19rem overflow-auto z-5" style="top: 55px;" *ngIf="isInstallDropdownVisible">
    <div class="flex flex-row gap-2 align-items-center px-3 pt-3 pb-2" *ngIf="hasOnPrems">
        <i class="icon-16 icon-on-prem bg-grey-normal-active"></i>
        <span class="text-grey-dark-hover font-bold">
            {{ 'CLIENT.INSTALL_BUTTON.ON_PREM' | translate }}
        </span>
    </div>
    <ng-container *ngFor="let environment of environments | filterEnvironmentType:'ON_PREM'">
        <a routerLink="deployments/{{ environment.id }}" class="no-underline pl-5 ml-2 pt-12px pb-12px pr-3">
            <span class="text-sm text-grey-dark">
                {{ environment.typeName | translate }}
            </span>
        </a>
    </ng-container>
    <div class="flex flex-row gap-2 align-items-center px-3 pt-3 pb-2" *ngIf="hasContainers">
        <i class="icon-16 icon-container bg-grey-normal-active"></i>
        <span class="text-grey-dark-hover font-bold">
            {{ 'CLIENT.INSTALL_BUTTON.CONTAINERS' | translate }}
        </span>
    </div>
    <ng-container *ngFor="let environment of environments | filterEnvironmentType:'CONTAINERS'">
        <a routerLink="deployments/{{ environment.id }}" class="no-underline pl-5 ml-2 pt-12px pb-12px pr-3">
            <span class="text-sm text-grey-dark">
                {{ environment.typeName | translate }}
            </span>
        </a>
    </ng-container>
    <div class="flex flex-row gap-2 align-items-center px-3 pt-3 pb-2" *ngIf="hasCloud">
        <i class="icon-16 icon-cloud bg-grey-normal-active"></i>
        <span class="text-grey-dark-hover font-bold">
            {{ 'CLIENT.INSTALL_BUTTON.CLOUD' | translate }}
        </span>
    </div>
    <ng-container *ngFor="let environment of environments | filterEnvironmentType:'CLOUD'">
        <a routerLink="deployments/{{ environment.id }}" class="no-underline pl-5 ml-2 pt-12px pb-12px pr-3">
            <span class="text-sm text-grey-dark">
                {{ environment.typeName | translate }}
            </span>
        </a>
    </ng-container>
</div>