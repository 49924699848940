import { Component, OnInit } from '@angular/core';
import {MyProjectsManagerService} from "../../../services/my-projects-manager.service";
import {InfoTable} from "./info-table";

@Component({
  selector: 'app-my-projects-info-page',
  templateUrl: './my-projects-info-page.component.html',
  styleUrls: ['./my-projects-info-page.component.scss']
})
export class MyProjectsInfoPageComponent implements OnInit {

  hasError: boolean = false;
  isLoading: boolean = true;
  tableData!: InfoTable[];

  constructor(
    private myProjectsManager: MyProjectsManagerService,
  ) {}

  public async ngOnInit() {
    try {
      this.isLoading = true;
      let projectsList = await this.myProjectsManager.list();

      this.tableData = []
      for(const project of projectsList) {
          let resp = await this.myProjectsManager.checkbasic(project.itemId);
          this.tableData.push({name: project.url, status: resp.status, url: project.itemId})
      }
    } catch (err) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
