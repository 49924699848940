import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DeploymentDataService } from './deployment-data.service';

@Component({
  selector: 'app-deployment-page',
  templateUrl: './deployment-page.component.html',
  styleUrls: ['./deployment-page.component.scss'],
  providers: [DeploymentDataService],
})
export class DeploymentPageComponent implements OnInit {
  @ViewChild('installOnPage', { static: false })
  private installOnPage!: ElementRef<HTMLElement>;
  @ViewChild('stickyMenu', { static: false })
  private stickyMenu!: ElementRef<HTMLElement>;

  public isInstallOnPageVisible: boolean = true;

  public isLoading: boolean = true;

  public traitsToDisplay: number = 4;

  public displayAllScores: boolean = false;

  public scoresWithTrends!: Record<string, [number, number]>;
  public scoresOrder: string[] = [
    'code',
    'vulnerability',
    'activity',
    'contributor',
    'language',
  ];
  public numberOfPositiveScores: number = 0;

  constructor(public deploymentData: DeploymentDataService) {}

  public showMoreTraits() {
    this.traitsToDisplay = this.deploymentData.traits.length;
  }

  public showAllScores() {
    this.displayAllScores = true;
  }

  public ngOnInit() {
    this.deploymentData.isLoadingDataChange.subscribe((value) => {
      this.isLoading = value;

      this.scoresWithTrends = {
        code: [
          this.deploymentData.codeRating,
          this.deploymentData.lastTrends.codeQuality,
        ],
        vulnerability: [
          this.deploymentData.vulnerabilityRating,
          this.deploymentData.lastTrends.vulnerability,
        ],
        activity: [
          this.deploymentData.activityRating,
          this.deploymentData.lastTrends.activity,
        ],
        contributor: [
          this.deploymentData.contributorRating,
          this.deploymentData.lastTrends.contributor,
        ],
        language: [
          this.deploymentData.languageCoverageRating,
          this.deploymentData.lastTrends.languageCoverage,
        ],
      };
      for (const [key, value] of Object.entries(this.scoresWithTrends)) {
        if (value[0] === null || (value[0] == 0 && key == 'vulnerability')) {
          const index = this.scoresOrder.indexOf(key, 0);
          if (index > -1) {
            this.scoresOrder.splice(index, 1);
          }
        }
      }
      this.numberOfPositiveScores = this.scoresOrder.length;
    });
  }


  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.installOnPage) {
      const rect = this.installOnPage.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 5;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isInstallOnPageVisible = topShown && bottomShown;
    }

    if (this.stickyMenu) {
      const rect = this.stickyMenu.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 30;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.stickyMenu.nativeElement.classList.toggle(
        'shadow-1',
        !(topShown && bottomShown),
      );
    }
  }
}
