<div class="flex flex-row justify-content-center pt-5 md:pt-7 relative">
  <div class="container flex-grow-1 flex flex-row flex-wrap align-items-start z-5 px-4 md:px-0">
    <div class="flex flex-column md:flex-row w-full gap-144px summary-section">
      <div class="flex flex-column gap-4 flex-grow-1 w-full md:w-8">
        <p class="m-0 text-5xl md:text-7xl font-superbold text-blue-darker" [innerHTML]="'CLIENT.SOLUTIONS.FDS.TITLE' | translate"></p>
        <div class="flex flex-column gap-3">
          <p class="font-semibold text-xl text-grey-dark m-0 mt-5" [innerHTML]="'CLIENT.SOLUTIONS.FDS.SUMMARY' | translate"></p>
        </div>
        <div class="flex flex-column md:flex-row align-items-stretch md:align-items-start gap-3 mt-5">
          <a routerLink="/about-us"
             fragment="contact" pRipple
             [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.FDS.CONTACT.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.FDS.CONTACT.MESSAGE'}"
             class="flex align-items-center cta px-4 py-3 no-underline border-round-lg flex-grow-1 justify-content-center">
            <span class="mx-2 font-bold">{{ 'CLIENT.SOLUTIONS.FDS.CONTACT.BUTTON' | translate }}</span>
            <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
          </a>
          <a routerLink="/about-us"
             fragment="contact" pRipple
             [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.FDS.ORDER.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.FDS.ORDER.MESSAGE'}"
             class="flex align-items-center cta px-4 py-3 no-underline border-round-lg flex-grow-1 justify-content-center text-center">
            <span class="mx-2 font-bold">{{ 'CLIENT.SOLUTIONS.FDS.ORDER.BUTTON' | translate }}</span>
            <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
          </a>
        </div>
      </div>
      <div class="flex-grow-1 mt-6 md:mt-0 w-full md:w-4">
        <img src="assets/icon-2.png" class="hidden md:block" alt="" style="width: 80%;">
      </div>
    </div>
  </div>
  <img src="/assets/decoration_pixels.svg" class="absolute left-0 z-0">
</div>

<!-- BENEFITS -->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container flex flex-column w-full align-items-center z-5">
    <div class="mb-3 mx-4 md:mx-0 w-10">
        <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.FDS.BENEFITS.TITLE' | translate }}</p>
        <div class="flex flex-column gap-3">
          <p class="font-semibold text-xl text-grey-dark m-0" [innerHTML]="'CLIENT.SOLUTIONS.FDS.BENEFITS.DESCRIPTION' | translate"></p>
        </div>
    </div>
  </div>
</div>

<!-- FEATURES -->
<!-- PIXELS RIGHT -->
<img src="/assets/decoration_pixels.svg" class="absolute w-10rem right-0 z-0" style="transform: scaleY(-1) scaleX(-1);">

<!-- (1) PIC ON LEFT -->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container flex flex-column w-full align-items-center z-5">
    <div class="flex flex-column md:flex-row w-full align-items-start gap-4">
      <div class="flex flex-row flex-wrap md:flex-nowrap align-items-start justify-content-center p-5 gap-6 w-full">
        <div class="flex flex-column w-full md:w-6">
          <img src="{{'CLIENT.SOLUTIONS.FDS.FEATURES.FIRST_PARAGRAPH.IMAGE' | translate }}" alt="" class="border-round-lg">
        </div>
        <div class="flex flex-column w-full md:w-6">
          <p class="font-bold text-4xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.FDS.FEATURES.FIRST_PARAGRAPH.TITLE' | translate }}</p>
          <p class="font-semibold text-xl text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.FDS.FEATURES.FIRST_PARAGRAPH.DESCRIPTION' | translate"></p>
        </div>
      </div>
    </div>
  </div>
</div>

<hr class="flex flex-row w-6 h-1 bg-blue-light border-blue-light">

<!-- PIXELS LEFT -->
<img src="/assets/decoration_pixels.svg" class="absolute left-0 z-0">

<!-- (2) PIC ON RIGHT -->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container flex flex-column w-full align-items-center z-5">
    <div class="flex flex-column md:flex-row w-full align-items-start gap-4">
      <div class="flex flex-row flex-wrap md:flex-nowrap align-items-start justify-content-center p-5 gap-6 w-full">
        <div class="flex flex-column w-full md:w-6">
          <p class="font-bold text-4xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.FDS.FEATURES.SECOND_PARAGRAPH.TITLE' | translate }}</p>
          <p class="font-semibold text-xl text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.FDS.FEATURES.SECOND_PARAGRAPH.DESCRIPTION' | translate"></p>
        </div>
        <div class="flex flex-column w-full md:w-6">
          <img src="{{'CLIENT.SOLUTIONS.FDS.FEATURES.SECOND_PARAGRAPH.IMAGE' | translate }}" alt="" class="border-round-lg">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- (3) PIC ON LEFT -->
<!-- BLUE SKEWED BACKGROUND START -->
<div class="justify-content-center pt-8 skew-top-right-lg bg-blue-light-hover align-items-center">
  <div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
    <div class="container flex flex-column w-full align-items-center z-5">
      <div class="flex flex-column md:flex-row w-full align-items-start gap-4">
        <div class="flex flex-row flex-wrap md:flex-nowrap align-items-start justify-content-center p-5 gap-6 w-full">
          <div class="flex flex-column w-full md:w-6">
            <img src="{{'CLIENT.SOLUTIONS.FDS.FEATURES.THIRD_PARAGRAPH.IMAGE' | translate }}" alt="" class="border-round-lg">
          </div>
          <div class="flex flex-column w-full md:w-6">
            <p class="font-bold text-4xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.FDS.FEATURES.THIRD_PARAGRAPH.TITLE' | translate }}</p>
            <p class="font-semibold text-xl text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.FDS.FEATURES.THIRD_PARAGRAPH.DESCRIPTION' | translate"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- (4) PIC ON RIGHT -->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container flex flex-column w-full align-items-center z-5">
    <div class="flex flex-column md:flex-row w-full align-items-start gap-4">
      <div class="flex flex-row flex-wrap md:flex-nowrap align-items-start justify-content-center p-5 gap-6 w-full">
        <div class="flex flex-column w-full md:w-6">
          <p class="font-bold text-4xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.FDS.FEATURES.FOURTH_PARAGRAPH.TITLE' | translate }}</p>
          <p class="font-semibold text-xl text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.FDS.FEATURES.FOURTH_PARAGRAPH.DESCRIPTION' | translate"></p>
        </div>
        <div class="flex flex-column w-full md:w-6">
          <img src="{{'CLIENT.SOLUTIONS.FDS.FEATURES.FOURTH_PARAGRAPH.IMAGE' | translate }}" alt="" class="border-round-lg">
        </div>
      </div>
    </div>
  </div>
  <!-- PIXELS LEFT -->
  <img src="/assets/decoration_pixels.svg" class="absolute left-0 z-0">
</div>

<hr class="flex flex-row w-6 h-1 bg-blue-light border-blue-light">

<!-- (5) PIC ON LEFT -->
<div class="flex flex-row justify-content-center pt-4 mb-6 md:mb-8 relative">
  <div class="container flex flex-column w-full align-items-center z-5">
    <div class="flex flex-column md:flex-row w-full align-items-start gap-4">
      <div class="flex flex-row flex-wrap md:flex-nowrap align-items-start justify-content-center p-5 gap-6 w-full">
        <div class="flex flex-column w-full md:w-6">
          <img src="{{'CLIENT.SOLUTIONS.FDS.FEATURES.FIFTH_PARAGRAPH.IMAGE' | translate }}" alt="" class="border-round-lg">
        </div>
        <div class="flex flex-column w-full md:w-6">
          <p class="font-bold text-4xl text-blue-darker">{{ 'CLIENT.SOLUTIONS.FDS.FEATURES.FIFTH_PARAGRAPH.TITLE' | translate }}</p>
          <p class="font-semibold text-xl text-grey-dark" [innerHTML]="'CLIENT.SOLUTIONS.FDS.FEATURES.FIFTH_PARAGRAPH.DESCRIPTION' | translate"></p>
        </div>
      </div>
    </div>
  </div>
</div>
