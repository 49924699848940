import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HomeDataService } from './home-data.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [HomeDataService],
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('subscriptionScroller')
  public subscriptionScroller!: ElementRef<HTMLDivElement>;
  public currentPageSubscriptionScroller: number = 0;

  @ViewChild('postScroller')
  public postScroller!: ElementRef<HTMLDivElement>;
  public currentPagePostScroller: number = 0;

  items: any[] | undefined;

  selectedItem: any;

  suggestions: any[] = [];

  public isLoading: boolean = true;

  public gcpIds = environment.gcpId;
  public awsIds = environment.awsId;
  public azureIds = environment.azureId;
  public dockerIds = environment.dockerId;
  public vmwareIds = environment.vmwareId;
  public kubernetesId = environment.kubernetesId;
  public alibabaId = environment.alibabaId;
  public rancherId = environment.rancherId;

  public serachFormGroup!: FormGroup;

  public promoted = environment.promotedCategories;

  private subscriptions: Subscription[] = [];

  constructor(
    public homeData: HomeDataService,
    public router: Router,
    public translator: TranslateService,
  ) {}

  public submitSearch() {
    this.router.navigate(['catalog'], {
      queryParams: {
        searchText: this.serachFormGroup.get('search')?.value,
      }
    });
  }

  private calculateCurrentElementOfSubscriptionScroller() {
    const items = this.subscriptionScroller.nativeElement.querySelectorAll('.slide');
    this.currentPageSubscriptionScroller = Math.round(this.subscriptionScroller.nativeElement.scrollLeft/items[0].clientWidth);
  }

  private calculateCurrentElementOfPostScroller() {
    const items = this.postScroller.nativeElement.querySelectorAll('.slide');
    this.currentPagePostScroller = Math.round(this.postScroller.nativeElement.scrollLeft/items[0].clientWidth);
  }

  public ngAfterViewInit(): void {
    const scrollSubscriptions$ = fromEvent(this.subscriptionScroller.nativeElement, 'scroll').pipe(debounceTime(100)).subscribe(() => this.calculateCurrentElementOfSubscriptionScroller());
    this.subscriptions.push(scrollSubscriptions$);
    this.subscriptionScroller.nativeElement.addEventListener('resize', (ev: Event) => this.calculateCurrentElementOfSubscriptionScroller());

    const scrollPosts$ = fromEvent(this.postScroller.nativeElement, 'scroll').pipe(debounceTime(100)).subscribe(() => this.calculateCurrentElementOfPostScroller());
    this.subscriptions.push(scrollPosts$);
    this.postScroller.nativeElement.addEventListener('resize', (ev: Event) => this.calculateCurrentElementOfPostScroller());
  }

  public ngOnInit(): void {
    this.serachFormGroup = new FormGroup({
      search: new FormControl(''),
    });

    const loading$ = this.homeData.isLoadingDataChange.subscribe(value => {
      this.isLoading = value;
    });

    this.subscriptions.push(loading$);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  promoDotClickHandle(index: number) {
    const elementWidth = this.postScroller.nativeElement.children[0].clientWidth;
    this.postScroller.nativeElement.scrollTo({
      behavior: 'smooth',
      left: elementWidth * index + 1,
    })
  }

  subscriptionsDotClickHandle(index: number) {
    const elementWidth = this.subscriptionScroller.nativeElement.children[0].clientWidth;
    this.subscriptionScroller.nativeElement.scrollTo({
      behavior: 'smooth',
      left: elementWidth * index + 1,
    })
  }

  hasTranslation(id: string){
      const translation = this.translator.instant(id);
      return translation !== id && translation !== '';
  }

  numSequence(n: number) {
    return Array(n);
  }

  search(event: AutoCompleteCompleteEvent) {
      this.suggestions = [...Array(10).keys()].map(item => event.query + '-' + item);
  }
}
