import { Component } from '@angular/core';

@Component({
  selector: 'app-product-similar-page',
  templateUrl: './product-similar-page.component.html',
  styleUrls: ['./product-similar-page.component.scss']
})
export class ProductSimilarPageComponent {

}
