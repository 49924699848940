import { Component, OnInit } from '@angular/core';
import { ProductDataService } from '../product-data.service';

@Component({
  selector: 'app-product-environments-page',
  templateUrl: './product-environments-page.component.html',
  styleUrls: ['./product-environments-page.component.scss']
})
export class ProductEnvironmentsPageComponent implements OnInit {
  public onPremEnvironments: any[] = [];
  public cloudEnvironments: any[] = [];
  public containerEnvironments: any[] = [];
  public isLoading: boolean = false;

  constructor(
    private productData: ProductDataService,
  ) {}

  public ngOnInit(): void {
    this.productData.isLoadingDataChange.subscribe((value) => {
      this.isLoading = value;

      if(this.isLoading) return;

      for(let i=0; i < this.productData.environments.length; i++) {
        switch(this.productData.environments[i].type) {
          case 'CLOUD':
            this.cloudEnvironments.push(this.productData.environments[i]);
            break;
          case 'CONTAINERS':
            this.containerEnvironments.push(this.productData.environments[i]);
            break;
          case 'ON_PREM':
            this.onPremEnvironments.push(this.productData.environments[i]);
            break;
        }
      }
    });
  }
}
