import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { TemplateNameDirective } from '../template-name.directive';

@Component({
  selector: 'entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss']
})
export class EntityCardComponent implements AfterViewInit {

  @ContentChildren(TemplateNameDirective)
  templates!: QueryList<TemplateNameDirective>;

  headerTemplate!: TemplateRef<any>;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.headerTemplate = this.templates.get(0)?.templateRef!;
    this.cdr.detectChanges();
  }

  @Input({
    required: true,
  })
  public url!: string;

  @Input({
    required: true,
  })
  public logoUrl!: string;

  @Input({
    required: false,
  })
  public deploymentsCount: number | undefined;

  @Input({
    required: false,
  })
  public deployment: string | undefined;

  @Input({
    required: true,
  })
  public scarmRating!: number;

  @Input({
    required: true,
  })
  public lastScarmTrend!: number;

  @Input({
    required: true,
  })
  public name!: string;

  @Input({
    required: true
  })
  public summary!: string;

  @Input({
    required: true,
  })
  public starsCount!: number;

  @Input({
    required: false,
  })
  public isLiked: boolean = false;

  @Output()
  public onLike: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  like(event: MouseEvent) {
    this.onLike.emit(event)
  }

}
