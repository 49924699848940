import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserManager } from '../services/user-manager.service';

@Component({
  selector: 'app-activate-user-page',
  templateUrl: './activate-user-page.component.html',
  styleUrls: ['./activate-user-page.component.scss']
})
export class ActivateUserPageComponent implements OnInit {

  public isLoading: boolean = true;
  public errored: boolean = false;

  constructor(private route: ActivatedRoute, private userManger: UserManager) {}

  public async ngOnInit() {
    try {
      this.isLoading = true;
      const { activationTokenId } = await firstValueFrom(this.route.params)
  
      const response = await this.userManger.activateUser(activationTokenId);
    } catch (err) {
      this.errored = true;
    } finally {
      this.isLoading = false
    }
  }
}
