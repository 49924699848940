import { Injectable } from '@angular/core';
import { ApiClient } from './api-client.service';
import { HttpParams } from '@angular/common/http';
import { ProductResponse } from './product-response';
import { SingleProductAPIResponse } from './single-product-api-response';

@Injectable({
  providedIn: 'root'
})
export class ProductManager {
  constructor(
    private apiClient: ApiClient,
  ) {}

  public async getProductList(
    page: number,
    itemsPerPage: number,
    searchBy?: {
      tagIds?: string[],
      environmentIds?: string[],
      ratingValues?: number[],
      searchText?: string,
    },
    sortBy?: {
      name: string,
      direction: 'asc'|'desc',
    }
  ) {
    let params = new HttpParams();

    params = params.set('page', page + 1);
    params = params.set('itemsPerPage', itemsPerPage);

    if(searchBy?.environmentIds) {
      searchBy.environmentIds.forEach((environmentId) => {
        params = params.append('environments', environmentId);
      });
    }

    if(searchBy?.tagIds) {
      searchBy.tagIds.forEach((tagId) => {
        params = params.append('categories', tagId);
      });
    }

    if(searchBy?.searchText && searchBy?.searchText.length > 0) {
      params = params.append('search', searchBy.searchText)
    }

    if(searchBy?.ratingValues) {
      params = params.append('ratings', searchBy.ratingValues.join(','));
    }

    if(sortBy){
      params = params.append('sortBy', sortBy.name+':'+sortBy.direction)
    }
    return this.apiClient.get<{totalAmount: number, data: ProductResponse[]}>(`/products`, params);
  }

  public getProduct(id: string) {
    return this.apiClient.get<SingleProductAPIResponse>(`/products/${id}`);
  }

  public getProductCategories(itemsPerPage: number, page: number) {
    const params = new HttpParams()
      .append('itemsPerPage', itemsPerPage)
      .append('page', page);

    return this.apiClient.get<{ data: { id: string; name: string; }[]; total: number; }>(`/deployment-types`, params);
  }

  public getProductTags(itemsPerPage: number, page: number) {
    const params = new HttpParams()
      .append('itemsPerPage', itemsPerPage)
      .append('page', page);
    return this.apiClient.get<{ items: { id: string; name: string; }[]; total: number; }>('/product-tags', params);
  }

  public async getDeploymentsList(page: number, itemsPerPage: number, searchBy?: {
    environmentIds?: string[],
    tagIds?: string[],
    searchText?: string;
    ratingValues?: number[],
  },
    sortBy?: {
      name: string,
      direction: 'asc'|'desc',
    }) {
    let params = new HttpParams()
      .set('page', page + 1)
      .set('itemsPerPage', itemsPerPage);

    if(searchBy?.environmentIds) {
      searchBy.environmentIds.forEach((environmentId) => {
        params = params.append('environments', environmentId);
      });
    }

    if(searchBy?.tagIds) {
      searchBy.tagIds.forEach((tagId) => {
        params = params.append('categories', tagId);
      });
    }

    if(searchBy?.searchText && searchBy?.searchText.length > 0) {
      params = params.append('search', searchBy.searchText)
    }

    if(searchBy?.ratingValues) {
      params = params.append('ratings', searchBy.ratingValues.join(','));
    }

    if(sortBy){
      params = params.append('sortBy', sortBy.name+':'+sortBy.direction)
    }

    return this.apiClient.get<{totalAmount: number, data: {
      id: string;
      productId: string;
      name: string;
      logoUrl: string;
      summary: string;
      isLiked: boolean;
      scarmRating: number;
      lastScarmTrend: number;
      starsCount: number;
      type: string;
      deployment: string,
    }[]}>(`/deployments`, params);
  }

  public async getDeployment(deploymentId: string) {

    return this.apiClient.get<{data: {
      id: string;
      productId: string;
      name: string;
      logoUrl: string;
      summary: string;
      isLiked: boolean;
      scarmRating: number;
      starsCount: number;
      type: string;
      deployment: string,
    }}>(`/deployments/${deploymentId}`);
  }
}
