<div class="flex flex-column mt-7 gap-7">
  <div class="flex flex-column gap-5">
    <div class="flex flex-row justify-content-between align-items-center">
      <span class="text-blue-darker font-bold text-3xl w-full">
        {{ 'CLIENT.DEPLOYMENT.REPORT.TITLE' | translate }}
      </span>
      <span class="flex flex-row justify-content-between md:justify-content-end gap-3 w-full min-w-0" #exportSelect>
          <div>
            <i class="pi pi-spin pi-spinner" *ngIf="isExporting"></i>
          </div>
          <p-dropdown
            [options]="exportOptions"
            optionLabel="name"
            (onHide)="removeOverflow()"
            [overlayOptions]="{mode: isMobile ? 'modal' : 'overlay'}"
            styleClass="bg-blue-light border-blue-light-hover h-full max-w-20rem"
            (onChange)="onTriggerExport($event)"
          >
              <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2 justify-content-between text-grey-dark">
                      <span>{{ 'CLIENT.DEPLOYMENT.REPORT_SECTION.EXPORT_OPTIONS.TITLE' | translate }}</span>
                  </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                  <div class="flex align-items-center gap-2 justify-content-between text-grey-dark">
                      <div>{{ option.translationId | translate }}</div>
                  </div>
              </ng-template>
          </p-dropdown>
      </span>
<!--      <p-skeleton styleClass="w-14rem" height="2.75rem" *ngIf="isLoading"></p-skeleton>-->
<!--      <p-button styleClass="bg-transparent border-blue-normal gap-2" *ngIf="!isLoading">-->
<!--        <i class="icon-16 icon-download bg-blue-normal"></i>-->
<!--        <span class="font-bold text-sm text-blue-normal">-->
<!--          {{ 'CLIENT.DEPLOYMENT.REPORT.DOWNLOAD_REPORT_AS_PDF' | translate }}-->
<!--        </span>-->
<!--      </p-button>-->
    </div>
    <span class="text-grey-dark-hover w-8">
      {{ 'CLIENT.DEPLOYMENT.REPORT.DESCRIPTION' | translate }}
    </span>
  </div>

  <div class="flex flex-column gap-5">
    <div class="flex flex-row align-items-center justify-content-between">
      <span class="font-semibold text-lg text-blue-darker">
        {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.TITLE' | translate }}
      </span>
      <help-button helpText="CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.HELP_TEXT"></help-button>
    </div>
<!--    <div class="flex flex-row justify-content-end gap-2" *ngIf="isLoading">-->
<!--      <p-skeleton height="1.5rem" width="8rem"></p-skeleton>-->
<!--      <p-skeleton height="1.5rem" width="15rem"></p-skeleton>-->
<!--    </div>-->
<!--    <div class="flex flex-row justify-content-end gap-2" *ngIf="!isLoading">-->
<!--      <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
<!--        <i class="icon-16 icon-export bg-blue-normal"></i>-->
<!--        <span class="font-bold text-sm text-blue-normal">-->
<!--          {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.EXPORT' | translate }}-->
<!--        </span>-->
<!--      </p-button>-->
<!--      <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
<!--        <i class="icon-16 icon-filter bg-blue-normal"></i>-->
<!--        <span class="font-bold text-sm text-blue-normal">-->
<!--          {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.COLLAPSE_DETAILS' | translate }}-->
<!--        </span>-->
<!--      </p-button>-->
<!--    </div>-->

    <p-skeleton styleClass="w-full mt-2" height="40rem" *ngIf="isLoading"></p-skeleton>
    <p-table [value]="deploymentData.components" dataKey="name" *ngIf="!isLoading" [expandedRowKeys]="openedKeys">
      <ng-template pTemplate="header">
          <tr>
              <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
                <div class="flex flex-row align-items-center gap-2">
                  <i class="icon-16 icon-lines bg-black-black-100"></i>
                  <span>
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.HEADER.COMPONENT_NAME' | translate }}
                  </span>
                </div>
              </th>
              <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
                {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.HEADER.DETAILS' | translate }}
              </th>
              <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
                <div class="flex flex-row align-items-center gap-2">
                  <span>
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.HEADER.SCORE' | translate }}
                  </span>
                </div>
              </th>
              <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-2">
                {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.HEADER.SEVERITY' | translate }}
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-component let-expanded="expanded">
          <tr [pRowToggler]="component" class="cursor-pointer" [class.bg-blue-light-hover]="component.isLeadingComponent === true">
              <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover">
                <div class="flex flex-row gap-2 align-items-center flex-wrap ">
                  <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"></i>
                  <i class="icon-code icon-16 bg-black-black-100"></i>
                  <span class="font-bold">
                    {{ component.name }}
                  </span>
                </div>
              </td>
              <td>
                <a routerLink="/component/{{ component.name }}" class="no-underline flex flex-row gap-2 text-black-black-100 align-items-center">
                  <span class="text-sm font-bold">
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.GO_TO_COMPONENT' | translate }}
                  </span>
                  <i class="icon-16 icon-link bg-black-black-100"></i>
                </a>
              </td>
              <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
                <div class="flex flex-row gap-2">
                  <i class="icon-round-star icon-16 bg-black-black-100"></i>
                  <span>
                    {{ component.scores.scarm * 10 | number: '1.1-1' }}
                  </span>
                </div>
              </td>
              <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
                <div class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
                  [class.bg-severity-critical]="component.scores.scarm * 10 >= 0 && component.scores.scarm * 10 < 1"
                  [class.bg-severity-high]="component.scores.scarm * 10 >= 1 && component.scores.scarm * 10 < 3"
                  [class.bg-severity-medium]="component.scores.scarm * 10 >= 3 && component.scores.scarm * 10 < 6"
                  [class.bg-severity-low]="component.scores.scarm * 10 >= 6 && component.scores.scarm * 10 < 8"
                  [class.bg-severity-no-risk]="component.scores.scarm * 10 >= 8 && component.scores.scarm * 10 <= 10"
                >
                  <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 0 && component.scores.scarm * 10 < 1">
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.CRITICAL' | translate }}
                  </span>
                  <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 1 && component.scores.scarm * 10 < 3">
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.HIGH' | translate }}
                  </span>
                  <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 3 && component.scores.scarm * 10 < 6">
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.MEDIUM' | translate }}
                  </span>
                  <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 6 && component.scores.scarm * 10 < 8">
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.LOW' | translate }}
                  </span>
                  <span class="text-white text-nowrap" *ngIf="component.scores.scarm * 10 >= 8 && component.scores.scarm * 10 <= 10">
                    {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.SAFE' | translate }}
                  </span>
                </div>
              </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-component>
        <ng-container *ngFor="let key of getObjectKeys(component.scores)" >
          <tr *ngIf="key !== 'scarm' && ((component.scores[key] != null && (key!='vulnerability')) || (component.scores[key] > 0 ))">
            <td colspan="2" class="pl-6 cursor-pointer border-x-1 border-bottom-1 border-blue-light-hover">
              <div class="flex flex-row gap-2 align-items-center ">
                <i class="icon-16 bg-grey-darker"
                   [class.icon-code]="key == 'codeQuality'"
                   [class.icon-vulnerability]="key == 'vulnerability'"
                   [class.icon-time]="key == 'activity'"
                   [class.icon-people]="key == 'contributor'"
                   [class.icon-branch]="key == 'languageCoverage'"
                ></i>
                <span class="text-sm">
                  {{ 'CLIENT.RATING.' + key.toUpperCase() + '.NAME' | translate }}
                </span>
              </div>
            </td>
            <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
              <div class="flex flex-row gap-2">
                <i class="icon-round-star icon-16 bg-black-black-100"></i>
                <span>
                  {{ component.scores[key] * 10 | number: '1.1-1' }}
                </span>
              </div>
            </td>
            <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
              <div class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
                [class.bg-severity-critical]="component.scores[key] * 10 >= 0 && component.scores[key] * 10 < 1"
                [class.bg-severity-high]="component.scores[key] * 10 >= 1 && component.scores[key] * 10 < 3"
                [class.bg-severity-medium]="component.scores[key] * 10 >= 3 && component.scores[key] * 10 < 6"
                [class.bg-severity-low]="component.scores[key] * 10 >= 6 && component.scores[key] * 10 < 8"
                [class.bg-severity-no-risk]="component.scores[key] * 10 >= 8 && component.scores[key] * 10 <= 10"
              >
                <span class="text-white text-nowrap" *ngIf="component.scores[key] * 10 >= 0 && component.scores[key] * 10 < 1">
                  {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.CRITICAL' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="component.scores[key] * 10 >= 1 && component.scores[key] * 10 < 3">
                  {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.HIGH' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="component.scores[key] * 10 >= 3 && component.scores[key] * 10 < 6">
                  {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.MEDIUM' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="component.scores[key] * 10 >= 6 && component.scores[key] * 10 < 8">
                  {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.LOW' | translate }}
                </span>
                <span class="text-white text-nowrap" *ngIf="component.scores[key] * 10 >= 8 && component.scores[key] * 10 <= 10">
                  {{ 'CLIENT.DEPLOYMENT.REPORT.COMPONENTS_TABLE.BODY.SEVERITY.SAFE' | translate }}
                </span>
              </div>
            </td>
          </tr>
        </ng-container>
    </ng-template>
  </p-table>

  <p-paginator
    (onPageChange)="onPageChange($event)"
    [first]="deploymentData.componentPage * deploymentData.componentsPerPage"
    [rows]="deploymentData.componentsPerPage"
    [totalRecords]="deploymentData.numberOfComponentsPages * deploymentData.componentsPerPage"
    *ngIf="!isLoading">
  </p-paginator>

  </div>


  <div class="flex flex-column gap-5">
    <div class="flex flex-row align-items-center justify-content-between">
      <span class="font-semibold text-lg text-blue-darker">
        {{ 'CLIENT.DEPLOYMENT.REPORT.CONTRIBUTION_MAP.TITLE' | translate }}
      </span>
      <help-button helpText="CLIENT.DEPLOYMENT.REPORT.CONTRIBUTION_MAP.HELP_TEXT"></help-button>
    </div>
    <span class="text-grey-dark-hover">
      {{ 'CLIENT.DEPLOYMENT.REPORT.CONTRIBUTION_MAP.DESCRIPTION' | translate }}
    </span>
  </div>
    <div>
    <app-contribution-map
      *ngIf="!isLoading"
      [contributions]="deploymentData.contributions">
    </app-contribution-map>
  </div>

  <div class="flex flex-column gap-4">
    <div class="flex flex-row align-items-center justify-content-between">
      <span class="font-semibold text-lg text-blue-darker">
        {{ 'CLIENT.DEPLOYMENT.REPORT.PROJECT_ACTIVITY.DESCRIPTION' | translate }}
      </span>
      <help-button helpText="CLIENT.DEPLOYMENT.REPORT.PROJECT_ACTIVITY.HELP_TEXT"></help-button>
    </div>
    <p-skeleton styleClass="w-full" height="5rem" *ngIf="isLoading"></p-skeleton>
    <div class="flex flex-row bg-blue-light border-1 border-grey-normal border-round-lg" *ngIf="!isLoading">

      <div class="flex flex-column gap-2 w-4 p-3">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-commits icon-16 bg-blue-darker"></i>
          <span class="font-bold text-base text-blue-darker">
            {{ 'CLIENT.DEPLOYMENT.REPORT.PROJECT_ACTIVITY.COMMITS_AMOUNT' | translate }}
          </span>
        </div>
        <div class="pl-4 text-grey-normal-active font-medium">
          {{ deploymentData.commitsCount ? (deploymentData.commitsCount | splitNumber: ' ') : ('CLIENT.NO_DATA' | translate) }}
        </div>
      </div>

      <div class="flex flex-column gap-2 w-4 p-3">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-commits icon-16 bg-blue-darker"></i>
          <span class="font-bold text-base text-blue-darker">
            {{ 'CLIENT.DEPLOYMENT.REPORT.PROJECT_ACTIVITY.OPEN_ISSUES_AMOUNT' | translate }}
          </span>
        </div>
        <div class="pl-4 text-grey-normal-active font-medium">
          {{ deploymentData.openIssuesCount ? (deploymentData.openIssuesCount | splitNumber: ' ') : ('CLIENT.NO_DATA' | translate) }}
        </div>
      </div>

      <div class="flex flex-column gap-2 w-4 p-3">
        <div class="flex flex-row gap-2 align-items-center">
          <i class="icon-commits icon-16 bg-blue-darker"></i>
          <span class="font-bold text-base text-blue-darker">
            {{ 'CLIENT.DEPLOYMENT.REPORT.PROJECT_ACTIVITY.CLOSED_ISSUES_AMOUNT' | translate }}
          </span>
        </div>
        <div class="pl-4 text-grey-normal-active font-medium">
          {{ deploymentData.closedIssuesCount ? (deploymentData.closedIssuesCount | splitNumber: ' ') : ('CLIENT.NO_DATA' | translate) }}
        </div>
      </div>

    </div>
  </div>

  <div class="flex flex-column gap-3">
    <div class="flex flex-row align-items-center justify-content-between">
      <span class="font-semibold text-lg text-blue-darker">
        {{ 'CLIENT.DEPLOYMENT.REPORT.TOP_5_CONTRIBUTORS.TITLE' | translate }}
      </span>
      <help-button helpText="CLIENT.DEPLOYMENT.REPORT.TOP_5_CONTRIBUTORS.HELP_TEXT"></help-button>
    </div>

    <div class="flex flex-column md:flex-row gap-4">
      <p-skeleton styleClass="w-full flex-grow-1" class="w-full flex-grow-1" height="10rem" *ngIf="isLoading"></p-skeleton>
      <p-skeleton styleClass="w-full" class="w-9" height="10rem" *ngIf="isLoading"></p-skeleton>
      <div class="flex w-full flex-grow-1" *ngIf="!isLoading">
        <p-table class="w-full" [value]="getObjectKeys(deploymentData.contributions) | slice:0:5">
          <ng-template pTemplate="header">
            <tr class="bg-transparent">
              <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm">
                <div class="flex flex-row gap-2">
                  <i class="icon-16 icon-location bg-black-black-100"></i>
                  <span>
                    {{ 'CLIENT.DEPLOYMENT.REPORT.TOP_5_CONTRIBUTORS.TABLE.HEADER.LOCATION' | translate }}
                  </span>
                </div>
              </th>
              <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm">
                <div class="flex flex-row gap-2">
                  <i class="icon-16 icon-people bg-black-black-100"></i>
                  <span>
                    {{ 'CLIENT.DEPLOYMENT.REPORT.TOP_5_CONTRIBUTORS.TABLE.HEADER.CONTRIBUTORS' | translate }}
                  </span>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-key let-rowIndex="rowIndex">
            <tr>
              <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-black-black-100">
                {{ key }}
              </td>
              <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
                {{ (deploymentData.contributions[key] / deploymentData.contributionsSum) * 100 | number: '1.0-0' }}% ({{ deploymentData.contributions[key]  | splitNumber: ',' }})
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <app-chart
        [inputData]="topContributors"
        [chartId]="'contributors'"
        class="flex flex-row flex-grow-1 md:w-9 h-19rem"
        *ngIf="!isLoading"
      ></app-chart>
    </div>
  </div>

  <div class="flex flex-column gap-3">
    <div class="flex flex-row align-items-center justify-content-between">
      <span class="font-semibold text-lg text-blue-darker">
        {{ 'CLIENT.DEPLOYMENT.REPORT.PROGRAMMING_LANGUAGES.TITLE' | translate }}
      </span>
      <help-button helpText="CLIENT.DEPLOYMENT.REPORT.PROGRAMMING_LANGUAGES.HELP_TEXT"></help-button>
    </div>

    <div class="flex flex-column md:flex-row gap-4">
      <p-skeleton styleClass="w-full flex-grow-1" class="w-full flex-grow-1" height="10rem" *ngIf="isLoading"></p-skeleton>
      <p-skeleton styleClass="w-full" class="w-9" height="10rem" *ngIf="isLoading"></p-skeleton>
      <div class="flex w-full flex-grow-1" *ngIf="!isLoading">
        <p-table class="w-full" [value]="deploymentData.programmingLanguages.slice(0, 5)" *ngIf="!isLoading">
          <ng-template pTemplate="header">
            <tr class="bg-transparent">
              <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm">
                <div class="flex flex-row align-items-center gap-2">
                  <i class="icon-globe icon-16 bg-black-black-100"></i>
                  <span>
                    {{ 'CLIENT.DEPLOYMENT.REPORT.PROGRAMMING_LANGUAGES.HEADER.LANGUAGE' | translate }}
                  </span>
                </div>
              </th>
              <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm">
                <div class="flex flex-row align-items-center gap-2">
                  <i class="icon-lines icon-16 bg-black-black-100"></i>
                  <span>
                    {{ 'CLIENT.DEPLOYMENT.REPORT.PROGRAMMING_LANGUAGES.HEADER.PERCENT' | translate }}
                  </span>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-entry let-rowIndex="rowIndex">
            <tr>
              <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-black-black-100">
                {{ entry.name }}
              </td>
              <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
                {{ (entry.value / deploymentData.totalCodeLines) * 100 | number: '1.0-0' }}% ({{ entry.value | splitNumber: ','}} LOC)
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <app-chart
      [inputData]="topProgrammingLangs"
      [chartId]="'languages'"
      class="flex flex-row flex-grow-1 md:w-9 h-19rem"
      *ngIf="!isLoading"
      ></app-chart>
    </div>
  </div>


</div>
