<div class="flex flex-row justify-content-center pt-4 mb-8">
    <div class="container flex-grow-1 flex flex-row flex-1 flex-wrap align-items-start gap-4 px-5 md:px-0 min-w-0">
        <div class="hidden md:flex flex-column border-1 border-blue-light-hover px-4 py-5 border-round-lg w-21rem min-w-21rem max-w-21rem gap-5 bg-white">
            <ng-template [ngTemplateOutlet]="filtersTemplate"></ng-template>
            <ng-template #filtersTemplate>
                <!-- <div>
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText placeholder="{{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.SEARCH.PLACEHOLDER' | translate }}" class="w-full bg-white border-blue-light-hover py-3 pr-3" />
                    </span>
                </div> -->
                <div class="gap-6 flex flex-column">
                    <div class="flex flex-column gap-4">
                        <div class="flex flex-row align-items-center gap-2">
                            <i class="icon-briefcase icon-16 bg-blue-darker"></i>
                            <span class="text-blue-darker font-bold">
                                {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.DEPLOYMENT_TYPES.TITLE' | translate }}
                            </span>
                        </div>
                        <div class="flex flex-column gap-3" *ngIf="!isLoading">
                            <div class="flex flex-row gap-2" *ngFor="let tag of productCatalogData.productTags; let index = index;">
                                <p-checkbox inputId="{{'tag_' + index}}" [value]="tag.id" [(ngModel)]="productCatalogData.tags" (onChange)="productCatalogData.toggleTag($event)"></p-checkbox>
                                <label for="{{'tag_' + index}}" class="text-grey-dark-hover">{{ tag.name | translate }}</label>
                            </div>
                        </div>
                        <div class="flex flex-column gap-3 cursor-wait" *ngIf="isLoading">
                            <div class="flex flex-row gap-2 align-items-center" *ngFor="let checkbox of [0,0,0,0,0]">
                                <p-skeleton shape="square" size="22px"></p-skeleton>
                                <p-skeleton height="22px" styleClass="w-full" class="w-full"></p-skeleton>
                            </div>
                        </div>
                        <!-- <a href="#" class="no-underline font-bold text-grey-dark px-3 py-12-px text-center border-y-1 border-blue-light">Pokaż więcej (10)</a> -->
                    </div>
                    <div class="flex flex-column gap-4">
                        <div class="flex flex-row align-items-center gap-2">
                            <i class="icon-star icon-16 bg-blue-darker"></i>
                            <span class="text-blue-darker font-bold">
                                {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.RATING.TITLE' | translate }}
                            </span>
                        </div>
                        <div class="flex flex-column gap-2">
                            <div class="px-2" id="test">
                                <p-slider #ratingSliderId [(ngModel)]="productCatalogData.ratingValues" [range]="true" [min]="0" [max]="10" [step]="0.1" (onSlideEnd)="productCatalogData.onSlideEnd()" (onChange)="onSlideChange()"></p-slider>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column gap-4">
                        <div class="flex flex-row align-items-center gap-2">
                            <i class="icon-document icon-16 bg-blue-darker"></i>
                            <span class="text-blue-darker font-bold">
                                {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.PRODUCT_TYPE.TITLE' | translate }}
                            </span>
                        </div>
                        <div class="gap-3 flex flex-column">
                            <div class="flex flex-row gap-2 align-items-center">
                                <p-radioButton name="produtcs" value="products" [(ngModel)]="productCatalogData.type" inputId="products" (onClick)="onTypeChanged($event)"></p-radioButton>
                                <label for="products" class="text-grey-dark-hover">
                                    {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.PRODUCT_TYPE.PRODUCTS' | translate }}
                                </label>
                            </div>
                            <div class="flex flex-flex gap-2 align-items-center">
                                <p-radioButton name="produtcs" value="deployments" [(ngModel)]="productCatalogData.type" inputId="deployments" (onClick)="onTypeChanged($event)"></p-radioButton>
                                <label for="deployments" class="text-grey-dark-hover">
                                    {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.PRODUCT_TYPE.DEPLOYMENTS' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column gap-4">
                        <div class="flex flex-row align-items-center gap-2">
                            <i class="icon-similar icon-16 bg-blue-darker"></i>
                            <span class="text-blue-darker font-bold">
                                {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.PRODUCT_CATEGORIES.TITLE' | translate }}
                            </span>
                        </div>
                        <div class="flex flex-column gap-3" *ngIf="!isLoading">
                            <div class="flex flex-row gap-2" *ngFor="let category of productCatalogData.deploymentTypes">
                                <p-checkbox [value]="category.id" [inputId]="category.id" [(ngModel)]="productCatalogData.environments" (onChange)="productCatalogData.toggleEnvironment($event)"></p-checkbox>
                                <label [for]="category.id" class="text-grey-dark-hover">{{ category.name | translate }}</label>
                            </div>
                        </div>
                        <div class="flex flex-column gap-3 cursor-wait" *ngIf="isLoading">
                            <div class="flex flex-row gap-2 align-items-center" *ngFor="let checkbox of [0,0,0,0,0,0,0,0]">
                                <p-skeleton shape="square" size="22px"></p-skeleton>
                                <p-skeleton height="22px" styleClass="w-full" class="w-full"></p-skeleton>
                            </div>
                        </div>
                        <!-- <a href="#" class="no-underline font-bold text-grey-dark px-3 py-12-px text-center border-y-1 border-blue-light">Pokaż więcej (10)</a> -->
                    </div>
                    <!-- <div class="flex flex-column gap-4">
                        <div class="flex flex-row align-items-center gap-2">
                            <i class="icon-briefcase icon-16 bg-blue-darker"></i>
                            <span class="text-blue-darker font-bold">
                                Właściciel
                            </span>
                        </div>
                        <div class="flex flex-column gap-3 cursor-wait" *ngIf="!isLoading">
                            <div class="flex flex-row gap-2">
                                <p-checkbox value="New York" inputId="ny"></p-checkbox>
                                <label for="ny" class="text-grey-dark-hover">New York</label>
                            </div>
                        </div>
                        <div class="flex flex-column gap-3" *ngIf="isLoading">
                            <div class="flex flex-row gap-2 align-items-center" *ngFor="let checkbox of [0,0,0,0,0]">
                                <p-skeleton shape="square" size="22px"></p-skeleton>
                                <p-skeleton height="22px" styleClass="w-full" class="w-full"></p-skeleton>
                            </div>
                        </div>
                        <a href="#" class="no-underline font-bold text-grey-dark px-3 py-12-px text-center border-y-1 border-blue-light">Pokaż więcej (10)</a>
                    </div> -->
                    <!-- <div class="flex flex-column gap-4">
                        <div class="flex flex-row align-items-center gap-2">
                            <i class="icon-people icon-16 bg-blue-darker"></i>
                            <span class="text-blue-darker font-bold">
                                {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.CONTRIBUTORS.TITLE' | translate }}
                            </span>
                        </div>
                        <div>
                            <span class="p-input-icon-left w-full">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText placeholder="Szukaj po ID lub e-mailu" class="w-full border-blue-light-hover py-3 pr-3 bg-blue-light text-grey-dark" />
                            </span>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="flex flex-column gap-4">
                    <div class="flex flex-row gap-2 align-items-center">
                        <i class="icon-globe icon-16 bg-blue-darker"></i>
                        <span class="font-bold text-blue-darker">
                            {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.LANGUAGES.TITLE' | translate }}
                        </span>
                    </div>
                    <div class="flex flex-column gap-3" *ngIf="!isLoading">
                        <div class="flex flex-row gap-2" *ngFor="let language of languages">
                            <p-checkbox value="New York" inputId="ny"></p-checkbox>
                            <label for="ny" class="text-grey-dark-hover">{{ language }}</label>
                        </div>
                    </div>
                    <div class="flex flex-column gap-3 cursor-wait" *ngIf="isLoading">
                        <div class="flex flex-row gap-2 align-items-center" *ngFor="let checkbox of [0,0,0,0,0]">
                            <p-skeleton shape="square" size="22px"></p-skeleton>
                            <p-skeleton height="22px" styleClass="w-full" class="w-full"></p-skeleton>
                        </div>
                    </div>
                    <a href="#" class="no-underline font-bold text-grey-dark px-3 py-12-px text-center border-y-1 border-blue-light">
                        {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.BUTTON.SHOW_MORE' | translate }} (10)
                    </a>
                </div> -->
            </ng-template>
        </div>

        <div class="flex flex-column flex-grow-1 gap-6 min-w-0" style="flex: 1 0 0;">
            <div class="flex flex-column gap-4 min-w-0">
                <div class="flex flex-row pt-1">
                    <span class="text-blue-darker font-bold text-3xl">
                        {{ 'CLIENT.PRODUCT_CATALOG.TITLE' | translate }}
                    </span>
                </div>
                <div>
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText class="w-full bg-blue-light-hover border-blue-light-active text-blue-dark py-4 pr-3" [(ngModel)]="productCatalogData.searchText" (ngModelChange)="productCatalogData.searchTextChange($event)" placeholder="{{ 'CLIENT.PRODUCT_CATALOG.SEARCH_PLACEHOLDER' | translate }}" />
                    </span>
                </div>
                <div class="flex flex-row justify-content-between md:justify-content-end gap-3 w-full min-w-0" #sortSelect>
                    <p-button styleClass="flex flex-row gap-2 py-3 px-4 font-bold" [outlined]="true" class="flex md:hidden min-w-0" (onClick)="filterSidebarVisible = !filterSidebarVisible">
                        <i class="pi pi-filter"></i>
                        {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.FILTERS.TITLE' | translate }}
                    </p-button>
                    <p-dropdown
                      [options]="filters"
                      [(ngModel)]="selectedFilter"
                      optionLabel="name"
                      (onHide)="removeOverflow()"
                      [appendTo]="sortSelect"
                      [overlayOptions]="{mode: isMobile ? 'modal' : 'overlay'}"
                      (onChange)="onSortChange($event)"
                      placeholder="{{ 'CLIENT.PRODUCT_CATALOG.FILTER_BUTTON.PLACEHOLDER' | translate }}"
                      styleClass="bg-blue-light border-blue-light-hover h-full max-w-20rem"
                    >
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2 justify-content-between text-grey-dark" *ngIf="selectedFilter">
                                <span>{{ 'CLIENT.PRODUCT_CATALOG.FILTER_BUTTON.TITLE' | translate }} {{ selectedFilter.translationId | translate }}</span>
                                <i class="pi pi-sort-amount-down" [class.pi-sort-amount-down]="selectedFilter.order === 'desc'" [class.pi-sort-amount-down-alt]="selectedFilter.order === 'asc'"></i>
                            </div>
                        </ng-template>
                        <ng-template let-filter pTemplate="item">
                            <div class="flex align-items-center gap-2 justify-content-between text-grey-dark">
                                <div>{{ filter.translationId | translate}}</div>
                                <i class="pi pi-sort-amount-down" [class.pi-sort-amount-down]="filter.order === 'desc'" [class.pi-sort-amount-down-alt]="filter.order === 'asc'"></i>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <!-- Loading -->
            <div class="flex flex-row align-items-start align-content-start gap-4 align-self-stretch flex-wrap" *ngIf="isLoading">
                <div class="border-1 border-blue-light-hover bg-white border-round-lg flex flex-column w-21rem cursor-wait" *ngFor="let fake of [0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, 0,0,0, ]; let index = index">
                    <div class="flex flex-row p-3 justify-content-between border-bottom-1 border-blue-light">
                        <p-skeleton styleClass="max-w-7rem w-full" class="max-w-7rem w-full"></p-skeleton>
                        <p-skeleton styleClass="max-w-7rem w-full" class="max-w-7rem w-full"></p-skeleton>
                    </div>
                    <div class="flex flex-row px-3 py-4 gap-3">
                        <div class="flex flex-column align-items-center">
                            <p-skeleton shape="circle" size="5.25rem" styleClass=""></p-skeleton>
                        </div>
                        <div class="flex flex-column gap-3 w-full">
                            <p-skeleton styleClass="max-w-7rem w-full" class="max-w-7rem w-full"></p-skeleton>
                            <p-skeleton height="4rem" styleClass="w-full"></p-skeleton>
                        </div>
                    </div>
                    <div class="flex flex-row justify-content-between align-items-center px-3 py-2 border-top-1 border-blue-light">
                        <p-skeleton styleClass="max-w-5rem w-full" height="1.5rem" class="max-w-7rem w-full"></p-skeleton>
                        <p-skeleton size="1.5rem"></p-skeleton>
                    </div>
                </div>
            </div>

            <div class="flex flex-row align-items-stretch align-content-start gap-4 align-self-stretch flex-wrap" *ngIf="!isLoading">
                <ng-container *ngFor="let product of productCatalogData.products; let index = index">
                    <product-card *ngIf="product.type == 'product'" [product]="product" (onLike)="likeProduct(product, $event)"></product-card>
                    <deployment-card *ngIf="product.type == 'deployment'" [deployment]="product" (onLike)="likeDeployment(product, $event)"></deployment-card>
                </ng-container>
            </div>

            <div class="flex flex-row justify-content-center">
                <div class="flex flex-row gap-2" *ngIf="isLoading">
                    <p-skeleton size="3rem"></p-skeleton>
                    <p-skeleton size="3rem"></p-skeleton>
                    <p-skeleton size="3rem"></p-skeleton>
                    <p-skeleton size="3rem"></p-skeleton>
                </div>

                <p-paginator (onPageChange)="onPageChange($event)" [first]="productCatalogData.page * productCatalogData.itemsPerPage" [rows]="productCatalogData.itemsPerPage" [totalRecords]="productCatalogData.totalNumber" *ngIf="!isLoading"></p-paginator>
            </div>
        </div>
    </div>
</div>

<p-sidebar [(visible)]="filterSidebarVisible" position="left" [modal]="true" [fullScreen]="true" [blockScroll]="true" styleClass="bg-special-background min-w-0">
    <ng-template pTemplate="header">
        <div class="flex flex-row overflow-x-hidden min-w-0 mr-4">
            <span class="text-xl font-semibold">
                {{ 'CLIENT.PRODUCT_CATALOG.SIDEBAR.FILTERS.TITLE' | translate }}
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="content" [ngTemplateOutlet]="filtersTemplate"></ng-template>
</p-sidebar>
