import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ComponentDataService } from './component-data.service';
import { CountriesDataService } from "../countries-data.service";
import { LikeType } from "../services/LikeType";
import { LikesManager } from "../services/likes-manager.service";
import { Message, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { AddedToFavourites, AddedToFavouritesError, RemovedFromFavourites } from "../messages";


@Component({
  selector: 'app-component-page',
  templateUrl: './component-page.component.html',
  styleUrls: ['./component-page.component.scss'],
  providers: [ComponentDataService],
})
export class ComponentPageComponent implements OnInit, AfterViewInit {
  public scoresWithTrends!: Record<string, [number, number]>;
  public scoresOrder: string[] = [
    'code',
    'vulnerability',
    'activity',
    'contributor',
    'language',
  ];
  public numberOfPositiveScores: number = 0;
  public isLoading: boolean = true;

  public topContributors: {
    name: string;
    value: number;
    pictureSettings: object;
  }[] = [];
  public topProgrammingLangs: {
    name: string;
    value: number;
    pictureSettings: object;
  }[] = [];

  public displayAllScores: boolean = false;

  constructor(
    public data: ComponentDataService,
    public countriesDataService: CountriesDataService,
    public likesManager: LikesManager,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.data.isLoadingDataChange.subscribe((value) => {
      this.isLoading = value;
    });
  }

  public ngAfterViewInit() {
    this.data.isLoadingDataChange.subscribe((value) => {
      if (value) return;

      this.isLoading = value;

      this.scoresWithTrends = {
        code: [this.data.codeQualityRating, this.data.lastTrends.codeQuality],
        vulnerability: [
          this.data.vulnerabilityRating,
          this.data.lastTrends.vulnerability,
        ],
        activity: [this.data.activityRating, this.data.lastTrends.activity],
        contributor: [
          this.data.contributorRating,
          this.data.lastTrends.contributor,
        ],
        language: [
          this.data.languageCoverageRating,
          this.data.lastTrends.languageCoverage,
        ],
      };
      for (const [key, value] of this.getObjectEntries(this.scoresWithTrends)) {
        if (value[0] === null || (value[0] == 0 && key == 'vulnerability')) {
          const index = this.scoresOrder.indexOf(key, 0);
          if (index > -1) {
            this.scoresOrder.splice(index, 1);
          }
        }
      }
      this.numberOfPositiveScores = this.scoresOrder.length;

      this.topContributors = Object.entries(this.data.contributionsList)
        .slice(0, 5)
        .map(([key, value]) => ({
          name: key,
          value,
          pictureSettings: {
            src: (() => {
              const country =
                this.countriesDataService.countriesAlpha3.get(key);
              return `/assets/flags/${country?.['alpha-2'].toLocaleLowerCase()}.svg`;
            })(),
          },
        }));

      this.topProgrammingLangs = this.data.languages
        .slice(0, 5)
        .map((data) => ({
          name: data.name,
          value: data.value,
          pictureSettings: {
            src: (() => {
              switch (data.name) {
                default:
                  return `/assets/languages/${data.name.toLocaleLowerCase()}.svg`;
              }
            })(),
          },
        }));
    });
  }

  public getObjectEntries(instance: object) {
    return Object.entries(instance);
  }

  public showAllScores() {
    this.displayAllScores = true;
  }

  // TODO: severity should be handled in ComponentDataService
  public setSeverity(score: number) {
    let multipliedScore = score * 10;

    if (multipliedScore < 1) {
      return 'CRITICAL';
    } else if (multipliedScore < 3) {
      return 'HIGH';
    } else if (multipliedScore < 6) {
      return 'MEDIUM';
    } else if (multipliedScore < 8) {
      return 'LOW';
    } else {
      return 'SAFE';
    }
  }

  public async likeComponent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    let message: Message;
    try {
      if (!this.data.isLiked) {
        await this.likesManager.like(this.data.name, LikeType.COMPONENT);
        this.data.isLiked = true;
        message = AddedToFavourites;
      } else {
        await this.likesManager.removeLike(this.data.name);
        this.data.isLiked = false;
        message = RemovedFromFavourites;
      }
    } catch (err) {
      console.error(err);
      message = AddedToFavouritesError;
    }
    this.messageService.add({
      severity: message.severity,
      summary: this.translate.instant(message.summary!),
      detail: this.translate.instant(message.detail!),
    });
  }
}
