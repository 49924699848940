import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiClient } from '../services/api-client.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DeploymentDataService {
  public productId!: string;
  public deploymentId!: string;

  public isLoadingDataChange: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );
  public componentPage = 0;
  // if you want to change componentsPerPage, you need to change it in backend too
  public componentsPerPage = 10;
  public numberOfComponentsPages: number = 0;

  public name!: string;
  public summary!: string;
  public logoUrl!: string;
  public description!: string;

  public deploymentVersion!: string;
  public releaseDate: Date | undefined;
  public creationDate: Date | undefined;
  public lastActivityDate: Date | undefined;
  public sourceUrl: string | undefined;
  public maintainer: string | undefined;

  public generalRating: number = 1;
  public activityRating: number = 1;
  public codeRating: number = 1;
  public contributorRating: number = 1;
  public vulnerabilityRating: number = 1;
  public languageCoverageRating: number = 1;

  public lastTrends: {
    scarm: number;
    codeQuality: number;
    contributor: number;
    activity: number;
    vulnerability: number;
    languageCoverage: number;
  } = {
    scarm: 0,
    codeQuality: 0,
    contributor: 0,
    activity: 0,
    vulnerability: 0,
    languageCoverage: 0,
  };

  public openIssuesCount: number | null = null;
  public closedIssuesCount: number | null = null;

  public commitsCount: number | null = null;

  public rawContributions!: Record<string, number>;

  public downloadUrl!: string;

  public components: {
    name: string;
    isLeadingComponent: boolean;
    scores: {
      activity: number;
      codeQuality: number;
      contributor: number;
      scarm: number;
      vulnerability: number;
      languageCoverage: number;
    };
  }[] = [];

  public documentations: {
    name: string;
    address: string;
  }[] = [];

  public instructions: Record<string, any> = {};

  public tags!: {
    id: string;
    name: string;
  }[];
  public traits!: string[];

  public contributions!: Record<string, number>;
  public contributionsSum!: number;

  public programmingLanguages!: {
    name: string;
    value: number;
  }[];

  public totalCodeLines!: number;

  constructor(
    private route: ActivatedRoute,
    private apiClient: ApiClient,
  ) {
    this.fetchData();
  }

  private async fetchData() {
    const params = await firstValueFrom(this.route.paramMap);

    const productId = params.get('product_id');

    if (!productId) throw new Error(`CANT_GET_PRODUCT_ID`);

    this.productId = productId;

    const deploymentId = params.get('deployment_id');

    if (!deploymentId) throw new Error(`CANT_GET_DEPLOYMENT_ID`);

    this.deploymentId = deploymentId;

    const deploymentData = await this.apiClient.get<{
      id: string;
      name: string;
      summary: string;
      logoUrl: string;
      description: string;
      productVersion: string;
      releaseDate: string;
      creationDate: string;
      lastActivityDate: string;
      sourceUrl: string;
      maintainer: string;
      commitsCount: number;
      documentations: {
        name: string;
        address: string;
      }[];
      instructions: Record<string, object>;
      components: {
        name: string;
        isLeadingComponent: boolean;
        scores: {
          activity: number;
          codeQuality: number;
          contributor: number;
          scarm: number;
          vulnerability: number;
          languageCoverage: number;
        };
      }[];
      numberOfComponentsPages: number;
      scores: {
        scarm: number;
        codeQuality: number;
        contributor: number;
        activity: number;
        vulnerability: number;
        languageCoverage: number;
      };
      lastTrends: {
        scarm: number;
        codeQuality: number;
        contributor: number;
        activity: number;
        vulnerability: number;
        languageCoverage: number;
      };
      programmingLanguagesList: {
        name: string;
        value: number;
      }[];
      totalCodeLines: number;
      sourceType: string;
      starsCount: string;
      followersCount: string;
      downloadUrl: string;
      version: string;
      default: boolean;
      typeName: string;
      type: string;
      traits: string[];
      contributionsList: Record<string, number>;
      openIssuesCount: number;
      closedIssuesCount: number;
      tags: {
        id: string;
        name: string;
      }[];
    }>(`/products/${productId}/deployments/${deploymentId}`);

    if (!deploymentData) throw new Error(`Can't fetch deployment data`);

    this.downloadUrl = deploymentData.downloadUrl;

    this.name = deploymentData.name;
    this.summary = deploymentData.summary;
    this.logoUrl = deploymentData.logoUrl;
    this.description = deploymentData.description;

    this.deploymentVersion = deploymentData.productVersion;
    this.releaseDate = deploymentData.releaseDate
      ? new Date(deploymentData.releaseDate)
      : undefined;
    this.creationDate = deploymentData.creationDate
      ? new Date(deploymentData.creationDate)
      : undefined;
    this.lastActivityDate = deploymentData.lastActivityDate
      ? new Date(deploymentData.lastActivityDate)
      : undefined;
    this.sourceUrl = deploymentData.sourceUrl;

    this.documentations = deploymentData.documentations;

    this.instructions = deploymentData.instructions;

    this.rawContributions = deploymentData.contributionsList;

    this.generalRating = deploymentData.scores.scarm;

    this.activityRating = deploymentData.scores.activity;

    this.codeRating = deploymentData.scores.codeQuality;

    this.contributorRating = deploymentData.scores.contributor;

    this.vulnerabilityRating = deploymentData.scores.vulnerability;

    this.languageCoverageRating = deploymentData.scores.languageCoverage;

    this.lastTrends = deploymentData.lastTrends;

    this.tags = deploymentData.tags;

    this.traits = deploymentData.traits;

    this.commitsCount = deploymentData.commitsCount;

    this.contributions = deploymentData.contributionsList;

    this.contributionsSum = deploymentData.contributionsList['_SUM'];

    this.programmingLanguages = deploymentData.programmingLanguagesList;

    this.totalCodeLines = deploymentData.totalCodeLines;

    delete this.contributions['_SUM'];

    this.components = deploymentData.components.map((component) => ({
      name: component.name,
      isLeadingComponent: component.isLeadingComponent,
      scores: component.scores,
    }));

    this.openIssuesCount = deploymentData.openIssuesCount;
    this.closedIssuesCount = deploymentData.closedIssuesCount;

    this.numberOfComponentsPages = deploymentData.numberOfComponentsPages;

    this.isLoadingDataChange.next(false);
  }

  public async getComponentsPage(pageNumber: number) {
    let params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('itemsPerPage', this.componentsPerPage);

    const components: {
      components: {
        name: string;
        isLeadingComponent: boolean;
        scores: {
          activity: number;
          codeQuality: number;
          contributor: number;
          scarm: number;
          vulnerability: number;
          languageCoverage: number;
        };
      }[];
    } = await this.apiClient.get(
      `products/${this.productId}/deployments/${this.deploymentId}/components`,
      params,
    );

    this.components = components.components.map((component) => ({
      name: component.name,
      isLeadingComponent: component.isLeadingComponent,
      scores: component.scores,
    }));
  }
}
