import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { debounceTime, fromEvent, Observable, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-score-circle',
  templateUrl: './score-circle.component.html',
  styleUrls: ['./score-circle.component.scss'],
})
export class ScoreCircleComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true })
  score: number = 0;

  @Input({ required: true })
  trend: number = 1;

  @Input()
  radius: number = 50;

  @Input()
  outerStrokeColor?: string;

  @Input()
  outerStrokeGradientStopColor?: string;

  @Input()
  helpText: string = '';

  @ViewChild('op')
  overlayPanel: OverlayPanel | undefined;

  @ViewChild('hoverElement')
  hoverElement!: ElementRef<HTMLDivElement>;

  public title: string = 'My title';
  public innerStrokeWidth: number = 0;
  public outerStrokeWidth: number = 0;
  public space: number = 0;
  public titleFontSize: number = 0;
  public trendTop: number = 0;
  public trendRight: number = 0;

  private $mouseLeave!: Subscription;
  private oMouseLeave!: Observable<MouseEvent>
  private $mouseEnter!: Subscription;
  private oMouseEnter!: Observable<MouseEvent>

  ngOnInit() {
    this.innerStrokeWidth = (22 * this.radius) / 50;
    this.outerStrokeWidth = (14 * this.radius) / 50;
    this.space = (-18 * this.radius) / 50;
    this.titleFontSize = (22 * this.radius) / 50;
    this.trendRight = this.radius * 1.1;
    this.trendTop = this.radius + this.titleFontSize * 1.1;
  }

  private initializeOverlay() {
    this.$mouseEnter = this.oMouseEnter
      .pipe(takeUntil(this.oMouseLeave))
      .pipe(debounceTime(1000))
      .subscribe((event: MouseEvent) => {
        this.overlayPanel?.show(event);
      });
  }

  ngAfterViewInit() {
    this.oMouseEnter = fromEvent<MouseEvent>(this.hoverElement.nativeElement, 'mouseenter');
    this.oMouseLeave = fromEvent<MouseEvent>(this.hoverElement.nativeElement, 'mouseleave');

    this.initializeOverlay();

    this.$mouseLeave = this.oMouseLeave
      ?.subscribe(() => {
        this.$mouseEnter.unsubscribe();
        this.overlayPanel?.hide();
        this.initializeOverlay();
      })
  }

  ngOnDestroy() {
    if(this.$mouseEnter) this.$mouseEnter.unsubscribe();
    if(this.$mouseLeave) this.$mouseLeave.unsubscribe();
  }
}
