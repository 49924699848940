import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CatalogFilter } from './catalog-filter';
import { ProductCatalogDataService } from './product-catalog-data.service';
import { ActivatedRoute } from '@angular/router';
import { LikesManager } from '../services/likes-manager.service';
import { LikeType } from '../services/LikeType';
import { Deployment, Product } from './Entity';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { Message, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { AddedToFavourites, AddedToFavouritesError, RemovedFromFavourites } from "../messages";
import { DOCUMENT } from '@angular/common';
import { Slider } from "primeng/slider";

@Component({
  selector: 'app-product-catalog-page',
  templateUrl: './product-catalog-page.component.html',
  styleUrls: ['./product-catalog-page.component.scss'],
  providers: [ProductCatalogDataService]
})
export class ProductCatalogPageComponent implements OnInit, AfterViewInit {

  @ViewChild('ratingSliderId', {static: false}) private ratingSliderRef!: Slider;

  fakeArray = new Array(20);
  searchString: string | undefined;
  filterSidebarVisible: boolean = false;
  isMobile: boolean = window.innerWidth < 768;
  filters: CatalogFilter[] = [
    {
      name: 'Unordered',
      order: undefined,
      propertyName: undefined,
      translationId: 'CLIENT.PRODUCT_CATALOG.SORT_BY.UNORDERED',
    },
    {
      name: 'Rating',
      order: 'desc',
      propertyName: 'scarmScore',
      translationId: 'CLIENT.PRODUCT_CATALOG.SORT_BY.RATING.DESC',
    },
    {
      name: 'Rating',
      order: 'asc',
      propertyName: 'scarmScore',
      translationId: 'CLIENT.PRODUCT_CATALOG.SORT_BY.RATING.ASC',
    },
  ];
  selectedFilter?: CatalogFilter = this.filters[0];
  isLoading: boolean = true;

  constructor(
    public productCatalogData: ProductCatalogDataService,
    public route: ActivatedRoute,
    public likesManager: LikesManager,
    private messageService: MessageService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit() {
    this.productCatalogData.isLoadingDataChange.subscribe(value => this.isLoading = value);
  }

  public ngAfterViewInit() {
    this.ratingSliderSetup();
  }

  onTypeChanged(event: RadioButtonClickEvent) {
    this.productCatalogData.page = 0;
    this.productCatalogData.refreshData();
  }

  public removeOverflow() {
    this.document.body.classList.remove('p-overflow-hidden');
  }

  public async likeProduct(product: Product, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    let message: Message;
    try {
      if(!product.isLiked) {
        await this.likesManager.like(product.id, LikeType.PRODUCT);
        product.isLiked = true;
        message = AddedToFavourites
      } else {
        await this.likesManager.removeLike(product.id);
        product.isLiked = false;
        message = RemovedFromFavourites
      }
    } catch (err) {
      console.error(err);
      message = AddedToFavouritesError
    }
    this.messageService.add({
      severity: message.severity,
      summary: this.translate.instant(message.summary!),
      detail: this.translate.instant(message.detail!)
    })
  }

  public async likeDeployment(deployment: Deployment, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    let message: Message;
    try {
      if(!deployment.isLiked) {
        await this.likesManager.like(deployment.id, LikeType.DEPLOYMENT, deployment.productId);
        deployment.isLiked = true;
        message = AddedToFavourites
      } else {
        await this.likesManager.removeLike(deployment.id);
        deployment.isLiked = false;
        message = RemovedFromFavourites
      }
    } catch (err) {
      console.error(err);
      message = AddedToFavouritesError
    }
    this.messageService.add({
      severity: message.severity,
      summary: this.translate.instant(message.summary!),
      detail: this.translate.instant(message.detail!)
    })
  }

  public onPageChange(event: {
    first?: number | undefined;
    rows?: number | undefined;
    page?: number | undefined;
    pageCount?: number | undefined;
  }) {
    if(typeof event.page !== 'number') return;
    this.productCatalogData.page = event.page;
    this.productCatalogData.refreshData();
  }

  public onSortChange(event: {
    value: {
      name: string,
      propertyName: string|undefined,
      order: 'asc'|'desc'|undefined
    }}) {
    if (event.value.propertyName && event.value.order) {
      this.selectedFilter = this.filters.filter(filter => (filter.name === event.value.name && filter.order === event.value.order))[0]
      this.productCatalogData.sortBy = {
        name: event.value.propertyName,
        direction: event.value.order
      }
    } else {
      this.productCatalogData.sortBy = undefined;
    }
    this.productCatalogData.page = 0;
    this.productCatalogData.refreshData();
  }

  public async ratingSliderSetup() {
    const minRating = 0;
    const maxRating = 10;
    this.ratingSliderRef.sliderHandleStart!.nativeElement.innerHTML = '<div class="text-grey-dark" style="position: relative; bottom: -18px">' +
        minRating.toString() + '</div>';
    this.ratingSliderRef.sliderHandleEnd!.nativeElement.innerHTML = '<div class="text-grey-dark" style="position: relative; bottom: -18px">' +
        maxRating.toString() + '</div>';
  }

  public async onSlideChange() {
    const minRating = this.ratingSliderRef.values![0];
    const maxRating = this.ratingSliderRef.values![1];

    const sliderStartRef = this.ratingSliderRef.sliderHandleStart;
    const sliderEndRef = this.ratingSliderRef.sliderHandleEnd;
    if (minRating >= 0){
      sliderStartRef!.nativeElement.innerHTML = '<div class="text-grey-dark" style="position: relative; bottom: -18px">' +
        minRating.toString() + '</div>';
    }
    if (maxRating <= 10){
      sliderEndRef!.nativeElement.innerHTML = '<div class="text-grey-dark" style="position: relative; bottom: -18px">' +
        maxRating.toString() + '</div>';
    }
  }
}
