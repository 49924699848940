import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as products from 'src/assets/products.json';

interface Product {
  id: string;
  name: string;
  logoUrl: string;
  tags: {
    id: string;
    name: string;
  }[];
  summary: string;
  screenshotUrls: string[];
  description: string;
  environments: {
    id: string;
    name: string;
    environment: string;
    type: string;
    description: string;
    summary: string;
    sourceUrl: string;
    ratings: {
      name: string;
      type: 'CODE' | 'VULNERABILITY' | 'ACTIVITY' | 'CONTRIBUTOR' | 'GENERAL';
      rating: number;
    }[];
    documentation: {
      name: string;
      url: string;
    }[];
    traits: string[];
    components: string[];
    projectStats: {
      stars: string;
      followers: string;
      forks: string;
    },
    programmingLangs: {
      name: string;
      value: number;
    }[];
    contributors: {
      name: string;
      value: number;
    }[];
    productVersion: string;
    creationDate: string;
    lastActivityDate: string;
    owner: string;
    releaseDate: string;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class ProductRepository {

  products: {
    id: string;
    name: string;
    logoUrl: string;
    screenshotUrls: string[];
    tags: {
      id: string;
      name: string;
    }[];
    summary: string;
    description: string;
    environments: {
      id: string;
      name: string;
      environment: string;
      type: string;
      description: string;
      summary: string;
      sourceUrl: string;
      ratings: {
        name: string;
        type: 'CODE' | 'VULNERABILITY' | 'ACTIVITY' | 'CONTRIBUTOR' | 'GENERAL';
        rating: number;
      }[];
      documentation: {
        name: string;
        url: string;
      }[];
      traits: string[];
      components: string[];
      projectStats: {
        stars: string;
        followers: string;
        forks: string;
      },
      programmingLangs: {
        name: string;
        value: number;
      }[];
      contributors: {
        name: string;
        value: number;
      }[];
      productVersion: string;
      creationDate: string;
      lastActivityDate: string;
      owner: string;
      releaseDate: string;
    }[];
  }[] = [];

  constructor(
    private httpClient: HttpClient,
  ) {
    this.products = (<any> products).default;
  }

  public getProducts() {
    return new Promise<Product[]>((resolve) => {
      setTimeout(() => {
        resolve(this.products);
      }, 1500);
    });
  }

  public getProduct(productId: string) {
    return new Promise<Product>((resolve) => {
      setTimeout(() => {
        resolve(
          this.products.find((product) => {
            return product.id == productId;
          })!
        );
      }, 1500);
    });
  }

  public getDeployment(productId: string, deploymentId: string) {
    const product = this.products.find((product) => product.id == productId);
    const deployment = product?.environments.find((deployment) => deployment.id == deploymentId);
    return deployment;
  }

  public getDeployments() {}

  public getFiltersData() {
    return {
      categories: [
        "System operacyjny",
        "Bazy danych",
      ],
      environments: [
        "Linux Server",
        "VMWare Workstation",
        "VMWare VCenter",
        "Virtual Box",
        "Kubernetes",
        "Docker",
        "Rancher",
        "OpenShift",
        "GCP",
        "AWS",
        "Azure",
        "Alibaba",
      ],
      languages: [
        "C++",
        "GoLang",
        "C#",
      ]
    };
  }
}
