<div class="flex flex-row justify-content-center pt-5 md:pt-7 mb-6 md:mb-8 relative">
    <div class="container flex-grow-1 flex flex-row flex-wrap align-items-start z-5 px-4 md:px-0">
        <div class="flex flex-column md:flex-row w-full gap-144px search-section">
            <div class="flex flex-column gap-4 flex-grow-1">
                <p class="m-0 text-5xl md:text-7xl font-superbold text-blue-darker" [innerHTML]="'CLIENT.HOME.SEARCH.TITLE' | translate"></p>
                <div class="flex flex-column gap-3">
                    <p class="font-semibold text-xl text-grey-dark m-0 mt-5" [innerHTML]="'CLIENT.HOME.SEARCH.SUMMARY' | translate"></p>
                </div>
                <div>
                    <form class="p-input-icon-left w-full" [formGroup]="serachFormGroup" (ngSubmit)="submitSearch()">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText class="w-full bg-blue-light-hover border-blue-light-active text-blue-dark" formControlName="search" placeholder="{{ 'CLIENT.HOME.SEARCH.PLACEHOLDER' | translate }}" />
                    </form>
                </div>
                <div class="flex flex-row flex-wrap gap-3">
                    <a routerLink="/catalog" [queryParams]="{tags: element.id}" class="flex flex-row border-1 border-round-lg pl-2 py-2 border-grey-normal bg-black-white no-underline" style="padding-right: 12px; gap: 6px;" *ngFor="let element of promoted">
                        <img [src]="element.iconUrl" style="width: 20px; max-height: 20px;" *ngIf="element.iconUrl">
                        <span class="text-grey-dark">
                            {{ element.name[translator.currentLang.toUpperCase()] }}
                        </span>
                    </a>
                </div>
            </div>
            <div class="flex-grow-1 mt-6 md:mt-0">
                <img src="assets/hero.png" alt="" style="width: 100%;">
            </div>
        </div>
    </div>
    <img src="/assets/decoration_pixels.svg" class="absolute left-0 z-0">
    <img src="/assets/decoration_pixels.svg" class="absolute right-0 z-0" style="transform: scaleX(-1); bottom: -40px;">
</div>

<div class="flex flex-column align-items-center relative" style="margin-top: 410px;">
    <!-- <div class="absolute skew-top-right-lg bg-blue-dark-hover top-0 left-0 right-0 bottom-0"></div> -->
    <div class="container flex flex-column w-full align-items-center z-5 px-4 md:px-0" style="margin-top: -300px;">
        <div class="flex flex-row align-items-center gap-2">
            <img src="assets/flame.png" alt="top products" class="h-full">
            <p class="font-bold text-5xl text-blue-darker" [innerHTML]="'CLIENT.HOME.TOP_LIST.TITLE' | translate"></p>
        </div>
        <div class="border-round-4xl border-1 border-blue-light-active bg-blue-light flex flex-column w-full p-4 md:p-6 border-round-lg gap-6 mt-6 md:mt-3">
            <div class="flex flex-column md:flex-row flex-wrap gap-4" *ngIf="!isLoading">
                <a routerLink="/products/{{product.id}}" class="flex flex-grow-1 flex-row gap-2 w-full product-card no-underline justify-content-between" *ngFor="let product of homeData.topProducts; let i = index">
                    <div class="flex flex-column justify-content-evenly overflow-hidden">
                        <p class="m-0 text-xl font-semibold text-blue-darker overflow-hidden text-overflow-ellipsis">{{ product.name | translate }}</p>
                        <p class="m-0 text-blue-darker webkit-box line-clamp-2 overflow-hidden webkit-box-vertical">{{ product.summary | translate }}</p>
                    </div>
                    <app-score-circle
                      [score] = "product.scarmScore"
                      [trend] = "product.lastScarmTrend"
                      [radius]="45"
                      [outerStrokeColor]="'#2079E2'"
                      [outerStrokeGradientStopColor]="'#134988'"
                      [attr.data-testid]="'product-link-'+i"
                    >
                    </app-score-circle>
                </a>
            </div>

            <div class="flex flex-row flex-wrap gap-4" *ngIf="isLoading">
                <div class="flex flex-grow-1 flex-row gap-4 product-card" *ngFor="let item of [0,0,0, 0,0,0, 0,0,0]">
                    <div class="flex flex-column justify-content-evenly w-full">
                        <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
                        <p-skeleton styleClass="w-full" class="w-full" height="3rem"></p-skeleton>
                    </div>
                    <p-skeleton shape="circle" size="6rem" styleClass=""></p-skeleton>
                </div>
            </div>

            <div class="flex flex-row justify-content-center align-items-center" *ngIf="!isLoading && homeData.topProducts.length == 0">
                <span class="font-bold text-4xl text-blue-darker">{{ 'CLIENT.HOME.TOP_LIST.NOT_FOUND_PRODUCTS' | translate }}</span>
            </div>

            <div class="flex flex-row justify-content-center w-full">
                <a routerLink="/catalog" data-testid="see-all-button" class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg">{{ 'CLIENT.HOME.TOP_LIST.ACTION_BUTTON' | translate }}</a>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-column justify-content-center align-items-center pt-7 mb-8 bg-blue-dark-hover skew-top-right" style="margin-top: -180px;">
    <div class="container w-full flex flex-column" style="padding-bottom: 60px; padding-top: 180px;">
        <div class="mb-8 z-5 w-10 environments-section">
            <p class="font-bold text-5xl md:text-6xl text-white" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.TITLE' | translate"></p>
            <p class="text-blue-light text-lg w-11" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.DESCRIPTION' | translate"></p>
        </div>
        <div class="flex flex-column md:flex-row gap-4 relative align-items-center px-4 md:px-0">
            <a routerLink="/catalog" [queryParams]="{environments: gcpIds}" class="flex flex-column flex-grow-1 bg-blue-light-hover border-round-lg p-4 z-5 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--                 <img src="assets/logos/google.svg" alt="">-->
                <p class="deployment-name">Google Cloud</p>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.GCP_DESCRIPTION' | translate"></p>
                </div>
            </a>
            <a routerLink="/catalog" [queryParams]="{environments: awsIds}" class="flex flex-column flex-grow-1 bg-blue-light-hover border-round-lg p-4 z-5 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--            <img src="assets/logos/aws.svg" alt="" width="118">-->
                <div>
                    <p class="deployment-name">AWS</p>
                </div>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.AWS_DESCRIPTION' | translate"></p>
                </div>
            </a>
            <a routerLink="/catalog" [queryParams]="{environments: azureIds}" class="flex flex-column flex-grow-1 bg-blue-light-hover border-round-lg p-4 z-5 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--                <img src="assets/logos/microsoft.svg" alt="">-->
                <div>
                    <p class="deployment-name">Microsoft Azure</p>
                </div>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.AZURE_DESCRIPTION' | translate"></p>
                </div>
            </a>
            <a routerLink="/catalog" [queryParams]="{environments: dockerIds}" class="flex flex-column flex-grow-1 bg-blue-light-hover border-round-lg p-4 z-5 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--                <img src="assets/logos/docker.svg" alt="">-->
                <div>
                    <p class="deployment-name">Docker</p>
                </div>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.DOCKER_DESCRIPTION' | translate"></p>
                </div>
            </a>
            <img src="/assets/decoration_pixels.svg" class="absolute w-10rem align-self-center" style="right: -10rem; transform: scaleY(-1);">
        </div>
        <div class="flex flex-column md:flex-row gap-4 mt-4 relative align-items-center px-4 md:px-0">
            <img src="/assets/decoration_pixels.svg" class="absolute w-10rem align-self-center z-0" style="left: -10rem; transform: scaleX(-1);">
            <a routerLink="/catalog" [queryParams]="{environments: vmwareIds}" class="flex flex-column flex-grow-1 bg-blue-light-hover  z-5 border-round-lg p-4 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--                <img src="assets/logos/vmware.svg" alt="">-->
                <div>
                    <p class="deployment-name">VMware</p>
                </div>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.VMWARE_DESCRIPTION' | translate"></p>
                </div>
            </a>
            <a routerLink="/catalog" [queryParams]="{environments: kubernetesId}" class="flex flex-column flex-grow-1 bg-blue-light-hover border-round-lg p-4 z-5 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--                <img src="assets/logos/kubernetes.svg" alt="">-->
                <div>
                    <p class="deployment-name">Kubernetes</p>
                </div>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.KUBERNETES_DESCRIPTION' | translate"></p>
                </div>
            </a>
            <a routerLink="/catalog" [queryParams]="{environments: rancherId}" class="flex flex-column flex-grow-1 bg-blue-light-hover border-round-lg p-4 z-5 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--                <img src="assets/logos/rancher.svg" alt="">-->
                <div>
                    <p class="deployment-name">Rancher</p>

                </div>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.RANCHER_DESCRIPTION' | translate"></p>
                </div>
            </a>
            <a routerLink="/catalog" [queryParams]="{environments: alibabaId}" class="flex flex-column flex-grow-1 bg-blue-light-hover border-round-lg p-4 z-5 environment-card justify-content-around align-items-start md:align-items-center no-underline">
                <!--                <img src="assets/logos/alibaba.svg" alt="">-->
                <div>
                    <p class="deployment-name">Alibaba</p>
                </div>
                <div class="hidden md:block">
                    <p class="mt-4 mb-0 text-blue-darker" [innerHTML]="'CLIENT.HOME.DEPLOYMENTS.ALIBABA_DESCRIPTION' | translate"></p>
                </div>
            </a>
        </div>
    </div>
</div>

<div class="flex flex-row justify-content-center pt-4 mb-6 relative">
    <img src="/assets/decoration_pixels.svg" class="absolute w-10rem right-0 z-0" style="transform: scaleY(-1) scaleX(-1);">
    <div class="container w-full flex flex-column z-5 md:px-px-160">
        <div class="mb-3 mx-4 md:mx-0">
            <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.HOME.PROS.TITLE' | translate }}</p>
        </div>
        <div class="flex flex-row gap-4 overflow-auto flex-nowrap w-full px-4 slider" #postScroller>
            <!-- style="flex-basis: 30%;" -->
            <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-auto slide">
                <img src="assets/shield-check.svg" width="90" alt="">
                <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.HOME.PROS.FIRST_CARD.TITLE' | translate }}</p>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.HOME.PROS.FIRST_CARD.DESCRIPTION' | translate"></p>
            </div>
            <!-- style="flex-basis: 40%;" -->
            <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-auto slide">
                <img src="assets/comment-info.svg" width="90" alt="" class="align-self-center">
                <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.HOME.PROS.SECOND_CARD.TITLE' | translate }}</p>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.HOME.PROS.SECOND_CARD.DESCRIPTION' | translate"></p>
            </div>
            <!-- style="flex-basis: 30%;" -->
            <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-auto slide">
                <img src="assets/hands-usd.svg" width="90" alt="">
                <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.HOME.PROS.THIRD_CARD.TITLE' | translate }}</p>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.HOME.PROS.THIRD_CARD.DESCRIPTION' | translate"></p>
            </div>
        </div>
        <div class="md:hidden flex flex-row gap-4 justify-content-center mt-3">
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="promoDotClickHandle(0)" [ngClass]="{'bg-blue-normal': currentPagePostScroller == 0}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="promoDotClickHandle(1)" [ngClass]="{'bg-blue-normal': currentPagePostScroller == 1}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="promoDotClickHandle(2)" [ngClass]="{'bg-blue-normal': currentPagePostScroller == 2}"></div>
        </div>
    </div>
</div>

<div class="flex flex-column justify-content-center pt-4 skew-top-right-lg bg-blue-light-hover align-items-center pb-6 mb-8">
    <!-- px-px-160 -->
    <div class="container w-full flex flex-column md:pt-180px md:px-px-160 px-4">
        <p class="font-bold text-5xl md:text-6xl text-blue-darker mb-0">{{ 'CLIENT.HOME.BLOG.TITLE' | translate }}</p>
        <div class="flex flex-column gap-6">
            <div class="hidden md:flex mt-8 flex-row gap-7">
                <div class="cover">
                    <img src="assets/home_holistic-and-clear-report.jpg" alt="" class="w-27rem border-round-lg">
                </div>
                <div>
                    <p class="font-bold text-3xl">{{ 'CLIENT.HOME.BLOG.FIRST_POST.TITLE' | translate }}</p>
                    <p class="text-grey-dark" [innerHTML]="'CLIENT.HOME.BLOG.FIRST_POST.DESCRIPTION' | translate"></p>
                </div>
            </div>
            <div class="flex md:hidden flex-column gap-4 mt-6">
                <div class="flex flex-column gap-3">
                    <div>
                        <img src="assets/home_holistic-and-clear-report.jpg" alt="" class="border-round-lg" style="width: 100%; height: 100%;">
                    </div>
                    <p class="font-bold text-lg m-0">{{ 'CLIENT.HOME.BLOG.FIRST_POST.TITLE' | translate }}</p>
                </div>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.HOME.BLOG.FIRST_POST.DESCRIPTION' | translate"></p>
            </div>
            <div class="w-full border-top-1 border-blue-light-active"></div>
            <div class="hidden md:flex flex-row gap-7">
                <div>
                    <p class="font-bold text-3xl">{{ 'CLIENT.HOME.BLOG.SECOND_POST.TITLE' | translate}}</p>
                    <p class="text-grey-dark" [innerHTML]="'CLIENT.HOME.BLOG.SECOND_POST.DESCRIPTION' | translate"></p>
                </div>
                <div class="cover">
                    <img src="assets/home_customization-of-scoring.jpg" alt="" class="w-27rem border-round-lg">
                </div>
            </div>
            <div class="flex md:hidden flex-column gap-4">
                <div class="flex flex-column-reverse md:flex-row gap-3 justify-content-between">
                    <p class="font-bold text-lg m-0">{{ 'CLIENT.HOME.BLOG.SECOND_POST.TITLE' | translate }}</p>
                    <div>
                        <img src="assets/home_customization-of-scoring.jpg" alt="" class="border-round-lg" style="width: 100%; height: 100%;">
                    </div>
                </div>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.HOME.BLOG.SECOND_POST.DESCRIPTION' | translate"></p>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-row justify-content-center pt-4 align-items-center pb-6 mb-8">
    <!-- px-px-160 -->

    <div class="container w-full flex flex-column z-5 md:px-px-160">
        <div class="mb-3 mx-4 md:mx-0">
            <p class="font-bold text-5xl md:text-6xl text-blue-darker">{{ 'CLIENT.HOME.SUBSCRIPTIONS.TITLE' | translate }}</p>
            <p class="text-grey-dark text-lg w-11" [innerHTML]="'CLIENT.HOME.SUBSCRIPTIONS.DESCRIPTION' | translate"></p>
        </div>
        <div class="flex flex-row gap-4 overflow-auto align-items-stretch flex-nowrap p-5 w-full slider" #subscriptionScroller>
            <!-- Free -->
            <div class="flex flex-none md:flex-auto flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-full md:w-3 gap-4 slide">
                <div class="flex flex-column gap-6">
                    <div class="flex flex-column gap-5">
                        <div class="flex flex-column gap-2 overflow-hidden">
                            <span class="font-semibold text-xl text-center text-grey-dark"> {{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.BASIC.TITLE' | translate }} </span>
                            <span class="font-bold text-3xl text-center text-blue-darker">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.BASIC.NAME' | translate }}</span>
                        </div>
                        <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center overflow-hidden">
                            <span class="text-xl pl-3 font-semibold" [innerHTML]="'CLIENT.HOME.SUBSCRIPTIONS.PLAN.BASIC.DETAILS' | translate"></span>
                        </div>
                    </div>
                    <div  class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
                        <ng-container  *ngFor="let n of numSequence(12); let i = index;">
                            <div *ngIf="hasTranslation('CLIENT.HOME.SUBSCRIPTIONS.PLAN.BASIC.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                                <i class="flex-shrink-0 icon-check icon-24 bg-blue-normal"></i>
                                <span class="font-normal text-blue-darker">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.BASIC.POINT_'+i | translate }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="flex flex-column mt-auto align-items-center">
                    <a routerLink="/about-us"
                       fragment="contact"
                       [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.PLAN.BASIC.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.PLAN.BASIC.MESSAGE'}"
                       class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg overflow-hidden"
                    >
                      {{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.BASIC.OFFER_BUTTON' | translate }}
                    </a>
                </div>
            </div>

            <!-- Standard -->
            <div class="flex flex-none md:flex-auto flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-full md:w-3 gap-4 slide">
                <div class="flex flex-column gap-6">
                    <div class="flex flex-column gap-5">
                        <div class="flex flex-column gap-2 overflow-hidden">
                            <span class="font-semibold text-xl text-center text-grey-dark">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ADVANCED.TITLE' | translate }} </span>
                            <span class="font-bold text-3xl text-center text-blue-darker">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ADVANCED.NAME' | translate }}</span>
                        </div>
                        <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center overflow-hidden">
                            <span class="text-xl pl-3 font-semibold" [innerHTML]="'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ADVANCED.DETAILS' | translate"></span>
                        </div>
                    </div>
                    <div  class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
                        <ng-container  *ngFor="let n of numSequence(12); let i = index;">
                            <div *ngIf="hasTranslation('CLIENT.HOME.SUBSCRIPTIONS.PLAN.ADVANCED.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                                <i class="flex-shrink-0 icon-check icon-24 bg-blue-normal"></i>
                                <span class="font-normal text-blue-darker">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ADVANCED.POINT_'+i | translate }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="flex flex-column mt-auto align-items-center">
                    <a routerLink="/about-us"
                       fragment="contact"
                       [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.PLAN.ADVANCED.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.PLAN.ADVANCED.MESSAGE'}"
                       class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg overflow-hidden"
                    >
                      {{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ADVANCED.OFFER_BUTTON' | translate }}
                    </a>
                </div>
            </div>

            <!-- Business -->
            <div class="flex flex-none md:flex-auto flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-full md:w-3 gap-4 slide">
                <div class="flex flex-column gap-6">
                    <div class="flex flex-column gap-5">
                        <div class="flex flex-column gap-2 overflow-hidden">
                            <span class="font-semibold text-xl text-center text-grey-dark">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ENTERPRISE.TITLE' | translate }}</span>
                            <span class="font-bold text-3xl text-center text-blue-darker">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ENTERPRISE.NAME' | translate }}</span>
                        </div>
                        <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center overflow-hidden">
                            <span class="text-xl pl-3 font-semibold" [innerHTML]="'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ENTERPRISE.DETAILS' | translate"></span>
                        </div>
                    </div>
                    <div  class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
                        <ng-container  *ngFor="let n of numSequence(12); let i = index;">
                            <div *ngIf="hasTranslation('CLIENT.HOME.SUBSCRIPTIONS.PLAN.ENTERPRISE.POINT_'+i)" class="flex flex-row gap-3 align-items-center">
                                <i class="flex-shrink-0 icon-check icon-24 bg-blue-normal"></i>
                                <span class="font-normal text-blue-darker">{{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ENTERPRISE.POINT_'+i | translate }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="flex flex-column mt-auto align-items-center">
                    <a routerLink="/about-us"
                       fragment="contact"
                       [queryParams]="{topicId: 'CONTACT_US.AUTOCOMPLETE.PLAN.ENTERPRISE.TOPIC', messageId: 'CONTACT_US.AUTOCOMPLETE.PLAN.ENTERPRISE.MESSAGE'}"
                       class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg overflow-hidden"
                    >
                        {{ 'CLIENT.HOME.SUBSCRIPTIONS.PLAN.ENTERPRISE.OFFER_BUTTON' | translate }}
                    </a>
                </div>
            </div>
        </div>
        <div class="md:hidden flex flex-row gap-4 justify-content-center mt-3">
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="subscriptionsDotClickHandle(0)" [ngClass]="{'bg-blue-normal': currentPageSubscriptionScroller == 0}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="subscriptionsDotClickHandle(1)" [ngClass]="{'bg-blue-normal': currentPageSubscriptionScroller == 1}"></div>
            <div style="width: 16px; height: 16px;" class="bg-blue-light-active border-circle" (click)="subscriptionsDotClickHandle(2)" [ngClass]="{'bg-blue-normal': currentPageSubscriptionScroller == 2}"></div>
        </div>
    </div>
</div>
