import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[templateName]'
})
export class TemplateNameDirective {
  @Input('templateName') templateName!: string;

  constructor(public templateRef: TemplateRef<any>) {
    if (!templateRef) {
      throw new Error('TemplateRef is required.');
    }
  }

}
