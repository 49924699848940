import { Component } from '@angular/core';
import { ProductDataService } from '../product-data.service';

@Component({
  selector: 'app-product-documentation-page',
  templateUrl: './product-documentation-page.component.html',
  styleUrls: ['./product-documentation-page.component.scss']
})
export class ProductDocumentationPageComponent {
  public isLoading: boolean = true;

  constructor(
    public productData: ProductDataService,
  ) {}

  public ngOnInit(): void {
    this.productData.isLoadingDataChange.subscribe(value => this.isLoading = value);
  }
}
