import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { ApiClient } from "../services/api-client.service";

@Injectable()
export class ComponentDataService {
  public isLoadingDataChange: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );

  public name!: string;

  public scarmRating!: number;
  public activityRating!: number;
  public contributorRating!: number;
  public codeQualityRating!: number;
  public cvss2Rating!: number;
  public cvss3Rating!: number;
  public vulnerabilityRating!: number;
  public languageCoverageRating!: number;

  public lastTrends: {
    scarm: number;
    activity: number;
    contributor: number;
    codeQuality: number;
    cvss2: number;
    cvss3: number;
    vulnerability: number;
    languageCoverage: number;
  } = {
    scarm: 0,
    activity: 0,
    contributor: 0,
    codeQuality: 0,
    cvss2: 0,
    cvss3: 0,
    vulnerability: 0,
    languageCoverage: 0,
  };

  public version!: string;

  public releaseDate!: Date | null;
  public creationDate!: Date | null;
  public lastActivityDate!: Date | null;

  public sourceUrl!: string | null;
  public owner!: string | null;
  public repoType!: string;
  public commitsCount!: number;
  public openIssuesCount!: number;
  public closedIssuesCount!: number;
  public totalLinesOfCode!: number;

  public isLiked?: boolean;

  public languages: {
    name: string;
    value: number;
  }[] = [];

  public cveList: {
    id: string;
    publicationDate: Date | null;
  }[] = [];

  public contributionsList: Record<string, number> = {};

  public contributionsCount!: number;

  constructor(
    private route: ActivatedRoute,
    private apiClient: ApiClient,
  ) {
    this.fetchData();
  }

  private async fetchData() {
    this.isLoadingDataChange.next(true);
    const { componentId } = await firstValueFrom(this.route.params);

    const {
      name,
      versions,
      dateCreated,
      lastContribution,
      codeUrl,
      owner,
      repoType,
      openIssuesCount,
      closedIssuesCount,
      isLiked,
    } = await this.apiClient.get<{
      closedIssuesCount: number;
      codeUrl: string;
      dateCreated: string;
      issuesCount: number;
      lastContribution: string;
      name: string;
      openIssuesCount: number;
      owner: string;
      repoType: string;
      starsCount: number;
      versions: {
        commitsCount: number;
        contributorsCount: number;
        contributionsCount: number;
        contributions: Record<string, number>;
        cves: {
          id: string;
          published: string | null;
        }[];
        datePublished: string;
        totalLinesOfCode: number;
        languages: {
          language: string;
          linesOfCode: number;
        }[];
        scores: {
          activity: number;
          codeQuality: number;
          contributor: number;
          cvss2: number;
          cvss3: number;
          scarm: number;
          vulnerability: number;
          languageCoverage: number;
        };
        lastTrends: {
          activity: number;
          codeQuality: number;
          contributor: number;
          cvss2: number;
          cvss3: number;
          scarm: number;
          vulnerability: number;
          languageCoverage: number;
        };
        version: string;
      }[];
      watchersCount: number;
      isLiked: boolean;
    }>(`/components/${componentId}`);

    this.name = name;
    this.owner = owner;
    this.repoType = repoType;
    this.sourceUrl = codeUrl;
    this.creationDate = dateCreated ? new Date(dateCreated) : null;
    this.lastActivityDate = lastContribution
      ? new Date(lastContribution)
      : null;
    this.commitsCount = versions[0].commitsCount;
    this.openIssuesCount = openIssuesCount;
    this.closedIssuesCount = closedIssuesCount;
    this.totalLinesOfCode = versions[0].totalLinesOfCode;
    this.isLiked = isLiked;

    const {
      scores,
      version,
      datePublished,
      languages,
      cves,
      contributions,
      contributionsCount,
      lastTrends,
    } = versions[0];

    const {
      activity,
      codeQuality,
      contributor,
      cvss2,
      cvss3,
      scarm,
      vulnerability,
      languageCoverage,
    } = scores;

    this.scarmRating = scarm;
    this.activityRating = activity;
    this.codeQualityRating = codeQuality;
    this.contributorRating = contributor;
    this.cvss2Rating = cvss2;
    this.cvss3Rating = cvss3;
    this.vulnerabilityRating = vulnerability;
    this.languageCoverageRating = languageCoverage;
    this.version = version;

    this.lastTrends = lastTrends;

    this.releaseDate = datePublished ? new Date(datePublished) : null;

    this.cveList = cves.map((cve) => ({
      id: cve.id,
      publicationDate: cve.published ? new Date(cve.published) : null,
    }));

    this.languages = languages
      .map((language) => ({
        name: language.language,
        value: language.linesOfCode,
      }))
      .sort((a, b) => b.value - a.value);

    this.contributionsList = contributions;
    this.contributionsCount = contributionsCount;
    this.isLoadingDataChange.next(false);
  }
}
