<div class="flex flex-column py-6 align-items-center px-4 md:px-0">
  <div class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="!isLoading && !hasError">
    <span class="font-bold text-6xl md:text-7xl text-center">
      {{ 'CLIENT.USER_DELETION.SUCCESS.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4 w-full md:w-auto">
      {{ 'CLIENT.USER_DELETION.SUCCESS.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-check-circle mt-6 md:mt-8 text-green-dark-hover text-7xl"></i>
  </div>

  <form class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="isLoading">
    <span class="font-bold text-6xl md:text-7xl">
      {{ 'CLIENT.USER_DELETION.PROCESSING.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4 mb-6 md:mb-8">
      {{ 'CLIENT.USER_DELETION.PROCESSING.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-spin pi-spinner text-grey-dark text-7xl"></i>
  </form>

  <div class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="!isLoading && hasError">
    <span class="font-bold text-6xl md:text-7xl">
      {{ 'CLIENT.USER_DELETION.ERROR.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4 w-full md:w-auto">
      {{ 'CLIENT.USER_DELETION.ERROR.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-times-circle mt-8 text-red-dark text-7xl"></i>
  </div>
</div>