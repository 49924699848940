import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEnvironmentType'
})
export class FilterEnvironmentTypePipe implements PipeTransform {

  transform(values: any[], type: string | undefined): any {
    if(!type) {
      return values;
    }

    return values.filter((value) => {
      return value.type == type;
    });
  }

}
