import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Deployment } from '../product-catalog-page/Entity';

@Component({
  selector: 'deployment-card',
  templateUrl: './deployment-card.component.html',
  styleUrls: ['./deployment-card.component.scss']
})
export class DeploymentCardComponent {
  @Input({
    required: true,
  })
  deployment!: Deployment;

  @Output()
  onLike: EventEmitter<MouseEvent> = new EventEmitter();

  public likeHandle(event: MouseEvent) {
    this.onLike.emit(event);
  }
}
