import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from "primeng/api";
import { EmailManagerService } from "../services/email-manager.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { SendEmailErrorMessage, SendEmailMessage } from "../messages";
import { UserManager } from '../services/user-manager.service';
import { TURNSTILE } from 'src/app/turnstile.provider';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit, AfterViewInit {

  formGroup!: FormGroup;

  isLoading: boolean = false;

  captchaToken!: FormControl<string | null>;
  queryParams: {
    topicId?: string,
    messageId?: string
  } = {} ;

  private botCheckId: string | undefined | null;

  constructor(
    private emailManager: EmailManagerService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private userManager: UserManager,
    @Inject(TURNSTILE)
    private turnstile: Promise<Turnstile.Turnstile>,
  ) {
     this.route.queryParamMap.subscribe((params:any) => {
       this.queryParams = params['params']
    });
  }

  private renderTurnstile(turnstile: Turnstile.Turnstile) {
    if(this.botCheckId) turnstile.remove(this.botCheckId);
    this.botCheckId = turnstile.render('#checkBot', {
      sitekey: environment.turnstileSiteKey,
      size: 'normal',
      theme: 'light',
      language: this.translate.currentLang,
      callback: (token) => {
        this.captchaToken.setValue(token);
      },
    });
  }

  public ngAfterViewInit() {
    this.turnstile.then((turnstile) => this.renderTurnstile(turnstile));
  }

  public async ngOnInit() {

    this.translate.onLangChange.subscribe(() => {
      this.turnstile.then((turnstile) => this.renderTurnstile(turnstile));
    });

    this.captchaToken = new FormControl('', [
      Validators.required,
    ]);

    const name = new FormControl('',
      Validators.required,
    );

    const email = new FormControl('', [
      Validators.required,
      Validators.email
    ]);

    this.userManager.getUserData().then(({ email: userEmail, firstName, lastName }) => {
      name.setValue(`${firstName ?? ''} ${lastName ?? ''}`.trim());
      email.setValue(userEmail);
    }).catch(() => {});

    const topic = new FormControl('', [ Validators.required, ]);
    const message = new FormControl('',[ Validators.required, ]);

    if (this.queryParams.topicId) {
        topic.setValue(this.translate.instant(this.queryParams.topicId));
    }
    if (this.queryParams.messageId) {
        message.setValue(this.translate.instant(this.queryParams.messageId));
    }

    this.formGroup = new FormGroup({
      name,
      email,
      topic,
      message,
      captcha: this.captchaToken,
    });
  }

  public async submit() {
    try {
      this.isLoading = true;
      const name = this.formGroup.get('name')?.value;
      const email = this.formGroup.get('email')?.value
      const topic = this.formGroup.get('topic')?.value
      const message = this.formGroup.get('message')?.value
      const token = this.captchaToken.value!;

      await this.emailManager.sendContactUs({
        name,
        email,
        topic,
        message,
        token,
      })

      this.router.navigate(['/']);
      const responseMessage = {
        severity: SendEmailMessage.severity,
        summary: this.translate.instant(SendEmailMessage.summary!),
        detail: this.translate.instant(SendEmailMessage.detail!),
      };
      this.messageService.add(responseMessage);

    } catch (err) {
        const responseMessage = {
          severity: SendEmailErrorMessage.severity,
          summary: this.translate.instant(SendEmailErrorMessage.summary!),
          detail: this.translate.instant(SendEmailErrorMessage.detail!),
        };
        this.messageService.add(responseMessage);
    } finally {
      this.isLoading = false;
    }
  }
}
