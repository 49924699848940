<!-- <div style="background-color: #DEEBFB" class="flex flex-row justify-content-center sticky top-0 z-5">
  <div class="container flex-grow-1 flex flex-row flex-wrap align-content-center">
    <a routerLink="documentation" pRipple class="font-bold p-4 flex align-items-center no-underline text-black-black-100">
        <img src="assets/document.svg" style="width: 16px; height: 16px;" alt="opis">
        <span class="ml-2">Dokumentacja</span>
    </a>
    <a routerLink="report" pRipple class="font-bold p-4 flex align-items-center no-underline text-black-black-100">
        <img src="assets/pie-chart.svg" style="width: 16px; height: 16px;" alt="opis">
        <span class="ml-2">Raport</span>
    </a>
  </div>
</div> -->

<div class="flex flex-row justify-content-center">
  <div class="container flex-grow-1 flex flex-column md:flex-row px-4 lg:px-0 align-content-center align-items-start gap-4 pt-4 md:pt-8 pb-8 mb-8 min-w-0">

    <div class="p-5 border-1 border-blue-light-hover border-round-lg sidebar hidden lg:flex flex-column flex-shrink-0 flex-wrap" *ngIf="isLoading">
      <p-skeleton styleClass="w-full h-12rem" class="w-full"></p-skeleton>
      <div class="flex flex-row justify-content-between mt-5">
        <div class="flex flex-column align-items-center gap-1">
          <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
          <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
        </div>
        <div class="flex flex-column align-items-center gap-1">
          <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
          <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
        </div>
        <div class="flex flex-column align-items-center gap-1">
          <p-skeleton styleClass="w-full" class="w-full"></p-skeleton>
          <p-skeleton shape="circle" size="5rem" styleClass=""></p-skeleton>
        </div>
      </div>

      <div class="mt-6">
        <div class="text-blue-darker font-semibold flex align-items-center gap-2">
          <p-skeleton size="1rem"></p-skeleton>
          <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium text-base">
          <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
        </div>
      </div>
      <div class="mt-6">
        <div class="text-blue-darker font-semibold flex align-items-center gap-2">
          <p-skeleton size="1rem"></p-skeleton>
          <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
          <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
        </div>
      </div>

      <div class="mt-6">
        <div class="text-blue-darker font-semibold flex align-items-center gap-2">
          <p-skeleton size="1rem"></p-skeleton>
          <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
        </div>
        <div class="flex mt-px-12">
          <p-skeleton styleClass="w-7 h-2rem" class="w-7 h-2rem"></p-skeleton>
        </div>
      </div>

      <div class="mt-6">
        <div class="text-blue-darker font-semibold flex align-items-center gap-2">
          <p-skeleton size="1rem"></p-skeleton>
          <p-skeleton styleClass="w-9" class="w-9"></p-skeleton>
        </div>
        <div class="mt-px-12 text-grey-dark font-medium">
          <p-skeleton styleClass="w-5" class="w-5"></p-skeleton>
        </div>
      </div>
    </div>

    <div class="p-5 border-1 border-blue-light-hover border-round-lg sidebar hidden lg:flex flex-column flex-shrink-0 flex-wrap" *ngIf="!isLoading">
      <div class="p-3 rating-general min-w-0 max-w-full overflow-x-hidden">
        <ng-template [ngTemplateOutlet]="mainRating"></ng-template>
      </div>
      <ng-template [ngTemplateOutlet]="sidebar"></ng-template>
    </div>

    <div class="flex flex-column min-w-0 max-w-full">
      <div class="flex flex-row align-items-center gap-3">
        <i class="icon-40 bg-blue-darker cursor-pointer" [ngClass]="{'icon-star': !data.isLiked, 'icon-star-full': data.isLiked}" (click)="likeComponent($event)"></i>
        <span class="font-bold text-6xl text-blue-darker">
          {{ data.name | translate }}
        </span>
      </div>

      <div class="flex flex-column lg:hidden p-3 border-round-lg bg-white mb-6 sm:w-6 min-w-0 overflow-x-hidden mt-4">
        <ng-template [ngTemplateOutlet]="mainRating"></ng-template>
      </div>

      <div class="flex flex-column mt-2 md:mt-7 gap-7">
        <div class="flex flex-column gap-5">
          <div class="flex flex-row justify-content-between align-items-center">
            <span class="text-blue-darker font-bold text-3xl overflow-hidden">
              {{ 'CLIENT.COMPONENT.REPORT.TITLE' | translate }}
            </span>
            <!--                <p-button styleClass="bg-transparent border-blue-normal gap-2">-->
            <!--                  <i class="icon-16 icon-download bg-blue-normal"></i>-->
            <!--                  <span class="font-bold text-sm text-blue-normal">Pobierz raport w PDF</span>-->
            <!--                </p-button>-->
          </div>
          <span class="text-grey-dark-hover w-8 overflow-hidden">
            {{ 'CLIENT.COMPONENT.REPORT.DESCRIPTION' | translate }}
          </span>
        </div>

        <div class="flex flex-column gap-5">
          <div class="flex flex-row align-items-center justify-content-between">
            <span class="font-semibold text-lg text-blue-darker">
              {{ 'CLIENT.COMPONENT.REPORT.SCORES_TABLE.TITLE' | translate }}
            </span>
            <help-button helpText="CLIENT.COMPONENT.REPORT.SCORES_TABLE.HELP_TEXT"></help-button>
          </div>
          <!--              <div class="flex flex-row justify-content-end gap-2">-->
          <!--                <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
          <!--                  <i class="icon-16 icon-export bg-blue-normal"></i>-->
          <!--                  <span class="font-bold text-sm text-blue-normal">Eksportuj</span>-->
          <!--                </p-button>-->
          <!--                <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
          <!--                  <i class="icon-16 icon-filter bg-blue-normal"></i>-->
          <!--                  <span class="font-bold text-sm text-blue-normal">Filtry</span>-->
          <!--                </p-button>-->
          <!--              </div>-->
          <p-skeleton styleClass="w-full mt-2" height="40rem" *ngIf="isLoading"></p-skeleton>

          <p-table [value]="[{ name: 'first' }]" dataKey="name" [expandedRowKeys]="{ first: true }" *ngIf="!isLoading">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm w-6">
                  <div class="flex flex-row align-items-center gap-2 overflow-hidden">
                    <i class="icon-16 icon-lines bg-black-black-100"></i>
                    <span>
                      {{ 'CLIENT.COMPONENT.REPORT.SCORES_TABLE.HEADER.COMPONENT_NAME' | translate }}
                    </span>
                  </div>
                </th>
                <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-4">
                  <div class="flex flex-row align-items-center gap-2 overflow-hidden">
                    <span>
                      {{ 'CLIENT.COMPONENT.REPORT.SCORES_TABLE.HEADER.SCORE' | translate }}
                    </span>
                  </div>
                </th>
                <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm w-2 overflow-hidden">
                  {{ 'CLIENT.COMPONENT.REPORT.SCORES_TABLE.HEADER.SEVERITY' | translate }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-component let-expanded="expanded">
              <tr [pRowToggler]="component" class="cursor-pointer">
                <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover">
                  <div class="flex flex-row gap-2 align-items-center flex-wrap ">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"></i>
                    <i class="icon-code icon-16 bg-black-black-100"></i>
                    <span class="font-bold">
                      {{ data.name }}
                    </span>
                  </div>
                </td>
                <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
                  <div class="flex flex-row gap-2">
                    <i class="icon-round-star icon-16 bg-black-black-100"></i>
                    <span>
                      {{ data.scarmRating * 10 | number: '1.1-1' }}
                    </span>
                  </div>
                </td>
                <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
                  <div
                    class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
                    [class.bg-severity-critical]="data.scarmRating * 10 >= 0 && data.scarmRating * 10 < 1"
                    [class.bg-severity-high]="data.scarmRating * 10 >= 1 && data.scarmRating * 10 < 3"
                    [class.bg-severity-medium]="data.scarmRating * 10 >= 3 && data.scarmRating * 10 < 6"
                    [class.bg-severity-low]="data.scarmRating * 10 >= 6 && data.scarmRating * 10 < 8"
                    [class.bg-severity-no-risk]="data.scarmRating * 10 >= 8 && data.scarmRating * 10 <= 10"
                  >
                    <span class="text-white text-nowrap">
                      {{ 'CLIENT.COMPONENT.REPORT.SCORES_TABLE.SEVERITY.' + setSeverity(data.scarmRating) | translate }}
                    </span>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-component>
              <ng-container *ngFor="let scoreName of scoresOrder;">
                <tr>
                  <td class="pl-6 border-x-1 border-bottom-1 border-blue-light-hover">
                    <div class="flex flex-row gap-2 align-items-center ">
                      <span class="text-sm font-bold">
                        {{ 'CLIENT.COMPONENT.REPORT.SCORES_TABLE.' + scoreName.toUpperCase() + '_RATING.NAME' | translate }}
                      </span>
                    </div>
                  </td>
                  <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover font-bold">
                    <div class="flex flex-row gap-2">
                      <i class="icon-round-star icon-16 bg-black-black-100"></i>
                      <span>
                        {{ scoresWithTrends[scoreName][0] * 10 | number: '1.1-1' }}
                      </span>
                    </div>
                  </td>
                  <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover font-bold">
                    <div class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
                         [class.bg-severity-critical]="scoresWithTrends[scoreName][0] * 10 >= 0 && scoresWithTrends[scoreName][0] * 10 < 1"
                         [class.bg-severity-high]="scoresWithTrends[scoreName][0] * 10 >= 1 && scoresWithTrends[scoreName][0] * 10 < 3"
                         [class.bg-severity-medium]="scoresWithTrends[scoreName][0] * 10 >= 3 && scoresWithTrends[scoreName][0] * 10 < 6"
                         [class.bg-severity-low]="scoresWithTrends[scoreName][0] * 10 >= 6 && scoresWithTrends[scoreName][0] * 10 < 8"
                         [class.bg-severity-no-risk]="scoresWithTrends[scoreName][0] * 10 >= 8 && scoresWithTrends[scoreName][0] * 10 <= 10"
                    >
                      <span class="text-white text-nowrap">
                        {{ 'CLIENT.COMPONENT.REPORT.SCORES_TABLE.SEVERITY.' + setSeverity(scoresWithTrends[scoreName][0]) | translate }}
                      </span>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </p-table>
        </div>

        <div class="flex flex-column gap-5">
          <div class="flex flex-row align-items-center justify-content-between">
            <span class="font-semibold text-lg text-blue-darker">
              {{ 'CLIENT.COMPONENT.REPORT.CVE_TABLE.TITLE' | translate }}
            </span>
            <help-button helpText="CLIENT.COMPONENT.REPORT.CVE_TABLE.HELP_TEXT"></help-button>
          </div>
          <!--              <div class="flex flex-row justify-content-end gap-2">-->
          <!--                <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
          <!--                  <i class="icon-16 icon-export bg-blue-normal"></i>-->
          <!--                  <span class="font-bold text-sm text-blue-normal">Eksportuj</span>-->
          <!--                </p-button>-->
          <!--                <p-button styleClass="bg-transparent border-blue-normal gap-2 border-none outline-none">-->
          <!--                  <i class="icon-16 icon-filter bg-blue-normal"></i>-->
          <!--                  <span class="font-bold text-sm text-blue-normal">Filtry</span>-->
          <!--                </p-button>-->
          <!--              </div>-->
          <p-skeleton styleClass="w-full" height="20rem" *ngIf="isLoading"></p-skeleton>

          <p-table [value]="data.cveList" rowGroupMode="subheader" groupRowsBy="type" *ngIf="!isLoading">
            <ng-template pTemplate="header">
              <tr class="bg-transparent">
                <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm">
                  <div class="flex flex-row align-items-center gap-2">
                    <i class="icon-16 icon-lines bg-black-black-100"></i>
                    <span>
                      {{ 'CLIENT.COMPONENT.REPORT.CVE_TABLE.HEAD.NAME' | translate }}
                    </span>
                  </div>
                </th>
                <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm">
                  <div class="flex flex-row align-items-center gap-2">
                    <i class="icon-16 icon-link bg-black-black-100"></i>
                    <span>
                      {{ 'CLIENT.COMPONENT.REPORT.CVE_TABLE.HEAD.REFERENCE' | translate }}
                    </span>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="100" class="border-x-1 border-blue-light-hover text-center">
                  {{ 'CLIENT.COMPONENT.REPORT.CVE_TABLE.BODY.EMPTY' | translate }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entry let-rowIndex="rowIndex">
              <tr>
                <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-black-black-100">
                  {{ entry.id }}
                </td>
                <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
                  <a href="{{ 'https://cve.mitre.org/cgi-bin/cvename.cgi?name=' + entry.id }}" target="_blank" class="no-underline text-black-black-100">cve.mitre.org</a>,
                  <a href="{{ 'https://www.cve.org/CVERecord?id=' + entry.id }}" target="_blank" class="no-underline text-black-black-100">cve.org</a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>


        <div class="flex flex-column gap-4">

          <div *ngIf="topContributors.length">
            <div class="flex flex-row align-items-center justify-content-between">
              <span class="font-semibold text-lg text-blue-darker">
                {{ 'CLIENT.COMPONENT.REPORT_SECTION.CONTRIBUTIONS_MAP.TITLE' | translate }}
              </span>
              <help-button helpText="CLIENT.COMPONENT.REPORT_SECTION.CONTRIBUTIONS_MAP.HELP_TEXT"></help-button>
            </div>
            <div class="my-5">
              <span class="text-grey-dark">
                {{ 'CLIENT.COMPONENT.REPORT_SECTION.CONTRIBUTIONS_MAP.DESCRIPTION' | translate }}
              </span>
            </div>
            <div>
              <app-contribution-map *ngIf="!isLoading" [contributions]="data.contributionsList">
              </app-contribution-map>
            </div>
          </div>

          <div class="flex flex-column gap-4 mt-4" *ngIf="data.commitsCount">
            <div class="flex flex-row align-items-center justify-content-between">
              <span class="font-semibold text-lg text-blue-darker">
                {{ 'CLIENT.COMPONENT.REPORT.PROJECT_ACTIVITY.DESCRIPTION' | translate }}
              </span>
              <help-button helpText="CLIENT.COMPONENT.REPORT.PROJECT_ACTIVITY.HELP_TEXT"></help-button>
            </div>

            <div class="flex flex-row bg-blue-light border-1 border-grey-normal border-round-lg">

              <div class="flex flex-column gap-2 w-4 p-3">
                <div class="flex flex-row gap-2 align-items-center">
                  <i class="icon-commits icon-16 bg-blue-darker"></i>
                  <span class="font-bold text-base text-blue-darker overflow-hidden">
                    {{ 'CLIENT.COMPONENT.REPORT.PROJECT_ACTIVITY.COMMITS_AMOUNT' | translate }}
                  </span>
                </div>
                <div class="pl-4 text-grey-normal-active font-medium">
                  {{ data.commitsCount }}
                </div>
              </div>

              <div class="flex flex-column gap-2 w-4 p-3" *ngIf="data.openIssuesCount">
                <div class="flex flex-row gap-2 align-items-center">
                  <i class="icon-commits icon-16 bg-blue-darker"></i>
                  <span class="font-bold text-base text-blue-darker overflow-hidden">
                    {{ 'CLIENT.COMPONENT.REPORT.PROJECT_ACTIVITY.OPEN_ISSUES_AMOUNT' | translate }}
                  </span>
                </div>
                <div class="pl-4 text-grey-normal-active font-medium">
                  {{ data.openIssuesCount }}
                </div>
              </div>

              <div class="flex flex-column gap-2 w-4 p-3" *ngIf="data.closedIssuesCount">
                <div class="flex flex-row gap-2 align-items-center">
                  <i class="icon-commits icon-16 bg-blue-darker"></i>
                  <span class="font-bold text-base text-blue-darker overflow-hidden">
                    {{ 'CLIENT.COMPONENT.REPORT.PROJECT_ACTIVITY.CLOSED_ISSUES_AMOUNT' | translate }}
                  </span>
                </div>
                <div class="pl-4 text-grey-normal-active font-medium">
                  {{ data.closedIssuesCount }}
                </div>
              </div>

            </div>
          </div>

          <div class="flex flex-column overflow-hidden gap-3 mt-4" *ngIf="topContributors.length">
            <div class="flex flex-row align-items-center justify-content-between">
              <span class="font-semibold text-lg text-blue-darker">
                {{ 'CLIENT.COMPONENT.REPORT.TOP_5_LOCATIONS.TITLE' | translate }}
              </span>
              <help-button helpText="CLIENT.COMPONENT.REPORT.TOP_5_LOCATIONS.HELP_TEXT"></help-button>
            </div>

            <div class="flex flex-column md:flex-row gap-4">
              <div class="flex w-full flex-grow-1" *ngIf="!isLoading">
                <p-table class="w-full" [value]="getObjectEntries(data.contributionsList) | slice:0:5">
                  <ng-template pTemplate="header">
                    <tr class="bg-transparent">
                      <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm">
                        <div class="flex flex-row gap-2">
                          <i class="icon-16 icon-location bg-black-black-100"></i>
                          <span>
                            {{ 'CLIENT.COMPONENT.REPORT_SECTION.TOP_5_LOCATIONS.TABLE.HEADER.NAME' | translate }}
                          </span>
                        </div>
                      </th>
                      <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm">
                        <div class="flex flex-row gap-2">
                          <i class="icon-16 icon-people bg-black-black-100"></i>
                          <span>
                            {{ 'CLIENT.COMPONENT.REPORT_SECTION.TOP_5_LOCATIONS.TABLE.HEADER.CONTRIBUTORS' | translate }}
                          </span>
                        </div>
                      </th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-entry let-rowIndex="rowIndex">
                    <tr>
                      <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-black-black-100">
                        {{ entry[0] }}
                      </td>
                      <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
                        <span class="text-black-black-100">{{ entry[1] / data.contributionsCount * 100 | number: '1.1-1' }}%</span>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <app-chart [inputData]="topContributors" [chartId]="'contributors'" class="flex flex-row flex-grow-1 md:w-9 h-19rem" *ngIf="!isLoading"></app-chart>
            </div>
          </div>

          <div class="flex flex-column overflow-hidden gap-3 mt-4" *ngIf="data.languages.length">
            <div class="flex flex-row align-items-center justify-content-between">
              <span class="font-semibold text-lg text-blue-darker">
                {{ 'CLIENT.COMPONENT.REPORT_SECTION.LANGUAGES.TITLE' | translate }}
              </span>
              <help-button helpText="CLIENT.COMPONENT.REPORT_SECTION.LANGUAGES.HELP_TEXT"></help-button>
            </div>

            <div class="flex flex-column md:flex-row gap-4">
              <div class="flex w-full flex-grow-1" *ngIf="!isLoading">
                <p-table class="w-full" [value]="data.languages">
                  <ng-template pTemplate="header">
                    <tr class="bg-transparent">
                      <th class="text-sm border-1 bg-grey-light border-blue-light-hover border-round-sm border-round-left-top-sm">
                        <div class="flex flex-row align-items-center gap-2">
                          <i class="icon-globe icon-16 bg-black-black-100"></i>
                          <span class="overflow-hidden">
                            {{ 'CLIENT.COMPONENT.REPORT_SECTION.LANGUAGES.TABLE.HEADER.NAME' | translate }}
                          </span>
                        </div>
                      </th>
                      <th class="text-sm border-y-1 border-right-1 bg-grey-light border-blue-light-hover border-round-right-top-sm">
                        <div class="flex flex-row align-items-center gap-2">
                          <i class="icon-lines icon-16 bg-black-black-100"></i>
                          <span class="overflow-hidden">
                            {{ 'CLIENT.COMPONENT.REPORT_SECTION.LANGUAGES.TABLE.HEADER.VALUE' | translate }}
                          </span>
                        </div>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-entry let-rowIndex="rowIndex">
                    <tr *ngIf="entry.value / data.totalLinesOfCode * 100 >= 1">
                      <td class="text-sm border-x-1 border-bottom-1 border-blue-light-hover text-black-black-100">
                        {{ entry.name }}
                      </td>
                      <td class="text-sm border-right-1 border-bottom-1 border-blue-light-hover">
                        <span class="no-underline text-black-black-100">{{ entry.value / data.totalLinesOfCode * 100 | number: '1.0-0' }}% ({{entry.value}} LoC)</span>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <app-chart [inputData]="topProgrammingLangs" [chartId]="'languages'" class="flex flex-row flex-grow-1 md:w-9 h-19rem" *ngIf="!isLoading"></app-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mainRating>
  <p class="text-base font-bold flex align-items-center text-blue-darker m-0">
    <img src="assets/round_star.svg" />
    <span class="pl-2 overflow-hidden">
      {{ 'CLIENT.COMPONENT.SIDEBAR.GENERAL_RATING' | translate }}
    </span>
  </p>
  <div class="mt-3 flex flex-row align-items-center lg:justify-content-evenly gap-2">
    <app-score-circle
      helpText="CLIENT.COMPONENT.SIDEBAR.GENERAL_RATING.HELP_TEXT"
      [score] = "data.scarmRating"
      [trend] = "data.lastTrends.scarm"
    ></app-score-circle>
    <div *ngIf="(data.scarmRating * 10) >= 6"
       class="border-round-lg px-3 py-2 justify-content-center flex flex-row"
       [class.bg-severity-low]="(data.scarmRating * 10) >= 6 && (data.scarmRating * 10) < 8"
       [class.bg-severity-no-risk]="(data.scarmRating * 10) >= 8 && (data.scarmRating * 10) < 10"
    >
      <span class="text-white" *ngIf="(data.scarmRating * 10) >= 6 && (data.scarmRating * 10) < 8">
        {{ 'CLIENT.COMPONENT.SIDEBAR.GENERAL_RATING.SEVERITY.LOW' | translate }}
      </span>
      <span class="text-white" *ngIf="(data.scarmRating * 10) >= 8 && (data.scarmRating * 10) < 10">
        {{ 'CLIENT.COMPONENT.SIDEBAR.GENERAL_RATING.SEVERITY.SAFE' | translate }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #sidebar>
  <div class="flex flex-row justify-content-evenly mt-5 flex-wrap align-items-center">
    <ng-container *ngFor="let scoreName of scoresOrder; index as i;">
      <div *ngIf="i < 3; else showMore" class="flex flex-column align-items-center">
        <span class="font-medium text-xs text-grey-dark-hover overflow-hidden">
          {{ 'CLIENT.COMPONENT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING' | translate }}
        </span>
        <app-score-circle
          [helpText]="'CLIENT.COMPONENT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING.HELP_TEXT'"
          [score] = "scoresWithTrends[scoreName][0]"
          [trend] = "scoresWithTrends[scoreName][1]"
          [radius]="32"
          [outerStrokeColor]="'#BBD6F7'"
          [outerStrokeGradientStopColor]="'#78ADED'"
        >
        </app-score-circle>
      </div>
      <ng-template #showMore>
        <div class="flex flex-column align-items-center" style="margin: 0 auto;" *ngIf="displayAllScores">
          <span class="font-medium text-xs text-grey-dark-hover overflow-hidden">
            {{ 'CLIENT.COMPONENT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING' | translate }}
          </span>
          <app-score-circle
            [helpText]="'CLIENT.COMPONENT.SIDEBAR.'+ scoreName.toUpperCase() +'_RATING.HELP_TEXT'"
            [score] = "scoresWithTrends[scoreName][0]"
            [trend] = "scoresWithTrends[scoreName][1]"
            [radius]="32"
            [outerStrokeColor]="'#BBD6F7'"
            [outerStrokeGradientStopColor]="'#78ADED'"
          >
          </app-score-circle>
        </div>
      </ng-template>
    </ng-container>
    </div>

    <div *ngIf="!displayAllScores && numberOfPositiveScores > 3">
      <button class="show-more-btn font-bold text-sm text-grey-dark" (click)="showAllScores()" pRipple>
        {{ 'CLIENT.COMPONENT.SIDEBAR.SHOW_MORE' | translate }} ({{numberOfPositiveScores-3}})
      </button>
    </div>


  <!-- <div class="mt-6">
      <div class="text-blue-darker font-semibold flex align-items-center">
          <img src="assets/folder-tree.svg" alt="">
          <span class="pl-2 ">Wersja produktu</span>
      </div>
      <div class="mt-px-12 text-grey-dark font-medium text-base">
          <span>
            {{ data.version }}
          </span>
      </div>
  </div> -->
  <!-- <div class="mt-5" *ngIf="data.releaseDate">
    <div class="text-blue-darker font-semibold flex align-items-center">
      <img src="assets/calendar-line.svg" alt="">
      <span class="pl-2 overflow-hidden">
        {{ 'CLIENT.COMPONENT.SIDEBAR.RELEASE_DATE' | translate }}
      </span>
    </div>
    <div class="mt-px-12 text-grey-dark font-medium">
      <span>{{ data.releaseDate | date : 'dd.MM.yyyy' }}</span>
    </div>
  </div>  -->
  <div class="mt-5" *ngIf="data.creationDate">
    <div class="text-blue-darker font-semibold flex align-items-center">
      <img src="assets/calendar-line.svg" alt="">
      <span class="pl-2 overflow-hidden">
        {{ 'CLIENT.COMPONENT.SIDEBAR.CREATION_DATE' | translate }}
      </span>
    </div>
    <div class="mt-px-12 text-grey-dark font-medium">
      <span>{{ data.creationDate | date : 'dd.MM.yyyy'}}</span>
    </div>
  </div>
  <div class="mt-5" *ngIf="data.lastActivityDate">
    <div class="text-blue-darker font-semibold flex align-items-center">
      <img src="assets/calendar-today.svg" alt="">
      <span class="pl-2 overflow-hidden">
        {{ 'CLIENT.COMPONENT.SIDEBAR.LAST_ACTIVITY_DATE' | translate}}
      </span>
    </div>
    <div class="mt-px-12 text-grey-dark font-medium">
      <span>{{ data.lastActivityDate | date : 'dd.MM.yyyy' }}</span>
    </div>
  </div>
  <div class="mt-6" *ngIf="data.sourceUrl">
    <div class="text-blue-darker font-semibold flex align-items-center">
      <img src="assets/branch.svg" alt="">
      <span class="pl-2 overflow-hidden">
        {{ 'CLIENT.COMPONENT.SIDEBAR.SOURCE' | translate }}
      </span>
    </div>
    <div class="flex mt-px-12">
      <a [href]="data.sourceUrl" class="source-link border-grey-normal border-1 text-grey-dark" target="_blank">
        <ng-container *ngIf="data.repoType === 'github'">
          <i class="pi pi-github text-grey-darker"></i>
          <span class="font-normal">Github</span>
        </ng-container>
      </a>
    </div>
  </div>
  <div class="mt-6" *ngIf="data.owner">
    <div class="text-blue-darker font-semibold flex align-items-center">
      <img src="assets/briefcase.svg" alt="">
      <span class="pl-2 overflow-hidden">
        {{ 'CLIENT.COMPONENT.SIDEBAR.MAINTAINER' | translate }}
      </span>
    </div>
    <div class="mt-px-12 text-grey-dark font-medium">
      <span>
        {{ data.owner }}
      </span>
    </div>
  </div>
</ng-template>
