import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-solutions-ezdrp-page',
  templateUrl: './solutions-ezdrp-page.component.html',
  styleUrls: ['./solutions-ezdrp-page.component.scss'],
})
export class SolutionsEzdrpPageComponent implements AfterViewInit {
  public ezdrpId = environment.ezdrpId;
  public ezdrpTagId = environment.ezdrpTagId;
  public ezdrpSaasId = environment.ezdrpSaasId;
  public subscriptionIsOnDemand: boolean = true;

  @ViewChild('postScroller')
  public postScroller!: ElementRef<HTMLDivElement>;
  public currentPagePostScroller: number = 0;

  @ViewChild('subscriptionScroller')
  public subscriptionScroller!: ElementRef<HTMLDivElement>;
  public currentPageSubscriptionScroller: number = 0;

  constructor(public translator: TranslateService) {}

  hasTranslation(id: string) {
    const translation = this.translator.instant(id);
    return translation !== id && translation !== '';
  }

  numSequence(n: number) {
    return Array(n);
  }

  promoDotClickHandle(index: number) {
    const elementWidth = this.postScroller.nativeElement.children[0].clientWidth;
    this.postScroller.nativeElement.scrollTo({
      behavior: 'smooth',
      left: (elementWidth * index) + (26 * index),
    })
  }

  subscriptionsDotClickHandle(index: number) {
    const elementWidth = this.subscriptionScroller.nativeElement.children[0].clientWidth;
    this.subscriptionScroller.nativeElement.scrollTo({
      behavior: 'smooth',
      left: (elementWidth * index) + (26 * index),
    })
  }

  private calculateCurrentElementOfSubscriptionScroller() {
    const items = this.subscriptionScroller.nativeElement.querySelectorAll('.slide');
    this.currentPageSubscriptionScroller = Math.round(this.subscriptionScroller.nativeElement.scrollLeft/items[0].clientWidth);
  }

  private calculateCurrentElementOfPostScroller() {
    const items = this.postScroller.nativeElement.querySelectorAll('.slide');
    this.currentPagePostScroller = Math.round(this.postScroller.nativeElement.scrollLeft/items[0].clientWidth);
  }

  public ngAfterViewInit(): void {
    fromEvent(this.subscriptionScroller.nativeElement, 'scroll').pipe(debounceTime(100)).subscribe(() => this.calculateCurrentElementOfSubscriptionScroller());
    this.subscriptionScroller.nativeElement.addEventListener('resize', (ev: Event) => this.calculateCurrentElementOfSubscriptionScroller());

    fromEvent(this.postScroller.nativeElement, 'scroll').pipe(debounceTime(100)).subscribe(() => this.calculateCurrentElementOfPostScroller());
    this.postScroller.nativeElement.addEventListener('resize', (ev: Event) => this.calculateCurrentElementOfPostScroller());
  }


}
