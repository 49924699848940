<div class="flex flex-row justify-content-center py-3 md:py-6">
  <div class="container flex-grow-1 flex flex-column md:flex-row gap-4 align-content-center align-items-start gap-3 min-w-0">
    <div class="flex flex-row min-w-0 max-w-full px-4 md:px-0 overflow-x-auto">
      <div class="flex flex-row md:flex-column bg-white md:px-4 py-2 md:py-5 gap-4 w-full md:w-22rem md:min-w-22rem border-round-2xl md:border-round-lg border-1 border-blue-light-hover min-w-max">
        <a routerLink="address" class="px-4 py-3 flex flex-row no-underline gap-2 align-items-center">
          <div class="icon-16 icon-address" [class.bg-gray-500]="this.router.url !== '/profile/address'" [class.bg-blue-normal]="this.router.url === '/profile/address'"></div>
          <span class="no-underline text-base font-semibold white-space-nowrap" [class.text-gray-900]="this.router.url !== '/profile/address'" [class.text-blue-normal]="this.router.url === '/profile/address'">
            {{ 'CLIENT.PROFILE.SIDEBAR.PROFILE_DETAILS' | translate }}
          </span>
        </a>
        <!-- <a routerLink="subscription" class="px-4 py-3 flex flex-row no-underline gap-2 align-items-center">
          <div class="icon-16 icon-subscription" [class.bg-gray-500]="this.router.url !== '/profile/subscription'" [class.bg-blue-normal]="this.router.url === '/profile/subscription'"></div>
          <span class="no-underline text-base font-semibold" [class.text-gray-900]="this.router.url !== '/profile/subscription'" [class.text-blue-normal]="this.router.url === '/profile/subscription'">
            {{ 'CLIENT.PROFILE.SIDEBAR.SUBSCRIPTIONS' | translate }}
          </span>
        </a> -->
        <a routerLink="support" class="px-4 py-3 flex flex-row no-underline gap-2 align-items-center">
          <div class="icon-16 icon-support" [class.bg-gray-500]="this.router.url !== '/profile/support'" [class.bg-blue-normal]="this.router.url === '/profile/support'"></div>
          <span class="no-underline text-base font-semibold white-space-nowrap" [class.text-gray-900]="this.router.url !== '/profile/support'" [class.text-blue-normal]="this.router.url === '/profile/support'">
            {{ 'CLIENT.PROFILE.SIDEBAR.ASK_EXPERT' | translate }}
          </span>
        </a>
        <a routerLink="favorite" class="px-4 py-3 flex flex-row no-underline gap-2 align-items-center">
          <div class="icon-16 icon-star" [class.bg-gray-500]="this.router.url !== '/profile/favorite'" [class.bg-blue-normal]="this.router.url === '/profile/favorite'"></div>
          <span class="text-base font-semibold white-space-nowrap" [class.text-gray-900]="this.router.url !== '/profile/favorite'" [class.text-blue-normal]="this.router.url === '/profile/favorite'">
            {{ 'CLIENT.PROFILE.SIDEBAR.FAVORITES' | translate }}
          </span>
        </a>
        <a routerLink="account" class="px-4 py-3 flex flex-row no-underline gap-2 align-items-center">
          <div class="icon-16 icon-similar" [class.bg-gray-500]="this.router.url !== '/profile/account'" [class.bg-blue-normal]="this.router.url === '/profile/account'"></div>
          <span class="no-underline text-base font-semibold white-space-nowrap" [class.text-gray-900]="this.router.url !== '/profile/account'" [class.text-blue-normal]="this.router.url === '/profile/account'">
            {{ 'CLIENT.PROFILE.SIDEBAR.ACCOUNT' | translate }}
          </span>
        </a>
        <a routerLink="my-projects" class="px-4 py-3 flex flex-row no-underline gap-2 align-items-center">
          <div class="icon-16 icon-folder-tree" [class.bg-gray-500]="this.router.url !== '/profile/my-projects'" [class.bg-blue-normal]="this.router.url === '/profile/my-projects'"></div>
          <span class="no-underline text-base font-semibold white-space-nowrap" [class.text-gray-900]="this.router.url !== '/profile/my-projects'" [class.text-blue-normal]="this.router.url === '/profile/my-projects'">
            {{ 'CLIENT.PROFILE.SIDEBAR.MY_PROJECTS' | translate }}
          </span>
        </a>
        <button class="bg-white border-none text-left px-4 py-3 m-0 cursor-pointer flex flex-row align-items-center flex-nowrap gap-2" (click)="logout()">
          <i class="pi pi-sign-out text-gray-500"></i>
          <span class="text-gray-900 no-underline text-base font-semibold white-space-nowrap">
            {{ 'CLIENT.PROFILE.SIDEBAR.LOGOUT' | translate }}
          </span>
        </button>
      </div>
    </div>
    <div class="flex flex-row px-4 md:px-0 w-full">
      <!-- <div class="flex flex-column flex-grow-1 bg-white border-round-lg px-4 py-6 md:p-6 border-1 border-blue-light-hover flex-grow-1 w-full md:w-auto"> -->
      <div class="flex flex-column w-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
