<div class="relative" #hoverElement>
  <circle-progress
   [percent]="score * 100"
   title="{{ score * 10 | number: '1.1-1'}}"
   [radius] = "radius"
   [innerStrokeWidth]="innerStrokeWidth"
   [outerStrokeWidth]="outerStrokeWidth"
   [space] = "space"
   [titleFontSize]="titleFontSize.toString()"
   [outerStrokeColor]="outerStrokeColor ?? '#2079E2'"
   [outerStrokeGradientStopColor]="outerStrokeGradientStopColor ?? '#134988'"
  >
  </circle-progress>

  <div class="absolute" [style.top.px]="trendTop" [style.right.px]="trendRight">
    <img *ngIf="trend > 0" src="assets/trending-up.svg" [style.width.px]="titleFontSize"/>
    <img *ngIf="trend === 0 || trend === null" src="assets/trending-neutral.svg" [style.width.px]="titleFontSize"/>
    <img *ngIf="trend < 0" src="assets/trending-down.svg" [style.width.px]="titleFontSize"/>
  </div>

</div>
<p-overlayPanel #op *ngIf="helpText" [style]="{'max-width': '320px', 'text-wrap': 'wrap', 'word-wrap': 'break-word'}">
  {{ helpText | translate }}
</p-overlayPanel>
