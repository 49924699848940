<div class="flex flex-column py-6 align-items-center px-4 md:px-0">
  <div class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="!isLoading && !errored && formSent">
    <span class="font-bold text-6xl md:text-7xl text-center">
      {{ 'CLIENT.ACTIVATE_PASSWORD.SUCCESS.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4 w-full md:w-auto">
      {{ 'CLIENT.ACTIVATE_PASSWORD.SUCCESS.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-check-circle mt-6 md:mt-8 text-green-dark-hover text-7xl"></i>
  </div>

  <form class="container w-full flex flex-column gap-4 h-30rem align-items-center justify-content-center" *ngIf="!isLoading && !formSent" [formGroup]="formGroup" (ngSubmit)="submit()">
    <span class="font-bold text-6xl md:text-7xl">
      {{ 'CLIENT.ACTIVATE_PASSWORD.INPUT.TITLE' | translate }}
    </span>
    <div class="flex flex-column md:w-5 w-full">
      <p-password [feedback]="false" class="pt-4" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.ACTIVATE_PASSWORD.INPUT.FORM.PASSWORD' | translate }}" autocomplete="new-password" formControlName="password"></p-password>
      <p-password [feedback]="false" class="pt-4" inputStyleClass="w-full bg-blue-light w-full border-blue-light-hover text-grey" styleClass="w-full" placeholder="{{ 'CLIENT.ACTIVATE_PASSWORD.INPUT.FORM.COFIRM_PASSWORD' | translate }}" autocomplete="new-password" formControlName="confirmPassword"></p-password>
      <div class="flex flex-row w-full mt-4 justify-content-end">
        <p-button label="{{ 'CLIENT.ACTIVATE_PASSWORD.INPUT.FORM.SUBMIT' | translate }}" class="w-4" styleClass="w-full" type="submit" [disabled]="formGroup.invalid"></p-button>
      </div>
    </div>
  </form>

  <form class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="isLoading && formSent">
    <span class="font-bold text-6xl md:text-7xl">
      {{ 'CLIENT.ACTIVATE_PASSWORD.PROCESSING.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4 mb-6 md:mb-8">
      {{ 'CLIENT.ACTIVATE_PASSWORD.PROCESSING.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-spin pi-spinner text-grey-dark text-7xl"></i>
  </form>

  <div class="container w-full flex flex-column h-30rem align-items-center justify-content-center" *ngIf="!isLoading && errored && formSent">
    <span class="font-bold text-6xl md:text-7xl">
      {{ 'CLIENT.ACTIVATE_PASSWORD.ERROR.TITLE' | translate }}
    </span>
    <span class="text-grey-dark pt-4 w-full md:w-auto">
      {{ 'CLIENT.ACTIVATE_PASSWORD.ERROR.DESCRIPTION' | translate }}
    </span>
    <i class="pi pi-times-circle mt-8 text-red-dark text-7xl"></i>
  </div>
</div>