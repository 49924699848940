import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent {
  constructor(public router: Router, private sessionService: SessionService) {}

  public async logout() {
    await this.sessionService.logout()
    await this.router.navigate(['/']);
  }
}
