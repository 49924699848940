<div class="flex flex-column gap-5">
  <div class="flex flex-row gap-3 align-items-center">
    <i class="icon-subscription icon-24 bg-blue-darker"></i>
    <span class="font-semibold text-3xl">
      Twoja subskrypcja
    </span>
  </div>
  <div class="flex flex-row gap-3 align-items-start">
    <!-- Free -->
    <div class="flex flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-4 gap-4">
      <div class="flex flex-column gap-6">
        <div class="flex flex-column gap-5">
          <div class="flex flex-column gap-2">
            <span class="font-semibold text-xl text-center text-grey-dark">plan</span>
            <span class="font-bold text-3xl text-center text-blue-darker">Darmowy</span>
          </div>
          <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center">
            <span class="text-6xl font-bold">0 zł</span>
            <span class="text-xl pl-3 font-semibold"> / miesiąc</span>
          </div>
        </div>
        <div class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Wsparcie community</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Podstawowy ranking</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-minus icon-24 bg-grey-normal-active"></i>
            <span class="text-grey-normal-active">Rozbudowane raporty</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-minus icon-24 bg-grey-normal-active"></i>
            <span class="text-grey-normal-active">Dostęp do forum</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-minus icon-24 bg-grey-normal-active"></i>
            <span class="text-grey-normal-active">Dedykowany opiekun</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-minus icon-24 bg-grey-normal-active"></i>
            <span class="text-grey-normal-active">Raporty na życzenie</span>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <a href="#" class="no-underline px-3 py-2 font-semibold text-blue-normal text-sm">Więcej</a>
      </div>
    </div>

    <!-- Standard -->
    <!-- <div class="flex flex-column border-4 border-blue-dark selected-shadow p-4 bg-blue-light border-round-2xl w-4 gap-4 py-4 mt-6"> -->
    <div class="flex flex-column border-4 border-blue-dark selected-shadow p-4 bg-blue-light border-round-2xl w-4 gap-4 py-6">
      <div class="flex flex-column gap-6">
        <div class="flex flex-column gap-5">
          <div class="flex flex-column gap-2">
            <span class="font-semibold text-xl text-center text-grey-dark">plan</span>
            <span class="font-bold text-3xl text-center text-blue-darker">Darmowy</span>
          </div>
          <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center">
            <span class="text-6xl font-bold">99 zł</span>
            <span class="text-xl pl-3 font-semibold"> / miesiąc</span>
          </div>
        </div>
        <div class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Wsparcie community</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Podstawowy ranking</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Rozbudowane raporty</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Dostęp do forum</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-minus icon-24 bg-grey-normal-active"></i>
            <span class="font-normal text-grey-normal-active">Dedykowany opiekun</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-minus icon-24 bg-grey-normal-active"></i>
            <span class="font-normal text-grey-normal-active">Raporty na życzenie</span>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <a href="#" class="no-underline px-3 py-2 font-semibold text-blue-normal text-sm">Więcej</a>
      </div>
    </div>

    <!-- Business -->
    <div class="flex flex-column border-1 border-grey-normal p-4 bg-blue-light border-round-2xl w-4 gap-4">
      <div class="flex flex-column gap-6">
        <div class="flex flex-column gap-5">
          <div class="flex flex-column gap-2">
            <span class="font-semibold text-xl text-center text-grey-dark">plan</span>
            <span class="font-bold text-3xl text-center text-blue-darker">Darmowy</span>
          </div>
          <div class="flex flex-row text-blue-normal-active align-items-end justify-content-center">
            <span class="text-6xl font-bold">199 zł</span>
            <span class="text-xl pl-3 font-semibold"> / miesiąc</span>
          </div>
        </div>
        <div class="flex flex-column text-blue-darker text-base font-normal gap-3 align-self-center">
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Wsparcie community</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Podstawowy ranking</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Rozbudowane raporty</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Dostęp do forum</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Dedykowany opiekun</span>
          </div>
          <div class="flex flex-row gap-3 align-items-center">
            <i class="icon-check icon-24 bg-blue-normal"></i>
            <span class="font-normal text-blue-darker">Raporty na życzenie</span>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <a href="#" class="no-underline px-3 py-2 font-semibold text-blue-normal text-sm">Więcej</a>
      </div>
    </div>
  
  </div>
</div>